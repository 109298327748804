import { Axios } from "../util/request"
import { API } from "../util/api";
import { getLangType } from '../util/function';
import intl from 'react-intl-universal';


// 商家登录
export const login = (data) => {
    return Axios.ajax({
        url: API.auth.LOGIN,
        method: "post",
        data
    })
}


// 商家登录
export const loginempartner = (data) => {
    return Axios.ajax({
        url: API.auth.LOGINEMPARTNER,
        method: "post",
        data
    })
}





// 管理员登录
export const AdminLogin = (data) => {
    return Axios.ajax({
        url: API.auth.partnerAdminLogin,
        method: "post",
        data
    })
}

// 商家注册
export const register = (data) => {
    return Axios.ajax({
        url: API.auth.PARTNER,
        method: "post",
        data
    })
}

// 更新token
export const PutToken = (data) => {
    return Axios.ajax({
        url: API.auth.LOGIN,
        method: "put"
    })
}

//获取商家信息
export const getPartner = () => {
    return Axios.ajax({
        url: `${API.auth.partnerAddress}&request_language_id=${getLangType()}&include=partnerAddress,region,style,package,partnerFoodLicense,salesArea,salesAreaUnion&appends=site_region`,
    })
}
//获取用户性质
export const getIsSuper = () => {
    return Axios.ajax({
        url: "isSuper",
    })
}

// 修改商家信息
export const UpdateShopMessage = (data) => {
    return Axios.ajax({
        url: API.auth.PARTNER + API.auth.UPDATE,
        method: "post",
        tips: intl.get("update.success"),
        data
    })
}

// 获取可提现金额
export const GetWalletMoney = () => {
    return Axios.ajax({
        url: `${API.wallet.wallet}${API.wallet.money}?request_language_id=${getLangType()}`,
        method: "get",
    })
}

// 提现
export const WalletWithdraw = (data) => {
    return Axios.ajax({
        url: API.wallet.wallet + API.wallet.withdraw,
        method: "post",
        tips: "提现成功",
        data
    })
}

// 获取提现记录
export const GetWithdrawLog = () => {
    return Axios.ajax({
        url: `${API.withdrawLog.withdrawLog}?request_language_id=${getLangType()}`,
        method: "get",
    })
}

// 获取提现详情
export const GetWithdrawDetail = (id) => {
    return Axios.ajax({
        url: `${API.withdrawLog.withdrawLog}/${id}?request_language_id=${getLangType()}`,
        method: "get",
    })
}


// 获取用户退款记录
export const GetUserRefund = (url, page) => {
    return Axios.ajax({
        url: API.withdrawLog.userWithdrawLog + "?is_order_refund=1" + url + "&page=" + page,
        method: "get",
    })
}


// 获取用户提现详情
export const GetUserWithdrawDetail = (id) => {
    return Axios.ajax({
        url: API.withdrawLog.userWithdrawLog + "/" + id,
        method: "get",
    })
}



// 用户提现处理
export const DisposeUserWithdraw = (data, id) => {
    return Axios.ajax({
        url: API.withdrawLog.userWithdrawLog + API.withdrawLog.dispose + "/" + id,
        method: "post",
        tips: "处理成功",
        data
    })
}




// 获取用户退款申请记录
export const GetUserOrderRefund = (url, page) => {
    return Axios.ajax({
        url: API.UserOrderRefundLog.UserOrderRefundLog + "?is_order_refund=1" + url + "&page=" + page,
        method: "get",
    })
}


// 获取用户退款申请详情
export const GetUserOrderRefundDetail = (id) => {
    return Axios.ajax({
        url: API.UserOrderRefundLog.UserOrderRefundLog + "/" + id,
        method: "get",
    })
}


// 用户提现处理
export const DisposeUserOrderRefund = (data, id) => {
    return Axios.ajax({
        url: API.UserOrderRefundLog.UserOrderRefundLog + API.UserOrderRefundLog.dispose + "/" + id,
        method: "post",
        tips: "处理成功",
        data
    })
}








// 新建座位
export const AddSeat = (data) => {
    return Axios.ajax({
        url: API.partnerSeat,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取座位列表
export const GetSeatList = () => {
    return Axios.ajax({
        url: `${API.partnerSeat}?request_language_id=${getLangType()}`,
        method: "get"
    })
}


// 获取图片
export const GetSeatImage = (url) => {
    return Axios.ajax({
        url: url,
        method: "get"
    })
}
// 修改座位
export const putSeat = (data, id) => {
    return Axios.ajax({
        url: API.partnerSeat + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除座位
export const deleteSeat = (id) => {
    return Axios.ajax({
        url: API.partnerSeat + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

// 新建运费
export const AddFreight = (data) => {
    return Axios.ajax({
        url: API.partnerDeliveryFree,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取运费列表
export const GetFreightList = () => {
    return Axios.ajax({
        url: `${API.partnerDeliveryFree}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改运费
export const putFreight = (data, id) => {
    return Axios.ajax({
        url: API.partnerDeliveryFree + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除运费
export const deleteFreight = (id) => {
    return Axios.ajax({
        url: API.partnerDeliveryFree + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}


// 获取用户列表
export const GetUserList = (num, url) => {
    return Axios.ajax({
        url: `${API.user}${API.page}${num}${url}&request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 修改用户
export const putUser = (data, id) => {
    return Axios.ajax({
        url: API.user + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}


// 自动更新用户等级id
export const userUpdateUserLevel = (data, id) => {
    return Axios.ajax({
        url: API.userUpdateUserLevel + "/" + id,
        method: "post",
        tips: "更新成功",
        data,
    })
}

// 自动更新用户等级id
export const userUpdateUserLevelNature = (data, id) => {
    return Axios.ajax({
        url: API.userUpdateUserLevelNature + "/" + id,
        method: "post",
        tips: "更新成功",
        data,
    })
}


// 自动清理过期
export const userAutoUpdateUserScore = (data, id) => {
    return Axios.ajax({
        url: API.userAutoUpdateUserScore + "/" + id,
        method: "post",
        tips: "清理成功",
        data,
    })
}







// 查询地址
export const QueryAddress = (key) => {
    return Axios.ajax({
        url: `${API.queryAddress}${key}&request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 皮肤列表
export const StyleList = (key) => {
    return Axios.ajax({
        url: API.style,
        method: "get"
    })
}

// 新建送餐区域
export const AddDeliveryAddress = (data) => {
    return Axios.ajax({
        url: API.partnerDeliveryAddress,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取送餐区域
export const GetDeliveryAddressList = () => {
    return Axios.ajax({
        url: `${API.partnerDeliveryAddress}?request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 修改送餐区域
export const putDeliveryAddress = (data, id) => {
    return Axios.ajax({
        url: API.partnerDeliveryAddress + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除送餐区域
export const deleteDeliveryAddress = (id) => {
    return Axios.ajax({
        url: API.partnerDeliveryAddress + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}



// 获取红包列表
export const GetRedPackageList = () => {
    return Axios.ajax({
        url: `${API.redPackageIndex}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 获取活动详情
export const GetPrizeActivityList = (id) => {
    return Axios.ajax({
        url: `${API.prizeActivity}${id}&request_language_id=${getLangType()}`,
        method: "get"
    })
}



// 新建管理员
export const AddAdmin = (data) => {
    return Axios.ajax({
        url: API.partnerAdmin,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取管理员列表
export const GetAdminList = () => {
    return Axios.ajax({
        url: `${API.partnerAdmin}?request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 通过编号获取管理员详情
export const GetAdminUniqueDetail = (id) => {
    return Axios.ajax({
        url: `${API.partnerAdminUniqueShow}${id}&request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 获取管理员详情
export const GetAdminDetail = (id) => {
    return Axios.ajax({
        url: `${API.partnerAdmin}/${id}&request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 修改管理员
export const putAdmin = (data, id) => {
    return Axios.ajax({
        url: API.partnerAdmin + "/" + id,
        method: "post",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除管理员
export const deleteAdmin = (id) => {
    return Axios.ajax({
        url: API.partnerAdmin + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

//语言列表
export const getLangList = () => {
    return Axios.ajax({
        url: API.partnerLangue,
        method: "get"
    })
}

export const editLang = (data) => {
    return Axios.ajax({
        url: "defaultLanguage",
        method: "post",
        tips: intl.get("update.success"),
        data
    })
}


export const getDefaultLang = () => {
    return Axios.ajax({
        url: "defaultLanguage",
        method: "get"
    })
}


export const editUse = (id, data) => {
    return Axios.ajax({
        url: "partnerLangue/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

export const QuerySubscrption = () => {
    return Axios.ajax({
        url: "partnerSubscription?include=partner,partnerOrder,prod,package,addon",
        method: "get"
    })
}

// export const submitPayAgain=(id)=>{
//     return Axios.ajax({
//         url:"partnerOrder?include=partner,partnerOrder,prod,package,addon&partner_subscription_id="+id,
//         method:"get"
//     })
// }
export const submitPayAgain = (id, is_trial) => {
    return Axios.ajax({
        url: "partnerOrder",
        method: "post",
        tips: "操作成功",
        data: {
            include: 'partner,partnerOrder,prod,package,addon',
            partner_subscription_id: id,
            is_trial
        },
    })
}

export const mySubscrDetail = (id) => {
    return Axios.ajax({
        url: `partnerSubscription/${id}`,
        method: "get"
    })
}

export const QueryOrders = () => {
    return Axios.ajax({
        url: "partnerOrder?include=partner,partnerSubscription.prod",
        method: "get"
    })
}

export const myOrderDetail = (id) => {
    return Axios.ajax({
        url: `partnerOrder/${id}`,
        method: "get"
    })
}

export const submitPay = (id) => {
    return Axios.ajax({
        url: `/partnerOrder/${id}/pay`,
        method: "post",
        tips: "操作成功",
        data: {
            include: 'partner,partnerOrder,prod,package,addon',
        },
    })
}


export const QueryStatistics = () => {
    return Axios.ajax({
        url: "data",
        method: "get"
    })
}


// 设置营业时间
export const setBusinesshours = (data) => {
    return Axios.ajax({
        url: API.businessHours,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}
// 公共假期列表
export const getHolidayList = (url) => {
    return Axios.ajax({
        url: API.holiday + url,
        method: "get",
    })
}


// 公共假期详情
export const getHolidayDetail = (id) => {
    return Axios.ajax({
        url: API.holiday + "/" + id,
        method: "get",
    })
}

// 新建公共假期
export const AddHoliday = (data) => {
    return Axios.ajax({
        url: API.holiday,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 编辑公共假期
export const putHoliday = (data, id) => {
    return Axios.ajax({
        url: API.holiday + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除公共假期
export const deleteHoliday = (id) => {
    return Axios.ajax({
        url: API.holiday + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}
// 假期营业列表
export const getHolidayBusinessList = () => {
    return Axios.ajax({
        url: API.holidayBusiness,
        method: "get",
    })
}


// 假期营业详情
export const getHolidayBusinessDetail = (id) => {
    return Axios.ajax({
        url: API.holidayBusiness + "/" + id,
        method: "get",
    })
}

// 新建假期营业
export const AddHolidayBusiness = (data) => {
    return Axios.ajax({
        url: API.holidayBusiness,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 编辑假期营业
export const putHolidayBusiness = (data, id) => {
    return Axios.ajax({
        url: API.holidayBusiness + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data,
    })
}

// 删除假期营业
export const deleteHolidayBusiness = (id) => {
    return Axios.ajax({
        url: API.holidayBusiness + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

//获取用户详情
export const getUserDetail = (id) => {
    return Axios.ajax({
        url: `/user/${id}?include=partner`,
        method: "get",
    })
}


// 获取用户反馈列表
export const getContactUsList = (page) => {
    return Axios.ajax({
        url: API.userContact.contactUs + "?page=" + page,
        method: "get",
    })
}


//修改用户反馈
export const UpdateContactUs = (data, id) => {
    return Axios.ajax({
        url: API.userContact.contactUs + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}



export const GetOrderListE = (page, id) => {
    return Axios.ajax({
        url: `/order?include=user,orderSpu&user_id=${id}&page=${page}`,
        method: "get"
    })
}


// 获取系统设置
export const GetConfigSet = () => {
    return Axios.ajax({
        url: API.config,
        method: "get",
    })
}
// 获取当前站点国家
export const GetSiteCountry = () => {
    return Axios.ajax({
        url: API.getConfig,
        method: "get",
    })
}


// 获取文档权限
export const getConfigDocument = () => {
    return Axios.ajax({
        url: API.getConfigDocument,
        method: "get",
    })
}






// 获取费用流水
export const GetPartnerMonthlyFeet = (page, url, date) => {
    return Axios.ajax({
        url: API.partnerPackageFeeLog + "?page=" + page + url + "&created_at=" + date,
        method: "get",
    })
}



//获取操作日志 
export const getOperationLogList = (page) => {
    return Axios.ajax({
        url: API.operationLog + "?page=" + page,
        method: "get",
    })
}



// 获取结算清单
export const GetPartnerStatementList = (page, url, date) => {
    return Axios.ajax({
        url: API.partnerPackageStatementLog + "?page=" + page + url + "&created_at=" + date,
        method: "get",
    })
}


// 获取结算清单详情
export const GetPartnerStatementDetail = (id) => {
    return Axios.ajax({
        url: API.partnerPackageStatementLog + "/" + id ,
        //url: `${API.BASEURL}${API.order.order}/${id}${API.order.include}&request_language_id=${getLangType()}`,
        method: "get",
    })
}







// stripe 支付
export const stripeCreate = (data) => {
    return Axios.ajax({
        url: API.stripeCreate  ,
        method: "post",
        data
    })
}


// 生成订单
export const createStatement = (data) => {
    return Axios.ajax({
        url: API.createStatement  ,
        method: "post",
        data
    })
}


 //获取商家银行卡信息
export const getPartnerCard = () => {
    return Axios.ajax({
        url: API.partnerCard  , 
        method: "get",
    })
} 

//删除商家银行卡信息
export const deletePartnerCard = () => {
    return Axios.ajax({
        url: API.partnerCard + '/clear' , 
        method: "post",
        tips: "删除成功",
         
    })
}




///////////////////////////////////////////////////////////////////////////
//临时code

// 获取插件列表
export const getPlugInList = () => {
    return Axios.ajax({
        url: API.plugin,
        method: "get"
    })
}

// 获取插件详情
export const getPlugInDeatil = (id) => {
    return Axios.ajax({
        url: API.plugin + "/" + id,
        method: "get"
    })
}

// 修改系统设置
export const UpdateConfigSet = (data) => {
    return Axios.ajax({
        url: API.setConfigs,
        method: "post",
        tips: "修改成功",
        data
    })
}

//修改插件
export const UpdatePlugIn = (data, id) => {
    return Axios.ajax({
        url: API.plugin + "/" + id,
        method: "put",
        tips: "修改成功",
        data
    })
}

export const delPlugIn = (id) => {
    return Axios.ajax({
        url: API.plugin + "/" + id,
        method: "delete",
        tips: "删除成功",
    })
}
export const addPlugIn = (data) => {
    return Axios.ajax({
        url: API.plugin,
        method: "post",
        tips: "提交成功",
        data
    })
}





// 获取用户积分
export const getUserPointsList = (id) => {
    return Axios.ajax({
        url: API.points.userPointsList+"?id="+id,
        method: "get"
    })
}



// 获取商家等级变更列表
export const getUserPointsExchangeOptionList = () => {
    return Axios.ajax({
        url: API.points.partnerPointsExchangeOption,
        method: "get"
    })
}

// 获取商家等级变更列表
export const getUserPointsExchangeOptionDeletedList = () => {
    return Axios.ajax({
        url: API.points.partnerPointsExchangeOption+"?is_deleted=1",
        method: "get"
    })
}


// 添加商家积分兑换项
export const recoverPointsExchangeOption = (id) => {
    return Axios.ajax({
        url: API.points.partnerPointsExchangeOptionRecover+"?id="+id,
        method: "get",
        tips: "恢复成功", 
    })
}





// 添加商家积分兑换项
export const addPointsExchangeOption = (data) => {
    return Axios.ajax({
        url: API.points.partnerPointsExchangeOption,
        method: "post",
        tips: "提交成功",
        data
    })
}

 
// 添加商家积分兑换项
export const putPointsExchangeOption = (data,id) => {
    return Axios.ajax({
        url: API.points.partnerPointsExchangeOption+"/"+id,
        method: "put",
        tips: "提交成功",
        data
    })
}


// 删除商家积分兑换项
export const delPointsExchangeOption = (id) => {
    return Axios.ajax({
        url: API.points.partnerPointsExchangeOption+"/"+id,
        method: "delete",
        tips: "归档成功",
        
    })
}



///////////////////////////////////////////////////



 // 获取用户成长值列表
export const getMemberGroupValueList = (id) => {
    return Axios.ajax({
        url: API.member.memberGroupValueList+"?id="+id,
        method: "get"
    })
}


// 获取用户等级变更列表
export const getMemberLevelList = (id) => {
    return Axios.ajax({
        url: API.member.memberLevelList+"?id="+id,
        method: "get"
    })
}



// 获取商家等级变更列表
export const getPartnerMemberLevelSetListList = () => {
    return Axios.ajax({
        url: API.member.partnerMemberLevelSetList,
        method: "get"
    })
}



// 修改商家会员等级
export const putPartnerMemberLevelSet = (data) => {
    return Axios.ajax({
        url: API.member.partnerMemberLevelSet,
        method: "post",
        tips: "提交成功",
        data
    })
}


// 获取商家等级 
export const getPartnerMemberLevel  = (param="") => {
    return Axios.ajax({
        url: API.member.partnerMemberLevel + param,
        method: "get"
    })
}



