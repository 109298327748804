import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch,Popconfirm } from 'antd';
import { getPrinterDetail, UpdatePrinter } from "../../../action/goodAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")



class EditPrinter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: "",
            is_auto_print: true
        }
    }

    componentDidMount() {
        const id = this.props.location.pathname.substring(13)
        getPrinterDetail(id).then(res => {
            this.setState({
                data: res.data,
                is_auto_print: res.data.is_auto_print === 1
            })
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.is_auto_print = this.state.is_auto_print ? "1" : "0"
                this.SubmitData(values)
            }

        });
    };

    async SubmitData(data) {
        const id = this.props.location.pathname.substring(13)
        await UpdatePrinter(id, data).then(res => {
            this.props.history.push("/PrinterList")
        })

    }

    render() {
        let { data, is_auto_print } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };



        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('nav.setting.printerType')}
                >
                    <Input disabled value={data && data.printerCategory.model} />
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.setting.printerDefaultName')}
                >
                    {getFieldDecorator('name', {
                        initialValue: data && data.name
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('pack.form.price')}
                >
                    <span>{currency_symbol}{data && data.printerCategory.price}</span>
                </Form.Item>
                {/* <Form.Item
                    label={intl.get('nav.setting.printerSn')}
                >
                    <Input disabled value={data && data.sn} />
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.setting.printerSecretKey')}
                >
                    <Input disabled value={data && data.key} />
                </Form.Item> */}
                <Form.Item
                    label={intl.get('nav.setting.automaticPrinting')}
                >
                    <Switch
                        checked={is_auto_print}
                        onChange={() => {
                            this.setState({
                                is_auto_print: !is_auto_print
                            })
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label={intl.get('order.table.remark')}
                >
                    {getFieldDecorator('remark', {
                        initialValue: data && data.remark
                    })(<Input />)}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(EditPrinter))
