import React from 'react'
import { withRouter } from 'react-router-dom'
import { Descriptions, Card, Divider } from 'antd';
import { mySubscrDetail } from "../../action/authAction";
import intl from 'react-intl-universal';


class SubscriptionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: {} };
    }

    async componentDidMount() {
        const id = this.props.location.search.substring(1);
        if (id) {
            let res = await mySubscrDetail(id);
            this.setState({ data: res.data })
        }

    }


    render() {
        const { data } = this.state;

        return (
            <Card>
                <Descriptions title={intl.get('subscription.info.base')}>
                    <Descriptions.Item label="id">{data?.id}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.price')}>${data?.price}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.status')}>{data?.status === 0 ? intl.get('subscription.status.zero') : data?.status === 1 ? intl.get('subscription.status.one') : ''}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.type')}>{data?.prod_type}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.serviceType')}>{
                        data?.service_type === 'setup' ? intl.get('subscription.serviceType.s') :
                            data?.service_type === 'year' ? intl.get('subscription.serviceType.y') :
                                data?.service_type === 'month' ? intl.get('subscription.serviceType.m') : ''
                    }</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.crateTime')}>{data?.created_at?.date}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.startTime')}>{data?.service_start_at}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.endTime')}>{data?.service_end_at}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.expire')}>{data?.is_expire === 1 ? intl.get('subscription.expire.one') : intl.get('subscription.expire.zero')}</Descriptions.Item>
                </Descriptions>
                <Divider />
                {data?.prod_type === 'package' && <Descriptions title={intl.get('subscription.info.package')}>
                    <Descriptions.Item label={intl.get('package.name')}>{data?.prod?.name}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.rate')}>{data?.prod?.rate}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.fee')}>{data?.prod?.order_fixed_fee}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.set')}>{data?.prod?.setup_fee}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.open')}>{data?.prod?.is_valid === true ? intl.get('package.hasOpen') : intl.get('package.toOpen')}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.service')}>{data?.prod?.service_fee}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.service.status')}>{data?.prod?.service_is_valid === true ? intl.get('plugin.hasSub') : intl.get('plugin.toSub')}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.month.fix')}>{data?.prod?.monthly_fixed_fee}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.month')}>{data?.prod?.monthly_rate_fee}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.days')}>{data?.prod?.trial_period_days}天</Descriptions.Item>
                    <Descriptions.Item label={intl.get('package.month.status')}>{data?.prod?.monthly_is_valid === true ? intl.get('plugin.hasSub') : intl.get('plugin.toSub')}</Descriptions.Item>
                </Descriptions>}
                {data?.prod_type === 'addon' && <Descriptions title={intl.get('subscription.info.addon')}>
                    <Descriptions.Item label={intl.get('plugin.name')}>{data?.prod?.name}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('plugin.client')}>{data?.prod?.client}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('plugin.route')}>{data?.prod?.route}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('plugin.price')}>${data?.prod?.price}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('plugin.status')}>{data?.prod?.status === true ? intl.get('plugin.hasSub') : intl.get('plugin.toSub')}</Descriptions.Item>
                </Descriptions>}
                <Divider />
            </Card>

        );
    }
}

export default withRouter(SubscriptionDetail)

