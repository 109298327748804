import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone';
import { Table, Popconfirm, Form, Button, Switch , Modal,Tooltip, message, Input,Icon} from 'antd';
import { getIsDeleteSpuGoodsList, deleteRealDestroySpuGoods, RecoverSpuGoods } from "../../action/goodAction";
import intl from 'react-intl-universal';
import eatmoonLogo from "../../img/eatmoon.png"


let currency_symbol = sessionStorage.getItem("currency_symbol")
class IsDeleteGoodList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            page: 1,
            closed: "" ,
            Visible: false,
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods.goodsImg"),
                width: '5%',
                align: "center",
                render: (record) => {
                    return <img src={record.image} style={{ width: "80px", height: "80px", objectFit: "contain" }} />
                }
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods_name"),
                width: '10%',
                align: "center",
                render: (record) => {
                    

                    // if (record.spuManyHelp && record.spuManyHelp.state === 1) {
                    //     return <span><span style={{ color: "#ff0000" }}>[{intl.get("nav.goodsManagement.cutgoodsType")}]</span>{record.name}</span>
                    // }
                    // return <span>{record.name}</span>

                    return <div style={{ display: "flex", alignItems: "center" }}>

                        
                        {

                             record.permanent_state === 1 &&  
                             <span><Icon type="stop" theme="filled" style={{ marginRight: "5px",color:"#ff5722" }} /></span>
                            

                        }

 
                        {record.spuManyHelp && record.spuManyHelp.state === 1 &&

                            <span style={{ color: "#ff0000" }}>[{intl.get("nav.goodsManagement.cutgoodsType")}]</span>
                        }


                        {
                            record.operation_type === 0 &&
                            <Icon type="shop" style={{ marginRight: "5px" }} />
                        }


                        {
                            record.operation_type === 1 &&
                            <img src={eatmoonLogo} alt="" style={{ width: "20px", height: "20px",margin:"5px" }} />
                        }



                        <span>{record.name}</span>


                     </div>




                }
            },
            {
                title: intl.get('orders.price'),
                width: '8%',
                render: (record) => {
                    let price = currency_symbol + record._spu.price
                    let discount_price = currency_symbol + record._spu.discount_price
                    let discount_on_fixed
                    if (record.discount_type === "fixed") {
                        discount_on_fixed = currency_symbol + record.discount_on_fixed
                    } else {
                        discount_on_fixed = (parseFloat(record.discount_on_percentage) * 100).toFixed(0) + "%"
                    }
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ marginBottom: "5px" }}>{intl.get("nav.goodsManagement.promotionalgoods.price")}:{price}</span>
                        <span style={{ marginBottom: "5px" }}>{intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed')}:{discount_on_fixed}</span>
                        <span>{intl.get('nav.goodsManagement.promotionalgoods.discount_price')}:{discount_price}</span>
                    </div>
                }
            },
            {
                title: intl.get('nav.goodsManagement.promotionalgoods.stock'),
                width: '6%',
                render: (record) => {
                    let stock = record.stock
                    let sale_quantity = record.sale_quantity
                    let total_stock = record.total_stock
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ marginBottom: "5px" }}>{intl.get("nav.goodsManagement.promotionalgoods.sale_quantity")}:{sale_quantity}</span>
                        <span style={{ marginBottom: "5px" }}>{intl.get('nav.goodsManagement.promotionalgoods.Remaining_inventory')}:{stock}</span>
                        <span>{intl.get('nav.goodsManagement.promotionalgoods.total_inventory')}:{total_stock}</span>
                    </div>
                }
            },
            {
                title: intl.get("home.table.service"),
                dataIndex: 'activity_service_rate',
                width: '4%',
                align: "center",
                render: (record) => {
                    return Number(record) * 100 + "%"
                }
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods.during_the_event"),
                // dataIndex: "created_at",
                width: '6%',
                align: "center",
                render: (record) => {
                    let start_at = record.start_at
                    let end_at = record.end_at
                    let end_at_year = end_at.split(" ")
                    let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
                    let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
                    let isRed = end_at_time < curDate
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{start_at.substring(0, start_at.length - 8)}</span>
                        <span>~</span>
                        <span style={{ color: isRed ? "#ff0000" : "" }}>{end_at.substring(0, end_at.length - 8)}</span>
                    </div>
                }
            },
            {
                title: intl.get('nav.orderManagement.list'),
                width: '4%',
                align: "center",
                render: (record) => {
                    return <Button
                        type="link"
                        onClick={() => {
                            this.props.history.push("/OrderList/" + record.id)
                        }}
                    >{intl.get("home.table.operation.btn.view")}
                    </Button>
                }
            },

            /*  
            {
                title: intl.get('subscription.status'), //状态
                width: '4%',
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            {
                                record.end_status ?
                                    <span style={{ color: "#ff0000" }}>{intl.get("nav.goodsManagement.promotionalgoods.end")}</span> :
                                    <span style={{ color: "green" }}>{intl.get("nav.goodsManagement.promotionalgoods.NormalSales")}</span>
                            }
                        </div>
                    )
                }
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods.isBuy"), //系统已购
                width: '5%',
                align: "center",
                render: (record) => {
                    return record.is_already_buy === 1 ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>
                }
            },*/



            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>


                            {  
                                record.is_delete === 0 &&
                                <Tooltip title={intl.get('orders.status.three')}>
                                    <Button
                                        //shape="circle"
                                        //icon="arrow-down"
                                        onClick={() => {
                                            this.setState({
                                                Visible: true,
                                                ItemData: record
                                            })
                                        }}
                                    >

                                     {intl.get('home.table.operation.btn.delete')}
                                    </Button>
                                </Tooltip>
                            }



                            {/* 
                                //record.sale_quantity === 0 &&

                                record.is_delete === 0 &&
                                <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                    <Button>
                                        {intl.get('home.table.operation.btn.delete')}
                                    </Button>
                                </Popconfirm> 
                            */}




                            {
                                 record.is_delete === 1 && <span style={{ color: "red" }}>已删除</span>
                            }


                            { record.is_delete == 0 &&
                                <Popconfirm title={intl.get('home.table.operation.btn.recoverHorder')} onConfirm={() => this.handleSpuRecover(record)}>
                                    <Button>
                                        {intl.get('home.table.operation.btn.recover')}
                                    </Button>
                                </Popconfirm>

                            }   


                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        this.GetgoodData(1)
    }

    async GetgoodData(num) {
        let res = await getIsDeleteSpuGoodsList(num)
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    async handleDelete(data) {
        await deleteRealDestroySpuGoods({ "spu_discount_id": data.id })
        
        this.GetgoodData(1);
        //const dataSource = [...this.state.data];
        //this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    handleSpuRecover(data) {
        RecoverSpuGoods(data.id).then(res => {
            this.GetgoodData(1)
        })

    }


    render() {
        const { data, meta ,closed ,Visible} = this.state
        if (data) {
            return (
                <div>
                    <Table
                        rowKey={record => record.id}
                        // bordered
                        dataSource={data}
                        columns={this.columns}
                        pagination={{
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(e) => {
                            this.setState({
                                page: e.current
                            }, () => this.GetgoodData(e.current))

                        }}
                        className="waperBox"
                    />


                    <Modal
                        title="删除"
                        visible={this.state.Visible}
                        onOk={() => {
                            if (closed === "delete") {
                                 
                                this.setState({
                                    Visible: false,
                                });                
                                this.handleDelete(this.state.ItemData)



                            } else {
                                message.error(intl.get("nav.goodsManagement.promotionalgoods.InputError"))
                            }
                        }}
                        onCancel={() => this.setState({ Visible: false, closed: "" })}
                        style={{ marginTop: "100px" }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <span style={{ marginBottom: "15px" }}>请在输入框中输入"delete"确认</span>
                            <Input
                                style={{ width: "300px", textAlign: 'center' }}
                                value={closed}
                                onChange={(e) => {
                                    e.persist()
                                    this.setState({
                                        closed: e.target.value.toLowerCase()
                                    })
                                }} />
                        </div>

                    </Modal>




                </div>

            );
        } else {
            return (<div />)
        }

    }
}

export default withRouter(Form.create()(IsDeleteGoodList))

