import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon,Select } from 'antd';
import { getPartner, UpdateShopMessage, GetConfigSet } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class Behavior extends React.Component {

    state = {
        min_pay_fee_on_online: "",
        is_charge_packing_fee: false,
        business_status: true,
        is_allday_order: true,
        is_partner_moon_enable: true,
        is_member_level_change:false,
        is_partner_gst: true,
        is_open_recommend: true,
        is_user_address: true,
        is_force_user_address: true,
        is_holiday_business: true,
        is_open_share: true,
        is_open_spu_many_help: false,
        order_expire: "",
        activity_order_expire: "",
        is_audit:false,
        is_open_moon_enable:false,
        is_open_gst:false,
        unpay_wait_time:0,
        member_level_change_duration:0,
        TimeData:[
                
 

                {   'id':6,
                    'name':'6个月'
                },

                {   'id':12,
                    'name':'12个月'
                }, 

                ]


    };

    async componentDidMount() {
        let res = await getPartner()
        let configData = await GetConfigSet()
        let data = res.data
        this.setState({
            min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
            // is_charge_packing_fee: data.is_charge_packing_fee === "1" ? true : false,
            business_status: data.business_status == 1 ? true : false,
            is_allday_order: data.is_allday_order == 1 ? true : false,
            is_partner_moon_enable: data.is_partner_moon_enable == 1 ? true : false,
            is_member_level_change: data.is_member_level_change == 1 ? true : false,
            is_partner_gst: data.is_partner_gst == 1 ? true : false,
            is_open_recommend: data.is_open_recommend == 1 ? true : false,
            // is_user_address: data.is_user_address == 1 ? true : false,
            // is_force_user_address: data.is_force_user_address == 1 ? true : false,
            is_holiday_business: data.is_holiday_business == 1 ? true : false,
            is_open_share: data.is_open_share == 1 ? true : false,
            is_authorization_code: data.is_authorization_code == 1 ? true : false,
            is_audit: data.is_audit == 1 ? true : false,
            is_open_moon_enable: data.is_open_moon_enable == 1 ? true : false,
            is_open_gst:data.is_open_gst == 1 ? true:false,
            is_discount: data.is_discount == 1 ? true : false,
            order_expire: data.order_expire || 30,
            activity_order_expire: data.activity_order_expire || 30,
            is_open_spu_many_help: data.is_open_spu_many_help == 1 ? true : false,
            many_help_order_expire: data.many_help_order_expire || 1,
            unpay_wait_time:data.unpay_wait_time,
            member_level_change_duration:data.member_level_change_duration,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let {member_level_change_duration,is_member_level_change,unpay_wait_time, business_status, is_partner_gst,is_partner_moon_enable, is_allday_order,is_open_recommend, is_holiday_business, is_open_share } = this.state
        let data = {} 
        data.is_member_level_change = is_member_level_change ? 1 : "0" 
        data.member_level_change_duration = this.props.form.getFieldValue('member_level_change_duration') 
        UpdateShopMessage(data)
    };

    check_order_expire(rule, value, callback) {
        if (value > 180 || value < 15) {
            callback(new Error(intl.get("setup.form.order_expire_tip")));
        } else {
            callback();
        }
    }

    activity_order_expire(rule, value, callback) {
        if (value > 180 || value < 15) {
            callback(new Error(intl.get("setup.form.order_expire_tip")));
        } else {
            callback();
        }
    }

    many_help_order_expire(rule, value, callback) {
        if (value > 90 || value < 1) {
            callback(new Error(intl.get("setup.form.activity_order_expire_tip")));
        } else {
            callback();
        }
    }

    render() {
        const {member_level_change_duration, is_member_level_change,TimeData, is_open_gst, is_open_moon_enable,is_audit,min_pay_fee_on_online,is_allday_order,is_partner_moon_enable, is_partner_gst,business_status, is_open_recommend, is_authorization_code, is_discount, is_holiday_business, is_open_share, order_expire, activity_order_expire, is_open_spu_many_help, many_help_order_expire } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
               
                

            

                {  
                    <Card style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>会员动态成长设置</div>
                        <Form.Item label={<span>开启等级动态成长</span>}>
                            <Switch checked={is_member_level_change} onChange={() => {
                                this.setState({
                                    is_member_level_change: !is_member_level_change
                                })
                            }} />
                        </Form.Item>


                        { is_member_level_change && 
                                <Form.Item
                                label={<span>等级动态成长周期</span>}
                            >
                                {getFieldDecorator('member_level_change_duration', {
                                    rules: [{ required: false}],
                                    initialValue:  member_level_change_duration
                                })(
                                    <Select
                                        onChange={(e) => {
                                            //this.handleGetPackageData(e)
                                        }}
                                    >
                                        {TimeData && TimeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                                    </Select>
                                )}
                            </Form.Item>    
                        }
                        




                    </Card>
                }



               


 
 

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
