import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button,Popconfirm } from 'antd';
import { AddPackCharge } from "../../action/goodAction";
import intl from 'react-intl-universal';

class NewPackCkarge extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (values.name) {
                    values.charge_type = 1
                    AddPackCharge(values)
                }
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('pack.form.name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: intl.get('pack.form.name.pleasehorder'), whitespace: true }],
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={intl.get('pack.form.price')}
                >
                    {getFieldDecorator('price', {
                        rules: [{ required: true, message: intl.get('seat.table.please') }],
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('pack.form.seq')}
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewPackCkarge))
