import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon } from 'antd';
import { getPartner, UpdateShopMessage } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class GoodsPlug extends React.Component {

    state = {
         
        is_user_points: false,
        is_user_points_money:false,
         
    };

    async componentDidMount() {
        let res = await getPartner()
        let data = res.data
        this.setState({  
            is_user_points_money: data.is_user_points_money == 1 ? true : false,
            is_user_points: data.is_user_points == 1 ? true : false,  
             
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let {   is_user_points_money} = this.state
        let data = {} 
        data.is_user_points_money = is_user_points_money ? 1 : "0"
        UpdateShopMessage(data)
    };

    render() {
        const { is_user_points_money,is_user_points  } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                 


                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>积分策略</div>
                    <Form.Item label={<span>积分抵钱</span>}>
                        <Switch 
                            checked={is_user_points_money}
                            disabled ={!is_user_points}
                            onChange={() => {
                                this.setState({
                                    is_user_points_money: !is_user_points_money
                                })
                            }} />
                    </Form.Item>
                </Card>

 


                {is_user_points && <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item> }

            </Form >

        );
    }
}

export default withRouter(Form.create()(GoodsPlug))
