import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form, Switch } from 'antd';
import { getSelectedGoodsList, deleteGoods, putGoods } from "../../action/goodAction";
import intl from 'react-intl-universal';

class GoodList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: ""
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('goods.list.img'),
                width: '10%',
                align: "center",
                render: (res) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={res.image} alt="" />
                    )
                }
            },
            {
                title: intl.get('goods.list.name'),
                dataIndex: 'name',
                width: '15%',
                align: "center",
            },

            {
                title: intl.get('ad.form.cate'),
                dataIndex: ["category", "name"],
                width: '10%',
                align: "center",
            }, {
                title: intl.get('goods.list.price'),
                dataIndex: 'price',
                width: '8%',
                align: "center",
            },
            {
                title: intl.get('goods.list.sort'),
                dataIndex: 'sort',
                width: '10%',
                align: "center",
            },
            // {
            //     title: intl.get('goods.list.isOk'),
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.state === 1} onChange={() => this.onChange(record, 'state')} />
            //         )
            //     }
            // },
            {
                title: intl.get('goods.list.selected'),
                width: '10%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch disabled={record.state === 0} checked={record.recommend === 1} onChange={() => this.onChange(record)} />
                    )
                }
            },
            // {
            //     title: intl.get('seat.table.operation'),
            //     dataIndex: 'operation',
            //     width: '25%',
            //     align: "center",
            //     render: (text, record) => {
            //         return (
            //             <div className='deleteView'>
            //                 <Button
            //                     // size="small"
            //                     onClick={() => {
            //                         this.props.history.push("/EditGood/" + record.id)
            //                     }}>
            //                     {intl.get('seat.table.btn.edit')}
            //                 </Button>
            //                 <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
            //                     <Button>
            //                         {intl.get('home.table.operation.btn.delete')}
            //                     </Button>
            //                 </Popconfirm>
            //             </div>

            //         );
            //     },
            // },

        ];
    }

    async componentDidMount() {
        this.GetgoodData(1)
    }

    async GetgoodData(num) {
        let res = await getSelectedGoodsList(num)
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    async handleDelete(data) {
        await deleteGoods(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }


    onChange = (record) => {
        putGoods({ "recommend": record.recommend === 1 ? "0" : "1" }, record.id).then(res => {
            this.GetgoodData(1)
        })
    }
    render() {
        const { data, meta } = this.state
        if (data) {
            return (
                <div>
                    <Table
                        rowKey={record => record.id}
                        // bordered
                        dataSource={data}
                        columns={this.columns}
                        pagination={{
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(e) => {
                            this.GetgoodData(e.current)
                        }}
                        className="waperBox"
                    />
                    <p style={{ margin: "15px 0px", color: "#ff0000" }}>{intl.get("goods.list.selected.maxselect")}</p>
                </div>
            );
        } else {
            return (<div />)
        }

    }
}

export default withRouter(Form.create()(GoodList))

