import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, InputNumber, Form, Button } from 'antd';
import { GetUserOrderRefund } from "../../action/authAction";

class UserWithdrawLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], url: "", meta: "",consumption_type :null ,url_2:"",url_1:""};
        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },


             {
                title: '订单号',
                dataIndex: 'order.order_sn',
                width: '10%',
                align: "center",
            },

            {
                title: '订单类型',
                //dataIndex: 'order.consumption_type',
                width: '10%',
                align: "center",
                render: (record) => {

                    return record.order.consumption_type == 1  ? "配送":"到店";
                     
                }


            },
             
            {
                title: '用户Email',
                dataIndex: 'user.email',
                width: '10%',
                align: "center",
            },

            {
                title: '用户手机',
                dataIndex: 'user.phone',
                width: '10%',
                align: "center",
            },

            {
                title: '退款金额',
                dataIndex: 'money',
                width: '10%',
                align: "center",
                render: (record) => {
                    return "$" + record
                }
            },
             
            {
                title: '退款原因', 
                width: '10%',
                align: "center",
                render: (record) => {

                    switch(record.reason_type){
                        case 1 : return "无时间消费"; break; 
                        case 2 : return "天气原因" ;break; 
                        case 3 : return "无理由" ;break; 
                        case 4 : return "其他" ;break; 
                        default: return "其他";break;
                    }
                     
                }
            },

            {
                title: '处理结果',
                dataIndex: 'sort',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            {
                                record.is_finish === 1 ?
                                    <span style={{ color: "green" }}>已退款</span> :
                                    <div>
                                        {record.is_finish === 4 ? <span style={{ color: "green" }}>待退货</span> : <span style={{ color: "blue" }}></span>}
                                        {record.is_finish === 5 ? <span style={{ color: "green" }}>退货中</span> : <span style={{ color: "blue" }}></span>}
                                        {record.is_finish === 3 ? <span style={{ color: "#ff0000" }}>已拒绝</span> : <span style={{ color: "blue" }}></span>}
                                        {record.is_finish === 0 ? <span style={{ color: "green" }}>待处理</span> : <span style={{ color: "blue" }}></span>}
                                    </div>
                            }
                        </div>

                    );
                },
            },
            {
                title: '申请时间',
                dataIndex: "created_at",
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {

                    return (
                        <div>
                            <Button onClick={() => this.props.history.push("/userOrderRefundDetail/" + record.id)}>
                                查看
                            </Button>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        this.HandleGetData(1)
    }

    HandleGetData = async (page) => {
        let res = await GetUserOrderRefund(this.state.url, page);
        this.setState({ data: res.data.data, meta: res.data.meta.pagination, })
    }




    render() {
        let { meta,url,url_2,url_1,consumption_type } = this.state
        return (
            <div>
 
                <div style={{ marginBottom: "5px" }}>  
                    <Button
                        style={{ marginRight: "5px", backgroundColor:   "#58924d", color:   "#fff" }}
                        onClick={() => {
                            this.setState({
                                 
                            }, () => {
                               
                            })
                        }}>
                        消费方式
                    </Button>




                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_1 === "&consumption_type=0" ? "#58924d" : "", color: url_1 === "&consumption_type=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({ 
                                url_1: "&consumption_type=0"  ,
                                url: "&consumption_type=0",  
                                url_2:"",
                                consumption_type:0,
                                is_finish:null,
                            }, () => {
                                 this.HandleGetData(1)
                            })
                        }}>
                        到店
                    </Button>
                    
                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_1 === "&consumption_type=1" ? "#58924d" : "", color: url_1 === "&consumption_type=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({ 
                                url : "&consumption_type=1" ,
                                url_1: "&consumption_type=1",  
                                consumption_type:1,
                                url_2:"",
                                is_finish:null
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        配送
                    </Button>

                   
                     
                </div>





                <div style={{ marginBottom: "5px" }}>
                    <Button
                         
                        style={{ marginRight: "5px", backgroundColor: url_2 === "" ? "#58924d" : "", color: url_2 === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                                            
                        onClick={() => {
                            this.setState({
                                url: "",
                                url_1:"",
                                url_2:"",
                                consumption_type:null,
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        全部
                    </Button>


                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_2 === "&is_finish=0" ? "#58924d" : "", color: url_2 === "&is_finish=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                          
                        onClick={() => {
                            this.setState({
                                url_2: "&is_finish=0",
                                url :  url_1 +   "&is_finish=0" ,
                                
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        待处理
                    </Button>


                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_2 === "&is_finish=6" ? "#58924d" : "", color: url_2 === "&is_finish=6" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                          
                        onClick={() => {
                            this.setState({
                                url_2: "&is_finish=6",
                                url :  url_1 +   "&is_finish=6" ,
                                
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        已取消
                    </Button>




                    {

                        (consumption_type == null  || consumption_type === 1 )   &&  

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&is_finish=4" ? "#58924d" : "", color: url_2 === "&is_finish=4" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            
                            onClick={() => {
                                this.setState({
                                    url_2: "&is_finish=4",
                                    url :  url_1 +   "&is_finish=4" ,
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            待退货
                        </Button>
                    }



                    {

                        (consumption_type == null  || consumption_type === 1 )  &&  

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&is_finish=5" ? "#58924d" : "", color: url_2 === "&is_finish=5" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            
                            onClick={() => {
                                this.setState({
                                    url_2: "&is_finish=5",
                                    url :  url_1 +   "&is_finish=5" ,
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            退货中
                        </Button>
                         


                    }
                     



                     <Button
                        style={{ marginRight: "5px", backgroundColor: url_2 === "&is_finish=3" ? "#58924d" : "", color: url_2 === "&is_finish=3" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        
                        onClick={() => {
                            this.setState({
                                url_2: "&is_finish=3",
                                url :  url_1 +   "&is_finish=3" ,
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        已拒绝
                    </Button>

                    



                    <Button
                        style={{ marginRight: "5px", backgroundColor: url_2 === "&is_finish=1" ? "#58924d" : "", color: url_2 === "&is_finish=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        
                        onClick={() => {
                            this.setState({
                                url_2: "&is_finish=1",
                                  url :  url_1 +   "&is_finish=1" ,
                            }, () => {
                                this.HandleGetData(1)
                            })
                        }}>
                        已退款
                    </Button>



                   



                </div>

                <Table
                    rowKey='id'
                    // components={components}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    className="waperBox"
                    rowClassName="editable-row"
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData()
                    }}
                />

            </div>
        );
    }
}

export default withRouter(Form.create()(UserWithdrawLog))

