import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Switch } from 'antd';
import { getPartnerNavList } from "../../action/AdAction";
import intl from 'react-intl-universal';

class LangList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: intl.get("common.table.id"),
                dataIndex: 'id',
                align: "center",
            },
            {
                title: intl.get("lang.list.name"),
                dataIndex: 'name',
                align: "center",
            }, {
                title: intl.get("category.list.isShow"),
                dataIndex: 'is_show',
                align: "center",
                render: text => <Switch checked={text === 1} disabled />
            },


        ];
    }

    async componentDidMount() {
        let res = await getPartnerNavList()
        this.setState({ data: res.data.data })
    }


    render() {
        return (
            <Table
                rowKey={record => record.id}
                dataSource={this.state.data}
                columns={this.columns}
                pagination={{
                    hideOnSinglePage: true
                }
                }
                className="waperBox"
            />

        );
    }
}

export default withRouter(LangList)

