import { BaseUrl } from './function';
export const API = {
    BASEURL: BaseUrl() + "/api/partner/",
    EmailBASEURL: BaseUrl() + "/api/",
    auth: {
        LOGIN: "login",
        LOGINEMPARTNER:'login/empartner',
        partnerAdminLogin: "partnerAdminLogin",
        partnerAddress: "partner?include=partnerAddress,region",
        PARTNER: "partner",
        UPDATE: "/update"
    },
    category: {
        NEWCATEGORY: "category/",
        EDITCATEGORY: "category/update/"
    },


    goodsTag: {
        NEWCATEGORY: "tag/",
        EDITCATEGORY: "tag/update/"
    },




    goodsType: "goodsType/",
    goodsSpecification: {
        goodsSpecification: "goodsSpecification",
        goodsSpecificationItem: "goodsSpecificationItem",
        include: "?include=goodsSpecificationItem"
    },
    goodsAttr: "goodsAttr",
    spuDiscount: "spuDiscount",
    spuManyHelp: "spuManyHelp",
    coupon: "coupon",
    blindbox:"blindstore",
    blindboxTicket:"blindstoreTicket",//商家申请的ticket
    userCouponCheck: "userCouponCheck",
    userCoupon: "userCoupon",
    NewGood: {
        spu: "spu",
        spuPiece:"spuPiece",
        include: "?include=spuSku,spuGoodsSpecification,spuGoodsAttr,spuRecommendCategory,spuGoodsAnnexType.spuGoodsAnnexTypeItem",
        include2: "&include=spuSku,spuGoodsSpecification,spuGoodsAttr,category",
        UPDATE: "/update",
        recommend: "&recommend=1",
        spuImageSort:"updateGoodsImageSort",
    },
    page: "?page=",
    search: "&search=",
    NewAd: {
        advertisement: "advertisement",
        update: "/update",
        list: "?type=1",
        include: "?include=spu,category"
    },
    Menu: "partnerMenuImage",
    Tag: "partnerTag",
    partnerAnnouncement: "/partnerAnnouncement",
    systemAnnouncement: "/systemAnnouncement?is_show=1&type=2",
    partnerPhoto: "/partnerPhoto",
    wallet: {
        wallet: "/wallet",
        withdraw: "/withdraw",
        money: "/money",
        WithdrawLog: "/withdrawLog"
    },
    order: {
        order: "order",
        orderLine: "orderLine",
        include: "?include=user",
        includeUser: "?include=orderSpu,user",
        phone: "&phone=",
        orderSn: "&order_sn=",
        orderReceiving: "orderReceiving/",
        StripeRefundOrder:"stripeCreateRefund",//订单退款
        confirmOrder: "confirmOrder",
        exportOrder: "exportOrder",
        orderExportSecret: "orderExportSecret"
    },
    withdrawLog: {
        withdrawLog: "withdrawLog",
        dispose: "/dispose"
    },


    UserOrderRefundLog: { 
        UserOrderRefundLog: "userRefundLog",
        dispose: "/dispose"
    },




    partnerSeat: "partnerSeat",
    partnerDeliveryFree: "partnerDeliveryFree",
    partnerPackCharge: "partnerPackCharge",
    
    user: "user",
    userUpdateUserLevel: "user/updateUserLevel", //动态等级更新
    userUpdateUserLevelNature: "user/updateUserLevelNature", //自然等级更新
    userAutoUpdateUserScore: "userPointsLog/autoUpdateUserScore", //清理过期积分

    userContact: {
        user: "user",
        include: "?include=partner",
        page: "&page=",
        phone: "&phone=",
        contactUs: "contactUs"
    },

    //用户积分
    points:{
        userPointsList:"userPointsLog", 
        partnerPointsExchangeOption:"partnerPointsExchangeOption",
        partnerPointsExchangeOptionRecover:"partnerPointsExchangeOptionRecover"
    },


    //用户积分
    member:{
        memberGroupValueList:"userMemberGroupValueLog",
        memberLevelList:"userMemberLevelLog",
        partnerMemberLevelSetList:"partnerMemberLevelChangeLog",
        partnerMemberLevelSet:"partnerMemberLevel/levelSet",
        partnerMemberLevel:"partnerMemberLevel",
    },





    queryAddress: BaseUrl() + "/api/addressQuery?query=",
    partnerDeliveryAddress: "partnerDeliveryAddress",
    partnerServiceTypeDiscount: "partnerServiceTypeDiscount",
    redPackageIndex: "redPackageIndex",
    prizeActivity: "prizeActivity/",
    partnerAdminUniqueShow: "partnerAdminUniqueShow?unique=",
    partnerAdmin: "partnerAdmin",
    partnerLangue: "partnerLangue",
    plugIn: "addon",
    package: "package",
    partnerApplyPackage: "partnerApplyPackage",
    packageLog: "packageLog",
    card: "card",
    businessHours: "businessHours",
    holiday: "holiday",
    holidayBusiness: "holidayBusiness",
    goodsAnnexType: {
        goodsAnnexType: "goodsAnnexType",
        includeType: "?include=goodsAnnexTypeItem",
        goodsAnnexTypeItem: "goodsAnnexTypeItem",
        includeItem: "?include=goodsAnnexType",
    },
    style: "style",
    partnerNav: "partnerNav",
    manualPrintOrder: "manualPrintOrder",
    printer: "printer",
    config: "config?partner_id=0",
    partnerPackageFeeLog: "partnerPackageFeeLog",//费用流水
    partnerPackageStatementLog: "partnerPackageStatementLog",//结算清单
    stripeCreate: "stripeCreate",//结算清单
    createStatement:"createStatement",//生成结算清单
    queryPrinterStatus: "queryPrinterStatus?sn=",
    getConfig: "getConfig/region_id",
    getConfigDocument: "getConfig/is_document_enable",
    verifyCode: "verifyCode",
    document: "document",
    documentcategory:'documentcategory',
    orderRefund: "orderRefund/",
    orderRefundGoods: "orderRefundGoods/",
    operationLog:'operationLog',

    partnerCard:"partnerCard",//获取商家付款信息

    
    
}
