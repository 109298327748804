import React from 'react'
import { withRouter } from 'react-router-dom'
// import "./setup.less"
import { Form, Input, Upload, Icon, message, Button, Switch, Popconfirm } from 'antd';
import { getMenuDeatil, UpdateMenu } from "../../action/AdAction";
import intl from 'react-intl-universal';

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error(intl.get('file.type.placehorder'));
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.error(intl.get('file.size.placehorder'));
    }
    return isJpgOrPng && isLt1M;
}


class EditMenu extends React.Component {

    state = {
        loading: false,
        logo_file: "",
        Data: "",
        imageUrl: "",
        IsShow: true
    };

    async componentDidMount() {
        const id = this.props.location.pathname.substring(10)
        let Data = await getMenuDeatil(id)
        this.setState({
            Data: Data.data,
            imageUrl: Data.data.image,
            IsShow: Data.data.is_show === 1
        })
    }





    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, img) =>

                this.setState({
                    imageUrl,
                    loading: false,
                    logo_file: img
                }),
            );
        }
    };


    onChange = (checked) => {
        if (checked) {
            this.setState({ is_use: 1 })
        } else {
            this.setState({ is_use: 0 })
        }
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.is_show = this.state.IsShow ? "1" : "0"
            if (!err) {
                this.SubmitData(values)
            }



        });
    };

    async SubmitData(data) {

        let formData = new FormData()
        formData.append("image_file", this.state.logo_file)
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        }
        const id = this.props.location.pathname.substring(10)
        await UpdateMenu(formData, id).then(res => {
            this.props.history.push("/menulist")
        })
    }


    handleCheck = (checked) => {
        this.setState({ IsShow: checked })
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };


        const { Data, imageUrl, loading } = this.state;
        const { spu_id, sort } = Data


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label={<span>{intl.get('menu.form.img')}</span>}
                >
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={this.handleChange}
                    >
                        {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>

                <Form.Item
                    label={intl.get('menu.form.id')}
                >
                    {getFieldDecorator('spu_id', {
                        initialValue: spu_id
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={intl.get('menu.form.seq')}
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: sort
                    })(<Input />)}
                </Form.Item>
                <Form.Item label={intl.get('category.list.isShow')}>
                    <Switch checked={this.state.IsShow} onChange={this.handleCheck} />
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(EditMenu))
