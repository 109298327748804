import React, { Component } from 'react'
import { Row, Avatar, Select, Icon, Dropdown, Menu } from 'antd'
import { connect } from 'react-redux'
import './index.less'
import { getPartner, GetAdminUniqueDetail, getLangList, editLang , getConfigDocument } from "../../action/authAction";
import { removeToken, setLangType, getLangType } from "../../util/function";
import intl from 'react-intl-universal';
import { LaptopOutlined } from '@ant-design/icons';
import { data } from "../../util/data";
const { Option } = Select;




class Header extends Component {
  state = {
    userName: ' ',
    langList: [],
    defaultLang: "",
    logo: "",
    is_audit: false,

    menuData: "", 

    is_document_enable:0,//是否显示文档

  }

  async componentDidMount() {


    let resDocument = await getConfigDocument() 
      this.setState({
        is_document_enable: resDocument.data.value,
        
      })






    let menuDataAll = data();
    this.setState({
        menuData:menuDataAll
      })



    let admin = sessionStorage.getItem("adminid")
    if (admin !== null) {
      GetAdminUniqueDetail(admin).then(res => {
        this.setState({
          userName: res.data.name,
          logo: res.data.head_image
        })
      })
    } else {
      let res = await getPartner()
      this.setState({
        userName: res.data.name,
        logo: res.data.logo,
        is_audit: res.data.is_audit === 0
      })
    }
    getLangList().then(response => {
      const data = response.data.data;
      let defaultLang = ""
      const langList = [];
      data.forEach(element => {
        const obj = {
          id: element.language.id,
          name: element.language.show_symbol,
          is_default: element.is_default,
        }
        // 单语言控制
        if (element.is_use === 1) {
          langList.push(obj);
        }
      });
      for (let i in langList) {
        if (langList[i].is_default === 1) {
          defaultLang = langList[i].name
          setLangType(langList[i].id)
        }
      }
      this.setState({
        langList, defaultLang
      })
    })

  }

  // logout = () => {
  //   removeToken()
  //   sessionStorage.removeItem('adminid')
  //   window.location.href = '/business'
  // }

  handleMenuClick = ({ key }) => {
    removeToken()
    sessionStorage.removeItem('adminid');
    sessionStorage.clear();
    window.location.href = '/'
  }

  langType = (type) => {
    sessionStorage.setItem("menuStorage", "")
    editLang({ manage_language_id: type }).then(data => {
      if (data && data.data) {
        setLangType(type)
        window.location.reload();
      }
    })

  }

  fn = (name) => {
    if (name.includes('nav.')) {
      return intl.get(name)
    }
    return name
  }

  render() {

    const menu = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="1">{intl.get('system.loginout')}</Menu.Item>
      </Menu>
    );

    const { langList, defaultLang, userName, logo, is_audit,menuData,is_document_enable } = this.state;
    if (userName) {
      localStorage.setItem("username", userName)
    }
    return (
      <>
        <div className='header'>
          <div className='logo-name'>{intl.get('system.title')}</div>
          <Row className='header-top'>



           

              <div
              style={{ marginRight: "10px", display: "inline-block", cursor: "pointer" }}
              onClick={() => { 
                 let menuStorage = { "childData":  menuData[8], "activeMenuIndex": 8 }
                 sessionStorage.setItem("menuStorage", JSON.stringify(menuStorage)) 
                 window.open('#/SystemAnnouncement')
              }}
            >
              <Icon type="message" />
            </div>

 


            {

              is_document_enable == "1" && 

                <div
                style={{ marginRight: "5px", display: "inline-block", cursor: "pointer" }}
                onClick={() => {

                   
                   let menuStorage = { "childData":  menuData[8], "activeMenuIndex": 8 }
                   sessionStorage.setItem("menuStorage", JSON.stringify(menuStorage))
   
                   //window.location.href = '#/documentList'
                   window.open('#/documentList')
                }}
              >
                <Icon type='question-circle' />
              </div>



            }
            




            {
              is_audit &&
              <span style={{ backgroundColor: "#ebebeb", padding: "5px 10px", borderRadius: "5px" }}>
                <span style={{ fontSize: "16px", color: "#ff0000" }}>{intl.get("system.is_audit")}</span>
              </span>
            }

            {
              langList.length > 1 ?
                <Select style={{ width: "80px" }} value={defaultLang} onChange={this.langType} suffixIcon={<Icon type="caret-down" theme="filled" style={{ color: '#000', marginLeft: 4 }} />} className="languageSelect">
                  {langList?.map(el => <Option value={el.id} key={el.id}>{el.name}</Option>)}
                </Select> : <span style={{ fontSize: "16px", fontWeight: "400", marginRight: "20px" }} > {defaultLang}</span>
            }
            {
              logo && <img src={logo} style={{ width: "30px", height: "30px", borderRadius: "20px", objectFit: "contain" }} />
            }
            <Dropdown overlay={menu} overlayClassName="languageSel">
              {
                this.state.userName &&
                <span style={{ backgroundColor: "#ebebeb", padding: "5px 10px", borderRadius: "5px" }}>

                  {/* <span>Username</span> */}
                  <span style={{ fontSize: "16px" }}>{this.state.userName}</span>
                </span>
              }

            </Dropdown>
          </Row>
        </div>
        {
          this.fn(this.props.menuName) !== "undefined" && <div className='breadcrumb'>{this.fn(this.props.menuName)}</div>
        }

      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    menuName: state.name.menuName
  }
}

export default connect(mapStateToProps)(Header)
