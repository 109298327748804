import React from 'react'
import { Form, Card, Button } from 'antd';
import intl from 'react-intl-universal';
import { getLangType } from "../../util/function";
import CnImage from "../../img/zh.png"
import EnImage from "../../img/en.png"
import "./index.less"

class Download extends React.Component {

    render() {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "80%" }}>
                <div style={{ background: "#000", padding: "15px", borderRadius: "40px" }}>
                    <img src={Number(getLangType()) === 1 ? CnImage : EnImage} width="280px" alt="" />
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "50px", alignItems: 'center' }}>
                    <p style={{ fontSize: "30px", fontWeight: "bold", color: "#000", margin: "0px" }}>{intl.get("nav.download.app_download")}</p>
                    <p style={{ fontSize: "30px", fontWeight: "bold", color: "#000" }}>{intl.get("nav.download.merchant")}</p>
                    <a className='download-button' target="_blank" href='https://apps.apple.com/us/app/%E6%BE%B3%E6%B4%B2e%E5%BA%97/id1522281821'>iPhone</a>
                    <a className='download-button' target="_blank" href='https://play.google.com/store/apps/details?id=com.goodshopapp&pli=1'>Android</a>
                </div>
            </div>
        );
    }
}

export default Download
