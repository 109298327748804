import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Select,Popconfirm } from 'antd';
import { AddTag, getGoodsList } from "../../action/goodAction";
import intl from 'react-intl-universal';

class NewTag extends React.Component {

    state = {
        is_show: true,
        goodsList: []
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.is_show = this.state.is_show ? "1" : "0"
                AddTag(values).then(res => {
                    this.props.history.push("/TagList")
                })
            }
        });
    };



    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const { is_show } = this.state
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('newtag.form.name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: intl.get('newtag.form.name.horder'), whitespace: true }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('newtag.form.id')}
                >
                    {/* {getFieldDecorator('spu_ids', {
                        rules: [{ required: true, message: intl.get('newtag.form.id.horder'), whitespace: true }],
                    })(<Input />)} */}
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="select one country"
                        defaultValue={['china']}
                        onSearch={(value) => {
                            getGoodsList("", value)
                        }}
                        optionLabelProp="label"
                    >

                    </Select>
                </Form.Item>



                <Form.Item
                    label={intl.get('newtag.form.sort')}
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('category.list.isShow')}
                >
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewTag))
