import React from 'react'
import { withRouter } from 'react-router-dom'
import "./setup.less"
import { Form, Input, Button } from 'antd';
import { UpdateShopMessage } from "../../action/authAction";
import intl from 'react-intl-universal';


class SetUpPassword extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let formData = new FormData()
                formData.append("password", values.password)
                UpdateShopMessage(formData)
            }
        });
    };

    checkPsd(rule, value, callback) {
        let password = this.props.form.getFieldValue('password');
        if (password !== value) {
            callback(new Error(intl.get("nav.setting.password.error")));
        } else {
            callback();
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 12,
                },
            },
        };

        let username = localStorage.getItem("username")

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('setup.form.user')}
                >
                    <span>{username}</span>
                </Form.Item>
                <Form.Item
                    label={intl.get('nav.setting.password.password')}
                >
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: intl.get('seat.table.please') + intl.get('nav.setting.password.password'), whitespace: true }],
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('nav.setting.password.confirm')}
                >
                    {getFieldDecorator('confirm', {
                        rules: [{ required: true }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                    })(<Input />)}
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(SetUpPassword))
