import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, message,Popconfirm } from 'antd';
import { AddGoodsAttr, getGoodType } from "../../action/goodAction";
import intl from 'react-intl-universal';

class NewGoodArr extends React.Component {

    state = {
        value: "",
        data: []
    };

    async componentDidMount() {
        let res = await getGoodType()
        this.setState({ data: res.data.data })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.goods_type_id = this.state.value
            if (!err) {
                if (values.name) {
                    if (values.goods_type_id) {
                        AddGoodsAttr(values).then(res => {
                            // this.props.history.push("/goodsList")
                        })
                    } else {
                        message.error(intl.get('goodsArr.form.type'))
                    }

                }
            }



        });
    };

    onChange = e => {
        this.setState({
            value: e
        });
    };

    render() {
        const { data } = this.state
        const { Option } = Select;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('goodsArr.form.name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: intl.get('goodsArr.form.name.plachordr'), whitespace: true }],
                    })(<Input />)}

                </Form.Item>


                <Form.Item
                    label={intl.get('goodsArr.form.type')}
                >
                    <Select defaultValue={intl.get('goodsArr.form.type')} style={{ width: "100%" }} onChange={this.onChange}>
                        {
                            data.length !== 0 && data.map(item => {
                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>


                <Form.Item
                    label={intl.get('announcementList.table.sort')}
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewGoodArr))
