import React from "react";
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch, Icon, Tooltip, Popover, Modal, message, Input } from 'antd';
import { getSpuDiscountList, deleteSpuDiscount, putSpuDiscount } from "../../action/goodAction";
import "../category/category.less"
import intl from 'react-intl-universal';
import eatmoonLogo from "../../img/eatmoon.png"

let currency_symbol = sessionStorage.getItem("currency_symbol")

const fileIcon = () => (
    <svg t="1672985602944" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1282" width="16" height="16"><path d="M143 398v415h737V398H143z m-80-48c0-17.673 14.327-32 32-32h833c17.673 0 32 14.327 32 32v511c0 17.673-14.327 32-32 32H95c-17.673 0-32-14.327-32-32V350z" fill="#515151" p-id="1283"></path><path d="M296.657 581.044a8 8 0 0 1 0-11.314l45.255-45.255a8 8 0 0 1 11.313 0l125.158 125.158 195.162-195.162a8 8 0 0 1 11.313 0l45.255 45.255a8 8 0 0 1 0 11.314L489.697 751.456c-6.248 6.249-16.379 6.249-22.627 0L296.657 581.044zM225 199a8 8 0 0 1 8-8h560a8 8 0 0 1 8 8v56a8 8 0 0 1-8 8H233a8 8 0 0 1-8-8v-56zM352 72a8 8 0 0 1 8-8h303a8 8 0 0 1 8 8v56a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8V72z" fill="#515151" p-id="1284"></path></svg>
)

class PromotionalGoodsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  url_1:"", url_2:"",url_3:"",url_4:"",  data: [], page: 1, url: "", Visible: false, ItemData: "", closed: "" , NewVisible:false};
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },



            // {
            //     title: "adid",
            //     dataIndex: 'adid',
            //     width: '5%',
            //     align: "center",
            // },
            // {
            //     title: "Qr Code",
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return <img src={record.qrcode_url} style={{ width: "100px", height: "100px" }} />
            //     }
            // },


            {
                title: intl.get("nav.goodsManagement.promotionalgoods.goodsImg"),
                width: '5%',
                align: "center",
                render: (record) => {
                    return <img src={record.image} style={{ backgroundColor:"rgb(239 239 239)",width: "80px", height: "80px", objectFit: "contain" }} />
                }
            },




            // {
            //     title: "Name",
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         if (record.spuManyHelp.state === 1) {
            //             return <span><span style={{ color: "#ff0000" }}>[{intl.get("nav.goodsManagement.cutgoodsType")}]</span>{record.spu.name}</span>
            //         }
            //         return <span>{record.spu.name}</span>
            //     }
            // },





            {
                title: intl.get("nav.goodsManagement.promotionalgoods_name"),
                width: '10%',
                // align: "center",
                render: (record) => {


                    return <div style={{ display: "flex", alignItems: "center" }}>

                        
                        {

                             record.permanent_state === 1 &&  
                             <span><Icon type="stop" theme="filled" style={{ marginRight: "5px",color:"#ff5722" }} /></span>
                            

                        }

 
                        {record.spuManyHelp && record.spuManyHelp.state === 1 &&

                            <span style={{ color: "#ff0000" }}>[{intl.get("nav.goodsManagement.cutgoodsType")}]</span>
                        }


                        {
                            record.operation_type === 0 &&
                            <Icon type="shop" style={{ marginRight: "5px" }} />
                        }


                        {
                            record.operation_type === 1 &&
                            <img src={eatmoonLogo} alt="" style={{ width: "20px", height: "20px",margin:"5px" }} />
                        }



                        <span>{record.name}</span>


                     </div>

                     
                    
                }
            },


            {
                title: intl.get('orders.price'),
                // title: intl.get('nav.goodsManagement.promotionalgoods.discount_price'),
                // dataIndex: ["_spu", "discount_price"],
                // dataIndex: "_spu",
                width: '8%',
                // align: "center",
                render: (record) => {
                    let price = currency_symbol + record._spu.price
                    let discount_price = currency_symbol + record._spu.discount_price
                    let discount_on_fixed
                    if (record.discount_type === "fixed") {
                        discount_on_fixed = currency_symbol + record.discount_on_fixed
                    } else {
                        discount_on_fixed = (parseFloat(record.discount_on_percentage) * 100).toFixed(0) + "%" + " off"
                    }
                    // return <span>{currency_symbol}{record}</span>
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ marginBottom: "5px" }}>{intl.get("nav.goodsManagement.promotionalgoods.price")}:{price}</span>
                        <span style={{ marginBottom: "5px" }}>Discount off: {discount_on_fixed}</span>
                        <span>{intl.get('nav.goodsManagement.promotionalgoods.discount_price')}:{discount_price}</span>
                    </div>
                }
            },


            
            // {
            //     title: "Price",
            //     dataIndex: ["_spu", "price"],
            //     width: '8%',
            //     align: "center",
            //     render: (record) => {
            //         return <span>{currency_symbol}{record}</span>
            //     }
            // },
            /*
            {
                title: intl.get('nav.goodsManagement.promotionalgoods.discount_type'),
                dataIndex: 'discount_type',
                width: '10%',
                align: "center",
            },
            */
            // {
            //     title: intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed'),
            //     // dataIndex: 'discount_on_fixed',
            //     width: '4%',
            //     align: "center",
            //     render: (record) => {
            //         if (record.discount_type === "fixed") {
            //             return currency_symbol + record.discount_on_fixed
            //         } else {
            //             return (parseFloat(record.discount_on_percentage) * 100).toFixed(0) + "%"
            //         }
            //         // return record.discount_type === "fixed" ? record.discount_on_fixed : ""
            //     }
            // },
            // {
            //     title: intl.get('nav.goodsManagement.promotionalgoods.discount_on_percentage'),
            //     // dataIndex: 'discount_on_percentage',
            //     width: '8%',
            //     align: "center",
            //     render: (record) => {

            //         return record.discount_type === "percentage" ? (parseFloat(record.discount_on_percentage) * 100).toFixed(0) : ""
            //     }
            // },
            {
                title: intl.get('nav.goodsManagement.promotionalgoods.stock'),
                // dataIndex: 'stock',
                width: '6%',
                // align: "center",
                render: (record) => {

                    let isRed =  (record.stock === 0)? true : false ; 
                    let stock = record.stock
                    let sale_quantity = record.sale_quantity
                    let total_stock = Number(stock) + Number(sale_quantity)
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ marginBottom: "5px" }}>{intl.get("nav.goodsManagement.promotionalgoods.sale_quantity")}:{sale_quantity}</span>
                        <span style={{ marginBottom: "5px" ,color: isRed ? "#ff0000" : "" }}>{intl.get('nav.goodsManagement.promotionalgoods.Remaining_inventory')}:{stock}</span>
                        <span>{intl.get('nav.goodsManagement.promotionalgoods.total_inventory')}:{total_stock}</span>
                    </div>
                }
            },
            // {
            //     title: intl.get("nav.goodsManagement.promotionalgoods.sale_quantity"),
            //     dataIndex: 'sale_quantity',
            //     width: '5%',
            //     align: "center",
            // },

            // {
            //     title: intl.get('nav.goodsManagement.promotionalgoods.quota'),
            //     dataIndex: 'quota',
            //     width: '5%',
            //     align: "center",
            // },



            // {
            //     title: intl.get("nav.goodsManagement.promotionalgoods.orderValidity"),
            //     dataIndex: ['order_expire_at', 'date'],
            //     width: '10%',
            //     align: "center",
            // },

            // {
            //     title: "start at",
            //     dataIndex: 'start_at',
            //     width: '10%',
            //     align: "center",
            // },
            // {
            //     title: "end at",
            //     dataIndex: 'end_at',
            //     width: '10%',
            //     align: "center",
            // },
            {
                title: intl.get("home.table.service"),
                // dataIndex: 'activity_service_rate',
                width: '7%',
                // align: "center",
                render: (record) => {
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ marginBottom: "5px" }}>{intl.get("home.table.service")}:{Number(record.activity_service_rate) * 100 + "%"}</span>
                        <span style={{ marginBottom: "5px" }}>{intl.get('nav.goodsManagement.promotionalgoods.order_fixed_fee')}:${record.order_fixed_fee}</span>
                    </div>


                }
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods.during_the_event"),
                width: '6%',
                align: "center",
                render: (record) => {
                    let start_at = record.start_at
                    let end_at = record.end_at
                    let end_at_year = end_at.split(" ")
                    let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
                    let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
                    let isRed = end_at_time < curDate
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{start_at.substring(0, start_at.length - 8)}</span>
                        <span>~</span>
                        <span style={{ color: isRed ? "#ff0000" : "" }}>{end_at.substring(0, end_at.length - 8)}</span>
                    </div>
                }
            },
            // {
            //     title: intl.get('nav.orderManagement.list'),
            //     width: '4%',
            //     align: "center",
            //     render: (record) => {
            //         return <Button
            //             type="link"
            //             onClick={() => {
            //                 this.props.history.push("/OrderList/" + record.id)
            //             }}
            //         >{intl.get("home.table.operation.btn.view")}
            //         </Button>
            //     }
            // },



            // {
            //     title: intl.get('subscription.status'),
            //     width: '4%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <div>
            //                 {
                               

            //                    record.permanent_state === 1 ?
            //                     <span style={{ color: "#ff0000" }}>{intl.get("nav.goodsManagement.promotionalgoods.Terminated")}</span> :
            //                     record.end_status ?
            //                         <span style={{ color: "#ff0000" }}>{intl.get("nav.goodsManagement.promotionalgoods.end")}</span> :
            //                         <span style={{ color: "green" }}>{intl.get("nav.goodsManagement.promotionalgoods.NormalSales")}</span>



            //                     /*
            //                     record.end_status ?
            //                         <span style={{ color: "#ff0000" }}>{intl.get("nav.goodsManagement.promotionalgoods.end")}</span> :
            //                         <span style={{ color: "green" }}>{intl.get("nav.goodsManagement.promotionalgoods.NormalSales")}</span>
            //                     */
            //                 }
            //             </div>
            //         )
            //     }
            // },
            





            // {
            //     title: intl.get("nav.goodsManagement.promotionalgoods.isBuy"),
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         return record.is_already_buy === 1 ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>
            //     }
            // },
            {
                title: intl.get('nav.goodsManagement.promotionalgoods.OffTheShelf'),
                width: '5%',
                align: "center",
                render: (record) => {


                    let thisState = false

                    /*
                    if (record.sale_quantity > 0 && record.stock !== 0 || record.sale_quantity > 0 && !record.is_end_at) {//当销量大于0 不可上下架，为0则可以
                        thisState = true
                    }

                    if (record.sale_quantity > 0 && record.stock === 0 || record.sale_quantity > 0 && record.is_end_at || record.sale_quantity === 0) {//库存为0可下不可上
                        thisState = false
                    }

                    if (record.end_status) {
                        thisState = true
                    }

                    */



                    // if (record.is_lock) {
                    //     thisState = true
                    // }




                    let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
                     
                    //判断可以随时上下架
                    let start_at = record.start_at
                    let start_at_year = start_at.split(" ")
                    let start_at_time = new Date(start_at_year[0].split("/").reverse().join("/") + " " + start_at_year[1]).getTime()
                    

                   
                    
                    if(record.is_first_state == 1){ //如果已经自送上架了，则可以随时上下架
                         thisState = false;
                    }else{
                        if(curDate < start_at_time){ //过了今天可以随时上下架，不管有没有自动上架
                            thisState = true;
                        }else{
                            thisState = true;
                        }

                    }


      

                    let end_at = record.end_at
                    let end_at_year = end_at.split(" ")
                    let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
                    

                    //未终止的可以上下架
                    if(record.permanent_state != 1){
                        if (end_at_time < curDate && record.state === 1 && record.sale_quantity > 0) {
                        return <Popconfirm placement="top" title={intl.get("nav.goodsManagement.promotionalgoods.OffTheShelf_tips")}
                            onConfirm={() => this.onChangeState(record)}
                        >
                            <Switch
                                checked={record.state === 1}
                                disabled={thisState}

                            />
                            </Popconfirm>
                        } else {
                            return <Switch
                                checked={record.state === 1}
                                disabled={thisState}
                                onChange={() => this.onChangeState(record)} />
                        }

                    }else{
                        return "";
                    }

                    




                }
            },



              {
                //intl.get('category.list.isShow')
                title: '店铺显示',
                width: '5%',
                align: "center",
                render: (record) => {
                    let thisState = false
                    if (record.sale_quantity > 0 && record.stock !== 0 || record.sale_quantity > 0 && !record.is_end_at) {//当销量大于0 不可上下架，为0则可以
                        thisState = true
                    }

                    if (record.sale_quantity > 0 && record.stock === 0 || record.sale_quantity > 0 && record.is_end_at || record.sale_quantity === 0) {//库存为0可下不可上
                        thisState = false
                    }

                    if (record.end_status) {
                        thisState = true
                    }
                    if (record.type === 1) {
                        thisState = true
                    }

                    // if (record.is_lock) {
                    //     thisState = true
                    // }


                    //商家才显示 店铺显示
                     if(record.operation_type != 1 && record.permanent_state != 1 ){
                            return (
                                <Switch
                                    //disabled={thisState}
                                    checked={record.is_show === 1}
                                    onChange={() => this.onChangeIsShow(record)} />
                            )

                     }else{
                            return ""
                     }
                    
                }
            }, 



            {
                 
                title: "平台显示", 
                width: '5%',
                align: "center",
                render: (record) => {
                    let thisState = true 

                    if(record.operation_type == 1 && record.permanent_state != 1 ){
                            return (
                                <Switch
                                    disabled={thisState}
                                    checked={record.is_eatmoon_show === 1}
                                    onChange={() => this.onChangeIsShow(record)} />
                            )

                    }else{
                        return ""
                    }
                    
                }
            },





            {
                title: <Tooltip title="如果该语言没有激活，则在当前语言环境下，不显示该条目" > 语言<Icon type="question-circle" style={{ marginLeft: "5px" }} /> </Tooltip>,
                width: '5%',
                align: "center",
                render: (record) => { 
                     return (
                        <Switch  
                            checked={record.disable_language == 0 } 
                            onChange={() => {
                            putSpuDiscount({ "disable_language": record.disable_language === 1 ? "0" : "1" }, record.id)
                                .then(res => {
                                    this.HandleGetData(1)
                                }).catch(error => {
                                    // console.log(error.response, "11")
                                })
                        }} />
                    )
                }
            },







            {
                title: "排序",
                dataIndex: 'sort',
                width: '3%',
                align: "center",
            },





            {
                title: intl.get('pack.form.opt'),
                width: '3%',
                align: "center",
                render: (text, record) => {
                    // return record.end_status === 1 || record.sale_quantity && record.sale_quantity > 0 ?
                    return <Popover
                        style={{ width: 500 }}
                        content={
                            <div style={{ display: "flex" }}>

                                <Tooltip title={intl.get('nav.orderManagement.list')}>
                                    <Button
                                        style={{ marginRight: "5px" }}
                                        shape="circle"
                                        icon="snippets"
                                        onClick={() => {
                                            this.props.history.push("/OrderList/" + record.id)
                                        }} />
                                </Tooltip>

                                <Tooltip title={intl.get('home.table.operation.btn.view')}>
                                    <Button
                                        style={{ marginRight: "5px" }}
                                        shape="circle"
                                        icon="eye"
                                        onClick={() => {
                                            this.props.history.push("/ViewPromotionalGoods/" + record.id)
                                        }} />
                                </Tooltip>

                                {
                                    record.end_status ?
                                        <Popconfirm
                                            title={intl.get('home.table.operation.btn.file_horder')}
                                            onConfirm={() => this.handleDelete(record)}
                                        >
                                            <Tooltip title={intl.get('home.table.operation.btn.file')}>
                                                <Button
                                                    style={{ margin: "0px 5px" }}
                                                    shape="circle"
                                                >
                                                    <Icon component={fileIcon} style={{ marginTop: "5px" }} />
                                                </Button>
                                            </Tooltip>
                                        </Popconfirm> : <div style={{ display: "flex" }}>
                                            {
                                                record.state === 1 || record.sale_quantity && record.sale_quantity > 0 ? null : <div style={{ display: "flex" }}>
                                                    <Tooltip title={intl.get('seat.table.btn.edit')}>
                                                        <Button
                                                            style={{ marginRight: "5px" }}
                                                            shape="circle"
                                                            icon="edit"
                                                            onClick={() => {
                                                                this.props.history.push("/EditPromotionalGoods/" + record.id)
                                                            }} />
                                                    </Tooltip>

                                                </div>
                                            }
                                            
                                        </div>
                                }


                               
                                { 
                                    record.permanent_state === 0 &&
                                    <Tooltip title={intl.get('orders.status.three')}>
                                        <Button
                                            shape="circle"
                                            icon="arrow-down"
                                            onClick={() => {
                                                this.setState({
                                                    Visible: true,
                                                    ItemData: record
                                                })
                                            }}
                                        />
                                    </Tooltip>
                                }



                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                    {/* <Tooltip title={intl.get('home.table.operation.btn.view')}>
                            <Button
                                style={{ marginRight: "5px" }}
                                shape="circle"
                                icon="eye"
                                onClick={() => {
                                    this.props.history.push("/ViewPromotionalGoods/" + record.id)
                                }} />
                        </Tooltip>
                        {
                            record.end_status ?
                                null : <div style={{ display: "flex" }}>
                                    {
                                        record.state === 1 || record.sale_quantity && record.sale_quantity > 0 ? null : <div style={{ display: "flex" }}>
                                            <Tooltip title={intl.get('seat.table.btn.edit')}>
                                                <Button
                                                    style={{ marginRight: "5px" }}
                                                    shape="circle"
                                                    icon="edit"
                                                    onClick={() => {
                                                        this.props.history.push("/EditPromotionalGoods/" + record.id)
                                                    }} />
                                            </Tooltip>
                                            <Popconfirm
                                                title={intl.get('home.table.operation.btn.horder')}
                                                onConfirm={() => this.handleDelete(record)}
                                            >
                                                <Tooltip title={intl.get('home.table.operation.btn.delete')}>
                                                    <Button
                                                        style={{ margin: "0px 5px" }}
                                                        shape="circle"
                                                        icon="delete"
                                                    />
                                                </Tooltip>
                                            </Popconfirm>
                                        </div>
                                    }
                                </div>
                        } */}


                },
            },

        ];
    }


    componentDidMount() {
        this.HandleGetData(1)
    }

    async HandleGetData(page) {
        let res = await getSpuDiscountList(page, this.state.url)
        this.setState({ data: res.data.data, meta: res.data.meta.pagination })
    }




    async handleDelete(data) {
        await deleteSpuDiscount(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    onChangeState = (item) => {
        let values = {}
        values.state = item.state === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.HandleGetData()
        })
    }

    onChangeIsShow = (item) => {
        let values = {}
        values.is_show = item.is_show === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.HandleGetData()
        })
    }

    handleOverOff = () => {
        let item = this.state.ItemData
        let values = {}
        // values.spu_id = item.spu_id
        values.permanent_state = item.permanent_state === 1 ? "0" : "1"
        putSpuDiscount(values, item.id).then(res => {
            this.setState({
                Visible: false,
            });
            this.HandleGetData()
        })

    }

    handleCancel = () => {
        this.setState({
            Visible: false,
        });
    };
    handleOk = e => {
        let item = this.state.ItemData

    };

    render() {
        let { meta, url, closed,url_1,url_2,url_3,url_4 } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" }}>


                    <div style={{ marginBottom: "5px" }}>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url === "" ? "#58924d" : "", color: url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "",
                                    url_1:"",
                                    url_2:"",
                                    url_3:"",
                                    url_4:"",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            {intl.get('home.table.useStatus.all')}
                        </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_1 === "&state=1" ? "#58924d" : "", color: url_1 === "&state=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&state=1" + url_2 +  url_3 + url_4,
                                    url_1:"&state=1",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已上架
                        </Button>



                         <Button
                            style={{ marginRight: "5px", backgroundColor: url_1 === "&state=0" ? "#58924d" : "", color: url_1 === "&state=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&state=0" + url_2 +  url_3 + url_4,
                                    url_1:"&state=0",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已下架
                        </Button>



                       



 


                        {/* <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&type=0" ? "#58924d" : "", color: url === "&type=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&type=0"
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            {intl.get('nav.goodsManagement.promotionalgoods.inside')}
                        </Button> */}


                        {/* <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&type=1" ? "#58924d" : "", color: url === "&type=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&type=1"
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            {intl.get('nav.goodsManagement.promotionalgoods.external')}
                        </Button>
                        */}



                        {/* <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&type=2" ? "#58924d" : "", color: url === "&type=2" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&type=2"
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            {intl.get('nav.goodsManagement.promotionalgoods.mysterious')}
                        </Button> */}




                        {/* <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&is_already_buy=1" ? "#58924d" : "", color: url === "&is_already_buy=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&is_already_buy=1"
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            {intl.get('nav.goodsManagement.promotionalgoods.isBuy')}
                        </Button> */}

                    </div>

                    <div>
                        <Tooltip title={intl.get('nav.goodsManagement.goods.isdeletegoodslist')}>
                            <Button
                                style={{ marginRight: "10px" }}
                                icon="project"
                                onClick={() => {
                                    this.props.history.push("/isdeletepromotionalgoodsList")
                                }} />
                        </Tooltip>
                        <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.addTips")}>
                            <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                        </Tooltip>


                        <Button
                            type="primary"
                            style={{ marginLeft: "10px" }}
                            onClick={() => 

                                this.setState({
                                    NewVisible: true, 
                                })


                            }
                        >
                            {intl.get('nav.goodsManagement.promotionalgoods.add')}
                        </Button>



                         <Modal
                            title="新建 Deal"
                            visible={this.state.NewVisible}  

                            onCancel={() => {
                                this.setState({
                                    NewVisible: false, 
                                })
                            }}
                             footer={null}
 
                            >   

 
                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>

                                <Button
                                    type="primary"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => this.props.history.push("/addPromotionalGoods/"+0 )}
                                >
                                   店铺 Deal
                                </Button>

                                <Button
                                    type="primary"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => this.props.history.push("/addPromotionalGoods/"+1)}
                                >
                                    平台 Deal
                                </Button>

                            </div>






                        </Modal>
                        


                    </div>
                </div>



                 <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0" }}> 
                    <div style={{ marginBottom: "5px" }}> 

                        <Button
                            style={{ marginRight: "5px", backgroundColor:  "#58924d"  , color: "#fff"   }}
                            onClick={() => {
                                this.setState({
                                    url: ""
                                }, () => {
                                    //this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            运营类型
                        </Button>

                         <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&operation_type=0" ? "#58924d" : "", color: url_2 === "&operation_type=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&operation_type=0" + url_1 +  url_3 + url_4,
                                    url_2:"&operation_type=0",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            店铺
                        </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_2 === "&operation_type=1" ? "#58924d" : "", color: url_2 === "&operation_type=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&operation_type=1" + url_1 +  url_3 + url_4,
                                    url_2:"&operation_type=1",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            平台
                        </Button> 
                    </div>
                </div>



                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0" }}> 
                    <div style={{ marginBottom: "5px" }}> 

                        <Button
                            style={{ marginRight: "5px", backgroundColor:  "#58924d"  , color: "#fff"   }}
                            onClick={() => {
                                this.setState({
                                    url: ""
                                }, () => {
                                    //this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            行为
                        </Button>

                         <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&is_expire=1" ? "#58924d" : "", color: url_3 === "&is_expire=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&is_expire=1" + url_1 +  url_2 + url_4,
                                    url_3:"&is_expire=1",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已过期
                        </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&is_stock_empty=1" ? "#58924d" : "", color: url_3 === "&is_stock_empty=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&is_stock_empty=1" + url_1 +  url_2 + url_4,
                                    url_3:"&is_stock_empty=1",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已售罄
                        </Button>

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_3 === "&permanent_state=1" ? "#58924d" : "", color: url_3 === "&permanent_state=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&permanent_state=1" + url_1 +  url_2 + url_4,
                                    url_3:"&permanent_state=1",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            已终止
                        </Button>




                    </div>
                </div>




                {/*
                 <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0" }}> 
                    <div style={{ marginBottom: "5px" }}> 
 
                        <Button
                            style={{ marginRight: "5px", backgroundColor:  "#58924d"  , color: "#fff"   }}
                            onClick={() => {
                                this.setState({
                                    url: ""
                                }, () => {
                                    //this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            状态
                        </Button>

                        

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&end_status=1" ? "#58924d" : "", color: url === "&end_status=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&end_status=1"
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            {intl.get('nav.goodsManagement.promotionalgoods.end')} [售罄或过期或终止]
                        </Button>


                        <Button
                            style={{ marginRight: "5px", backgroundColor: url === "&end_status=0" ? "#58924d" : "", color: url === "&end_status=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&end_status=0"
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            {intl.get('nav.goodsManagement.promotionalgoods.NormalSales')} [未过期并且有库存]
                        </Button>


                         
                        
                    </div>
                </div>
                */}



                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" }}> 
                    <div style={{ marginBottom: "5px" }}> 
                        <Button
                            style={{ marginRight: "5px", backgroundColor: "#58924d"  , color:  "#fff"   }}
                            onClick={() => {
                                this.setState({
                                    url: ""
                                }, () => {
                                    //this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            排序
                        </Button>



                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_4 === "&orderby=1" ? "#58924d" : "", color: url_4 === "&orderby=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&orderby=1" + url_1 +  url_2 + url_3,
                                    url_4:"&orderby=1",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            创建时间
                        </Button>

                        <Button
                            style={{ marginRight: "5px", backgroundColor: url_4 === "&orderby=2" ? "#58924d" : "", color: url_4 === "&orderby=2" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&orderby=2" + url_1 +  url_2 + url_3,
                                    url_4:"&orderby=2",
                                }, () => {
                                    this.HandleGetData(1, this.state.url)
                                })
                            }}>
                            开始时间
                        </Button> 
                    </div>
                </div>




                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}> 
                        <div style={{ marginBottom: "5px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#58924d"  , color: "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        //this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                语言
                            </Button>

                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_disable_language=1" ? "#58924d" : "", color: url_3 === "&is_disable_language=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_disable_language=1"  + url_1 +  url_2  , 
                                        url_3:"&is_disable_language=1",
                                         
                                    }, () => {
                                        this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                已激活
                            </Button>



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_3 === "&is_disable_language=0" ? "#58924d" : "", color: url_3 === "&is_disable_language=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_disable_language=0"    + url_1 +  url_2  , 
                                         url_3:"&is_disable_language=0",
                                        
                                    }, () => {
                                        this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                未激活
                            </Button> 
                        </div>
                    </div>









                <Table
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    columns={this.columns}
                    // scroll={{ x: 1200 }}
                    // rowClassName={(record) => {
                    //     if (record.is_lock === 1) {
                    //         return "userRowBackground"
                    //     }
                    // }}
                    pagination={{
                        pageSize: meta && meta.per_page,
                        total: meta && meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(e.current)
                    }}
                    className="waperBox"
                />

                <Modal
                    title={intl.get('nav.goodsManagement.promotionalgoods.OffTheShelf_tips')}
                    visible={this.state.Visible}
                    onOk={() => {
                        if (closed === "confirm") {
                            this.handleOverOff()
                        } else {
                            message.error(intl.get("nav.goodsManagement.promotionalgoods.InputError"))
                        }
                    }}
                    onCancel={() => this.setState({ Visible: false, closed: "" })}
                    style={{ marginTop: "100px" }}
                >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <span style={{ marginBottom: "15px" }}>{intl.get("nav.goodsManagement.promotionalgoods.EnterClosed")}</span>
                        <Input
                            style={{ width: "300px", textAlign: 'center' }}
                            value={closed}
                            onChange={(e) => {
                                e.persist()
                                this.setState({
                                    closed: e.target.value.toLowerCase()
                                })
                            }} />
                    </div>

                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(PromotionalGoodsList))

