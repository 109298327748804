import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon } from 'antd';
import { getPartner, UpdateShopMessage, GetConfigSet } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class Behavior extends React.Component {

    state = {
        min_pay_fee_on_online: "",
        is_charge_packing_fee: false,
        business_status: true,
        is_allday_order: true,
        is_open_recommend: true,
        is_user_address: true,
        is_force_user_address: true,
        is_holiday_business: true,
        is_open_share: true,
        is_open_spu_many_help: false,
        order_expire: "",
        activity_order_expire: "",
    };

    async componentDidMount() {
        let res = await getPartner()
        let configData = await GetConfigSet()
        let data = res.data
        this.setState({
            min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
            // is_charge_packing_fee: data.is_charge_packing_fee === "1" ? true : false,
            business_status: data.business_status == 1 ? true : false,
            is_allday_order: data.is_allday_order == 1 ? true : false,
            is_open_recommend: data.is_open_recommend == 1 ? true : false,
            // is_user_address: data.is_user_address == 1 ? true : false,
            // is_force_user_address: data.is_force_user_address == 1 ? true : false,
            is_holiday_business: data.is_holiday_business == 1 ? true : false,
            is_open_share: data.is_open_share == 1 ? true : false,
            is_authorization_code: data.is_authorization_code == 1 ? true : false,
            is_discount: data.is_discount == 1 ? true : false,
            order_expire: data.order_expire || 30,
            activity_order_expire: data.activity_order_expire || 30,
            is_open_spu_many_help: data.is_open_spu_many_help == 1 ? true : false,
            many_help_order_expire: data.many_help_order_expire || 1,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { business_status, is_allday_order,is_open_recommend, is_holiday_business, is_open_share } = this.state
        let data = {}
        data.business_status = business_status ? 1 : "0"
        data.is_allday_order = is_allday_order ? 1 : "0"
        data.is_open_recommend = is_open_recommend ? 1 : "0"
        // data.is_user_address = is_user_address ? 1 : "0"
        // data.is_force_user_address = is_force_user_address ? 1 : "0"
        data.is_holiday_business = is_holiday_business ? 1 : "0"
        data.is_open_share = is_open_share ? 1 : "0"
        data.order_expire = this.props.form.getFieldValue('order_expire')
        data.activity_order_expire = this.props.form.getFieldValue('activity_order_expire')
        data.many_help_order_expire = this.props.form.getFieldValue('many_help_order_expire')

        UpdateShopMessage(data)
    };

    check_order_expire(rule, value, callback) {
        if (value > 180 || value < 15) {
            callback(new Error(intl.get("setup.form.order_expire_tip")));
        } else {
            callback();
        }
    }

    activity_order_expire(rule, value, callback) {
        if (value > 180 || value < 15) {
            callback(new Error(intl.get("setup.form.order_expire_tip")));
        } else {
            callback();
        }
    }

    many_help_order_expire(rule, value, callback) {
        if (value > 90 || value < 1) {
            callback(new Error(intl.get("setup.form.activity_order_expire_tip")));
        } else {
            callback();
        }
    }

    render() {
        const { min_pay_fee_on_online,is_allday_order, business_status, is_open_recommend, is_authorization_code, is_discount, is_holiday_business, is_open_share, order_expire, activity_order_expire, is_open_spu_many_help, many_help_order_expire } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                 

                
                {
                    is_authorization_code &&
                    <Card style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('setup.form.order_expire_header')}</div>
                        <Form.Item label={<span>{intl.get('setup.form.order_expire')}</span>}>
                            {getFieldDecorator('order_expire', {
                                rules: [
                                    { required: false },
                                    { validator: (rule, value, callback) => { this.check_order_expire(rule, value, callback) } }
                                ],
                                getValueFromEvent: (event) => {
                                    return event.target.value.replace(/[^\d]/g, '')
                                },
                                initialValue: order_expire
                            })(<Input suffix="days" style={{ width: "100px" }} />)}
                        </Form.Item>
                        {
                            is_discount &&
                            <Form.Item label={<span>{intl.get('setup.form.activity_order_expire')}</span>}>
                                {getFieldDecorator('activity_order_expire', {
                                    rules: [
                                        { required: false },
                                        { validator: (rule, value, callback) => { this.activity_order_expire(rule, value, callback) } }
                                    ],
                                    getValueFromEvent: (event) => {
                                        return event.target.value.replace(/[^\d]/g, '')
                                    },
                                    initialValue: activity_order_expire
                                })(<Input suffix="days" style={{ width: "100px" }} />)}
                            </Form.Item>
                        }

                        {is_open_spu_many_help &&
                            <Form.Item label={<span>{intl.get('setup.form.many_help_order_expire')}</span>}>
                                {getFieldDecorator('many_help_order_expire', {
                                    rules: [
                                        { required: false },
                                        { validator: (rule, value, callback) => { this.many_help_order_expire(rule, value, callback) } }
                                    ],
                                    getValueFromEvent: (event) => {
                                        return event.target.value.replace(/[^\d]/g, '')
                                    },
                                    initialValue: many_help_order_expire
                                })(<Input suffix="days" style={{ width: "100px" }} />)}
                            </Form.Item>
                        }
                    </Card>
                }




                


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
