import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button } from 'antd';
import { getOrderLine } from "../../action/goodAction";
import "../category/category.less"
import intl from 'react-intl-universal';


class orderLine extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], editingKey: '' };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '15%',
                editable: false,
                align: "center",
            },
            {
                title: intl.get('orderline.table.price'),
                dataIndex: 'price',
                width: '15%',
                editable: true,
                align: "center",
            },
            {
                title: intl.get('orderline.table.payStatus'),
                width: '15%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span>{record.is_pay === 0 ? intl.get('home.table.status.name_n') : intl.get('home.table.status.name_y')}</span>
                    )
                }
            }, {
                title: intl.get('orderline.table.useStatus'),
                width: '15%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span>{record.is_finish === 1 ? intl.get('home.table.useStatus.name_y') : intl.get('home.table.useStatus.name_n')}</span>
                    )
                }
            },
            {
                title: intl.get('orderline.table.operation'),
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    {intl.get('home.table.operation.btn.delete')}
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        let res = await getOrderLine();
        this.setState({ data: res.data.data })
    }


    async handleDelete(data) {
        // await deleteOrder(data.id)
        // const dataSource = [...this.state.data];
        // this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {

        const columns = this.columns.map(col => {
            return {
                ...col,
                onCell: record => ({
                    record,
                    title: col.title,
                }),
            };
        });

        return (
            <Table
                rowKey={record => record.id}
                // bordered
                dataSource={this.state.data}
                columns={columns}
                pagination={{
                    // onChange: this.cancel,
                    hideOnSinglePage: true
                }
                }
                className="waperBox"
            />
        );
    }
}

export default withRouter(Form.create()(orderLine))

