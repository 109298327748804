import React from 'react'
import { Form, Input, Card, Button } from 'antd';
import { getPartner } from "../../action/authAction";
import intl from 'react-intl-universal';
import licenceImahe from "../../img/licence.png"
class Licence extends React.Component {
    state = {
        is_authorization_code: false,
        bdid: ""
    };
    async componentDidMount() {
        let res = await getPartner()
        let data = res.data
        this.setState({
            bdid: data.bdid,
            is_authorization_code: data.is_authorization_code === 1 ? true : false,
        })
    }
    render() {
        const { is_authorization_code, bdid } = this.state;
        if (bdid) {
            return <Card>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ width: "300px", display: "flex", alignItems: "center" }}>
                        <img style={{ width: "60px" }} src={licenceImahe} alt='="' />
                        <div style={{ marginLeft: "20px" }}>
                            <p>{intl.get("setup.form.licence")}:</p>
                            {
                                is_authorization_code ?
                                    <span>{bdid}</span> :
                                    <span style={{ color: "#ff0000" }}>{intl.get("setup.form.no_licence")}</span>
                            }


                        </div>

                    </div>
                    {
                        !is_authorization_code ?
                            <div style={{ display: "flex", justifyContent: "center", marginLeft: "50px" }}>
                                <Button type="primary"
                                    style={{ whiteSpace: "pre" }}
                                    onClick={() => this.props.history.push("/package")}
                                >
                                    &nbsp;{intl.get('nav.package')}&nbsp;
                                </Button>
                            </div> :
                            <div />
                    }
                </div>
            </Card>
        } else {
            return null
        }

    }
}

export default Licence
