import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Table, Modal, Checkbox, Row, Col, InputNumber, message,Popconfirm } from 'antd';
import { AddGoodsAnnexType, getGoodsAnnexTypeItemList } from "../../action/goodAction";
import intl from 'react-intl-universal';

class NewGoodsAnnexType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            isRequired: false,
            isCheck: false,
            ChooseData: [],
            AnnexItemData: [],
            isModalVisible: false,
            ChooseDefaultData: [],

        };
        this.columns = [
            {
                title: intl.get('goods.list.goodsAnnex.name'),
                dataIndex: 'name',
                width: '15%',
                align: "center",
            },
            {
                title: intl.get('goods.list.goodsAnnexType.max_count'),
                width: '15%',
                align: "center",
                render: (record) => {
                    return (
                        <InputNumber min={0} defaultValue={record.max_quantity} onChange={(value) => {
                            let { ChooseData } = this.state
                            for (let i in ChooseData) {
                                if (ChooseData[i].id === record.id) {
                                    ChooseData[i].max_quantity = value
                                }
                            }
                            this.setState({
                                ChooseData
                            })
                        }} />
                    )
                }
            },
            {
                title: intl.get('goods.list.goodsAnnex.price'),
                dataIndex: 'price',
                width: '15%',
                editable: true,
                align: "center",
            },
            {
                title: intl.get('goods.list.goodsAnnexType.set_default'),
                width: '10%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_default === 1} onChange={(value) => {
                            let { ChooseData, isCheck } = this.state
                            let set_default_num = 0
                            let max_select = this.props.form.getFieldValue('max_select') || 1
                            for (let i in ChooseData) {
                                if (ChooseData[i].is_default === 1) {
                                    set_default_num += 1
                                }
                                if (ChooseData[i].id === record.id) {
                                    if (set_default_num < parseInt(max_select)) {
                                        ChooseData[i].is_default = value ? 1 : 0
                                    } else if (!value) {
                                        ChooseData[i].is_default = value
                                    }
                                    else {
                                        message.error(intl.get("goods.list.goodsAnnexType.set_default_num.error"))
                                    }
                                    if (!value) {
                                        ChooseData[i].force_default = 0
                                    }
                                }
                            }
                            this.setState({
                                ChooseData
                            })
                        }} />
                    )
                }
            },
            {
                title: intl.get('goods.list.goodsAnnexType.must_default'),
                width: '10%',
                align: "center",
                render: (record) => {

                    return (
                        <Switch checked={record.force_default === 1} onChange={(value) => {
                            let { ChooseData } = this.state
                            let must_default_num = 0
                            let min_select = this.props.form.getFieldValue('min_select') || 1
                            if (this.state.isRequired) {
                                for (let i in ChooseData) {
                                    if (ChooseData[i].force_default === 1) {
                                        must_default_num += 1
                                    }
                                }
                                for (let i in ChooseData) {
                                    if (!this.state.isCheck) {
                                        if (ChooseData[i].id === record.id) {
                                            ChooseData[i].force_default = 1
                                            ChooseData[i].is_default = 1
                                        } else {
                                            ChooseData[i].force_default = 0
                                            ChooseData[i].is_default = 0
                                        }
                                    } else {
                                        if (ChooseData[i].id === record.id) {
                                            if (must_default_num < parseInt(min_select)) {
                                                ChooseData[i].force_default = value ? 1 : 0
                                                if (value) {
                                                    ChooseData[i].is_default = 1
                                                }
                                            } else if (!value) {
                                                ChooseData[i].force_default = value
                                            }
                                            else {
                                                message.error(intl.get("goods.list.goodsAnnexType.must_default_num.error"))
                                            }
                                        }
                                    }
                                }
                            } else {
                                message.error(intl.get("goods.list.goodsAnnexType.must_default.error"))
                            }
                            this.setState({
                                ChooseData
                            })
                        }} />
                    )
                }
            },
            {
                title: intl.get('pack.form.opt'),
                dataIndex: 'operation',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return <Button onClick={() => this.HandleDeleteAnnexTypeItem(record)}>
                        {intl.get('home.table.operation.btn.delete')}
                    </Button>
                },
            },
        ]
    }

    async componentDidMount() {
        let res = await getGoodsAnnexTypeItemList(1, "")
        this.setState({
            AnnexItemData: res.data.data,
        })
    }




    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.required = this.state.isRequired ? "1" : "0"
                values.check = this.state.isCheck ? "1" : "0"
                let { ChooseData } = this.state
                let goods_annex_type_items = []
                let force_default_item = []
                for (let i in ChooseData) {
                    if (ChooseData[i].force_default === 1) {
                        force_default_item.push(ChooseData[i].force_default)
                    }
                    goods_annex_type_items.push({
                        "goods_annex_type_item_id": ChooseData[i].id,
                        "max_quantity": ChooseData[i].max_quantity,
                        "is_default": ChooseData[i].is_default ? "1" : "0",
                        "force_default": ChooseData[i].force_default ? "1" : "0"
                    })
                }
                values.goods_annex_type_items = goods_annex_type_items
                if (this.state.isCheck) {
                    if (values.min_select > ChooseData.length) {
                        message.error(intl.get("goods.list.goodsAnnexType.chooseNumError"))
                        return false
                    }
                } else {
                    values.min_select = 1
                }
                if (!this.state.isCheck && !this.state.isRequired) {
                    values.min_select = 0
                    values.max_select = 1
                }
                if (parseInt(values.min_select) < force_default_item.length) {
                    message.error(intl.get("goods.list.goodsAnnexType.must_default_num.error"))
                    return false
                }
                if (ChooseData.length > 0) {
                    AddGoodsAnnexType(values).then(res => {
                        this.props.history.push("/goodsAnnexTypeList")
                    })
                } else {
                    message.error(intl.get("goods.list.goodsAnnexType.minOneError"))
                }

            }
        });
    };

    onChange = e => {
        this.setState({
            value: e
        });
    };

    handleCheckRequired = (required) => {
        let { ChooseData } = this.state
        if (!required) {
            for (let i in ChooseData) {
                ChooseData[i].force_default = 0
                ChooseData[i].is_default = 0
            }
        }
        this.setState({ isRequired: required, ChooseData })
    }
    handleCheckCheck = (check) => {
        let { ChooseData } = this.state
        if (!check) {
            for (let i in ChooseData) {
                ChooseData[i].force_default = 0
                ChooseData[i].is_default = 0
            }
        }
        this.setState({ isCheck: check, ChooseData })
    }

    checkPsd(rule, value, callback) {
        let max_select = this.props.form.getFieldValue('max_select');
        if (max_select && parseInt(max_select) < parseInt(value)) {
            callback(new Error(intl.get("goods.list.goodsAnnexType.min_select_error")));
        } else {
            callback();
        }
    }
    checkPsd2(rule, value, callback) {
        let { ChooseData } = this.state;
        if (ChooseData.length < parseInt(value)) {
            callback(new Error(intl.get("goods.list.goodsAnnexType.max_select_error")));
        } else {
            callback();
        }
    }

    HandleDeleteAnnexTypeItem = (item) => {
        let { ChooseData } = this.state
        for (let i in ChooseData) {
            if (ChooseData[i].id === item.id) {
                ChooseData.splice(i, 1)
            }
        }
        this.setState({ ChooseData }, () => this.HandleChooseDefault())
    }

    HandleChooseDefault = () => {
        let ChooseDefaultData = []
        let { ChooseData, AnnexItemData } = this.state
        for (let i in AnnexItemData) {
            for (let k in ChooseData) {
                if (ChooseData[k].id === AnnexItemData[i].id) {
                    ChooseDefaultData.push(AnnexItemData[i])
                }
            }
        }
        this.setState({ ChooseDefaultData: [...ChooseDefaultData] })
    }


    render() {
        const { ChooseData, isModalVisible, AnnexItemData, ChooseDefaultData } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('goods.list.goodsAnnexType.name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item label={intl.get('goods.list.goodsAnnexType.required')}>
                    <Switch checked={this.state.isRequired} onChange={this.handleCheckRequired} />
                </Form.Item>
                <Form.Item label={intl.get('goods.list.goodsAnnexType.check')}>
                    <Switch checked={this.state.isCheck} onChange={this.handleCheckCheck} />
                </Form.Item>
                {
                    this.state.isCheck && <div>
                        <Form.Item
                            label={intl.get('goods.list.goodsAnnexType.max_select')}
                        >
                            {getFieldDecorator('max_select', {
                                rules: [{ required: false }, { validator: (rule, value, callback) => { this.checkPsd2(rule, value, callback) } }],
                                initialValue: ChooseData.length || 1
                            })(<InputNumber type="number" min={1} />)}
                        </Form.Item>
                        <Form.Item
                            label={intl.get('goods.list.goodsAnnexType.min_select')}
                        >
                            {getFieldDecorator('min_select', {
                                rules: [{ required: true }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                                initialValue: this.state.isRequired ? 1 : 0
                            })(<InputNumber min={this.state.isRequired ? 1 : 0} type="number" />)}
                        </Form.Item>
                    </div>
                }


                <Form.Item label={<div></div>} colon={false}>
                    <div style={{ marginTop: "50px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            <div />
                            <Button type="primary"
                                onClick={() => this.setState({ isModalVisible: true })}
                            >
                                {intl.get('nav.goodsManagement.itemadditional.add')}
                            </Button>
                        </div>
                        <Table
                            rowKey={record => record.id}
                            dataSource={ChooseData}
                            columns={this.columns}
                            pagination={{
                                hideOnSinglePage: true
                            }}
                        />
                    </div>
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
                <Modal title={intl.get("goods.list.goodsAnnexType")}
                    visible={isModalVisible}
                    centered={true}
                    onCancel={() => this.setState({ isModalVisible: false })}
                    footer={null}
                    width={800}
                >
                    <Checkbox.Group style={{ width: '100%' }} value={ChooseDefaultData} onChange={(value) => {
                        for (let i in value) {
                            value[i].is_default = false
                            value[i].force_default = false
                            value[i].max_quantity = 1
                        }
                        this.setState({
                            ChooseData: value
                        }, () => this.HandleChooseDefault())
                    }}>
                        <Row>
                            {AnnexItemData.map((item, index) => {
                                return <Col span={6} key={index} style={{ marginBottom: "10px" }}>
                                    <Checkbox value={item}>{item.name}</Checkbox>
                                </Col>
                            })}
                        </Row>
                    </Checkbox.Group>
                </Modal>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewGoodsAnnexType))
