import React from 'react'
import { Form, Input, Card, Button,Switch } from 'antd';
import { getPartner,UpdateShopMessage } from "../../action/authAction";
import intl from 'react-intl-universal';
import licenceImahe from "../../img/token.png"
class UpLiveToken extends React.Component {
    state = {
        up_live_token: "",
        is_open_up_live: false,
        is_up_live: false,
    };
    async componentDidMount() {
 
        let res = await getPartner()
        let data = res.data
        this.setState({
            up_live_token: data.up_live_token,
            is_open_up_live: data.is_open_up_live == 1 ? true : false,
            is_up_live: data.is_up_live == 1 ? true : false,
        })
    }




    async HandleGetData() {
        let {  is_up_live } = this.state
        let data = {}
        //data.is_printer = is_printer ? 1 : "0"
        data.is_up_live = is_up_live ? 1 : "0"
        //UpdateShopMessage(data)
    }




    render() {
        const { up_live_token,is_up_live,is_open_up_live } = this.state;
        if (up_live_token) {
            return  ( 
                    



                 <Card>  
                 <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}> 
                    <div>
                        <span>第三方视频平台 ：</span>
                        <Switch 
                        disabled={!is_open_up_live}

                        checked={is_up_live} onChange={() => {
                            this.setState({
                                is_up_live: !is_up_live
                            }, () => {
                                 UpdateShopMessage({ "is_up_live": !is_up_live ? "1" : "0" }).then(res => {
                                        this.HandleGetData()
                                    })
                            })
                        }} />
                    </div>
                    </div> 


                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ width: "300px", display: "flex", alignItems: "center" }}>
                            <img style={{ width: "60px" }} src={licenceImahe} alt='="' />
                            <div style={{ marginLeft: "20px" }}>
                                <p>{intl.get("nav.plugin.third_party_video_platform")}token:</p>
                                <span>{up_live_token}</span>
                            </div>
                        </div> 
                    </div>


                    <Button
                        id="deliverybut"
                        type="primary"
                        style={{ marginRight: "10px", marginTop:"10px" }}
                        onClick={() => {
                            window.open("https://www.auepartner.com/");
                            //window.location.href = "https://www.auepartner.com/";
                              
                        }}
                    >
                        跳转到主播后台
                    </Button>





                </Card>
            )
            
        } else {
            return null
        }

    }
}

export default UpLiveToken
