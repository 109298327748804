import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Modal,InputNumber,Icon } from 'antd';
import { getUserPointsList, delPlugIn } from "../../action/authAction";

class PlugInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] , pointsVisible:false , points:0 , noteVisible:false,};
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '15%',
            },{
                title: '积分',
                //dataIndex: 'points',
                width: '15%',
                render: (record) => {
                    return <span>{record.points}{record.is_expire == 1?"[已过期]":""}</span>
                }
            },
             
             {
                title: '来源',
                dataIndex: 'explain',
                width: '10%',
                render: (record) => {
                    return <span>{record}</span>
                }
            } ,

            {
                title: <span>过期时间<Icon type="question-circle" style={{ marginLeft: "5px" }} 

                onClick={() => {
                    this.setState({
                        noteVisible: true
                    })
                }} 


                /> </span>, 
                width: '15%',
                align: "center",
                render: (record) => {
                    return record.expire_at && record.expire_at.substring(0, 10)
                }

             }, 
             

             {
                title: '日期', 
                width: '15%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
             }, 

             

            // {
            //     title: '操作',
            //     dataIndex: 'operation',
            //     width: '20%',
            //     align: "center",
            //     render: (text, record) => {
            //         return (
            //             <div className='deleteView'>
                           
            //                 <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
            //                     <Button type="danger" ghost>
            //                         删除
            //                     </Button>
            //                 </Popconfirm>
            //             </div>

            //         );
            //     },
            // },

        ];
    }

    async componentDidMount() {

        const id = this.props.location.pathname.substring(16) 
        let res = await getUserPointsList(id)
        this.setState({ data: res.data.data })
    }


    async handleDelete(data) {
        await delPlugIn(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {

        const { data,points,pointsVisible ,noteVisible } = this.state
        return (

            <div>


                {/*<div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px",  }}> 
                    <div style= {{ display: "flex" , alignItems: "center"  }}  > 
                        <Button type="primary"
                            onClick={() => 
                                this.setState({
                                    pointsVisible: true,  
                                })
                            }
                        >
                            积分充值
                        </Button> 
                    </div> 
                </div>*/}


            <Table
                rowKey='id'
                // bordered
                dataSource={this.state.data}
                columns={this.columns}
                pagination={{
                    hideOnSinglePage: true
                }}
                className="waperBox"
            />



            <Modal
                title="过期时间说明"
                visible={this.state.noteVisible}
                footer={null}
                onOk={() => { 
                    
                }}
                onCancel={() => {
                    this.setState({ 
                        noteVisible: false
                    })
                }} 
                > 
                <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" ,flexDirection: 'column' }} > 
                    <span>1.以用户的注册时间为基准，往后延长一个自然年为周期，一个自然年的结束时间为过期时间，如果用户获取的积分，在第二个自然年，则以第二个自然年的结束时间为过期时间，以此类推。</span> 
                    <span>2.过期积分和扣除积分，不存在过期时间。</span>
                </div>  
             </Modal> 


           
            <Modal
                title="请输入要充值的积分"
                visible={this.state.pointsVisible}
                onOk={() => { 
                    // if (money && update_pay_param_code) {
                    //     UpdateUser({ money,update_pay_param_code }, ItemId).then(res => {
                    //         this.setState({
                    //             money: "",
                    //             pointsVisible: false
                    //         }, () => {
                    //             this.GetUserData("")
                    //         })
                    //     })
                    // } else {
                    //     message.error("请先填入要充值的积分")
                    // }

                }}
                onCancel={() => {
                    this.setState({
                        //points: "",
                        pointsVisible: false
                    })
                }}


                >


                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} >
                    <InputNumber
                        max={10000}
                        value={points}
                        formatter={value => `${value}`}
                        parser={value => value.replace("$", '')}
                        style={{ width: "100%" }}
                        onChange={(e) => {
                            this.setState({
                                points: e
                            })
                        }} /> 
                </div>
                <span>备注：充值的金额可以为负值</span> 


             </Modal>

             </div>

        );
    }
}

export default withRouter(Form.create()(PlugInList))

