import React from 'react'
import { withRouter } from 'react-router-dom'
import { Descriptions, Card, Divider, Button } from 'antd';
import { myOrderDetail, submitPay } from "../../action/authAction";
import intl from 'react-intl-universal';


// const serviceStatus = {
//     'setup': '开通服务',
//      'year':'年服务',
//      'month':'月服务'
// }


class PlugInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: {} };
    }

    async componentDidMount() {
        const id = this.props.location.search.substring(1);
        if (id) {
            let res = await myOrderDetail(id);
            this.setState({ data: res.data })
        }

    }


    payHandle = async () => {
        const id = this.props.location.search.substring(1);
        await submitPay(id);
        if (id) {
            let res = await myOrderDetail(id);
            this.setState({ data: res.data })
        }
    }

    render() {
        const { data } = this.state;

        return (
            <Card>
                {data?.status === 0 && <div style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.payHandle}>支付</Button>
                </div>}
                <Descriptions title={intl.get('subscription.info.base')}>
                    <Descriptions.Item label="id">{data?.id}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('home.table.ordernumber')}>{data?.order_sn}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.status')}>{
                        data?.status === 0 ? intl.get('orders.status.zero') :
                            data?.status === 1 ? intl.get('orders.status.one') :
                                data?.status === 2 ? intl.get('orders.status.two') :
                                    data?.status === 3 ? intl.get('orders.status.three') :
                                        data?.status === 4 ? intl.get('orders.status.four') : null
                    }</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.pay_type')}>{data?.pay_type}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.price')}>{data?.price}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.real_price')}>{data?.real_price}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.use_money')}>{data?.use_money}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.is_trial')}>{data?.is_trial === 1 ? intl.get('orders.is_trial.one') : intl.get('orders.is_trial.zero')}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.is_expire')}>{data?.is_expire === 1 ? intl.get('subscription.expire.one') : intl.get('subscription.expire.zero')}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.payTime')}>{data?.pay_at}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('order.table.remark')}>{data?.remark}</Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions title={intl.get('orders.subsrc')}>
                    <Descriptions.Item label="id">{data?.partnerSubscription?.id}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.price')}>{data?.partnerSubscription?.price}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.type')}>{data?.partnerSubscription?.prod_type}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('orders.status')}>{
                        data?.partnerSubscription?.status === 0 ? intl.get('orders.status.zero') :
                            data?.partnerSubscription?.status === 1 ? intl.get('orders.status.one') :
                                data?.partnerSubscription?.status === 2 ? intl.get('orders.status.two') :
                                    data?.partnerSubscription?.status === 3 ? intl.get('orders.status.three') :
                                        data?.partnerSubscription?.status === 4 ? intl.get('orders.status.four') : null
                    }</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.serviceType')}>{
                        data?.partnerSubscription?.service_type === 'setup' ? intl.get('subscription.serviceType.s') :
                            data?.partnerSubscription?.service_type === 'year' ? intl.get('subscription.serviceType.y') :
                                data?.partnerSubscription?.service_type === 'month' ? intl.get('subscription.serviceType.m') : ''
                    }</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.startTime')}>{data?.partnerSubscription?.service_start_at}</Descriptions.Item>
                    <Descriptions.Item label={intl.get('subscription.endTime')}>{data?.partnerSubscription?.service_end_at}</Descriptions.Item>
                </Descriptions>
                <Divider />
            </Card>

        );
    }
}

export default withRouter(PlugInList)

