import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, List,Popconfirm } from 'antd';
import { AddDeliveryAddress, QueryAddress } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")
class NewAddress extends React.Component {

    state = {
        suburbsList: "",
        isShow: true
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                AddDeliveryAddress(values)
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        const { suburbsList, isShow } = this.state

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label={intl.get('suburbs.table.suburbs')}
                >
                    {getFieldDecorator('suburbs', {
                        rules: [{ required: true, message: intl.get('seat.table.please'), whitespace: true }],
                    })(<Input onChange={(e) => {
                        e.persist()
                        let key = e.target.value
                        if (key.length > 2) {
                            QueryAddress(key).then(res => {
                                this.setState({
                                    suburbsList: res.data,
                                    isShow: true
                                })
                            })
                        }
                    }}
                    />)}
                    {
                        suburbsList && isShow && <List
                            size="small"
                            bordered
                            dataSource={suburbsList}
                            renderItem={item =>
                                <List.Item onClick={() => {
                                    this.props.form.setFieldsValue({
                                        suburbs: item.name + ", " + item.state.abbreviation + ", " + item.postcode
                                    })
                                    this.setState({ isShow: false })
                                }}>{item.name}, {item.state.abbreviation}, {item.postcode}</List.Item>
                            }
                        />
                    }
                </Form.Item>


                <Form.Item
                    label={intl.get('suburbs.table.price')}
                >
                    {getFieldDecorator('price', {
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewAddress))
