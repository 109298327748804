import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon,Select } from 'antd';
import { getPartner, UpdateShopMessage, GetConfigSet } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class Behavior extends React.Component {

    state = {
        min_pay_fee_on_online: "",
        is_charge_packing_fee: false,
        business_status: true,
        is_allday_order: true,
        is_partner_moon_enable: true,
        is_partner_gst: true,
        is_open_recommend: true,
        is_user_address: true,
        is_force_user_address: true,
        is_holiday_business: true,
        is_open_share: true,
        is_open_spu_many_help: false,
        order_expire: "",
        activity_order_expire: "",
        is_audit:false,
        is_open_moon_enable:false,
        is_open_gst:false,
        unpay_wait_time:0,
        user_points_exchage_set:"",
        system_points_exchange_set:0,//系统设置的积分兑换比例

        TimeData:[
                {   'id':'10',
                    'name':'10 mins'
                },

                {   'id':'15',
                    'name':'15 mins'
                },

                {'id':'30',
                    'name':'30 mins'
                },

                {'id':'60',
                    'name':'60 mins'
                },



                ]


    };

    async componentDidMount() {
        let res = await getPartner()
        let configData = await GetConfigSet()
        let data = res.data
        this.setState({
            min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value, 
            user_points_exchage_set:data.user_points_exchage_set,
            system_points_exchange_set:configData.data.data.find(el => el.key === "system_points_exchange_set").value, 
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let {user_points_exchage_set  } = this.state
        let data = {} 
        data.user_points_exchage_set = this.props.form.getFieldValue('user_points_exchage_set')

        UpdateShopMessage(data)
    };

    check_order_expire(rule, value, callback) {
        if (value > 180 || value < 15) {
            callback(new Error(intl.get("setup.form.order_expire_tip")));
        } else {
            callback();
        }
    }

    activity_order_expire(rule, value, callback) {
        if (value > 180 || value < 15) {
            callback(new Error(intl.get("setup.form.order_expire_tip")));
        } else {
            callback();
        }
    }

    many_help_order_expire(rule, value, callback) {
        if (value > 90 || value < 1) {
            callback(new Error(intl.get("setup.form.activity_order_expire_tip")));
        } else {
            callback();
        }
    }

    render() {
        const {user_points_exchage_set, system_points_exchange_set  } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}> 
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>积分兑换比例</div>

                    <div style={{ display:"flex",   flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }}>

                    <div style={{ width:"100%"}}> 
                     <Form.Item
                        label={<span>兑换$1需要积分
                        <Tooltip title='修改积分兑换比例之前，请删除积分兑换项' >
                            <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                        </Tooltip></span>}

                    >
                        {getFieldDecorator('system_points_exchange_set', {
                            rules: [{ required: false }],
                            initialValue: system_points_exchange_set
                        })(<Input disabled />)}
                    </Form.Item>
                    </div>

                   

                    </div>
                </Card>
 
                {/*<Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>*/}
            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
