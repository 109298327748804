import React from "react";
import { withRouter } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { Table, Input, InputNumber, Popconfirm, Form, Button, Modal, Tooltip, Icon } from 'antd';
import { GetAdminList, putAdmin, deleteAdmin } from "../../action/authAction";
import intl from 'react-intl-universal';

const EditableContext = React.createContext();


class AdminList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            passwordVisible: false,
            newPassword: "",
            confirmPassword: "",
            isError: false,
            itemId: "",
            newPasswordError: false,
            confirmPasswordError: false,
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '15%',
                editable: false,
                align: "center",
            },
            {
                title: intl.get('user.table.header'),
                dataIndex: 'head_image',
                width: '15%',
                editable: true,
                align: "center",
                render: (record) => {
                    return (
                        <img src={record} style={{ width: "60px", objectFit: "contain" }} />
                    )
                }
            },
            {
                title: intl.get('admin.table.name'),
                dataIndex: 'name',
                width: '30%',
                editable: true,
                align: "center",
            },

            {
                title: intl.get('admin.table.no'),
                dataIndex: 'unique',
                width: '30%',
                editable: false,
                align: "center",
            },
            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return <div className='deleteView'>
                        <Button style={{ marginRight: "5px" }} onClick={() => this.showPasswordModal(record)}>
                            {intl.get('nav.setting.password')}
                        </Button>
                        <Button style={{ marginRight: "5px" }} onClick={() => {
                            this.props.history.push("/EditAdmin/" + record.id)
                        }}>
                            {intl.get('seat.table.btn.edit')}
                        </Button>
                        <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                            <Button>
                                {intl.get('home.table.operation.btn.delete')}
                            </Button>
                        </Popconfirm>
                    </div >
                },
            },

        ];
    }

    async componentDidMount() {
        this.getData()
    }

    getData = () => {
        GetAdminList().then(res => {
            this.setState({ data: res.data.data })
        })

    }

    async handleDelete(data) {
        await deleteAdmin(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }


    showPasswordModal = (item) => {
        this.setState({
            passwordVisible: true,
            itemId: item.id,
            newPassword: "",
            confirmPassword: "",
            newPasswordError: false,
            confirmPasswordError: false,
        });
    };

    handlePasswordOk = e => {
        const { newPassword, confirmPassword, itemId, newPasswordError, confirmPasswordError } = this.state

        if (newPassword !== confirmPassword) {
            this.setState({
                isError: true
            })
        } else {
            this.setState({
                isError: false
            })
            if (newPassword && confirmPassword && !newPasswordError && !confirmPasswordError) {
                putAdmin({ "password": newPassword }, itemId).then(res => {
                    this.setState({
                        passwordVisible: false,
                    });
                })
            }

        }

    };

    handleCancel = e => {
        this.setState({
            passwordVisible: false,
        });
    };




    render() {
        let { newPassword, newPasswordError, confirmPassword, confirmPasswordError } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <div>
                        <Tooltip title={intl.get("admin.table.name_tips")}>
                            <Icon type="question-circle" style={{ marginRight: "10px" }} />
                        </Tooltip>
                        <Button type="primary" onClick={() => {
                            this.props.history.push("/NewAdmin")
                        }}>
                            <PlusOutlined /> {intl.get('admin.table.new')}
                        </Button>
                    </div>
                </div>
                <Table
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    columns={this.columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
                <Modal
                    title={intl.get('nav.setting.password')}
                    visible={this.state.passwordVisible}
                    onOk={this.handlePasswordOk}
                    onCancel={this.handleCancel}
                >
                    <div style={{ height: "60px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>{intl.get('nav.setting.password.password')}:</span>
                            <Input
                                value={newPassword}
                                onChange={(e) => {
                                    e.persist()
                                    //let value = e.target.value.replace(/\W/g, '')
                                    let value = e.target.value;

                                    // if (value.length > 16 || value.length < 6) {
                                    //     this.setState({
                                    //         newPasswordError: true
                                    //     })
                                    // } else {
                                    //     this.setState({
                                    //         newPasswordError: false
                                    //     })
                                    // }


                                    //密码正则校验
                                    var patt=/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/g;
                                    var result=patt.exec(value);    
                                    if(result == null){  
                                        this.setState({
                                            newPasswordError: true
                                        }) 
                                    }else {
                                        if (value.length > 16 || value.length < 6) {
                                            this.setState({
                                                newPasswordError: true
                                            })

                                        } else {
                                            this.setState({
                                                newPasswordError: false
                                            })
                                        }   
                                    }





                                    this.setState({
                                        newPassword: value
                                    })
                                }} />
                        </div>
                        {
                            this.state.newPasswordError &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请输入6-16位数字和字母，允许特殊符号</span>
                            </div>
                        }
                    </div>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ width: "120px", textAlign: "right", marginRight: 5 }}>{intl.get('nav.setting.password.confirm')}:</span>
                            <Input
                                value={confirmPassword}
                                onChange={(e) => {
                                    e.persist()
                                    //let value = e.target.value.replace(/\W/g, '')
                                    let value = e.target.value


                                    // if (value.length > 16 || value.length < 6) {
                                    //     this.setState({
                                    //         confirmPasswordError: true
                                    //     })
                                    // } else {
                                    //     this.setState({
                                    //         confirmPasswordError: false
                                    //     })
                                    // }


                                    //密码正则校验
                                    var patt=/^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/g;
                                    var result=patt.exec(value);    
                                    if(result == null){
                                        this.setState({
                                            confirmPasswordError: true
                                        })
                                    }else{
                                        if (value.length > 16 || value.length < 6) {
                                            this.setState({
                                                confirmPasswordError: true
                                            })
                                        } else {
                                            this.setState({
                                                confirmPasswordError: false
                                            })
                                        }
                                    } 





                                    this.setState({
                                        confirmPassword: value
                                    })
                                }} />
                        </div>
                        {
                            this.state.isError &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>{intl.get("nav.setting.password.error")}</span>
                            </div>
                        }
                        {
                            this.state.confirmPasswordError &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ width: "120px", textAlign: "right", marginRight: 5 }} />
                                <span style={{ width: "100%", fontSize: "12px", marginTop: "4px", color: "#ff0000" }}>请输入6-16位数字和字母，允许特殊符号</span>
                            </div>
                        }

                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(AdminList))

