import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Upload, message, Icon, Popconfirm } from 'antd';
import { getCategoryDetail, putCategory } from "../../action/goodAction";
import intl from 'react-intl-universal';

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error(intl.get('file.type.placehorder'));
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.error(intl.get('file.size.placehorder'));
    }
    return isJpgOrPng && isLt1M;
}


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

class EditCategory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            IsShow: true,
            imageUrl: "",
            goodImage: "",
            loading: false,
            data: ""
        }
    }

    componentDidMount() {
        const id = this.props.location.pathname.substring(14)
        getCategoryDetail(id).then(res => {
            this.setState({
                data: res.data,
                imageUrl: res.data.image,
                IsShow: res.data.is_show === 1 ? true : false
            })
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.is_show = this.state.IsShow ? "1" : "0"
            if (!err) {
                if (values.name) {
                    this.SubmitData(values)
                }
            }

        });
    };

    async SubmitData(data) {
        const id = this.props.location.pathname.substring(14)
        let formData = new FormData();
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }
        }
        if (this.state.goodImage) {
            formData.append("image_file", this.state.goodImage)
        }

        await putCategory(formData, id).then(res => {
            this.props.history.push("/cateList")
        })

    }

    handleCheckIsShow = (checked) => {
        if (checked) {
            this.setState({ IsShow: true })
        } else {
            this.setState({ IsShow: false })
        }
    }

    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            getBase64(info.file.originFileObj, (imageUrl, img) => {
                this.setState({
                    imageUrl,
                    loading: false,
                    goodImage: img
                })
            }

            );
        }
    };

    render() {
        let { IsShow, imageUrl, loading, data } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('category.list.name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: intl.get('category.list.name.horder'), whitespace: true }],
                        initialValue: data.name
                    })(<Input maxLength={20} />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('category.list.sort')}
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: data.sort
                    })(<Input />)}
                </Form.Item>
                <Form.Item label={intl.get('category.list.isShow')}>
                    <Switch checked={IsShow} onChange={this.handleCheckIsShow} />
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(EditCategory))
