import React, { Component } from 'react';
import * as Barcode from 'jsbarcode';

class SimpleBarcode extends Component {
  componentDidMount() {
    this.createBarcode();
  }

  componentDidUpdate() {
    this.createBarcode();
  }

  createBarcode = () => {
    if (!this.barcode) return;
    const {
      width = 0.5, height = 20, margin = 0, fontSize = 12, textAlign = "center", label, displayValue = false,
    } = this.props;
    if (!label) {
      return;
    }
    Barcode(this.barcode, label, {
      displayValue,
      width,
      height,
      margin,
      fontSize,
      textAlign
    });
  };

  render() {
    return (
      <svg
        ref={(ref) => {
          this.barcode = ref;
        }}
      />
    );
  }
}

export default SimpleBarcode;
