import { Axios } from "../util/request";
import { API } from "../util/api";
import { getLangType } from '../util/function';
import intl from 'react-intl-universal';

// 新建广告
export const AddAd = (data) => {
    return Axios.ajax({
        url: API.NewAd.advertisement,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取广告列表
export const getAdList = () => {
    return Axios.ajax({
        url: API.NewAd.advertisement + "?request_language_id=" + getLangType(),
        method: "get"
    })
}

// 删除广告
export const deleteAd = (id) => {
    return Axios.ajax({
        url: API.NewAd.advertisement + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

// 获取广告详情
export const getAdDeatil = (id) => {
    return Axios.ajax({
        url: `${API.NewAd.advertisement}/${id}${API.NewAd.include}&request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改广告
export const UpdateAd = (data, id) => {
    return Axios.ajax({
        url: API.NewAd.advertisement + API.NewAd.update + "/" + id,
        method: "post",
        tips: intl.get("update.success"),
        data
    })
}

// 新建菜单
export const AddMenu = (data) => {
    return Axios.ajax({
        url: API.Menu,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取菜单列表
export const getMenuList = () => {
    return Axios.ajax({
        url: API.Menu,
        method: "get"
    })
}

// 删除菜单
export const deleteMenu = (id) => {
    return Axios.ajax({
        url: API.Menu + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}
// 获取广告详情
export const getMenuDeatil = (id) => {
    return Axios.ajax({
        url: `${API.Menu}/${id}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 修改菜单
export const UpdateMenu = (data, id) => {
    return Axios.ajax({
        url: API.Menu + "/" + id,
        method: "post",
        data
    })
}

// 新建公告
export const AddAnnouncement = (data) => {
    return Axios.ajax({
        url: API.partnerAnnouncement,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取公告列表
export const getAnnouncementList = () => {
    return Axios.ajax({
        url: `${API.partnerAnnouncement}?request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 获取公告告详情
export const getAnnouncementDeatil = (id) => {
    return Axios.ajax({
        url: `${API.partnerAnnouncement}/${id}?request_language_id=${getLangType()}`,
        method: "get"
    })
}
//修改公告
export const UpdateAnnouncement = (data, id) => {
    return Axios.ajax({
        url: API.partnerAnnouncement + "/" + id,
        method: "post",
        tips: intl.get("update.success"),
        data
    })
}

// 删除公告
export const deleteAnnouncement = (id) => {
    return Axios.ajax({
        url: API.partnerAnnouncement + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

// 获取公告内容
export const getEditorContent = (url) => {
    return Axios.ajax({
        url: `${url}?request_language_id=${getLangType()}`,
        method: "get",
        noToken: true
    })
}

// 获取系统公告列表
export const getSystemAnnouncementList = () => {
    return Axios.ajax({
        url: API.systemAnnouncement,
        method: "get"
    })
}

// 获取系统公告告详情
export const getSystemAnnouncementDetail = (id) => {
    return Axios.ajax({
        url: "systemAnnouncement/" + id,
        method: "get"
    })
}

// 新建相册
export const AddPartnerPhoto = (data) => {
    return Axios.ajax({
        url: API.partnerPhoto,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取相册列表
export const getPartnerPhotoList = () => {
    return Axios.ajax({
        url: `${API.partnerPhoto}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 获取相册详情
export const getPartnerPhotoDetail = (id) => {
    return Axios.ajax({
        url: `${API.partnerPhoto}/${id}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

//修改相册
export const UpdatePartnerPhoto = (data, id) => {
    return Axios.ajax({
        url: "partnerPhotoUpdate/" + id,
        method: "post",
        tips: intl.get("update.success"),
        data
    })
}

// 删除相册
export const deletePartnerPhoto = (id) => {
    return Axios.ajax({
        url: API.partnerPhoto + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}






// 新建打折方式
export const AddDiscount = (data) => {
    return Axios.ajax({
        url: API.partnerServiceTypeDiscount,
        method: "post",
        tips: intl.get("summit.success"),
        data
    })
}

// 获取打折方式列表
export const getDiscountList = () => {
    return Axios.ajax({
        url: `${API.partnerServiceTypeDiscount}?request_language_id=${getLangType()}`,
        method: "get"
    })
}

// 删除打折方式
export const deleteDiscount = (id) => {
    return Axios.ajax({
        url: API.partnerServiceTypeDiscount + "/" + id,
        method: "delete",
        tips: intl.get("delete.success"),
    })
}

// 获取打折方式详情
export const getDiscountDeatil = (id) => {
    return Axios.ajax({
        url: `${API.partnerServiceTypeDiscount}/${id}?request_language_id=${getLangType()}`,
        method: "get"
    })
}


// 修改打折方式
export const UpdateDiscount = (data, id) => {
    return Axios.ajax({
        url: API.partnerServiceTypeDiscount + "/" + id,
        method: "put",
        tips: intl.get("update.success"),
        data
    })
}

// 获取导航列表
export const getPartnerNavList = () => {
    return Axios.ajax({
        url: `${API.partnerNav}?request_language_id=${getLangType()}`,
        method: "get"
    })
}
// 获取文档列表
export const getDocumentList = (page,category_id) => {

    if(category_id){
        return Axios.ajax({
            url: API.document + "?category_id="+ category_id+"&request_language_id=" + getLangType() + "&page=" + page,
            method: "get"
         })
    }else{
        return Axios.ajax({
            url: API.document + "?request_language_id=" + getLangType() + "&page=" + page,
            method: "get"
         })
    }
    
}
// 获取文档详情
export const getDocumentDetail = (id) => {
    return Axios.ajax({
        url: API.document + "/" + id,
        method: "get"
    })
}

// 获取文档内容
export const getContent = (url) => {
    return Axios.ajax({
        url: url,
        method: "get",
        noToken: true
    })
}




// 获取文档分类
export const GetDocumentcategory = (page) => {
    return Axios.ajax({
        url: API.documentcategory + "?request_language_id=" + getLangType() + "&page=" + page,
        method: "get"
    })
}