import React from 'react'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Upload, message, Icon, Popconfirm, TimePicker, Select, Switch } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import intl from 'react-intl-universal';
import { putHolidayBusiness, getHolidayBusinessDetail, getHolidayList } from "../../../action/authAction";
import moment from 'moment';
const { Option } = Select
class EditHolidayBusiness extends React.Component {

    state = {
        business_hours: [],
        Holiday_id: "",
        HolidayData: "",
        is_rest: false
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(21)
        let data = await getHolidayBusinessDetail(id)
        let HolidayData = await getHolidayList("?state=nsw&page_size=999")
        let business_hours_data = data.data.business_hours
        for (let i in business_hours_data) {
            business_hours_data[i] = business_hours_data[i].split("~")
        }
        this.setState({
            business_hours: data.data.business_hours,
            Holiday_id: data.data.holiday_id,
            is_rest: data.data.is_rest === 1 ? true : false,
            HolidayData: HolidayData.data.data
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const id = this.props.location.pathname.substring(21)
        let data = {}
        let key = []
        let { business_hours, Holiday_id, is_rest } = this.state
        for (let i in business_hours) {
            let business_hours_item = business_hours[i]
            let item = business_hours_item[0] + "-" + business_hours_item[1]
            key.push(item)
        }
        if (Holiday_id) {
            data.business_hours = key
            data.holiday_id = Holiday_id
            data.is_rest = is_rest ? "1" : "0"
            putHolidayBusiness(data, id).then(res => {
                this.props.history.goBack()
            })
        } else {
            message.error(intl.get("nav.setting.select_public_holidays"))
        }
    };



    render() {
        let { business_hours, Holiday_id, HolidayData, is_rest } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        if (Holiday_id) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView" style={{ minWidth: "1000px" }}>
                    {
                        business_hours.map((field, index) => {
                            return (
                                <Form.Item
                                    key={field + index}
                                    label={<span>{intl.get('nav.setting.businessHours') + " " + (index + 1)}</span>}
                                >
                                    <TimePicker
                                        format='HH:mm:ss'
                                        defaultValue={field.length > 0 ? moment(field[0], 'HH:mm:ss') : ""}
                                        onChange={(val) => {
                                            let { business_hours } = this.state
                                            business_hours[index][0] = moment(val).format('HH:mm:ss')
                                            this.setState({
                                                business_hours
                                            })
                                        }}
                                    />
                                    <span style={{ margin: "10px" }}>-</span>
                                    <TimePicker format='HH:mm:ss'
                                        defaultValue={field.length > 0 ? moment(field[1], 'HH:mm:ss') : ""}
                                        onChange={(val) => {
                                            let { business_hours } = this.state
                                            business_hours[index][1] = moment(val).format('HH:mm:ss')
                                            this.setState({
                                                business_hours
                                            })
                                        }}

                                    />
                                    {
                                        business_hours.length > 1 &&
                                        <MinusCircleOutlined
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => {
                                                let data = this.state.business_hours
                                                data.splice(index, 1)
                                                this.setState({
                                                    business_hours: data
                                                })
                                            }}
                                        />
                                    }
                                </Form.Item>
                            )
                        })
                    }
                    <Form.Item {...tailFormItemLayout}>
                        <Button
                            type="dashed"
                            onClick={() => {
                                let { business_hours } = this.state
                                business_hours.push(["00:00:00", "23:59:59"])
                                this.setState({
                                    business_hours
                                })
                            }}
                            style={{ width: '285px' }}
                        >
                            Add time
                        </Button>
                    </Form.Item>


                    <Form.Item
                        label={
                            <span className="ant-form-item-required">{intl.get('nav.setting.public_holidays')}</span>
                        }
                    >
                        <Select
                            defaultValue={Holiday_id}
                            style={{ width: '285px' }}
                            placeholder={intl.get("nav.setting.select_public_holidays")}
                            onChange={(e) => {
                                this.setState({
                                    Holiday_id: e
                                })
                            }}>
                            {
                                HolidayData.length !== 0 && HolidayData.map(item => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={intl.get('nav.setting.is_rest')}
                        style={{ width: "90%" }}
                    >
                        <Switch checked={is_rest} onChange={(value) => {
                            this.setState({
                                is_rest: value
                            })

                        }} />
                    </Form.Item>


                    <Form.Item {...tailFormItemLayout}>
                        <Popconfirm
                            title={intl.get('seat.table.btn.cancel.placeHorder')}
                            onConfirm={() => {
                                this.props.history.goBack()
                            }}>
                            <Button
                                type="primary"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                    this.props.history.goBack()
                                }}
                            >
                                {intl.get('seat.table.btn.cancel')}
                            </Button>
                        </Popconfirm>
                        <Button type="primary" htmlType="submit">
                            {intl.get('cashwithdrawal.form.submit')}
                        </Button>
                    </Form.Item>
                </Form >

            );
        } else {
            return null
        }

    }
}

export default withRouter(Form.create()(EditHolidayBusiness))
