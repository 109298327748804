import React from 'react'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, message, Switch,Popconfirm } from 'antd';
import intl from 'react-intl-universal';
import { getAnnouncementDeatil, UpdateAnnouncement, getEditorContent } from "../../action/AdAction";
import { getLangType } from "../../util/function";



class EditAnnouncement extends React.Component {

    state = {
        title: "",
        sort: 0,
        is_show: true,
        editorState: "",
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(18)
        let AdData = await getAnnouncementDeatil(id)
        this.setState({
            title: AdData.data.title,
            editorState: BraftEditor.createEditorState(AdData.data.content),
            sort: AdData.data.sort,
            is_show: AdData.data.is_show === "1" ? true : false,
        }, () => {
            this.ChangeEditorData(AdData.data.content)
        })

    }


    ChangeEditorData = (data) => {
        getEditorContent(data).then(res => {
            this.setState({
                editorState: BraftEditor.createEditorState(res.data)
            })
        }).catch(error => {
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        const id = this.props.location.pathname.substring(18)

        const { editorState } = this.state
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.content = editorState.toHTML()
            UpdateAnnouncement(values, id).then(res => {
                this.props.history.push("/AnnouncementList")
            }).catch(error => {
                message.warning(intl.get('announcement.placeholder'))
            })
        })
    };

    handleChange = (editorState) => {
        this.setState({ editorState })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        const { title, editorState, is_show } = this.state
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label={intl.get('announcementList.table.title')}
                >
                    {getFieldDecorator('title', {
                        rules: [{ required: true, whitespace: true }],
                        initialValue: title
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<span>{intl.get('announcementList.table.sort')}</span>}
                >
                    {getFieldDecorator('sort', {
                        initialValue: "0"
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('category.list.isShow')}
                >
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item>


                <Form.Item
                    label={intl.get('ad.form.content')}
                >
                    <div style={{ border: "solid 0.5px rgba(0, 0, 0, 0.2)" }}>
                        <BraftEditor
                            value={editorState}
                            language={Number(getLangType()) == 1 ? "zh" : "en"}
                            onChange={this.handleChange} />
                    </div>
                </Form.Item>



                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(EditAnnouncement))
