import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Input, Button, Switch, Tooltip, Modal,InputNumber } from 'antd';
import { GetUserList, putUser, getPartner } from "../../action/authAction";
import "../category/category.less"
import intl from 'react-intl-universal';
let { Search } = Input

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            pointsVisible:false,
            points:0,
            url:"",
            url_1:"",
            url_2:"", 
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('user.table.avatar'),
                width: '5%',
                align: "center",
                render: (res) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: "cover" }} src={res.head_portrait} alt="" />
                    )
                }
            },
            {
                title: intl.get('user.table.nickname'),
                //dataIndex: 'name',
                width: '5%',
                align: "center",

                render: (record) => {
                    return (
                           record.userPartnerInfo.data[0]?.is_freeze  == 1 ?  
                             "[已冻结积分]" + record.name :  record.name 
                    )
                }



            },
            {
                title: intl.get('user.table.phone'),
                dataIndex: 'phone',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('setup.form.email'),
                dataIndex: 'email',
                width: '10%',
                align: "center",
            }, {
                title: "等级", 
                width: '10%',
                align: "center", 
                render: (record) => {
                    return ( 
                        record.userPartnerInfo.data.length > 0 ? record.userPartnerInfo.data[0].member_level_name : "common" 
                    )
                }



            }, {
                title: "成长值",
                //dataIndex: 'group_value_total',
                width: '10%',
                align: "center",

                render: (record) => {
                    return (
                        record.userPartnerInfo.data.length > 0 ? record.userPartnerInfo.data[0].group_value_total : "0" 
                    )
                }


            }, {
                title: "积分",
                //dataIndex: 'points_total',
                width: '10%',
                align: "center",
                render: (record) => {
                    return (
                         record.userPartnerInfo.data.length > 0 ? record.userPartnerInfo.data[0].points_total : "0" 
                    )
                }


            },


            // {
            //     title: intl.get('home.table.address'),
            //     width: '15%',
            //     render: (text, res) => {
            //         let address = res?.userAddress?.data
            //         return (
            //             <div>
            //                 {
            //                     address && address.length !== 0 && address.map((item, index) => {
            //                         return (
            //                             <p key={index}>({index + 1}) {item.fill}</p>
            //                         )
            //                     })
            //                 }
            //             </div>
            //         )
            //     }
            // },

            {
                title: intl.get('user.table.share'),
                width: '5%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_share === 1} onChange={() => this.HandlePutUser(record)} />
                    )
                }
            },

            {
                title: intl.get('home.table.operation'),
                width: '10%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div>
                        <Tooltip title={intl.get('home.table.operation.btn.view')}>
                            <Button
                                style={{ marginRight: "5px" }}
                                //shape="circle"
                                //icon="eye"
                                onClick={() => {
                                    this.props.history.push("/userDetail/" + record.id)
                                }} >
                                查看
                            </Button>
                        </Tooltip>  
                        <Button style={{ marginRight: "5px" }}
                            onClick={() => {
                                this.setState({
                                    pointsVisible: true,  
                                    ItemId: record.id,
                                })
                            }}>
                            积分充值
                        </Button> 
                        </div>
                    )
                }
            },

            //  {
            //     title: intl.get('user.table.growup'),
            //     dataIndex: 'grow_up',
            //     width: '5%',
            //     align: "center"
            // },
            // {
            //     title: intl.get('user.table.money'),
            //     dataIndex: 'money',
            //     width: '5%',
            //     align: "center"
            // },
        ];
    }


    async componentDidMount() {

        let shopData = await getPartner()
        let is_open_share = shopData.data.is_open_share === 0
        if (is_open_share) {
            this.columns.splice(5, 1)
        }

        this.GetUserData(1, "")
    }

    async GetUserData(num, url) {
        let res = await GetUserList(num, url)
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    HandlePutUser = (item) => {
        let { is_share, id } = item
        putUser({ "is_share": is_share === 1 ? "0" : "1" }, id).then(res => {
            this.GetUserData(1, "")
        })

    }


    render() {
        const { data, meta, pointsVisible,points,url_1,url_2, url } = this.state
        if (data) {
            return (
                <div>
                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "10px" }}>

                        <Button
                            style={{ marginRight: "5px", backgroundColor:   "", color:  "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: ""  , 
                                    url_1:"",
                                    url_2:"", 
                                }, () => {
                                      this.GetUserData(  "",this.state.url)
                                })
                            }}>
                            全部
                        </Button> 


                        <Search
                            placeholder={intl.get("home.placehorder_p")}
                            allowClear
                            enterButton={intl.get("home.search")}
                            // size="large"
                            style={{ width: 250, marginRight: "10px" }}
                            onSearch={(value) => {
                                this.GetUserData(1, "&phone=" + value)
                            }}
                        />
                        <Search
                            placeholder={intl.get("setup.form.email_enter")}
                            allowClear
                            enterButton={intl.get("home.search")}
                            // size="large"
                            style={{ width: 250 }}
                            onSearch={(value) => {
                                this.GetUserData(1, "&email=" + value)
                            }}
                        />
                    </div>




                     <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" }}> 
                        <div style={{ marginBottom: "5px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#58924d"  , color: "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        //this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                冻结积分功能
                            </Button>

                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_1 === "&is_freeze=1" ? "#58924d" : "", color: url_1 === "&is_freeze=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_freeze=1" + url_2   ,
                                        url_1:"&is_freeze=1",
                                         
                                    }, () => {
                                         this.GetUserData(1, this.state.url)
                                    })
                                }}>
                                已冻结
                            </Button>



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_1 === "&is_freeze=0" ? "#58924d" : "", color: url_1 === "&is_freeze=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_freeze=0" + url_2   , 
                                        url_1:"&is_freeze=0",
                                        
                                    }, () => {
                                        this.GetUserData(1, this.state.url)
                                    })
                                }}>
                                未冻结
                            </Button> 
                        </div>
                    </div>



                     <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" }}> 
                        <div style={{ marginBottom: "5px" }}> 

                            <Button
                                style={{ marginRight: "5px", backgroundColor:  "#58924d"  , color: "#fff"   }}
                                onClick={() => {
                                    this.setState({
                                        url: ""
                                    }, () => {
                                        //this.HandleGetData(1, this.state.url)
                                    })
                                }}>
                                开启分享
                            </Button>

                             <Button
                                style={{ marginRight: "5px", backgroundColor: url_2 === "&is_share=1" ? "#58924d" : "", color: url_2 === "&is_share=1" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_share=1" + url_1  ,
                                        url_2:"&is_share=1",
                                         
                                    }, () => {
                                         this.GetUserData(1, this.state.url)
                                    })
                                }}>
                                已开启
                            </Button>



                            <Button
                                style={{ marginRight: "5px", backgroundColor: url_2 === "&is_share=0" ? "#58924d" : "", color: url_2 === "&is_share=0" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.setState({
                                        url: "&is_share=0" + url_1  , 
                                        url_2:"&is_share=0",
                                        
                                    }, () => {
                                         this.GetUserData(1, this.state.url)
                                    })
                                }}>
                                未开启
                            </Button> 
                        </div>
                    </div>





                    <Table
                        rowKey={record => record.id}
                        dataSource={data}
                        columns={this.columns}
                        pagination={{
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(e) => {
                            this.GetUserData(e.current, "")
                        }}
                        className="waperBox"
                        style={{ marginTop: "10px" }}
                    />


                    <Modal
                        title="请输入要充值的积分"
                        visible={this.state.pointsVisible}
                        onOk={() => {  
                            putUser({ "points": this.state.points   }, this.state.ItemId).then(res => {  
                                    this.setState({
                                        pointsVisible: false
                                    },()=>{
                                        this.GetUserData(1, "")
                                    })  
                            }) 

                            // if (money && update_pay_param_code) {
                            //     UpdateUser({ money,update_pay_param_code }, ItemId).then(res => {
                            //         this.setState({
                            //             money: "",
                            //             pointsVisible: false
                            //         }, () => {
                            //             this.GetUserData("")
                            //         })
                            //     })
                            // } else {
                            //     message.error("请先填入要充值的积分")
                            // }

                        }}
                        onCancel={() => {
                            this.setState({
                                //points: "",
                                pointsVisible: false
                            })
                        }}


                        >


                        <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }} >
                            <InputNumber
                                max={10000}
                                value={points}
                                formatter={value => `${value}`}
                                parser={value => value.replace("$", '')}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    this.setState({
                                        points: e
                                    })
                                }} /> 
                        </div>
                        <span>备注：充值的金额可以为负值</span> 


                     </Modal>



                </div>

            );
        } else {
            return (<div />)
        }
    }
}

export default withRouter(Form.create()(UserList))

