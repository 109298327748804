import React from 'react'
import { withRouter } from 'react-router-dom'
import "../shopSetup/setup.less"
import { Form, Input, Button,Popconfirm } from 'antd';
import { GetWalletMoney, WalletWithdraw } from "../../action/authAction";
import intl from 'react-intl-universal';



class CashWithdrawal extends React.Component {

    state = {
        money: "",
    };


    async componentDidMount() {
        let res = await GetWalletMoney()

        this.setState({
            money: res.data.money,
        })
    }



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.SubmitData(values)
            }
        });
    };

    async SubmitData(data) {
        await WalletWithdraw(data)
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 12,
                },
            },
        };


        const { money } = this.state;


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('cashwithdrawal.form.money')}
                >
                    <p>{money}</p>
                </Form.Item>

                <Form.Item
                    label={intl.get('cashwithdrawal.form.bank')}
                >
                    {getFieldDecorator('bank_name', {
                        rules: [{ required: true, message: intl.get('cashwithdrawal.form.bank.placehorder'), whitespace: true }],
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('cashwithdrawal.form.account')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: intl.get('cashwithdrawal.form.account.placehorder'), whitespace: true }],
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={<span>{intl.get('cashwithdrawal.form.bsb')}</span>}
                >
                    {getFieldDecorator('bsb', {
                        rules: [{ required: true, message: intl.get('cashwithdrawal.form.bsb.placehorder'), whitespace: true }],
                    })(<Input />)}
                </Form.Item>

                <Form.Item label={intl.get('cashwithdrawal.form.num')}>
                    {getFieldDecorator('bank_number', {
                        rules: [
                            {
                                required: true,
                                message: intl.get('cashwithdrawal.form.num.placehorder'), whitespace: true
                            }],

                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={<span>{intl.get('setup.form.abn')}</span>}
                >
                    {getFieldDecorator('abn', {
                        rules: [{ required: true, message: intl.get('cashwithdrawal.form.abn.placehorder'), whitespace: true }],
                    })(<Input />)}
                </Form.Item>






                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(CashWithdrawal))
