import React from 'react'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Upload, message, Icon, Popconfirm, DatePicker } from 'antd';
import intl from 'react-intl-universal';
import { putHoliday, getHolidayDetail } from "../../../action/authAction";
import moment from 'moment';

class EditHoliday extends React.Component {

    state = {
        data: "",
        date: ""
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(13)
        let data = await getHolidayDetail(id)
        this.setState({
            data: data.data,
            date: data.data.start_at.date.substring(0, 10).replace(/\//g, "-")
        })

    }




    SubmitData(data) {
        const id = this.props.location.pathname.substring(13)
        data.start_at = this.state.date.split("-").reverse().join("-")
        putHoliday(data, id).then(res => {
            this.props.history.goBack()
        })
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.SubmitData(values)
            }
        });
    };



    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        const { data, date } = this.state;
        if (data) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                    <Form.Item
                        label={intl.get('nav.CostFlow.name')}
                    >
                        {getFieldDecorator('name', {
                            rules: [{ required: true }],
                            initialValue: data.name
                        })(<Input />)}
                    </Form.Item>

                    <Form.Item
                        label={<span>{intl.get('nav.setting.public_holidays_detail')}</span>}
                    >
                        {getFieldDecorator('detail', {
                            rules: [{ required: true }],
                            initialValue: data.detail
                        })(<Input />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>{intl.get('setup.form.state')}</span>}
                    >
                        {getFieldDecorator('state', {
                            rules: [{ required: true }],
                            initialValue: data.state
                        })(<Input disabled />)}
                    </Form.Item>
                    <Form.Item
                        label={<span>{intl.get('nav.setting.public_holidays_date')}</span>}
                    >
                        <DatePicker
                            defaultValue={moment(date, "DD-MM-YYYY")}
                            // defaultValue={[moment(data.start_at, "DD-MM-YYYY"), ""]}
                            format='DD-MM-YYYY'
                            onChange={(value) => {
                                this.setState({
                                    date: moment(value).format("YYYY-MM-DD")
                                })
                            }}
                        />
                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <Popconfirm
                            title={intl.get('seat.table.btn.cancel.placeHorder')}
                            onConfirm={() => {
                                this.props.history.goBack()
                            }}>
                            <Button
                                type="primary"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                    this.props.history.goBack()
                                }}
                            >
                                {intl.get('seat.table.btn.cancel')}
                            </Button>
                        </Popconfirm>
                        <Button type="primary" htmlType="submit">
                            {intl.get('cashwithdrawal.form.submit')}
                        </Button>
                    </Form.Item>
                </Form>

            );
        } else {
            return null
        }

    }
}

export default withRouter(Form.create()(EditHoliday))
