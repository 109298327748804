import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Radio, Switch } from 'antd';
import { GetConfigSet, UpdateConfigSet,UpdateShopMessage,getPartner } from "../../action/authAction";

class Behavior extends React.Component {

    state = {
        data: "",  
        is_member_level_strategy:0,//会员策略：等级开关
        is_member_level: false,
        is_open_member_level:false,



    };

    async componentDidMount() { 
        let res = await getPartner()
        let data = res.data
        this.setState({ 
            is_member_level_strategy: data.is_member_level_strategy == 1 ? true : false, 
            is_member_level: data.is_member_level == 1 ? true : false, 
            is_open_member_level: data.is_open_member_level == 1 ? true : false, 
        })


    }

    handleSubmit = (e) => { 
        e.preventDefault();
        let {  is_member_level_strategy } = this.state
        let data = {} 
        data.is_member_level_strategy = is_member_level_strategy ? 1 : "0" 
        UpdateShopMessage(data)
    };


    render() {
        const { data, is_member_level_strategy ,is_open_member_level, is_member_level } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ maxWidth: "1000px", marginLeft: "100px" }}>


                <Card style={{ marginBottom: "10px" }}>

                    <div style={{  borderBottom:" 1px solid #dedede", padding: "10px 0", display: "flex" , alignItems: "center", justifyContent: "space-between" ,margin: "0 0 20px 0" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>会员策略</div>
                      
                    </div>
 
 
                    {/*<div> 
                    <Form.Item label={<span>不使用</span>} >
                        <Switch checked={silver_is_use}
                            onChange={() => {
                                this.setState({
                                    silver_is_use   : !silver_is_use
                                })
                            }} />
                     </Form.Item>  
                     </div>
                     */}



                    <div>  
                    <Form.Item label={<span>会员等级</span>}>
                        <Switch 
                            checked={is_member_level_strategy}
                            disabled ={!is_member_level}
                            onChange={() => {
                                this.setState({
                                    is_member_level_strategy : !is_member_level_strategy
                                })
                            }} />
                     </Form.Item>   
                     </div>




                     {/*
                     <div> 
                    <Form.Item label={<span>会员卡</span>}>
                        <Switch checked={gold_is_use}
                            onChange={() => {
                                this.setState({
                                    gold_is_use : !gold_is_use
                                })
                            }} />
                     </Form.Item>   
                     </div>




                    <div> 
                    <Form.Item label={<span>会员订阅</span>}>
                        <Switch checked={gold_is_use}
                            onChange={() => {
                                this.setState({
                                    gold_is_use : !gold_is_use
                                })
                            }} />
                     </Form.Item>   
                     </div>
                     */}

 

                     {/* 

                    <div> 
                    <Form.Item label={<span>会员卡</span>}>
                        <Switch checked={diamond_is_use}
                            onChange={() => {
                                this.setState({
                                    diamond_is_use: !diamond_is_use
                                })
                            }} />
                     </Form.Item>   
                     </div>
 
                      <div>  
                        <Form.Item label={<span>会员订阅</span>}>
                            <Switch checked={diamond_is_use}
                                onChange={() => {
                                    this.setState({
                                        diamond_is_use: !diamond_is_use
                                    })
                                }} />
                         </Form.Item>   
                         </div> 

                     */} 
                     
                </Card>
 
                 
                 {is_member_level && <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item> }


            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
