import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch } from 'antd';
import { getIsDeleteGoodsList, deleteSpuRealDestroyGoods, SpuRecoverGoods } from "../../action/goodAction";
import intl from 'react-intl-universal';

class IsDeleteGoodList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            page: 1
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '12%',
                align: "center",
            },
            {
                title: intl.get('goods.list.name'),
                dataIndex: 'name',
                width: '25%',
                align: "center",
            },
            {
                title: intl.get('ad.form.cate'),
                dataIndex: ["category", "name"],
                width: '15%',
                align: "center",
            }, {
                title: intl.get('goods.list.img'),
                width: '12%',
                align: "center",
                render: (res) => {
                    return (
                        <img style={{ width: "50px", height: "50px", objectFit: 'cover' }} src={res.image} alt="" />
                    )
                }
            }
            , {
                title: intl.get('goods.list.price'),
                dataIndex: 'show_price',
                width: '8%',
                align: "center",
            },
            {
                title: intl.get('goods.list.sort'),
                dataIndex: 'sort',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '25%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            {
                                record.sale_quantity === 0 &&
                                <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                    <Button>
                                        {intl.get('home.table.operation.btn.delete')}
                                    </Button>
                                </Popconfirm>
                            }

                            <Popconfirm title={intl.get('home.table.operation.btn.recoverHorder')} onConfirm={() => this.handleSpuRecover(record)}>
                                <Button>
                                    {intl.get('home.table.operation.btn.recover')}
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        this.GetgoodData(1)
    }

    async GetgoodData(num) {
        let res = await getIsDeleteGoodsList(num, "")
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    async handleDelete(data) {
        await deleteSpuRealDestroyGoods({ "spu_id": data.id })
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    handleSpuRecover(data) {
        SpuRecoverGoods(data.id).then(res => {
            this.GetgoodData(1)
        })

    }


    render() {
        const { data, meta } = this.state
        if (data) {
            return (
                <div>
                    <Table
                        rowKey={record => record.id}
                        // bordered
                        dataSource={data}
                        columns={this.columns}
                        pagination={{
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(e) => {
                            this.setState({
                                page: e.current
                            }, () => this.GetgoodData(e.current))

                        }}
                        className="waperBox"
                    />
                </div>

            );
        } else {
            return (<div />)
        }

    }
}

export default withRouter(Form.create()(IsDeleteGoodList))

