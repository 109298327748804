import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, InputNumber, Popconfirm, Form, Button } from 'antd';
import { getGoodsAttr, putGoodsAttr, deleteGoodsAttr } from "../../action/goodAction";
import "../category/category.less"
import intl from 'react-intl-universal';

const EditableContext = React.createContext();

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps} >
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `${intl.get('seat.table.please')}${title}!`,
                                },
                            ],
                            initialValue: record[dataIndex],
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}


class GoodsArrList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], editingKey: '' };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '15%',
                editable: true,
            },
            {
                title: intl.get('goods.list.speciname'),
                dataIndex: 'name',
                width: '30%',
                editable: true,
            }, {
                title: intl.get('goods.list.sort'),
                dataIndex: 'sort',
                width: '15%',
                editable: true,
            },
            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '20%',
                render: (text, record) => {
                    const { editingKey } = this.state;
                    const editable = this.isEditing(record);
                    return editable ? (
                        <span>
                            <EditableContext.Consumer>
                                {form => (
                                    <Button
                                        onClick={() => this.save(form, record)}
                                        style={{ marginRight: 8 }}
                                    >
                                        {intl.get('seat.table.btn.save')}
                                    </Button>
                                )}
                            </EditableContext.Consumer>
                            <Popconfirm title={intl.get('seat.table.btn.cancel.placeHorder')} onConfirm={() => this.cancel(record.key)}>
                                <Button>{intl.get('seat.table.btn.cancel')}</Button>
                            </Popconfirm>
                        </span>
                    ) : (
                        <div className='deleteView'>
                            <Button disabled={editingKey !== ''} onClick={() => this.edit(record)}>
                                {intl.get('seat.table.btn.edit')}
                            </Button>
                            <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    {intl.get('home.table.operation.btn.delete')}
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }



    async componentDidMount() {
        let res = await getGoodsAttr();
        this.setState({ data: res.data.data })
    }


    isEditing = record => record.id === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });

                this.SubmitData(newData, newData[index])


            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '' });
            }
        });
    }

    async SubmitData(newData, item) {
        const { id, name, sort } = item
        let data = { "name": name, "sort": sort }
        await putGoodsAttr(data, id)
        this.setState({ data: newData, editingKey: '' });
    }


    edit(key) {
        this.setState({ editingKey: key.id });
    }

    async handleDelete(data) {
        await deleteGoodsAttr(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'sort' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        return (
            <EditableContext.Provider value={this.props.form}>
                <Table
                    rowKey={record => record.id}
                    components={components}
                    // bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        // onChange: this.cancel,
                        hideOnSinglePage: true
                    }}
                />
            </EditableContext.Provider>
        );
    }
}

export default withRouter(Form.create()(GoodsArrList))

