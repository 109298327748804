import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form, Button, DatePicker,Modal ,Tooltip,Icon } from 'antd';
import { GetPartnerStatementList , stripeCreate,createStatement} from "../../../action/authAction";
import intl from 'react-intl-universal';
import moment from 'moment';
import { getLangType } from "../../../util/function";
let currency_symbol = sessionStorage.getItem("currency_symbol")

const { MonthPicker } = DatePicker;
class OrderFeeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: "",
            date: "",
            url: "",
            statementPayVisible:false,
            feeVisible:false,
            statementFree:0,//账单金额
        };
        this.columns = [



            {
                title: "ID",
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },

            {
                title: "结算周期",
                width: ' 6%',
                align: "center",
                dataIndex:'statement_date'
                // render: (record) => {
                //     return "2023.10.1-2023.10.31"


                // }
            },




            // {
            //     title: intl.get("home.table.ordernumber"),
            //     width: '15%',
            //     align: "center",
            //     render: (record) => {
            //         return <Button
            //             type='link'
            //             onClick={() => {
            //                 this.props.history.push("/OrderDetail/" + record.order.id)
            //             }}
            //         >
            //             {record.spu_discount_id && "【Deal】"}
            //             {record.is_user_coupon && "【Coupon】"}
            //             {record.order && record.order.order_sn}
            //         </Button>
            //     }
            // },



            // {
            //     title: intl.get('nav.CostFlow.name'),
            //     dataIndex: 'name',
            //     width: '10%',
            //     align: "center",
            // },


            {
                title: intl.get('nav.CostFlow.cost'),
                dataIndex: 'free',
                width: '10%',
                align: "center",
                render: (record) => {
                    let value = ""
                    if (!!record[0] && record[0] === '-') {

                        value = "-" + currency_symbol + record.slice(1)
                    } else {
                        value = currency_symbol + record
                    }
                    return <span>{value}</span>
                }
            },




            // {
            //     title: intl.get('nav.PayRoute'),
            //     dataIndex: 'payee',
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return <span>{record}</span>
            //     }
            // },



            {
                title: "结算状态", 
                width: '7%',
                align: "center",
                render: (record) => { 
                    if(record.free == '0.00' ){
                        return <span  >/</span>     
                    }else{
                        if(record.settle_status == "1"){
                            return <span style={{color:'green'}}>已结清</span>
                        }else if(record.settle_status == "0"){
                            return <span style={{color:'red'}}>未结清 </span> 
                        }else if(record.settle_status == "3"){
                            return <span style={{color:'red'}}>校验中 </span> 
                        }else{
                            return <span style={{color:'red'}}>/</span>     
                        }
                    }
                }
            },



            {
                title: "付款状态", 
                width: '7%',
                align: "center",
                render: (record) => {
                    // return  record == "1" ? 
                    //     <span style={{color:'green'}}>已付款</span>:  <span style={{color:'red'}}>未付款</span> 
                    if(record.free == '0.00' ){
                        return <span  >/</span>     
                    }else{
                        if(record.pay_status == "1"){
                            return <span style={{color:'green'}}>已付款</span>
                        }else if(record.pay_status == "0"){
                            return <span style={{color:'red'}}>未付款</span> 
                        }else if(record.pay_status == "3"){
                            return <span style={{color:'red'}}>校验中</span> 
                        }else{
                            return <span style={{color:'red'}}>/</span>     
                        }
                    }

                }
            },


           




            {
                title: "类型", 
                width: '10%',
                align: "center",
                render: (record) => {

                    if(record.free == '0.00' ){
                        return <span  >/</span>     
                    }else{
                       return  record.free > 0 ?  <span style={{color:'green'}}>商家应收</span>:  <span style={{color:'red'}}>商家应付</span>  
                    }
                    
                }
            },



             {
                title: "是否逾期", 
                width: '10%',
                align: "center",
                render: (record) => {

                    if(record.free == '0.00' ){
                        return <span  >/</span>     
                    }else{
                        return record.settle_status == 0  &&   ( record.is_overdue == 0   ?  <span style={{color:'green'}}>未逾期</span>:  <span style={{color:'red'}}>已逾期</span>  )
                    }
                    
                }
            },


            

            // {
            //     title: intl.get("nav.goodsManagement.promotionalgoods.isBuy"),
            //     dataIndex: "order",
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return record.is_already_buy === 1 ? <span style={{ color: "green" }}>Yes</span> : <span style={{ color: "red" }}>No</span>
            //     }
            // },




            // {
            //     title: intl.get('home.table.payee'),
            //     dataIndex: 'payee',
            //     width: '10%',
            //     align: "center",
            // },






            {
                title: intl.get('order.table.remark'),
                dataIndex: 'statement_note',
                width: '10%',
                align: "center",
            },

 

            {
                title: intl.get('package.created'),
                dataIndex: 'created_at',
                width: '15%',
                align: "center",
                render: (record) => {
                    return record.date.substring(0, record.date.length - 7)
                }
            },



            // {
            //     title: '操作',
            //     width: '5%',
            //     align: "center",
            //     render: (record) => {
            //         return  <Button
            //                 style={{ marginRight: "5px" }}
            //                 onClick={() => {
            //                     //this.props.history.push("/orderdetail/" + record.id)
            //                 }}>查看</Button> 
            //      }
            // },

             {
                title: "操作",
                width: '20%',
                align: "center",
                render: (record) => {
                    // return  (record.free > 0 &&  record.pay_status == 0 && record.settle_status == 0 && <Button
                    //         style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                    //         onClick={() => { 
                    //             this.stripePay(record) 
                    //         }}>
                    //        去支付
                    //     </Button>   )


                     return  (

                       <div>


                       { record.free < 0 &&  record.pay_status != 1 && record.settle_status != 1 &&  <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => { 

                                this.setState({ 
                                    statementPayVisible: true,
                                    statementFree: record
                                }) 
                                //this.stripePay(record) 
                            }}>
                           去支付
                        </Button>}


                        <Button
                            style={{ marginRight: "5px",   color:  "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => { 
                                this.props.history.push("/statementDetail/" + record.id)
                            }}>
                           查看
                        </Button>   

                        </div>


                        )




                }
            },





        ];
    }
    componentDidMount() {
        this.HandleGetData(1)
    }

    async HandleGetData(page) {
        let res = await GetPartnerStatementList(page, "" + this.state.url, this.state.date)
        this.setState({ data: res.data.data, meta: res.data.meta.pagination })
    }


    async createStatement(data) { 
        createStatement(data).then(res => {
            this.HandleGetData(1) 
        }) 
    }



    /*
    stripePay = (item) => {

 
        let isHttps = 'https:' == document.location.protocol ? true : false;
        let callback_success = '';
        let callback_error = '';
        if (isHttps) { 
           let fromhost = encodeURIComponent('https://'+ window.location.host); 
          callback_success =   'http://localhost:3000/#/statementDetail'; 
          callback_error =  'http://localhost:3000/#/statementDetail';
        } else { 
           let fromhost =  encodeURIComponent('http://'+ window.location.host);
           callback_success =   'http://localhost:3000/#/statementDetail'; 
          callback_error =  'http://localhost:3000/#/statementDetail';
           
        }

 
        let values = {
              statement_id: item.id,  
              redirect: callback_success,
              cancel_url: callback_error,
          }
 
        stripeCreate(values, item.id).then(res => {
                
            console.log("res",res.data.pay_url);

            window.location.href =  res.data.pay_url; 
            //this.HandleGetData()
        })
    }
    */


     stripePay = (item) => {

 
        let isHttps = 'https:' == document.location.protocol ? true : false;
        let host = window.location.host;
        let port = window.location.port;
        let fromhost = "";

        let callback_success = '';
        let callback_error = '';
        if (isHttps) { 

            // if(port != 80){
            //     fromhost =  'https://'+ host + ":" + port;  
            // }else{
            //     fromhost =  'https://'+ host;  
            // }
           
            fromhost = 'https://'+ host; 
           
          callback_success =   fromhost + '/#/statementDetail/'+item.id; 
          callback_error =  fromhost + '/#/statementDetail/'+item.id;

        } else { 
           //let fromhost =  encodeURIComponent('http://'+ window.location.host);

           // if(port != 80){
           //      fromhost =  'http://'+ host + ":" + port;  
           //  }else{
           //      fromhost =  'http://'+ host;  
           //  }

             fromhost = 'http://'+ host; 
           callback_success =   fromhost + '/#/statementDetail/'+item.id; 
           callback_error =  fromhost +  '/#/statementDetail/'+item.id;
           
        }

 
        let values = {
              statement_id: item.id,  
              redirect: callback_success,
              cancel_url: callback_error,
          }
 
        stripeCreate(values, item.id).then(res => {
                
            console.log("res",res.data.pay_url);

            window.location.href =  res.data.pay_url; 
            //this.HandleGetData()
        })
    }





    priceDeal2(value){ 
        let price  = ""; 
        value =  ""+value ;  
        if (!!value[0] && value[0] === '-') { 
            price =   currency_symbol + value.slice(1)
        }    
        return price;
    }


 




    expandedRowRender = (record) => {
        const columns = [
            {
                title: intl.get('nav.CostFlow.name'),
                dataIndex: "name",
                width: '10%',
                align: "left"
            },
            // {
            //     title: intl.get('nav.CostFlow.costType'),
            //     dataIndex: "money_type",
            //     width: '10%',
            //     align: "center"
            // },
            {
                title: intl.get('nav.CostFlow.cost'),
                width: '10%',
                align: "left",
                render: (record) => {
                    return   record.money
                }
            },


        ];

        const data = record.charge_data;
        return <Table columns={columns} className="extraTable" bordered rowKey={record => record.id} dataSource={data} pagination={false} />;
    };

    render() {
        let { meta,statementPayVisible ,feeVisible} = this.state
        return (
            <div>
                <div style={{ marginBottom: "5px", display: 'flex', justifyContent: "space-between" , alignItems: "center"}}>
                    <div  style={{ marginBottom: "5px", display: 'flex', justifyContent: "space-between" ,    flexDirection:"column"  }}>


                        <div>

                        {/*
                     <Button
                        style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                url: "",
                            }, () => {
                                this.createStatement({type:'today'})
                            })
                        }}>
                        生成本月清单 
                    </Button>


                    <Button
                        style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                        onClick={() => {
                            this.setState({
                                url: "",
                            }, () => {
                                this.createStatement()
                            })
                        }}>
                        生成上个月清单 
                    </Button>
                    */}



 


                        {/*

                            <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "",
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            {intl.get('home.table.useStatus.all')}
                        </Button>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "&payee=system" ? "#58924d" : "", color: this.state.url === "&payee=system" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&payee=system",
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            system
                        </Button>
                        <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "&payee=own" ? "#58924d" : "", color: this.state.url === "&payee=own" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            onClick={() => {
                                this.setState({
                                    url: "&payee=own",
                                }, () => {
                                    this.HandleGetData(1)
                                })
                            }}>
                            own
                        </Button>

                        */}
                        
                        </div>

                        <div style={{ fontSize:"14px",padding:"10px 0 "}}>备注：每个月8号生成上个月的结算清单，当月22号之前完成清单结算</div>


                    </div>


                    <MonthPicker
                        placeholder="Select month"
                        allowClear
                        onChange={(e) => {
                            let date = moment(e).format('YYYY-MM')
                            this.setState({ date }, () => {
                                this.HandleGetData(1)
                            })
                        }} />
                </div>


                <div  className="waperBox">
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns} 
                    //expandedRowRender={this.expandedRowRender}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(e.current)
                    }}
                />


                 <Modal
                    title="手续费说明"
                    visible={this.state.feeVisible}
                    footer={null}
                    onOk={() => { 
                        
                    }}
                    onCancel={() => {
                        this.setState({ 
                            feeVisible: false
                        })
                    }} 
                    >  
                    <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" ,flexDirection: 'column' }} > 
                         <span>1.75% + AUD $0.30 针对国内 (澳大利亚) 银行卡</span>
                        <span>2.9% + AUD $0.30 适用于 International 银行卡</span>
                        <span>Stripe GST 10%</span>
                    </div>  

                 </Modal> 




                <Modal
                    title="支付详情"
                    visible={this.state.statementPayVisible}
                    onOk={() => { 
                        this.stripePay(this.state.statementFree) 
                    }}
                    onCancel={() => {
                        this.setState({ 
                            statementPayVisible: false
                        })
                    }} 
                    > 
                    <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" ,flexDirection: 'column' }} >
                        <span>付款金额：{    this.priceDeal2(this.state.statementFree.free)  } </span> 
                        {/*
                            <span>支付通道手续费： $123123</span> 
                            <span>实际付款金额： $1231</span> 
                        */}
                    </div> 

                    <div> 
                        <div> *澳大利亚银行卡第三方支付平台 (Stripe) 手续费
                            <Icon type="question-circle" style={{ marginLeft: "5px" }} 
                            onClick={() => {
                                this.setState({
                                    feeVisible: true
                                })
                            }} 
                            /> 
                        </div> 
                    </div>

                    <div>*可以联系管理员线下付款</div>  
                 </Modal>



                </div> 
            </div>


        );
    }
}

export default withRouter(Form.create()(OrderFeeList))

