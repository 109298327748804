import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, InputNumber, Button, Select, Radio, DatePicker, message, Switch, Upload, Icon, Tooltip, Checkbox, Popconfirm } from 'antd';
import moment from 'moment';
import { getNoSkuGoodsList, AddSpuDiscount } from "../../action/goodAction";
import { GetConfigSet, getPartner, getLangList } from "../../action/authAction";
import intl from 'react-intl-universal';
import BraftEditor from 'braft-editor'
import { getLangType } from "../../util/function";
const { Option } = Select;
const { RangePicker } = DatePicker;
let currency_symbol = sessionStorage.getItem("currency_symbol")

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}

class NewPromotionalGoods extends React.Component {
    state = {
        value: "",
        SearchData: [],
        spu_id: "",
        discount_type: "fixed",
        price_type: "price",
        date: "",
        goods: "",
        imageUrl: "",
        logo_file: "",
        is_cut_show: false,
        min_pay_fee_on_online: "",
        one_fixed: "1",
        default_one_fixed: "1",
        one_percentage: "1",
        default_one_percentage: "1",
        one_people_money_price_type: "1",
        one_discount_price: "0",
        two_fixed: "1",
        default_two_fixed: "1",
        two_percentage: "1",
        default_two_percentage: "1",
        two_people_money_price_type: "",
        two_discount_price: "0",
        three_fixed: "1",
        default_three_fixed: "1",
        three_percentage: "1",
        default_three_percentage: "1",
        three_people_money_price_type: "",
        three_discount_price: "0",
        choose_people: "",
        is_show_spu_many_help: false,
        is_eatmoon_show: false,
        is_show: false,
        disable_language: true,
        is_disable_language: false,
        is_update_activity_service_rate: false,
        operation_type:0,//运营类型
        typeData: [
            // {
            //     id: 0,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.inside")
            // },
            {
                id: 1,
                name: intl.get("nav.goodsManagement.promotionalgoods.external")
            },
            // {
            //     id: 2,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.mysterious")
            // }
        ],


        consumptionData: [
            {
                value: 0,
                label: "到店",
                disabled: false
            },
            {
                value: 1,
                label: "配送"
            },

        ],


        operationTypeData: [
            {
                 id: 0,
                 name: "店铺"
            },

            {
                id: 1,
                name: "平台"
            },
            
        ],

        order_fixed_fee:0,//套餐固定费用
        activity_service_rate:0,//套餐里的活动订单服务费
        order_fee_total:0,//活动服务费合计 
        off_price:0,//折扣后价格 
        editorState: BraftEditor.createEditorState(null),
        consumption_type:"",//消费类型
    };

    async componentDidMount() {


        const operation_type = parseInt(this.props.location.pathname.substring(21)); //字符串转成数字

 

        let configData = await GetConfigSet()
        let res = await getPartner()
        let data = res.data
        let language = await getLangList()
        let languageData = language.data.data
        let languageList = []
        for (let i in languageData) {
            if (languageData[i].is_use === 1) {
                languageList.push(languageData[i])
            }
        }


        let consumptionData = [...this.state.consumptionData]
        //consumptionData[0].disabled = is_authorization_code ? false : true



        this.setState({
            consumptionData: consumptionData,
            operation_type: operation_type,
            min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
            is_show_spu_many_help: data.is_show_spu_many_help == 1 ? true : false,
            is_update_activity_service_rate: data.is_update_activity_service_rate == 1 ? true : false,
            is_eatmoon_show: data.is_eatmoon_show == 1 ? true : false,
            is_disable_language: languageList.length === 1 ? true : false, 
            editorStateCopy : BraftEditor.createEditorState(intl.get("nav.goodsManagement.promotionalgoods.detail_tips")  ) , //初始化编辑器内容
            
            //系统默认rate值
            activity_service_rate_default : data.activity_service_rate * 100 ?  data.activity_service_rate * 100 : data.package.activity_service_rate * 100 ,
            activity_service_rate : data.activity_service_rate * 100 ?  data.activity_service_rate * 100 : data.package.activity_service_rate * 100 ,
            order_fixed_fee : data.activity_order_fixed_fee? data.activity_order_fixed_fee : data.package.order_fixed_fee ,




        })
    }

    getNowFormatDate = () => {
        var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        return currentdate;
    }


    handleSubmit = (e) => {
        e.preventDefault();
        let ha = this.getNowFormatDate()
        this.props.form.validateFieldsAndScroll((err, values) => {
            let formData = new FormData()
            let { consumption_type,activity_service_rate,order_fee_total, spu_id, discount_type, price_type, logo_file, is_show, is_cut_show, one_fixed, one_percentage, two_fixed, two_percentage, three_fixed, three_percentage, one_people_money_price_type, two_people_money_price_type, three_people_money_price_type, choose_people, editorState ,editorStateCopy } = this.state
            values.spu_id = spu_id
            values.discount_type = discount_type
            values.price_type = price_type
            values.disable_language = this.state.disable_language ? "0" : "1"
            values.detail = editorState && editorState.toHTML() //活动详情
            values.copyright = editorStateCopy && editorStateCopy.toHTML() //版权信息
            values.type = 1 //默认是全站 只有一种类型
            //values.operation_type = operation_type 

            values.consumption_type = consumption_type.toString()
            values.order_fee_total = order_fee_total
            values.activity_service_rate = activity_service_rate / 100

            if (values.type === 1) {
                values.is_show = "1"
            } else {
                values.is_show = is_show ? "1" : "0"
            }

            values.start_at = values.date ? moment(values.date[0]).format("YYYY-MM-DD") + " 00:00:00" : ha + " 00:00:00"
            values.end_at = values.date ? moment(values.date[1]).format("YYYY-MM-DD") + " 23:59:59" : ha + " 23:59:59"
            values.discount_on_percentage = parseInt(values.discount_on_percentage) / 100
            delete values.good_image
            delete values.date
            let people_money_item = [
                {
                    "people": "1",
                    "price": one_people_money_price_type === "1" ? one_fixed : one_percentage,
                    "price_type": one_people_money_price_type
                },
                {
                    "people": "2",
                    "price": two_people_money_price_type === "1" ? two_fixed : two_percentage,
                    "price_type": two_people_money_price_type
                },
                {
                    "people": "3",
                    "price": three_people_money_price_type === "1" ? three_fixed : three_percentage,
                    "price_type": three_people_money_price_type
                }
            ]
            let submit_people = []
            if (choose_people) {
                for (let i in choose_people) {
                    for (let k in people_money_item) {
                        if (parseInt(choose_people[i]) === parseInt(people_money_item[k].people)) {
                            submit_people.push(people_money_item[k])
                        }
                    }
                }
            }

            for (let i in submit_people) {
                let sku = "spu_many_help[people_money][" + i + "]"
                values[sku + "[people]"] = submit_people[i].people
                values[sku + "[price]"] = submit_people[i].price_type === "0" ? parseInt(submit_people[i].price) / 100 : submit_people[i].price
                values[sku + "[price_type]"] = submit_people[i].price_type
            }
            if (is_cut_show) {
                values["spu_many_help[state]"] = 1
            }

            if (values.quota) {
                if (parseInt(values.quota) > parseInt(values.stock)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.quota_cannot_stock"))
                    return false
                }
            }
            if (values.quota && values.max_quantity) {
                if (parseInt(values.quota) < parseInt(values.max_quantity)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.maximum_cannot_limit"))
                    return false
                }
            }
            if (values.min_quantity && values.max_quantity) {
                if (parseInt(values.min_quantity) > parseInt(values.max_quantity)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.minimum_cannot_maximum"))
                    return false
                }
            }
            if (values.quota && values.min_quantity) {
                if (parseInt(values.quota) < parseInt(values.min_quantity)) {
                    message.error(intl.get("nav.goodsManagement.promotionalgoods.minimum_cannot_limit"))
                    return false
                }
            }

            if (!err) {
                if (values.spu_id) {
                    for (let i in values) {
                        if (values[i]) {
                            formData.append(i, values[i])
                        }
                    }
                    logo_file && formData.append("image_file", logo_file)
                    AddSpuDiscount(formData).then(res => {
                        this.props.history.push("/promotionalgoodsList")
                    })


                } else {
                    message.error(intl.get('nav.goodsManagement.promotionalgoods.selectError'))
                }
            }


        });
    };

    onChange = e => {
        this.setState({
            value: e
        });
    };

    onSelectSearch = (val) => {
        if (val.length > 1) {
            getNoSkuGoodsList(1, val).then(res => {
                this.setState({
                    SearchData: res.data.data
                })
            })
        }

    }

    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };






    //固定折扣价格检查
    checkPsd(rule, value, callback) {

 
        let price = this.state.goods.price; 
        let splitData = value.toString().split(".")
        let num = splitData.length === 1 ? 1 : splitData[1].length 
        if (price && parseInt(price) < parseInt(value)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.discountPrice")));
        } else if (this.accSub(price, value) < parseInt(this.state.min_pay_fee_on_online)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.minimumOrder")));
        } else if (num > 2) {
            callback(new Error(intl.get("goods.list.goodsAnnexType.goodsAnnex_price.error")));
        } else { 
            callback();
        }
    }

    //折扣百分比 价格检查
    checkPsd2(rule, value, callback) {
        let price = this.state.goods.price; 
        if (price) { 
            if (this.accMul(price, 100-value) / 100 < parseInt(this.state.min_pay_fee_on_online)) {
                callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.minimumOrder")));
            } else { 
                callback();
            }
        }

    }




    //检查百分比费用
    check_order_rate(rule, value, callback){  
        if (value > 100) {
            callback(new Error("最大不能超过100%"));
        }else if(value < this.state.activity_service_rate_default){
            callback(new Error("不能小于"+this.state.activity_service_rate_default+"%")); 
        }else{
            callback();
        }  

    } 


    //检查固定费用
    check_order_fee(rule, value, callback){  
        if (value > 100) {
            callback(new Error("最大不能超过$100"));
        }else{
            callback();
        } 

    } 





     //检查固定费用
    check_order_fee_total(value,type){ 

        
        if(type == 'fixed'){


            //计算服务费合计 
            let off_price = value ;  //减的价格 
            if(parseFloat(off_price) > 0 ){
                let order_fixed_fee = this.state.order_fixed_fee;
                let activity_service_rate = this.state.activity_service_rate / 100 ;   
                let order_fee_total = (parseFloat(off_price * activity_service_rate)  + parseFloat(order_fixed_fee)).toFixed(2); 
                this.setState({
                    order_fee_total: order_fee_total, 
                    off_price:off_price,
                })

                //this.form.setFieldsValue({
                    //order_fee_total: order_fee_total
                //}) 
            }



            //计算服务费合计
            /*
            let off_price = this.state.off_price;  //减的价格 
            let order_fixed_fee = value;
            let activity_service_rate = this.state.activity_service_rate / 100 ; 

            if(off_price > 0 ){ 
                let order_fee_total = (parseFloat(off_price * activity_service_rate)  + parseFloat(order_fixed_fee)).toFixed(2);  
                console.log("off_price",off_price)
                console.log("order_fixed_fee",order_fixed_fee)
                console.log("activity_service_rate",activity_service_rate)
               
                this.setState({
                    order_fee_total: order_fee_total, 
                    order_fixed_fee: value,
                })

                //this.form.setFieldsValue({
                 // order_fee_total: order_fee_total
              //}) 
            } 
            */

        }


        if(type == 'rate'){
            //计算服务费合计
            let price = this.state.goods.price; 
            let off_price = this.accMul(price, value) / 100;  //减的价格   
            if(parseFloat(off_price) > 0 ){ 
                let order_fixed_fee = this.state.order_fixed_fee;
                let activity_service_rate = this.state.activity_service_rate / 100 ;  
                let order_fee_total = (parseFloat(off_price * activity_service_rate)  + parseFloat(order_fixed_fee)).toFixed(2); 
                this.setState({
                    order_fee_total: order_fee_total, 
                    off_price:off_price,
                })
 
                 //this.form.setFieldsValue({
                    //order_fee_total: order_fee_total
                // })  
            }

        }


        if(type == 'order_fee'){

            //计算服务费合计
            let off_price = this.state.off_price;  //减的价格 
            let order_fixed_fee = value;
            let activity_service_rate = this.state.activity_service_rate / 100 ; 

            if(off_price > 0 ){

                let order_fee_total = (parseFloat(off_price * activity_service_rate)  + parseFloat(order_fixed_fee)).toFixed(2);  
                console.log("off_price",off_price)
                console.log("order_fixed_fee",order_fixed_fee)
                console.log("activity_service_rate",activity_service_rate)
               
                this.setState({
                    order_fee_total: order_fee_total, 
                    order_fixed_fee: value,
                })

                //this.form.setFieldsValue({
                     // order_fee_total: order_fee_total
                //  }) 
            } 


        }



        if(type == 'order_rate'){

            //计算服务费合计
            let off_price = this.state.off_price;  //减的价格 
            let order_fixed_fee = this.state.order_fixed_fee; 
            let activity_service_rate = value / 100  ;  
            let order_fee_total = (parseFloat(off_price * activity_service_rate)  + parseFloat(order_fixed_fee)).toFixed(2); 
            this.setState({
                order_fee_total: order_fee_total, 
                activity_service_rate : value,
            })
        
            // this.form.setFieldsValue({
                  //order_fee_total: order_fee_total
            //  }) 
        }


         

    } 





    



    
    checkStock(rule, value, callback) {

        let stock = this.state.data && this.state.data.stock;
        if (stock && value && parseInt(stock) > parseInt(value)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.stock_error")));
        } else {
            callback();
        }
    }
    // 除以
    accDiv(arg1, arg2) {
        let t1 = 0,
            t2 = 0,
            r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length
        } catch (e) { }
        try {
            t2 = arg2.toString().split(".")[1].length
        } catch (e) { };
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
        return (r1 / r2) * Math.pow(10, t2 - t1);
    }
    // 乘以
    accMul(arg1, arg2) {

        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length
        } catch (e) { }
        try {
            m += s2.split(".")[1].length
        } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }
    // 减
    accSub(arg1, arg2) {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度 
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }



    render() {
        const { activity_service_rate, order_fixed_fee, order_fee_total , SearchData, spu_id, discount_type, price_type, goods, imageUrl, is_show, is_cut_show, one_fixed, one_percentage, two_fixed, two_percentage, three_fixed, three_percentage, one_people_money_price_type, two_people_money_price_type, three_people_money_price_type, one_discount_price, two_discount_price, three_discount_price, choose_people, is_show_spu_many_help, is_eatmoon_show, typeData, consumptionData, operationTypeData,is_disable_language, disable_language, is_update_activity_service_rate , operation_type } = this.state;
        const { getFieldDecorator } = this.props.form;
        let discount_price_save = ""
        if (discount_type === "fixed") {
            discount_price_save = goods.price && this.accSub(goods.price, this.props.form.getFieldValue('discount_on_fixed') || 0)
        } else {
            discount_price_save = goods.price && parseFloat(this.accMul(parseFloat(goods.price), parseFloat(100-this.props.form.getFieldValue('discount_on_percentage')) / 100 || 1)).toFixed(2)
        }
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView" style={{ minWidth: "1000px" }}>
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods_name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods_name_error") }],
                        initialValue: goods && goods.name
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<span>
                        {intl.get('nav.goodsManagement.promotionalgoods.goodsImg')}
                        <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                            <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                        </Tooltip>

                    </span>}
                >
                    {getFieldDecorator('good_image', {
                        rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods.goodsImgError") }]
                    })(
                        <Upload
                            name="avatar"
                            accept=".jpg,.png,.jpeg"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={this.handleChange}
                        >
                            {!!imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item
                    label={<span>
                        {intl.get('ad.form.selectgoods')}
                        <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.chooseGoodsTips")}>
                            <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                        </Tooltip>
                    </span>}
                >
                    <Select
                        showSearch
                        // value={this.state.value}
                        placeholder={intl.get('ad.form.selectgoods')}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={this.onSelectSearch}
                        onChange={(e, value) => {
                            this.props.form.setFieldsValue({ discount_on_fixed: "0" })
                            this.props.form.setFieldsValue({ discount_on_percentage: "0" })
                            this.setState({
                                spu_id: value.props.value,
                                goods: value.props.item,
                                is_cut_show: false
                            })
                        }}
                        notFoundContent={null}
                    >
                        {
                            SearchData.length > 0 && SearchData.map((item, index) => {
                                return <Option key={index} value={item.id} item={item}>{item.name}</Option>
                            })
                        }
                    </Select>
                </Form.Item>




                <Form.Item
                    label="商品BDID"
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: false, message: intl.get("nav.goodsManagement.promotionalgoods_name_error") }],
                        initialValue: goods && goods.bdid
                    })(<Input disabled />)}
                </Form.Item>





                {
                    spu_id &&
                    <div>
                        <Form.Item
                            label={intl.get('nav.goodsManagement.promotionalgoods.discount_type')}
                        >
                            <Radio.Group
                                onChange={(e) => {
                                    this.setState({
                                        discount_type: e.target.value
                                    })
                                }}
                                value={discount_type}>
                                <Radio value={"fixed"}>{intl.get("nav.goodsManagement.promotionalgoods.quota_discount")}</Radio>
                                <Radio value={"percentage"}>{intl.get("nav.goodsManagement.promotionalgoods.percent_discount")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {
                            discount_type === "fixed" ?
                                <Form.Item
                                    label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed')}
                                >
                                    {getFieldDecorator('discount_on_fixed', {
                                        rules: [{ required: true, message: "" }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                                        initialValue: "0"
                                    })(<InputNumber
                                        formatter={value => `${currency_symbol}${value}`}
                                        parser={value => value.replace(currency_symbol, '')}
                                        style={{ width: "200px" }} 
                                        onChange={() => this.setState({ is_cut_show: false })} 
                                        onKeyUp={() => this.check_order_fee_total(this.props.form.getFieldValue('discount_on_fixed'),'fixed')}

                                        />)}
                                </Form.Item> :
                                <Form.Item
                                    label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_percentage')}
                                >
                                    {getFieldDecorator('discount_on_percentage', {
                                        rules: [{ required: true, pattern: new RegExp(/^[1-99]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.enter_integer") }, { validator: (rule, value, callback) => { this.checkPsd2(rule, value, callback) } }],
                                        initialValue: 1
                                    })(<InputNumber formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')} min={1} max={99} style={{ width: "200px" }}
                                        onChange={() => this.setState({ is_cut_show: false })}
                                        onKeyUp={() => this.check_order_fee_total(this.props.form.getFieldValue('discount_on_percentage'),'rate')}

                                    />)}
                                </Form.Item>
                        }



                        <Form.Item
                            label={intl.get('nav.goodsManagement.promotionalgoods.price_type')}
                        >
                            <Radio.Group
                                onChange={(e) => {
                                    this.setState({
                                        price_type: e.target.value
                                    })
                                }}
                                value={price_type}
                                style={{ marginTop: "10px" }}
                            >
                                {
                                    goods && goods.rrp && parseInt(goods.rrp) > 0 &&
                                    <Radio style={{ display: "block", marginBottom: "10px" }} value={"rrp"}>
                                        RRP：${goods && goods.rrp}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {
                                            discount_type === "fixed" ? "-" + currency_symbol + `${goods ? (parseFloat(goods.rrp) - this.accSub(goods.price, parseFloat(this.props.form.getFieldValue('discount_on_fixed')))).toFixed(2) : 0}` : parseFloat(goods.rrp) === 0 ? "0%" :
                                                `${goods && goods.price && (this.accDiv(this.accMul(goods.price, this.props.form.getFieldValue('discount_on_percentage') || 0), parseFloat(goods.rrp))).toFixed(2)}` + "%"
                                        }
                                        &nbsp; {intl.get("nav.goodsManagement.promotionalgoods.displayOnly")}
                                    </Radio>
                                }
                                {
                                    goods && goods.rrp && parseInt(goods.rrp) > 0 ?
                                        <Radio value={"price"} >
                                            {intl.get("goods.list.price")}：{currency_symbol}{goods ? goods.price : 0}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {
                                                discount_type === "fixed" ? "-" + currency_symbol + `${this.props.form.getFieldValue('discount_on_fixed') || 0}` :
                                                    `${parseFloat(this.props.form.getFieldValue('discount_on_percentage')) || 100}` + "% off" +  ` (-${currency_symbol}` + 

                                                    (parseFloat(this.accMul(parseFloat(goods.price), parseFloat(this.props.form.getFieldValue('discount_on_percentage')) || 1)) / 100).toFixed(2)  + ")"


                                            }
                                        </Radio> :
                                        <span>
                                            {intl.get("goods.list.price")}：{currency_symbol}{goods ? goods.price : 0}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            {
                                                discount_type === "fixed" ? "-" + currency_symbol + `${this.props.form.getFieldValue('discount_on_fixed') || 0}` :
                                                    `${parseFloat(this.props.form.getFieldValue('discount_on_percentage')) || 100}` + "% off" +  ` (-${currency_symbol}` + 

                                                    (parseFloat(this.accMul(parseFloat(goods.price), parseFloat(this.props.form.getFieldValue('discount_on_percentage')) || 1)) / 100).toFixed(2)   + ")"

                                            }
                                        </span>

                                }
                            </Radio.Group>
                        </Form.Item >
                        <Form.Item
                            label={intl.get("nav.goodsManagement.promotionalgoods.discount_price")}
                        >
                            <span>
                                {currency_symbol}{
                                    (goods && goods.rrp) || (goods && goods.price) ?
                                        discount_type === "fixed" ?
                                            goods ? this.accSub(goods.price, this.props.form.getFieldValue('discount_on_fixed') || 0) : 0 :
                                            goods && (parseFloat(this.accMul(parseFloat(goods.price), parseFloat(100-this.props.form.getFieldValue('discount_on_percentage')) || 1)) / 100).toFixed(2) : 0
                                }
                            </span>
                        </Form.Item>
                    </div>
                }

                {
                    is_show_spu_many_help &&
                    <Form.Item
                        label={intl.get('nav.goodsManagement.openCutGoodsType')}
                    >
                        <Switch checked={is_cut_show} onChange={() => {
                            if (goods) {
                                this.setState({
                                    is_cut_show: !is_cut_show,
                                    choose_people: ["1"],
                                    one_fixed: parseFloat(discount_price_save) - 1,
                                    default_one_fixed: parseFloat(discount_price_save) - 1,
                                    one_percentage: Math.ceil((1 / parseFloat(discount_price_save) * 100)),
                                    default_one_percentage: Math.ceil((1 / parseFloat(discount_price_save) * 100)),
                                    one_discount_price: 1
                                })
                            } else {
                                message.error(intl.get("nav.goodsManagement.promotionalgoods.select_products"))
                            }

                        }} />
                    </Form.Item>
                }

                {
                    is_cut_show &&
                    <Form.Item
                        label={intl.get('nav.goodsManagement.cutgoods.BargainingNumber')}
                    >
                        <Checkbox.Group style={{ width: '100%' }}
                            value={choose_people}
                            onChange={(e) => {
                                if (e[0] !== "1") {
                                    message.error(intl.get("nav.goodsManagement.promotionalgoods.1_person_required"))
                                    return false
                                }
                                if (e.length === 2 && e[1] !== "2") {
                                    message.error(intl.get("nav.goodsManagement.promotionalgoods.2_person_required"))
                                    return false
                                }
                                this.setState({
                                    choose_people: e,
                                })
                            }}>
                            <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #d9d9d9", marginLeft: "8px" }}>
                                <Checkbox value="1" />
                                <div style={{ width: "120%", height: "100px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ marginLeft: "10px" }}>1</span>
                                    <Radio.Group
                                        onChange={(e) => {
                                            this.setState({
                                                one_people_money_price_type: e.target.value,
                                                one_discount_price: e.target.value === "0" ?
                                                    this.accMul(parseFloat(discount_price_save), parseFloat(one_percentage) / 100).toFixed(2) : this.accSub(parseFloat(discount_price_save), parseFloat(one_fixed))
                                            })
                                        }}
                                        value={one_people_money_price_type}
                                    >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Radio value={"1"} style={{ marginBottom: "10px" }}>
                                                <span style={{ width: "200px", marginRight: "45px" }}>fixed: </span>
                                                <Input
                                                    prefix={currency_symbol}
                                                    disabled={one_people_money_price_type === "0"}
                                                    style={{ width: "100px" }}
                                                    value={one_fixed}
                                                    onBlur={(e) => {
                                                        let price = this.accSub(parseFloat(discount_price_save), parseFloat(e.target.value))
                                                        if (price < 1) {
                                                            this.setState({
                                                                one_fixed: this.state.default_one_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            one_discount_price: price,
                                                            default_one_fixed: one_fixed
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value.toString().match(/^\d+(?:\.\d{0,2})?/)
                                                        if (value) {
                                                            this.setState({
                                                                one_fixed: value[0]
                                                            })
                                                        } else {
                                                            this.setState({
                                                                one_fixed: ""
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                            <Radio value={"0"}>
                                                <span style={{ width: "200px", marginRight: "10px" }}>percentage:</span>
                                                <InputNumber
                                                    formatter={value => `${value}%`}
                                                    style={{ width: "100px" }}
                                                    disabled={one_people_money_price_type === "1"}
                                                    min={0}
                                                    max={99}
                                                    value={one_percentage}
                                                    onBlur={(e) => {
                                                        let price = this.accDiv(this.accMul(parseFloat(discount_price_save), parseFloat(e.target.value) || 0), 100).toFixed(2)
                                                        if (parseFloat(price) < 1) {
                                                            this.setState({
                                                                one_percentage: this.state.default_one_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            one_discount_price: price,
                                                            default_one_percentage: one_percentage
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        if ((/(^[1-99]\d*$)/.test(e))) {
                                                            this.setState({
                                                                one_percentage: e
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                        </div>
                                    </Radio.Group>
                                    <div style={{ width: "150px", textAlign: "right" }}>
                                        <span>{intl.get("nav.goodsManagement.promotionalgoods.discount_price")}：</span>
                                        <span>{currency_symbol}{one_discount_price}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #d9d9d9" }}>
                                <Checkbox
                                    value="2"
                                    onChange={(e) => {
                                        if (choose_people.length === 1 && e.target.checked) {
                                            this.setState({
                                                two_fixed: parseFloat(discount_price_save) - 1,
                                                default_two_fixed: parseFloat(discount_price_save) - 1,
                                                two_percentage: Math.ceil((1 / parseFloat(discount_price_save) * 100)),
                                                default_two_percentage: Math.ceil((1 / parseFloat(discount_price_save) * 100)),
                                                two_discount_price: 1,
                                                two_people_money_price_type: "1"
                                            })
                                        } else if (choose_people.length === 2) {
                                            this.setState({
                                                two_people_money_price_type: ""
                                            })
                                        } else {

                                        }
                                    }}
                                />

                                <div style={{ width: "120%", height: "100px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ marginLeft: "10px" }}>2</span>
                                    <Radio.Group
                                        onChange={(e) => {
                                            this.setState({
                                                two_people_money_price_type: e.target.value,
                                                two_discount_price: e.target.value === "0" ?
                                                    this.accMul(parseFloat(discount_price_save), parseFloat(two_percentage) / 100).toFixed(2) : this.accSub(parseFloat(discount_price_save), parseFloat(two_fixed))
                                            })
                                        }}
                                        value={two_people_money_price_type}
                                    >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Radio value={"1"} style={{ marginBottom: "10px" }}>
                                                <span style={{ width: "200px", marginRight: "45px" }}>fixed: </span>
                                                <Input
                                                    prefix={currency_symbol}
                                                    disabled={!(two_people_money_price_type === "1") || !choose_people[1] || !(choose_people[1] === "2")}
                                                    style={{ width: "100px" }}
                                                    value={two_fixed}
                                                    onBlur={(e) => {
                                                        let price = this.accSub(parseFloat(discount_price_save), parseFloat(e.target.value))
                                                        if (price < 1) {
                                                            this.setState({
                                                                two_fixed: this.state.default_two_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        if (parseFloat(price) >= parseFloat(one_discount_price)) {
                                                            this.setState({
                                                                two_fixed: this.state.default_two_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.two_people_cannot_equal_one_person"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            two_discount_price: price,
                                                            default_two_fixed: two_fixed
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value.toString().match(/^\d+(?:\.\d{0,2})?/)
                                                        if (value) {
                                                            this.setState({
                                                                two_fixed: value[0]
                                                            })
                                                        } else {
                                                            this.setState({
                                                                two_fixed: ""
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                            <Radio value={"0"}>
                                                <span style={{ width: "200px", marginRight: "10px" }}>percentage:</span>
                                                <InputNumber
                                                    formatter={value => `${value}%`}
                                                    style={{ width: "100px" }}
                                                    disabled={!(two_people_money_price_type === "0") || !choose_people[1] || !(choose_people[1] === "2")}
                                                    min={1}
                                                    max={99}
                                                    value={two_percentage}
                                                    onBlur={(e) => {
                                                        let price = this.accDiv(this.accMul(parseFloat(discount_price_save), parseFloat(e.target.value) || 0), 100).toFixed(2)
                                                        if (parseFloat(price) < 1) {
                                                            this.setState({
                                                                two_percentage: this.state.default_two_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        if (parseFloat(price) >= parseFloat(one_discount_price)) {
                                                            this.setState({
                                                                two_percentage: this.state.default_two_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.two_people_cannot_equal_one_person"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            two_discount_price: price,
                                                            default_two_percentage: two_percentage
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        if ((/(^[1-99]\d*$)/.test(e))) {
                                                            this.setState({
                                                                two_percentage: e
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                        </div>

                                    </Radio.Group>
                                    <div style={{ width: "150px", textAlign: "right" }}>
                                        <span>{intl.get("nav.goodsManagement.promotionalgoods.discount_price")}：</span>
                                        <span>{currency_symbol}{two_discount_price}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #d9d9d9" }}>
                                <Checkbox
                                    value="3"
                                    onChange={(e) => {
                                        if (choose_people.length === 2 && e.target.checked) {
                                            this.setState({
                                                three_fixed: parseFloat(discount_price_save) - 1,
                                                default_three_fixed: parseFloat(discount_price_save) - 1,
                                                three_percentage: Math.ceil((1 / parseFloat(discount_price_save) * 100)),
                                                default_three_percentage: Math.ceil((1 / parseFloat(discount_price_save) * 100)),
                                                three_discount_price: 1,
                                                three_people_money_price_type: "1"
                                            })
                                        } else {
                                            this.setState({
                                                three_people_money_price_type: ""
                                            })
                                        }
                                    }}
                                />

                                <div style={{ width: "120%", height: "100px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span style={{ marginLeft: "10px" }}>3</span>
                                    <Radio.Group
                                        onChange={(e) => {
                                            this.setState({
                                                three_people_money_price_type: e.target.value,
                                                three_discount_price: e.target.value === "0" ?
                                                    this.accMul(parseFloat(discount_price_save), parseFloat(three_percentage) / 100).toFixed(2) : this.accSub(parseFloat(discount_price_save), parseFloat(three_fixed))
                                            })
                                        }}
                                        value={three_people_money_price_type}
                                    >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <Radio value={"1"} style={{ marginBottom: "10px" }}>
                                                <span style={{ width: "200px", marginRight: "45px" }}>fixed: </span>
                                                <Input
                                                    prefix={currency_symbol}
                                                    disabled={!(three_people_money_price_type === "1") || !choose_people[2] || !(choose_people[2] === "3")}
                                                    style={{ width: "100px" }}
                                                    value={three_fixed}
                                                    onBlur={(e) => {
                                                        let price = this.accSub(parseFloat(discount_price_save), parseFloat(e.target.value))

                                                        if (price < 1) {
                                                            this.setState({
                                                                three_fixed: this.state.default_three_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        if (parseFloat(price) >= parseFloat(two_discount_price)) {
                                                            this.setState({
                                                                three_fixed: this.state.default_three_fixed
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.three_people_cannot_equal_two_person"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            three_discount_price: price,
                                                            default_three_fixed: three_fixed
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        let value = e.target.value.toString().match(/^\d+(?:\.\d{0,2})?/)
                                                        if (value) {
                                                            this.setState({
                                                                three_fixed: value[0]
                                                            })
                                                        } else {
                                                            this.setState({
                                                                three_fixed: ""
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                            <Radio value={"0"}>
                                                <span style={{ width: "200px", marginRight: "10px" }}>percentage:</span>
                                                <InputNumber
                                                    formatter={value => `${value}%`}
                                                    style={{ width: "100px" }}
                                                    disabled={!(three_people_money_price_type === "0") || !choose_people[2] || !(choose_people[2] === "3")}
                                                    value={three_percentage}
                                                    min={1}
                                                    max={99}
                                                    onBlur={(e) => {
                                                        let price = this.accDiv(this.accMul(parseFloat(discount_price_save), parseFloat(e.target.value) || 0), 100).toFixed(2)
                                                        if (parseFloat(price) < 1) {
                                                            this.setState({
                                                                three_percentage: this.state.default_three_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.price_less_than_1"))
                                                            return false
                                                        }
                                                        if (parseFloat(price) >= parseFloat(two_discount_price)) {
                                                            this.setState({
                                                                three_percentage: this.state.default_three_percentage
                                                            })
                                                            message.error(intl.get("nav.goodsManagement.promotionalgoods.three_people_cannot_equal_two_person"))
                                                            return false
                                                        }
                                                        this.setState({
                                                            three_discount_price: price,
                                                            default_three_percentage: three_percentage
                                                        })
                                                    }}
                                                    onChange={(e) => {
                                                        if ((/(^[1-99]\d*$)/.test(e))) {
                                                            this.setState({
                                                                three_percentage: e
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Radio>
                                        </div>


                                    </Radio.Group>
                                    <div style={{ width: "150px", textAlign: "right" }}>
                                        <span>{intl.get("nav.goodsManagement.promotionalgoods.discount_price")}：</span>
                                        <span>{currency_symbol}{three_discount_price}</span>
                                    </div>
                                </div>
                            </div>
                        </Checkbox.Group>
                    </Form.Item>
                }


                {/*
                <Form.Item
                    label={intl.get('category.list.isShow')}
                >
                    <Switch
                        checked={is_show}
                        onChange={() => {
                            this.setState({
                                is_show: !is_show
                            })
                        }}
                    />
                </Form.Item>
                */}


                {
                    !is_disable_language &&
                    <Form.Item label={<span>{intl.get('goods.list.disableLanguage')}</span>}>
                        <Switch checked={disable_language}
                            onChange={() => {
                                this.setState({
                                    disable_language: !disable_language
                                })
                            }} />
                    </Form.Item>
                }





                <Form.Item
                    label={<span>{intl.get('nav.goodsManagement.promotionalgoods.time')}</span>}
                >
                    {getFieldDecorator('date', {
                        rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods.timeError") }],
                    })(<RangePicker
                        format="DD/MM/YYYY"
                        disabledDate={(current) => {
                            //return current && current < moment().subtract(1, "days")
                            return current < moment().endOf('day')
                        }}
                    />)}
                    {/* <RangePicker
                        format="YYYY-MM-DD"
                        onChange={(val) => {
                            this.setState({
                                date: val
                            })
                        }}
                    /> */}
                </Form.Item>


                {/* <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.activity_service_rate_money')}
                >
                    {getFieldDecorator('activity_service_rate_money', {
                        rules: [{ required: false }],
                    })(<Input prefix={currency_symbol} />)}
                </Form.Item>
                 */}

                { 
                    is_update_activity_service_rate &&

                    <div>
                       
                        <Form.Item
                            label={<span> 
                                {intl.get('nav.goodsManagement.promotionalgoods.order_fixed_fee')}
                                <Tooltip title="最大为$100" >
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>
                                </span>}

                        >
                            {getFieldDecorator('order_fixed_fee', { 
                                rules: [{ required: true, message: "" }, { validator: (rule, value, callback) => { this.check_order_fee(rule, value, callback) } }],          
                                initialValue: order_fixed_fee 
                            })(<Input prefix={currency_symbol}  disabled

                            onKeyUp={() => this.check_order_fee_total(this.props.form.getFieldValue('order_fixed_fee'),'order_fee')}

                             />)}
                        </Form.Item>


                         <Form.Item 
                            label= {<span> 
                                 {intl.get('nav.goodsManagement.promotionalgoods.activity_service_rate')}
                                    <Tooltip title="不能小于系统设置的值，最大为100%" >
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                    </Tooltip>
                                </span>} 
                        >
                            {getFieldDecorator('activity_service_rate', { 
                                rules: [{ required: true,   message: "" }, { validator: (rule, value, callback) => { this.check_order_rate(rule, value, callback) } }],
                                initialValue: activity_service_rate  
                            })(<Input suffix="%"   disabled={operation_type == 0} 

                            onKeyUp={() => this.check_order_fee_total(this.props.form.getFieldValue('activity_service_rate'),'order_rate')}
                             />)}
                        </Form.Item>


                            
                       {spu_id &&  <Form.Item
                            label= "订单服务费小计"
                        >
                            <span>
                                {currency_symbol}{order_fee_total}
                            </span> 

                            {getFieldDecorator('order_fee_total', { 
                                initialValue: order_fee_total  
                            })(<Input hidden />)} 
                        </Form.Item> }

                    </div>
                 }


                

                 
                {/*<Form.Item
                    label={intl.get("subscription.type")}
                >
                    {getFieldDecorator('type', {
                        rules: [{ required: true }],
                         initialValue: 1
                    })(<Select>
                        {typeData && typeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                    </Select>)}
                </Form.Item>
                */}
                

                 <Form.Item
                    label="运营类型"
                >
                    {getFieldDecorator('operation_type', {
                        rules: [{ required: true }],
                        initialValue: operation_type
                    })(<Select disabled>
                        {operationTypeData && operationTypeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                    </Select>)}
                </Form.Item>





                <Form.Item
                    label='消费方式'
                >
                    {getFieldDecorator('consumption_type', {
                        rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods.operationType_tips") }],
                        initialValue: [0]
                    })(
                        <Checkbox.Group
                            options={consumptionData}
                            onChange={(e) => {
                                console.log(e, "2323232")
                                this.setState({
                                    consumption_type: e.length > 1 ? 2 : e[0]
                                })
                            }} />
                    )}
                </Form.Item>




                





                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.stock')}
                >
                    {getFieldDecorator('total_stock', {
                        rules: [{ required: true, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }, { validator: (rule, value, callback) => { this.checkStock(rule, value, callback) } }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            {intl.get('nav.goodsManagement.promotionalgoods.quota')}
                            <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.total_quota")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('quota', {
                        rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                    })(<Input type="number" />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            {intl.get('nav.goodsManagement.promotionalgoods.max_quantity')}
                            <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.Single_max_quantity")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>

                    }
                >
                    {getFieldDecorator('max_quantity', {
                        rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                    })(<Input type="number" />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            {intl.get('nav.goodsManagement.promotionalgoods.min_quantity')}
                            <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.Single_min_quantity")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('min_quantity', {
                        rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                    })(<Input type="number" />)}
                </Form.Item>


                 <Form.Item
                    label={intl.get('goods.list.sort')}
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>


                    
                <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.detail')}
                >
                    <div style={{
                        border: "1px solid #dedede",
                    }}>
                        <BraftEditor
                            value={this.state.editorState}
                            language={Number(getLangType()) == 1 ? "zh" : "en"}
                            onChange={(editorState) => {
                                this.setState({ editorState })
                            }} />
                    </div>
                </Form.Item>



                <Form.Item
                    label="版权说明"
                >
                    <div>
                        <div style={{
                            border: "1px solid #dedede",
                        }}>
                            <BraftEditor
                                value={this.state.editorStateCopy}
                                language={Number(getLangType()) == 1 ? "zh" : "en"}
                                onChange={(editorStateCopy) => {
                                    this.setState({ editorStateCopy })
                                }} />
                        </div>

                        <div style={{     padding: "20px 0"  }}>
                        <p style={{ color: "#333", lineHeight: "20px" }}>提示：请使用“ $shop_name ”代替店铺名称</p>

                        <p style={{ color: "#333", lineHeight: "20px" }}>参考内容：{intl.get("nav.goodsManagement.promotionalgoods.detail_tips")}</p> 
                        </div>

                    </div>
                </Form.Item>




                {/* <Form.Item
                    label={intl.get('nav.goodsManagement.promotionalgoods.expire_day')}
                >
                    {getFieldDecorator('expire_day', {
                        rules: [{ required: false }],
                        initialValue: 30
                    })(<Input type="number" />)}
                </Form.Item> */}


                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >
        );
    }
}

export default withRouter(Form.create()(NewPromotionalGoods))
