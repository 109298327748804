import React from 'react'
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, message, Switch, Popconfirm, DatePicker } from 'antd';
import intl from 'react-intl-universal';
import moment from 'moment';
import { getSystemAnnouncementDetail, getEditorContent } from "../../action/AdAction";
import { getLangType } from "../../util/function";
const { RangePicker } = DatePicker;


class EditAnnouncement extends React.Component {

    state = {
        title: "",
        sort: 0,
        date: "",
        editorState: "",
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(24)
        let AdData = await getSystemAnnouncementDetail(id)
        this.setState({
            title: AdData.data.title,
            editorState: BraftEditor.createEditorState(AdData.data.content),
            sort: AdData.data.sort,
            date: [moment(AdData.data.start_at, "YYYY-MM-DD HH:mm:ss"), moment(AdData.data.stop_at, "YYYY-MM-DD HH:mm:ss")],
        }, () => {
            this.ChangeEditorData(AdData.data.content)
        })

    }


    ChangeEditorData = (data) => {
        getEditorContent(data).then(res => {
            this.setState({
                editorState: BraftEditor.createEditorState(res.data)
            })
        }).catch(error => {
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const { title, editorState, date } = this.state
        return (
            <Form {...formItemLayout} className="FormView">
                <Form.Item
                    label={intl.get('announcementList.table.title')}
                >
                    {getFieldDecorator('title', {
                        rules: [{ required: true, whitespace: true }],
                        initialValue: title
                    })(<Input disabled />)}
                </Form.Item>
                <Form.Item
                    label={intl.get("announcementList.table.time")}
                >
                    {
                        date &&
                        <RangePicker
                            disabled
                            defaultValue={date}
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                            onOk={(val) => {
                                this.setState({
                                    date: val
                                })
                            }}
                        />

                    }

                </Form.Item>

                <Form.Item
                    label={<span>{intl.get('announcementList.table.sort')}</span>}
                >
                    {getFieldDecorator('sort', {
                        initialValue: "0"
                    })(<Input disabled />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('ad.form.content')}
                >
                    <div style={{ border: "solid 0.5px rgba(0, 0, 0, 0.2)" }}>
                        <BraftEditor
                            disabled
                            value={editorState}
                            language={Number(getLangType()) == 1 ? "zh" : "en"}
                        />
                    </div>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(EditAnnouncement))
