import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch } from 'antd';
import { getGoodsAnnexTypeList, putGoodsAnnexType, deleteGoodsAnnexType } from "../../action/goodAction";
import "../category/category.less"
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class goodsAnnexTypeList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], meta: "" };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '5%',
                editable: false,
                align: "center",
            },
            {
                title: intl.get('goods.list.goodsAnnexType.name'),
                dataIndex: 'name',
                width: '30%',
                editable: true,
                align: "center",
            },
            {
                title: intl.get('goods.list.goodsAnnexType.max_select'),
                dataIndex: 'max_select',
                width: '15%',
                editable: true,
                align: "center",
            },
            {
                title: intl.get('goods.list.goodsAnnexType.min_select'),
                dataIndex: 'min_select',
                width: '15%',
                editable: true,
                align: "center",
            },
            // {
            //     title: intl.get('goods.list.goodsAnnexType.required'),
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.required === 1} onChange={() => this.onChangeRequired(record)} />
            //         )
            //     }
            // },
            // {
            //     title: intl.get('goods.list.goodsAnnexType.check'),
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.check === 1} onChange={() => this.onChangeCheck(record)} />
            //         )
            //     }
            // },
            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                    this.props.history.push("/EditGoodsAnnexType/" + record.id)
                                }}>
                                {intl.get('seat.table.btn.edit')}
                            </Button>
                            <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    {intl.get('home.table.operation.btn.delete')}
                                </Button>
                            </Popconfirm>
                        </div>
                    )
                },
            },

        ];
    }

    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getGoodsAnnexTypeList();
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    async handleDelete(data) {
        await deleteGoodsAnnexType(data.id)
        this.HandleGetData()

    }


    onChangeRequired = (item) => {
        const { id, required } = item
        let data = { "required": required === 1 ? "0" : "1" }
        putGoodsAnnexType(data, id).then(res => {
            this.HandleGetData()
        })
    }
    onChangeCheck = (item) => {
        const { id, check } = item
        let data = { "check": check === 1 ? "0" : "1" }
        putGoodsAnnexType(data, id).then(res => {
            this.HandleGetData()
        })
    }

    expandedRowRender = (record) => {
        const columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                key: 'id',
                align: "center"
            },
            {
                title: intl.get("goods.list.goodsAnnex.name"),
                dataIndex: 'name',
                key: 'name',
                align: "center"
            },
            {
                title: intl.get("goods.list.goodsAnnex.price"),
                dataIndex: 'price',
                key: "price",
                align: "center",
                render: (record) => {
                    return <span>{currency_symbol}{record}</span>
                }
            },
            {
                title: intl.get("category.list.sort"),
                dataIndex: 'sort',
                key: "sort",
                align: "center"
            },
            {
                title: intl.get("goods.list.goodsAnnexType.set_default"),
                dataIndex: 'is_default',
                key: "is_default",
                align: "center",
                render: (record) => {
                    return record === 1 ? <span style={{ color: "green" }}>{intl.get("lang.list.yes")}</span> : <span style={{ color: "#ff0000" }}>{intl.get("lang.list.no")}</span>
                }
            },
            {
                title: intl.get("goods.list.goodsAnnexType.must_default"),
                dataIndex: 'force_default',
                key: "force_default",
                align: "center",
                render: (record) => {
                    return record === 1 ? <span style={{ color: "green" }}>{intl.get("lang.list.yes")}</span> : <span style={{ color: "#ff0000" }}>{intl.get("lang.list.no")}</span>
                }
            },

        ];

        const data = record.goodsAnnexTypeItem.data;
        return <Table columns={columns} className="extraTable" bordered rowKey={record => record.id} dataSource={data} pagination={false} />;
    };


    render() {
        const { data, meta } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <div>
                        <Button
                            style={{ marginRight: "10px" }}
                            type="primary"
                            onClick={() => this.props.history.push("/goodsAnnexTypeItemList")}
                        >
                            {intl.get('nav.goodsManagement.itemadditional.list')}
                        </Button>
                        <Button type="primary"
                            onClick={() => this.props.history.push("/addGoodsAnnexType")}
                        >
                            {intl.get('nav.goodsManagement.additional.add')}
                        </Button>
                    </div>
                </div>
                <Table
                    rowKey={record => record.id}
                    dataSource={data}
                    columns={this.columns}
                    expandedRowRender={this.expandedRowRender}
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e) => {
                        this.GetgoodData(e.current)
                    }}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(goodsAnnexTypeList))

