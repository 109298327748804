import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone';
import { Table, Popconfirm, Form, Button, Switch ,Icon} from 'antd';
import { getIsDeleteCouponList, deleteRealDestroyCoupon, RecoverCoupon } from "../../action/goodAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")
class IsDeleteCoupon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: "",
            meta: "",
            page: 1
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '3%',
                align: "center",
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods_name"),
                width: '10%',
                align: "center",
                render: (record) => {
                    return  <div style={{  }}>

                    { 
                         record.permanent_state === 1 &&  
                         <Icon type="stop" theme="filled" style={{ marginRight: "5px",color:"#ff5722" }} /> 

                    }

                    {
                        record.spu_id === 0 &&
                        <Icon type="shop" style={{ marginRight: "5px" }} />
                    }

                    <span>{record.name}</span>
                     </div>
                }
            },
            {
                title: intl.get('nav.goodsManagement.promotionalgoods.stock'),
                width: '6%',
                render: (record) => {
                    let stock = record.stock
                    let total_stock = record.total_stock
                    let sale_quantity = record.sale_quantity
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ marginBottom: "5px" }}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_receive_count")}:{record.sale_quantity}</span>
                        <span style={{ marginBottom: "5px" }}>{intl.get('nav.goodsManagement.promotionalgoods.coupon_use_count')}:{record.use_quantity}</span>
                        <span style={{ marginBottom: "5px" }}>{intl.get('nav.goodsManagement.promotionalgoods.coupon_remaining_count')}:{record.stock}</span>
                        <span style={{ marginBottom: "5px" }}>{intl.get('nav.goodsManagement.promotionalgoods.coupon_total_count')}:{record.total_stock}</span>
                    </div>
                }
            },
            {
                title: intl.get("home.table.service"),
                dataIndex: 'activity_service_rate',
                width: '4%',
                align: "center",
                render: (record) => {
                    return Number(record) * 100 + "%"
                }
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods.during_the_event"),
                // dataIndex: "created_at",
                width: '6%',
                align: "center",
                render: (record) => {
                    let start_at = record.start_at
                    let end_at = record.end_at
                    let end_at_year = end_at.split(" ")
                    let end_at_time = new Date(end_at_year[0].split("/").reverse().join("/") + " " + end_at_year[1]).getTime()
                    let curDate = new Date(moment().tz('Australia/Sydney').format("YYYY/MM/DD HH:mm:ss")).getTime();
                    let isRed = end_at_time < curDate
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{start_at.substring(0, start_at.length - 8)}</span>
                        <span>~</span>
                        <span style={{ color: isRed ? "#ff0000" : "" }}>{end_at.substring(0, end_at.length - 8)}</span>
                    </div>
                }
            },

            /* {
                title: intl.get('subscription.status'),
                width: '4%',
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            {
                                record.permanent_state === 1 ?
                                    <span style={{ color: "#ff0000" }}>{intl.get("nav.goodsManagement.promotionalgoods.Terminated")}</span> :
                                    record.end_status ?
                                        <span style={{ color: "#ff0000" }}>{intl.get("nav.goodsManagement.promotionalgoods.end")}</span> :
                                        <span style={{ color: "green" }}>{intl.get("nav.goodsManagement.promotionalgoods.NormalSales")}</span>
                            }
                        </div>
                    )
                }
            }, */

            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '3%',
                align: "center",
                render: (text, record) => {

                    // if (record.permanent_state !== 1) {
                    //     return (
                    //         <div className='deleteView'>
                    //             {
                    //                 record.sale_quantity === 0 &&
                    //                 <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                    //                     <Button>
                    //                         {intl.get('home.table.operation.btn.delete')}
                    //                     </Button>
                    //                 </Popconfirm>
                    //             }

                    //             <Popconfirm title={intl.get('home.table.operation.btn.recoverHorder')} onConfirm={() => this.handleSpuRecover(record)}>
                    //                 <Button>
                    //                     {intl.get('home.table.operation.btn.recover')}
                    //                 </Button>
                    //             </Popconfirm>
                    //         </div>

                    //     );
                    // } else {


                        return (
                            <div className='deleteView'>


                                {
                                    record.is_delete === 1 && <span style={{ color: "red" }}>已删除</span>
                                }
 
 
                                {
                                     record.is_delete != 1 &&  //record.sale_quantity === 0 &&
                                    <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                        <Button>
                                            {intl.get('home.table.operation.btn.delete')}
                                        </Button>
                                    </Popconfirm>
                                }

                                {   
                                     record.is_delete != 1 && 

                                    <Popconfirm title={intl.get('home.table.operation.btn.recoverHorder')} onConfirm={() => this.handleSpuRecover(record)}>
                                        <Button>
                                            {intl.get('home.table.operation.btn.recover')}
                                        </Button>
                                    </Popconfirm>

                                }
                                 



                            </div>

                        );


                    

                },
            },

        ];
    }

    async componentDidMount() {
        this.GetgoodData(1)
    }

    async GetgoodData(num) {
        let res = await getIsDeleteCouponList(num)
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    async handleDelete(data) {
        await deleteRealDestroyCoupon({ "spu_discount_id": data.id })
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    handleSpuRecover(data) {
        RecoverCoupon(data.id).then(res => {
            this.GetgoodData(1)
        })

    }


    render() {
        const { data, meta } = this.state
        if (data) {
            return (
                <div>
                    <Table
                        rowKey={record => record.id}
                        // bordered
                        dataSource={data}
                        columns={this.columns}
                        pagination={{
                            pageSize: meta.per_page,
                            total: meta.total
                        }}
                        onChange={(e) => {
                            this.setState({
                                page: e.current
                            }, () => this.GetgoodData(e.current))

                        }}
                        className="waperBox"
                    />
                </div>

            );
        } else {
            return (<div />)
        }

    }
}

export default withRouter(Form.create()(IsDeleteCoupon))

