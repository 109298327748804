import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon, InputNumber } from 'antd';
import { getPartner, UpdateShopMessage, GetConfigSet } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class DeliveryPlug extends React.Component {
    state = {
        is_full_delivery_free: true,
        full_delivery_free: "",
    };

    async componentDidMount() {
        let res = await getPartner()
        let configData = await GetConfigSet()
        let data = res.data
        this.setState({
            is_full_delivery_free: data.is_full_delivery_free == 1 ? true : false,
            full_delivery_free: data.full_delivery_free,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { is_full_delivery_free } = this.state
        let data = {}
        data.is_full_delivery_free = is_full_delivery_free ? 1 : "0"
        data.full_delivery_free = this.props.form.getFieldValue('full_delivery_free')
        UpdateShopMessage(data)
    };

    render() {
        const { is_full_delivery_free, full_delivery_free } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get("setup.form.delivery")}</div>
                    <Form.Item label={<span>{intl.get('setup.form.full_delivery_free')}</span>}>
                        <Switch
                            checked={is_full_delivery_free}
                            onChange={() => {
                                this.setState({
                                    is_full_delivery_free: !is_full_delivery_free
                                })
                            }} />
                    </Form.Item>
                    <Form.Item label={<span>{intl.get('discount.form.free')}</span>}>
                        {getFieldDecorator('full_delivery_free', {
                            initialValue: full_delivery_free
                        })(
                            <InputNumber
                                min={1}
                                formatter={value => `$${value}`}
                                parser={value => value.replace("$", '')}
                            />
                        )}
                    </Form.Item>
                </Card>
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(DeliveryPlug))
