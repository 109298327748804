import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon } from 'antd';
import { getPartner, UpdateShopMessage } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class ToStorePlug extends React.Component {

    state = {
        is_open_partner_seat: false,
        is_partner_seat: false,
    };

    async componentDidMount() {
        let res = await getPartner()
        let data = res.data
        this.setState({
            is_open_partner_seat: data.is_open_partner_seat == 1 ? true : false,
            is_partner_seat: data.is_partner_seat == 1 ? true : false,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { is_partner_seat } = this.state
        let data = {}
        data.is_partner_seat = is_partner_seat ? 1 : "0"
        UpdateShopMessage(data)
    };

    render() {
        const { is_open_partner_seat, is_partner_seat } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('seat.table.open_seat')}</div>
                    <Form.Item label={<span>{intl.get('seat.table.open_seat')}</span>}>
                        <Switch
                            disabled={!is_open_partner_seat}
                            checked={is_partner_seat}
                            onChange={() => {
                                this.setState({
                                    is_partner_seat: !is_partner_seat
                                })
                            }} />
                    </Form.Item>
                </Card>


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(ToStorePlug))
