import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button } from 'antd';
import { getMemberLevelList, delPlugIn } from "../../action/authAction";

class PlugInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            
             
             {
                title: 'ID',
                dataIndex: 'id',
                width: '10%',
                render: (record) => {
                    return <span>{record}</span>
                }
            } ,
            {
                title: '变更等级',
                dataIndex: 'member_level_name',
                width: '10%',
                render: (record) => {
                    return <span>{record}</span>
                }
            } ,


            {
                title: '备注',
                dataIndex: 'explain',
                width: '15%',
            },



            {
                title: '日期', 
                width: '15%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
             }, 


             {
                title: '操作人', 
                width: '15%',
                align: "center",
                render: (record) => {
                    return "partner"
                }
             }, 



             
            // {
            //     title: '操作',
            //     dataIndex: 'operation',
            //     width: '20%',
            //     align: "center",
            //     render: (text, record) => {
            //         return (
            //             <div className='deleteView'>
                            
            //                 <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
            //                     <Button type="danger" ghost>
            //                         删除
            //                     </Button>
            //                 </Popconfirm>
            //             </div>

            //         );
            //     },
            // },

        ];
    }

    async componentDidMount() {

          const id = this.props.location.pathname.substring(17) 
        let res = await getMemberLevelList(id)
        this.setState({ data: res.data.data })
    }


    async handleDelete(data) {
        await delPlugIn(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        return (
            <Table
                rowKey='id'
                // bordered
                dataSource={this.state.data}
                columns={this.columns}
                pagination={{
                    hideOnSinglePage: true
                }}
                className="waperBox"
            />

        );
    }
}

export default withRouter(Form.create()(PlugInList))

