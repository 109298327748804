import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Select } from 'antd';
import { getPartner } from "../../action/authAction";
import intl from 'react-intl-universal';
class SysTemSet extends React.Component {

    state = {
        data: "",
    };

    async componentDidMount() {
        getPartner().then(res => {
            this.setState({
                data: res.data,
            })
        })

    }

    render() {
        const { data } = this.state
        console.log(data, "12121212")
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>

                        {/* <Form.Item
                            label={intl.get("setup.form.state")}
                        >
                            <Input disabled value={data?.salesArea?.state} />
                            <Input disabled value="NSW" />
                        </Form.Item>
                         */}

                


                         <Form.Item
                            label={<span>州</span>}
                        > 
                            <Input  disabled value={data.state?data.state.state:""}  />
                        </Form.Item>





                        <Form.Item
                            label={<span>所在联合大区</span>}
                        >   
                             <Input  disabled value={data.salesAreaUnion ? data.salesAreaUnion.name:""}  />
                           
                        </Form.Item>





                        <Form.Item
                            label={<span>所在销售大区</span>}
                        >
                              <Input  disabled value={data.salesArea ? data.salesArea.name : ""} />   
                        </Form.Item>





                        {
                            
                            <Form.Item
                                label={intl.get("setup.form.salesArea_small")}
                            >   

                              <Input disabled value={data.salesAreaSub ? data.salesAreaSub.name : ""}  />   
                               
                            </Form.Item>
                        }





                        {/*<Form.Item
                            label={intl.get("setup.form.salesArea_small")}
                        >
                            <Input disabled value={data?.salesAreaSub?.name} />
                        </Form.Item>
                        */}




            </Form>
        );
    }
}

export default withRouter(Form.create()(SysTemSet))
