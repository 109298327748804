import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Input, Button, Card, Pagination } from 'antd';
import { getDocumentDetail, getContent,GetDocumentcategory } from "../../action/AdAction";
import intl from 'react-intl-universal';
import "./index.css"
import Header from "../header/index"

class documentDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            Height: document.body.clientHeight - 130 + "px",
            dataCategory:"",
        };
    }


    async componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState({
                Height: document.body.clientHeight - 130 + "px"
            })
        })

        this.GetDocumentcategoryData(1);
        this.GetDocumentData()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {
            this.setState({
                Height: document.body.clientHeight - 130 + "px"
            })
        })
    }

    async GetDocumentData() {
        const id = this.props.location.pathname.substring(16)

        let res = await getDocumentDetail(id)
        let data = res.data
        if (data) {
            getContent(data.content).then(a => {
                console.log(a.data)
                data.current_content = a.data
                this.setState({
                    data
                })
            })
        }


    }


      async GetDocumentcategoryData(page) {
        let res = await GetDocumentcategory(page)
        let dataCategory = res.data.data

        console.log("category====",dataCategory);

        if (dataCategory) { 
            this.setState({
                dataCategory: dataCategory,
                meta: res.data.meta.pagination
            })
        

        }


    }







    render() {
        const { data, Height ,dataCategory } = this.state
        if (data) {
            return (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    



                    <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "10px" ,marginLeft: "10px" }}>
                            <Button
                                style={{ backgroundColor: "#fff"  , color:  "rgba(0, 0, 0, 0.65)" }}
                                 onClick={() => this.props.history.push("/documentList")} 
                                 
                            >
                                {intl.get('home.table.useStatus.all')}
                            </Button>


                            {
                               dataCategory && 
                                dataCategory.map((item, index) => {
                                    return    <Button
                                        style={{ marginLeft: "5px", backgroundColor: "#fff"  , color:   "rgba(0, 0, 0, 0.65)" }}
                                        onClick={() => this.props.history.push("/documentList/" + item.id)} 

                                    >
                                        {item.title}
                                    </Button> 
                                }) 
                            }
                        </div>

















                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                        <div>
                      {/*<Button
                        style={{ backgroundColor:  "#58924d" , color:   "#fff"   }}
                        onClick={() => this.props.history.push("/documentList")}
                    >
                        {intl.get('seat.table.btn.return')}
                    </Button>*/}
                    </div>
                    </div>



                    <div style={{paddingTop:"50px",backgroundColor:"#fff", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <p style={{ paddingLeft:'10px', width:'1000px', fontSize: "24px", fontWeight: "700", marginBottom: "10px", marginTop: "-10px" }}>{data.title}</p>

                        {data.published_at && 
                            <h5 style={{ paddingLeft:'10px',fontSize:'12px',width:'1000px'}}>
                                {data.published_at.date.substring(0, data.published_at.date.length - 16)}
                            </h5>
                        }

                        {!data.published_at && 
                            <h5 style={{ paddingLeft:'10px',fontSize:'12px',width:'1000px'}}>
                                {data.created_at.date.substring(0, data.created_at.date.length - 16)}
                            </h5>
                        }




                        <div className="container" style={{ maxWidth: "1000px",  width: "1000px",  height: Height, overflowY: "scroll", justifyContent: "center", backgroundColor: "#fff", margin: "0px 20px" }}>

                            <p
                                style={{ width: "100%", wordBreak: "break-word",  padding: "20px" }}
                                dangerouslySetInnerHTML={{
                                    __html: data.current_content
                                }}
                            />


                        </div>
                    </div>
                </div >

            );
        } else {
            return (<div />)
        }
    }
}

export default withRouter(Form.create()(documentDetail))

