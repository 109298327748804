import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon, InputNumber } from 'antd';
import { getPartner, UpdateShopMessage, GetConfigSet } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class Behavior extends React.Component {

    state = {
        is_on_delivery: false,
        is_open_on_delivery: false,
        is_delivery_free: false,
        is_system_delivery_free: false,
        is_full_delivery_free: false,
        is_open_full_delivery_free: false,
        full_delivery_free: "",
        is_weight: false,
        is_logistics: false,
        is_open_logistics: false,
        is_send_receipt: false,
    };

    async componentDidMount() {
        let res = await getPartner()
        let configData = await GetConfigSet()
        let data = res.data
        this.setState({
            is_on_delivery: data.is_on_delivery == 1 ? true : false,
            is_open_on_delivery: data.is_open_on_delivery == 1 ? true : false,
            is_on_delivery_goods: data.is_on_delivery_goods == 1 ? true : false, 
            is_system_delivery_free: data.is_system_delivery_free == 1 ? true : false,
            is_delivery_free: data.is_delivery_free == 1 ? true : false,
            is_full_delivery_free: data.is_full_delivery_free == 1 ? true : false,
            is_open_full_delivery_free: data.is_open_full_delivery_free == 1 ? true : false,
            is_logistics: data.is_logistics == 1 ? true : false,
            is_open_logistics: data.is_open_logistics == 1 ? true : false,
            full_delivery_free: data.full_delivery_free,
            is_weight: data.is_weight,
            is_send_receipt: data.is_send_receipt == 1 ? true : false,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { is_on_delivery,is_on_delivery_goods, is_send_receipt, is_delivery_free, is_weight,is_full_delivery_free, is_logistics } = this.state
        let data = {}
        data.is_on_delivery = is_on_delivery ? 1 : "0"
        data.is_delivery_free = is_delivery_free ? 1 : "0"
        data.is_full_delivery_free = is_full_delivery_free ? 1 : "0"
        data.is_weight = is_weight ? 1 : "0"
        data.is_logistics = is_logistics ? 1 : "0"
        data.is_send_receipt = is_send_receipt ? 1 : "0"
        data.full_delivery_free = this.props.form.getFieldValue('full_delivery_free')
        UpdateShopMessage(data)
    };

    render() {
        const { is_show_spu_many_help, is_send_receipt, is_on_delivery, is_system_delivery_free,   is_on_delivery_goods,  is_open_on_delivery, is_open_full_delivery_free, is_delivery_free, is_full_delivery_free, full_delivery_free,is_weight, is_logistics, is_open_logistics } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>送货</div>
                    

                    {/*<Form.Item label={<span>{intl.get('setup.form.openDelivery')}</span>}>
                        <Switch
                            disabled={!is_open_on_delivery}

                            checked={is_on_delivery}
                            onChange={() => {
                                this.setState({
                                    is_on_delivery: !is_on_delivery
                                })
                                if (is_on_delivery) {
                                    this.setState({
                                        is_logistics: false,
                                        is_delivery_free: false,
                                    })
                                }
                            }} />
                    </Form.Item>
                    */}
                    


                   {/* <Form.Item label={<span>{intl.get('setup.form.is_logistics')}</span>}>
                        <Switch
                            checked={is_logistics}
                            disabled={!is_on_delivery || !is_open_logistics}
                            onChange={() => {
                                this.setState({
                                    is_logistics: !is_logistics
                                })
                                if (is_on_delivery) {
                                    this.setState({
                                        is_delivery_free: false,
                                    })
                                }
                            }} />
                    </Form.Item>

                    <Form.Item label={<span>{intl.get('setup.form.openFreight')}</span>}>
                        <Switch
                            checked={is_delivery_free}
                            disabled={!is_on_delivery || !is_logistics || !is_system_delivery_free}
                            onChange={() => {
                                if (is_delivery_free) {
                                    this.setState({
                                        is_full_delivery_free: false
                                    })
                                }
                                this.setState({
                                    is_delivery_free: !is_delivery_free
                                })
                            }} />
                    </Form.Item>
                    */}

                    


                    {/*<Form.Item label={<span>{intl.get('setup.form.full_delivery_free')}</span>}>
                        <Switch
                            disabled={!is_delivery_free || !is_open_full_delivery_free}
                            checked={is_full_delivery_free}
                            onChange={() => {
                                if (is_delivery_free) {
                                    this.setState({
                                        is_full_delivery_free: !is_full_delivery_free
                                    })
                                }

                            }} />
                    </Form.Item>*/}

                    {/*
                        is_full_delivery_free &&
                        <Form.Item label={<span>{intl.get('discount.form.free')}</span>}>
                            {getFieldDecorator('full_delivery_free', {
                                initialValue: full_delivery_free
                            })(
                                <InputNumber
                                    min={1}
                                    formatter={value => `$${value}`}
                                    parser={value => value.replace("$", '')}
                                />
                            )}
                        </Form.Item>
                    */}
                    


                     
                    {/*   
                    <Form.Item label={<span>开启重量</span>}>
                        <Switch
                            
                            checked={is_weight}
                            onChange={() => { 
                                this.setState({
                                    is_weight: !is_weight
                                }) 
                            }} />
                    </Form.Item>
                    */}
                   

                      { is_on_delivery && 
                    
                         <Form.Item label={<span>{intl.get('setup.form.is_logistics')}</span>}>
                            <Switch
                                //checked={is_logistics &&  is_on_delivery_goods }
                                checked={is_on_delivery }
                                //disabled={ !is_open_logistics  ||  !is_on_delivery_goods } 
                                disabled
                                onChange={() => {
                                    this.setState({
                                        is_logistics: !is_logistics
                                    })
                                    if (is_on_delivery) {
                                        this.setState({
                                            is_delivery_free: false,
                                        })
                                    }
                                }} />
                        </Form.Item>

                    }

                    {
                        !is_on_delivery && <div>配送开关已经关闭</div>
                    }




                </Card>

                
                

                { /*
                is_on_delivery &&  
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
                */}


            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
