import React from 'react'
import { Form, Input, Card, Button,Popconfirm } from 'antd';
import { getPartner,getPartnerCard,deletePartnerCard } from "../../action/authAction";
import intl from 'react-intl-universal';
import licenceImahe from "../../img/token.png"
class UpLiveToken extends React.Component {
    state = { 
        //userStripeCardId:0, 
        stripeCardInfo:"暂无",
        data:[],
    };
    async componentDidMount() {
        this.PartnerCard();

    }

    async handleDelete() { 
        deletePartnerCard().then(res => {
            this.PartnerCard();    
        })  

        // setTimeout(() => {
        //      this.PartnerCard();
        //  }, 2000)


    }


    async PartnerCard() { 
        
        let res =  await getPartnerCard();    
        if(res.data.data.length > 0 ){ 
            let data = res.data.data ; 
            let item = data[0]; 
            this.setState({ 
                //userStripeCardId : item.id,
                data:data,
                stripeCardInfo : `${item.data.brand}(${item.card_number})`  
            })    
        }else{
            this.setState({  
                data:[],
                stripeCardInfo :'暂无'  
            }) 
        } 



    }





    render() {
        const { stripeCardInfo ,data } = this.state; 
            return <Card>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                    
                        
                <div style={{ marginLeft: "20px" }}>

                     
                        <div style={{ fontWeight:"bold"}}>银行卡信息: {  stripeCardInfo } </div>

                         <div style={{fontSize:"12px",padding:"12px 0 "  }}>*系统会记录最近一次支付的银行卡信息</div>
                         
                    
                </div> 
                     


                { data.length > 0  && 
                     <div style= {{ margin:"10px 0"}} >
                           <Popconfirm title={"确认删除"} onConfirm={() => this.handleDelete()}  >

                            <Button
                            style={{ marginRight: "5px" }}
                            >
                                删除
                            </Button>
                        </Popconfirm>
                    </div> 
                }




                </div>
            </Card>
        

    }
}

export default UpLiveToken
