import React from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { Form, Input, InputNumber, Button, Select, Radio, DatePicker, message, Switch, Upload, Icon, Tooltip, Checkbox, Popconfirm } from 'antd';
import moment from 'moment';
import { getCouponDetail, putCoupon, getNoSkuGoodsList, getGoodsDetail } from "../../action/goodAction";
import { GetConfigSet, getPartner } from "../../action/authAction";
import { getEditorContent } from "../../action/AdAction";
import intl from 'react-intl-universal';
import BraftEditor from 'braft-editor'
import { getLangType } from "../../util/function";
const { Option } = Select;
const { RangePicker } = DatePicker;

let currency_symbol = sessionStorage.getItem("currency_symbol")

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}

class EditCoupon extends React.Component {
    state = {
        name: "",
        SearchData: [],
        spu_id: "",
        discount_type: "fixed",
        image_type: "default",
        coupon_type: "",
        price_type: "price",
        date: "",
        data: "",
        imageUrl: "",
        logo_file: "",
        min_pay_fee_on_online: "",
        is_show_spu_many_help: false,
        is_show: false,
        offState: false,
        editorState: "",
        goods: "",
        qrcode: "",
        typeData: [
            // {
            //     id: 0,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.inside")
            // },
            {
                id: 1,
                name: intl.get("nav.goodsManagement.promotionalgoods.external_coupon")
            },
            // {
            //     id: 2,
            //     name: intl.get("nav.goodsManagement.promotionalgoods.mysterious")
            // }
        ],
        positionData: [
            {
                value: 1,
                label: intl.get("nav.goodsManagement.promotionalgoods.on-line"),
                disabled: false
            },
            {
                value: 0,
                label: intl.get("nav.goodsManagement.promotionalgoods.Offline")
            },

        ],
        operation_type: [],
    };

    async componentDidMount() {
        const id = this.props.location.pathname.substring(12)
        let configData = await GetConfigSet()
        let PartnerData = await getPartner()
        getCouponDetail(id).then(res => {
            if (res.data.spu_id !== 0) {
                this.HandleGetSpuProduct(res.data.spu_id)
            }

            this.ChangeEditorData(res)
            let goods = res.data.spu
            let is_authorization_code = PartnerData.data.is_authorization_code === 1
            let positionData = [...this.state.positionData]
            positionData[0].disabled = is_authorization_code ? false : true
            if (res.data.qrcode) {
                this.HandleGetQrcode(res.data.qrcode)
            }
            this.setState({
                data: res.data,
                goods: res.data.spu,
                imageUrl: res.data.image,
                discount_type: res.data.discount_type,
                price_type: res.data.price_type,
                image_type: res.data.image_type === 0 ? "default" : "self",
                spu_id: res.data.spu_id,
                is_show: res.data.is_show,
                offState: res.data.state === 1,
                date: [moment(res.data.start_at, "YYYY-MM-DD HH:mm:ss"), moment(res.data.end_at, "YYYY-MM-DD HH:mm:ss")],
                min_pay_fee_on_online: configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,
                is_show_spu_many_help: PartnerData.data.is_show_spu_many_help === 1,
                editorState: BraftEditor.createEditorState(res.data.detail),
                positionData: positionData,
                coupon_type: res.data.spu_id !== 0 ? "goods" : "shop",
                operation_type: res.data.operation_type.toString() && (res.data.operation_type !== 2 ? [res.data.operation_type] : [0, 1])
            })
        })
    }

    HandleGetSpuProduct = (id) => {
        getGoodsDetail(id).then(res => {
            this.setState({
                name: res.data.name
            })
        })
    }
    onChange = e => {
        this.setState({
            value: e
        });
    };
    onSelectSearch = (val) => {
        if (val.length > 1) {
            getNoSkuGoodsList(1, val).then(res => {
                this.setState({
                    SearchData: res.data.data
                })
            })
        }

    }
    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };

    checkPsd(rule, value, callback) {
        let price = this.state.data.spu && this.state.data.spu.price;
        let splitData = value.toString().split(".")
        let num = splitData.length === 1 ? 1 : splitData[1].length
        if (price && parseInt(price) < parseInt(value)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.discountPrice")));
        } else if (this.accSub(price, value) < parseInt(this.state.min_pay_fee_on_online)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.minimumOrder")));
        } else if (num > 2) {
            callback(new Error(intl.get("goods.list.goodsAnnexType.goodsAnnex_price.error")));
        } else {
            callback();
        }
    }

    checkPsd2(rule, value, callback) {
        let price = this.state.data.spu.price;
        if (this.accMul(price, value) / 100 < parseInt(this.state.min_pay_fee_on_online)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.minimumOrder")));
        } else {
            callback();
        }
    }
    checkStock(rule, value, callback) {
        let stock = this.state.data.stock;
        if (stock && parseInt(stock) > parseInt(value)) {
            callback(new Error(intl.get("nav.goodsManagement.promotionalgoods.stock_error")));
        } else {
            callback();
        }
    }
    // 除以
    accDiv(arg1, arg2) {
        let t1 = 0,
            t2 = 0,
            r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length
        } catch (e) { }
        try {
            t2 = arg2.toString().split(".")[1].length
        } catch (e) { };
        r1 = Number(arg1.toString().replace(".", ""))
        r2 = Number(arg2.toString().replace(".", ""))
        return (r1 / r2) * Math.pow(10, t2 - t1);
    }
    // 乘以
    accMul(arg1, arg2) {

        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length
        } catch (e) { }
        try {
            m += s2.split(".")[1].length
        } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }
    // 减
    accSub(arg1, arg2) {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //动态控制精度长度 
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }

    ChangeEditorData = (data) => {
        getEditorContent(data.data.detail).then(res => {
            this.setState({
                editorState: BraftEditor.createEditorState(res.data)
            })
        }).catch(error => {
        })
    }

    HandleGetQrcode = (url) => {
        axios.get(url).then(res => {
            this.setState({
                qrcode: res.data
            })
        })
    }

    render() {
        const { name, qrcode, SearchData, discount_type, spu_id, data, goods, operation_type, date, price_type, positionData, coupon_type, image_type, imageUrl, is_show, offState, is_show_spu_many_help, editorState, typeData } = this.state;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );
        if (data) {
            return (
                <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView" style={{ minWidth: "1000px" }}>
                    <Form.Item
                        label="CDID"
                    >
                        <Input disabled value={data.adid} />
                    </Form.Item>
                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.coupon_name')}
                    >
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods_name_error") }],
                            initialValue: data.name
                        })(<Input disabled />)}
                    </Form.Item>
                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.coupon_style')}
                    >
                        <Radio.Group
                            disabled
                            value={image_type}>
                            <Radio value={"default"}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_style_default")}</Radio>
                            <Radio value={"self"}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_style_self")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        image_type === "self" &&
                        <Form.Item
                            label={<span>
                                {intl.get('nav.goodsManagement.promotionalgoods.coupon_style_img')}
                                <Tooltip title={intl.get("nav.goodsManagement.goods.prompt")}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>

                            </span>}
                        >

                            {!!imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Form.Item>
                    }
                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.coupon_type')}
                    >
                        <Radio.Group
                            disabled
                            onChange={(e) => {
                                this.setState({
                                    coupon_type: e.target.value
                                })
                            }}
                            value={coupon_type}>
                            <Radio value={"goods"}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_type_goods")}</Radio>
                            <Radio value={"shop"}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_type_shop")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {
                        coupon_type === "goods" ?

                            (spu_id !== 0 && name) ?

                                < Form.Item
                                    label={<span>{intl.get('ad.form.selectgoods')}</span>}
                                >
                                    <Select
                                        disabled
                                        showSearch
                                        defaultValue={name}
                                        placeholder={intl.get('ad.form.selectgoods')}
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={this.onSelectSearch}
                                        onChange={(e, value) => {
                                            let data = this.state.data
                                            data.discount_on_fixed = 0
                                            data.discount_on_percentage = 100
                                            this.setState({
                                                spu_id: value.props.value,
                                                goods: value.props.item,
                                                data,
                                            })
                                        }}
                                        notFoundContent={null}
                                    >
                                        {
                                            SearchData.length > 0 && SearchData.map((item, index) => {
                                                return <Option key={index} value={item.id} item={item}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item> :
                                <div>
                                    < Form.Item
                                        label={<span>{intl.get('ad.form.selectgoods')}</span>}
                                    >
                                        {getFieldDecorator('goods_rules', {
                                            rules: [{ required: true }],
                                        })(
                                            <Select
                                                showSearch
                                                placeholder={intl.get('ad.form.selectgoods')}
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={false}
                                                onSearch={this.onSelectSearch}
                                                onChange={(e, value) => {
                                                    let data = this.state.data
                                                    data.discount_on_fixed = 0
                                                    data.discount_on_percentage = 100
                                                    this.setState({
                                                        spu_id: value.props.value,
                                                        goods: value.props.item,
                                                        data,
                                                    })
                                                }}
                                                notFoundContent={null}
                                            >
                                                {
                                                    SearchData.length > 0 && SearchData.map((item, index) => {
                                                        return <Option key={index} value={item.id} item={item}>{item.name}</Option>
                                                    })
                                                }
                                            </Select>
                                        )}
                                    </Form.Item>
                                </div>
                            : null
                    }



                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.coupon_discount_type')}
                    >
                        <Radio.Group
                            disabled
                            onChange={(e) => {
                                this.setState({
                                    discount_type: e.target.value
                                })
                            }}
                            value={discount_type}>
                            <Radio value={"fixed"}>{intl.get("nav.goodsManagement.promotionalgoods.quota_discount")}</Radio>
                            {
                                coupon_type === "goods" &&
                                <Radio value={"percentage"}>{intl.get("nav.goodsManagement.promotionalgoods.percent_discount")}</Radio>
                            }

                        </Radio.Group>
                    </Form.Item>

                    {
                        discount_type === "fixed" ?
                            <Form.Item
                                label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed')}
                            >
                                {getFieldDecorator('discount_on_fixed', {
                                    rules: [{ required: true }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                                    initialValue: data.discount_on_fixed
                                })(<InputNumber
                                    disabled
                                    formatter={value => `${currency_symbol}${value}`}
                                    parser={value => value.replace(currency_symbol, '')}
                                    style={{ width: "200px" }} />)}
                            </Form.Item> :
                            <Form.Item
                                label={intl.get('nav.goodsManagement.promotionalgoods.discount_on_percentage')}
                            >
                                {getFieldDecorator('discount_on_percentage', {
                                    rules: [
                                        {
                                            required: true, pattern: new RegExp(/^[1-99]\d*$/, "g"),
                                            message: intl.get("nav.goodsManagement.promotionalgoods.enter_integer")
                                        },
                                        { validator: (rule, value, callback) => { this.checkPsd2(rule, value, callback) } }
                                    ],
                                    initialValue: data.discount_on_percentage * 100
                                })(<InputNumber
                                    disabled
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')} min={1} max={100} style={{ width: "200px" }} />)}
                            </Form.Item>
                    }

                    {
                        coupon_type === "goods" &&
                        <Form.Item
                            label={intl.get("nav.goodsManagement.promotionalgoods.discount_price")}
                        >
                            <span>
                                {currency_symbol}{
                                    (goods && goods.rrp) || (goods ? goods.price : 0) ?
                                        discount_type === "fixed" ?
                                            goods && this.accSub(goods.price, this.props.form.getFieldValue('discount_on_fixed')) || 0 :
                                            goods && (parseFloat(this.accMul(parseFloat(goods.price), parseFloat(this.props.form.getFieldValue('discount_on_percentage')) || 1)) / 100).toFixed(2) : 0
                                }
                            </span>
                        </Form.Item>
                    }
                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.OffTheShelf')}
                    >
                        <Switch
                            disabled
                            checked={offState}
                            onChange={() => {
                                this.setState({
                                    offState: !offState
                                })
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={<span>{intl.get('nav.goodsManagement.promotionalgoods.term_of_validity')}</span>}
                    >
                        {getFieldDecorator('date', {
                            rules: [{ required: true }],
                            initialValue: date
                        })(<RangePicker
                            disabled
                            format="DD/MM/YYYY"
                            disabledDate={(current) => {
                                return current && current < moment().subtract(1, "days")
                            }}
                        />)}
                    </Form.Item>
                    <Form.Item
                        label={intl.get("nav.goodsManagement.promotionalgoods.marketing_type")}
                    >
                        {getFieldDecorator('type', {
                            rules: [{ required: true }],
                            initialValue: 1
                        })(<Select
                            disabled
                        >
                            {typeData && typeData.map(el => <Select.Option key={el.id} value={el.id}>{el.name}</Select.Option>)}
                        </Select>)}
                    </Form.Item>
                    <Form.Item
                        label={intl.get("nav.goodsManagement.promotionalgoods.operationType")}
                    >
                        {getFieldDecorator('operation_type', {
                            rules: [{ required: true, message: intl.get("nav.goodsManagement.promotionalgoods.operationType_tips") }],
                            initialValue: operation_type
                        })(
                            <Checkbox.Group
                                disabled
                                options={positionData}
                                onChange={(e) => {
                                    this.setState({
                                        operation_type: e.length > 1 ? 2 : e[0]
                                    })
                                }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.stock')}
                    >
                        {getFieldDecorator('stock', {
                            rules: [{ required: true, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }, { validator: (rule, value, callback) => { this.checkStock(rule, value, callback) } }],
                            initialValue: data.total_stock
                        })(<InputNumber disabled max={400} style={{ width: "100%" }} />)}
                    </Form.Item>
                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.coupon_receive_count')}
                    >
                        {getFieldDecorator('sale_quantity', {
                            initialValue: data.sale_quantity
                        })(<InputNumber disabled style={{ width: "100%" }} />)}
                    </Form.Item>
                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.coupon_use_count')}
                    >
                        {getFieldDecorator('use_quantity', {
                            initialValue: data.use_quantity
                        })(<InputNumber disabled style={{ width: "100%" }} />)}
                    </Form.Item>
                    <Form.Item
                        label={
                            <span>
                                {intl.get('nav.goodsManagement.promotionalgoods.quota')}
                                <Tooltip title={intl.get("nav.goodsManagement.promotionalgoods.coupon_total_quota")}>
                                    <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                                </Tooltip>
                            </span>
                        }
                    >
                        {getFieldDecorator('quota', {
                            rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: intl.get("nav.goodsManagement.promotionalgoods.integer") }],
                            initialValue: data.quota
                        })(<Input disabled type="number" />)}
                    </Form.Item>
                    {

                        coupon_type === "shop" && discount_type === "fixed" &&
                        <Form.Item
                            label={intl.get('nav.goodsManagement.promotionalgoods.full_price')}>
                            {getFieldDecorator('full_price', {
                                rules: [{ required: false }],
                                initialValue: data.full_price
                            })(<Input disabled prefix={currency_symbol} type="number" />)}
                        </Form.Item>
                    }
                    <Form.Item
                        label={intl.get('goods.list.sort')}
                    >
                        {getFieldDecorator('sort', {
                            rules: [{ required: false }],
                            initialValue: data.sort
                        })(<Input disabled type="number" />)}
                    </Form.Item>
                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.coupon_type_poster')}
                    >
                        <img src={qrcode} alt="" style={{ width: '300px' }} />
                    </Form.Item>
                    <Form.Item
                        label={intl.get('nav.goodsManagement.promotionalgoods.coupon_detail')}
                    >
                        <div style={{
                            border: "1px solid #dedede",
                        }}>
                            <BraftEditor
                                disabled
                                value={editorState}
                                language={Number(getLangType()) == 1 ? "zh" : "en"}
                                onChange={(editorState) => {
                                    this.setState({ editorState })
                                }} />
                        </div>
                    </Form.Item>


                    <Form.Item {...tailFormItemLayout}>
                        <Popconfirm
                            title={intl.get('seat.table.btn.cancel.placeHorder')}
                            onConfirm={() => {
                                this.props.history.goBack()
                            }}>
                            <Button
                                type="primary"
                                style={{ marginRight: "10px" }}
                            >
                                {intl.get('seat.table.btn.cancel')}
                            </Button>
                        </Popconfirm>
                        <Button type="primary" htmlType="submit">
                            {intl.get('cashwithdrawal.form.submit')}
                        </Button>
                    </Form.Item>


                </Form >
            );
        } else {
            return null
        }

    }
}

export default withRouter(Form.create()(EditCoupon))
