import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Form } from 'antd';
import { getPackageLogList } from "../../action/goodAction";
import intl from 'react-intl-universal';

class PackageLogList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], is_trial: 0, visible: false, row: null, key: '' };
        this.columns = [
            {
                title: "ID",
                dataIndex: 'id',
                width: '5%',
            },
            {
                title: intl.get('package.name'),
                dataIndex: 'package[name]',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('package.fixed_fee'),
                dataIndex: 'order_fixed_fee',
                width: '12%',
                align: "center",
                render: (record) => {
                    if (record) {
                        return "$" + record
                    }
                }
            },
            {
                title: intl.get('package.rate'),
                dataIndex: 'rate',
                width: '15%',
                align: "center",
                render: (record) => {
                    if (record) {
                        return record + "%"
                    }
                }
            },
            {
                title: intl.get('package.ActivityServiceFee'),
                dataIndex: 'activity_service_rate',
                width: '15%',
                align: "center",
                render: (record) => {
                    if (record) {
                        return record + "%"
                    }
                }
            },
            {
                title: intl.get('package.reason'),
                dataIndex: 'event',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('package.record'),
                dataIndex: 'record_at',
                width: '10%',
                align: "center",
                // render: (text) => text.date.substring(0, 19)
                render: (text) => text.date.substring(0, text.date.length - 15)
            },
            {
                title: intl.get('package.implement'),
                dataIndex: 'implement_at',
                width: '10%',
                align: "center",
                // render: (text) => text.date.substring(0, text.date.length - 7)
                render: (text) => text.date.substring(0, text.date.length - 15)
            },
            {
                title: intl.get('package.created'),
                dataIndex: 'created_at',
                width: '10%',
                align: "center",
                render: (text) => text.date.substring(0, text.date.length - 15)
            },
            // {
            //     title: intl.get('order.table.remark'),
            //     dataIndex: 'remarks',
            //     width: '15%',
            //     align: "center",
            // },
        ];
    }

    async componentDidMount() {
        let res = await getPackageLogList()
        this.setState({ data: res.data.data })
    }


    render() {
        return <Table
            rowKey={record => record.id}
            // bordered
            dataSource={this.state.data}
            columns={this.columns}
            pagination={{
                hideOnSinglePage: true
            }}
            className="waperBox"
        />
    }
}

export default withRouter(Form.create()(PackageLogList))

