import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, InputNumber, Form, Button } from 'antd';
import { GetRedPackageList } from "../../action/authAction";
import intl from 'react-intl-universal';

const EditableContext = React.createContext();

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps} >
                {
                    children
                }
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class RedPackageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ""
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '10%',
                editable: false,
                align: "center",
            },
            {
                title: intl.get('red.table.redname'),
                dataIndex: 'name',
                width: '20%',
                editable: true,
                align: "center",
            }
            , {
                title: intl.get('red.table.activeMoney'),
                dataIndex: 'money',
                width: '15%',
                editable: true,
                align: "center",
            },
            {
                title: intl.get('red.table.activenum'),
                dataIndex: 'ticket',
                width: '15%',
                editable: true,
                align: "center",
            },
            {
                title: intl.get('red.table.activeTotal'),
                dataIndex: 'max_limit',
                width: '10%',
                editable: true,
                align: "center",
            },
            {
                title: intl.get('red.table.status'),
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div>
                            {record.status === 1 ? <span>{intl.get('red.table.en')}</span> : <span>{intl.get('red.table.dis')}</span>}
                        </div>
                    )
                },
            },
            {
                title: intl.get('home.table.operation'),
                dataIndex: 'operation',
                width: '30%',
                align: "center",
                render: (text, record) => {
                    return (
                        <Button onClick={() => this.props.history.push("/ActivityList/" + record.id)}>
                            {intl.get('red.table.log')}
                        </Button>
                    )
                },
            },

        ];
    }


    expandedRowRender = (record) => {
        const columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'act[id]',
                align: "center"
            },
            {
                title: intl.get('red.table.activebalance'),
                dataIndex: 'act[limit]',
                align: "center"
            },
            {
                title: intl.get('red.table.currentTotal'),
                dataIndex: 'act[money]',
                align: "center",
            },
            {
                title: intl.get('red.table.currentTotalNum'),
                dataIndex: 'act[ticket]',
                align: "center",
            },
            {
                title: intl.get('red.table.balanceNum'),
                dataIndex: 'act[ticket_limit]',
                align: "center",
            },

        ];


        return <Table columns={columns} rowKey={record => record.id} className="extraTable" bordered dataSource={this.state.data} pagination={false} />;
    };


    async componentDidMount() {
        let res = await GetRedPackageList();
        this.setState({ data: res.data.data })
    }


    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'sort' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,

                }),
            };
        });

        return (
            <EditableContext.Provider value={this.props.form}>
                {
                    this.state.data && <Table
                        //   rowKey={record => record.id}
                        components={components}
                        bordered
                        dataSource={this.state.data}
                        columns={columns}
                        expandedRowRender={this.expandedRowRender}
                        rowClassName="editable-row"
                        pagination={{
                            hideOnSinglePage: true
                        }}
                        className="waperBox"
                    />
                }
            </EditableContext.Provider>
        );
    }
}
export default withRouter(Form.create()(RedPackageList))

