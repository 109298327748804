import React, { Component } from 'react'
import { Button, Row, Form, Input, message,Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import './index.less'
import { loginempartner, AdminLogin, getDefaultLang, getPartner } from "../../action/authAction";
import { setTokenStorage, getTokenStorage, setLangType, getLangType } from "../../util/function";
import backgroundImg from "../../img/background.png"
import adminbackgroundImg from "../../img/adminbackground.png"
class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.resizeTTY.bind(this)
        this.state = {
            isAdmin: false,
            siteName: "Partner login",
            width: document.body.clientWidth,
            height: document.body.clientHeight
        }
    }


    componentDidMount() {
        let token = getTokenStorage()
        if (token && token !== "null") {
            window.location.href = '/'
        }
        window.addEventListener('resize', this.resizeTTY)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeTTY)
    }
    resizeTTY = () => {
        var width = document.body.clientWidth;
        var height = document.body.clientHeight;
        if (width < 1000) {
            this.setState({
                width: 1000
            })
        } else {
            this.setState({
                width, height
            })
        }

    }

    HandleGetPartner = () => {
        getPartner().then(res => {
            let currency_symbol = res.data.region.currency_symbol
            sessionStorage.setItem('currency_symbol', currency_symbol || "$")
            this.props.history.push('/')
        })
    }

    handleOk = () => {
        const { form } = this.props
        const { validateFieldsAndScroll } = form
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return
            }
            if (this.state.isAdmin) {
                AdminLogin(values).then(res => {
                    setTokenStorage(res.data.access_token)
                    if (getLangType() > 0) {
                        setLangType(Number(getLangType()))
                        this.HandleGetPartner()
                    } else {
                        getDefaultLang().then(data => {
                            let defaultLang = 2;
                            if (data && data.data) {
                                defaultLang = data.data.id
                            }
                            setLangType(defaultLang);
                            this.HandleGetPartner()
                        })
                    }
                    message.success('Login successful')
                    setTimeout(() => {
                        this.props.history.push('/')
                    }, 1000)
                }).catch(error => {
                    // message.error(error.response.data.message)
                })
                sessionStorage.setItem("adminid", values.unique)
            } else {
                loginempartner(values).then(res => {
                    setTokenStorage(res.data.access_token)
                    message.success('Login successful')
                    if (getLangType() > 0) {
                        setLangType(Number(getLangType()))
                        this.HandleGetPartner()
                    } else {
                        getDefaultLang().then(data => {
                            let defaultLang = 2;
                            if (data && data.data) {
                                defaultLang = data.data.id
                            }
                            setLangType(defaultLang);
                            this.HandleGetPartner()
                        })
                    }
                }).catch(error => {
                    // message.error(error.response.data.message)
                })
                sessionStorage.removeItem('adminid')
            }
            // 成功跳转给出提示


        })
    }

    render() {
        const { isAdmin, siteName, width, height } = this.state
        const FormItem = Form.Item
        const { getFieldDecorator } = this.props.form
        return (
            <div style={{
                width: width + "px",
                height: height + "px",
                position: "relative",
                backgroundColor: "#58924d"

            }}>
                <div style={{ position: "absolute", top: "20%", display: "flex", marginLeft: "30px" }}>
                    {
                        isAdmin ?
                            <img style={{ width: "500px",height: "512px" }} src={adminbackgroundImg} alt='="' /> :
                            <img style={{ width: "500px",height: "512px" }} src={backgroundImg} alt='="' />
                    }


                    <div className='loginForm'  style={{ height: "340px"}}>
                        <div className='Loginlogo'>
                            <span>{siteName}</span>


                            {/*

                             <div  className='emlogin' 

                             onClick={() => {
                                this.props.history.push("/login/")
                             }}   ><Icon type='user' /></div>

                            */}
                            

                        </div>
                        <Form> 
                            

                            {
                                 <FormItem hasFeedback>
                                    {getFieldDecorator('bdid', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "BDID is required"
                                            }
                                        ]
                                    })(
                                        <Input onPressEnter={this.handleOk} placeholder={`BDID`} />
                                    )}
                                </FormItem>
                            }



                            {
                                isAdmin ? <FormItem hasFeedback>
                                    {getFieldDecorator('unique', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Administrator number is required"
                                            }
                                        ]
                                    })(
                                        <Input onPressEnter={this.handleOk} placeholder={`Administrator number`} />
                                    )}
                                </FormItem> : <FormItem hasFeedback>
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Username is required"
                                            }
                                        ]
                                    })(
                                        <Input onPressEnter={this.handleOk} placeholder={`Username`} />
                                    )}
                                </FormItem>
                            }
                            <FormItem hasFeedback>
                                {getFieldDecorator('password', {
                                    rules: [
                                        {
                                            required: true
                                        }
                                    ]
                                })(
                                    <Input
                                        type='password'
                                        placeholder={`Password`}
                                    />
                                )}
                            </FormItem>
                            <Row>
                                <Button style={{ backgroundColor: "#73b669", color: "#000", border: "none" }} onClick={this.handleOk}>
                                    Sign in
                                </Button>
                                 
                            </Row>
                        </Form>
                    </div>

                </div>
                <div style={{ textAlign: "center", position: "absolute", bottom: "10px", width: "100%" }}>
                    <span>© 2023 Eatmoon System. All Rights Reserved. </span>
                </div>
            </div>
        )
    }
}

export default withRouter(Form.create()(LoginForm))
