import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Button } from 'antd';
import { QueryOrders } from "../../action/authAction";
import intl from 'react-intl-universal';


class MyOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };

        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('home.table.ordernumber'),
                dataIndex: 'order_sn',
                width: '9%',
                align: "center",
            },
            {
                title: intl.get('orders.price'),
                dataIndex: 'price',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('orders.real_price'),
                dataIndex: 'real_price',
                width: '7%',
                align: "center",
            },
            {
                title: intl.get('orders.use_money'),
                dataIndex: 'use_money',
                width: '6%',
                align: "center",
            },
            {
                title: intl.get('orders.status'),
                dataIndex: 'status',
                width: '7%',
                render: (text, record) => {
                    return <span>{
                        text === 0 ? intl.get('orders.status.zero') :
                            text === 1 ? intl.get('orders.status.one') :
                                text === 2 ? intl.get('orders.status.two') :
                                    text === 3 ? intl.get('orders.status.three') :
                                        text === 4 ? intl.get('orders.status.four') : null
                    }</span>
                }
            },
            {
                title: intl.get('orders.pay_type'),
                dataIndex: 'pay_type',
                width: '7%',
                align: "center",
            },
            {
                title: intl.get('orders.is_trial'),
                dataIndex: 'is_trial',
                width: '7%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span >{text === 1 ? intl.get('orders.is_trial.one') : intl.get('orders.is_trial.zero')}</span>
                    )
                }
            },

            {
                title: intl.get('orders.is_expire'),
                dataIndex: 'is_expire',
                width: '8%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span style={{ color: record.is_expire === 1 ? "green" : "#ff0000" }}>{record.is_expire === 1 ? intl.get('subscription.expire.one') : intl.get('subscription.expire.zero')}</span>
                    )
                }
            },
            {
                title: intl.get('subscription.option'),
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return (
                        <Button onClick={() => {
                            this.props.history.push("/myOrderDetail?" + record.id)
                        }}>{intl.get('subscription.see')}</Button>
                    )
                }
            },
        ];
    }

    async componentDidMount() {
        QueryOrders().then(data => {
            if (data && data.data) {
                this.setState({
                    data: data.data.data
                })
            }
        })
    }

    render() {

        return (
            <div className="waperBox">
                <Table
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(MyOrders))

