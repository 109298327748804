import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon } from 'antd';
import { getPartner, UpdateShopMessage, GetConfigSet } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class Behavior extends React.Component {

    state = {
        is_in_store: true,
        eat_in: true,
        take: true,
        is_open_partner_seat: false,
        is_open_shop_service_end_at: true,
        is_order_receiving: true,
        is_delivery_free: true,
        is_full_delivery_free: true,
        is_open_prepare_time: true,
        is_system_delivery_free: false,
        full_delivery_free: "",
    };

    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getPartner()
        let configData = await GetConfigSet()
        let data = res.data
        this.setState({
            is_in_store: data.is_in_store == 1 ? true : false,
            eat_in: data.in_store_type.eat_in == 1 ? true : false,
            take: data.in_store_type.take == 1 ? true : false,
            is_open_shop_service_end_at: data.is_open_shop_service_end_at == 1 ? true : false,
            is_order_receiving: data.is_order_receiving == 1 ? true : false,
            is_delivery_free: data.is_delivery_free == 1 ? true : false,
            is_full_delivery_free: data.is_full_delivery_free == 1 ? true : false,
            is_open_prepare_time: data.is_open_prepare_time == 1 ? true : false,
            is_open_partner_seat: data.is_open_partner_seat == 1 ? true : false,
            is_system_delivery_free: data.is_system_delivery_free == 1 ? true : false,
            prepare_time: data.prepare_time || 30,
            stop_order_time: data.stop_order_time || 15,
            consumer_code: data.consumer_code,
            take_code: data.take_code,
            table_name: data.table_name,
            full_delivery_free: data.full_delivery_free,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { eat_in, take, is_in_store, is_open_shop_service_end_at, is_order_receiving, is_delivery_free, is_full_delivery_free, is_open_prepare_time } = this.state
        let data = {}
        data.is_in_store = is_in_store ? 1 : "0"
        data.in_store_type = { "eat_in": eat_in ? 1 : "0", "take": take ? 1 : "0" }
        data.is_open_shop_service_end_at = is_open_shop_service_end_at ? 1 : "0"
        data.is_order_receiving = is_order_receiving ? 1 : "0"
        data.is_delivery_free = is_delivery_free ? 1 : "0"
        data.is_full_delivery_free = is_full_delivery_free ? 1 : "0"
        data.is_open_prepare_time = is_open_prepare_time ? 1 : "0"
        data.prepare_time = this.props.form.getFieldValue('prepare_time')
        data.stop_order_time = this.props.form.getFieldValue('stop_order_time')
        data.consumer_code = this.props.form.getFieldValue('consumer_code')
        data.take_code = this.props.form.getFieldValue('take_code')
        data.table_name = this.props.form.getFieldValue('table_name')
        data.full_delivery_free = this.props.form.getFieldValue('full_delivery_free')

        UpdateShopMessage(data).then(res => {
            this.HandleGetData()
        }).catch(error => {
            this.HandleGetData()
        })
    };





    render() {
        const { eat_in, take, is_in_store, is_show_spu_many_help, is_open_shop_service_end_at, is_order_receiving, is_delivery_free, is_full_delivery_free, is_open_prepare_time, prepare_time, stop_order_time, consumer_code, take_code, table_name, is_open_partner_seat, is_system_delivery_free, full_delivery_free } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>


                 

                 

                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('setup.form.key_header')}</div>
                    <Form.Item label={<span>自定义命名取单号</span>}>
                        {getFieldDecorator('consumer_code', {
                            initialValue: consumer_code
                        })(<Input style={{ width: "100px" }} />)}
                    </Form.Item>
                     
                    {
                        is_open_partner_seat &&
                        <Form.Item label={<span>自定义命名桌号</span>}>
                            {getFieldDecorator('table_name', {
                                initialValue: table_name
                            })(<Input style={{ width: "100px" }} />)}
                        </Form.Item>
                    }

                </Card>





                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
