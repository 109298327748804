import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Select } from 'antd';
import { GetSiteCountry } from "../../action/authAction";
import intl from 'react-intl-universal';
class SysTemSet extends React.Component {

    state = {
        data: "",
        regionList: ""
    };

    async componentDidMount() {
        GetSiteCountry().then(res => {
            this.setState({
                data: res.data.region,
            })
        })

    }



    render() {
        const { data, regionList } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>

                <Form.Item
                    label={intl.get("nav.setting.SiteCountry")}
                >
                    {getFieldDecorator('region_id', {
                        rules: [{ required: false, message: 'Please select region!' }],
                        initialValue: data && data.full_name
                    })(
                        <Select disabled>
                            {regionList && regionList.map(el => <Select.Option key={el.id} value={el.id}>{el.full_name}</Select.Option>)}
                        </Select>
                    )}
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(SysTemSet))
