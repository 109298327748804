import React from 'react'
import { withRouter } from 'react-router-dom'
import { Descriptions, Card, Divider, Table, Button, Modal, Input, Popconfirm } from 'antd';
import { getUserDetail, GetOrderListE, getPartner } from "../../action/authAction";
import { putOrder, getUserCouponList } from "../../action/goodAction"
import intl from 'react-intl-universal';
const { TextArea } = Input
let currency_symbol = sessionStorage.getItem("currency_symbol")
class PlugInList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: "",
            orderData: "",
            couponData: "",
            visible: false,
            message: "",
            ItemId: "",
            is_authorization_code: false,
            is_coupon: false,
            tabType: "order",
            page: 1
        };
    }

    async componentDidMount() {
        const id = this.props.location.pathname.substring(12)
        if (id) {
            let res = await getUserDetail(id);
            let shopData = await getPartner();
            this.HandleGetCouponData(this.state.page)
            this.HandleGetOrderData(this.state.page)
            this.setState({
                data: res.data,
                is_authorization_code: shopData.data.is_authorization_code === 1,
                is_coupon: shopData.data.is_coupon === 1,
                tabType: shopData.data.is_authorization_code === 1 ? "order" : "coupon"
            })
        }
    }

    HandleGetCouponData = (page) => {
        const id = this.props.location.pathname.substring(12)
        getUserCouponList(page, "?user_id=" + id).then(res => {
            this.setState({
                couponData: res.data.data,
                couponMeta: res.data.meta.pagination
            })
        })
    }
    HandleGetOrderData = (page) => {
        const id = this.props.location.pathname.substring(12)
        GetOrderListE(page, id).then(res => {
            this.setState({
                orderData: res.data.data,
                orderMeta: res.data.meta.pagination
            })
        })
    }


    render() {
        const { data, orderData, message, ItemId, is_authorization_code, is_coupon, couponData, tabType, couponMeta, orderMeta } = this.state;
        const couponColumns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '2%',
                align: "center",
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods_name"),
                dataIndex: 'coupon',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>{record.name}</span>
                }
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods.coupon_number"),
                dataIndex: 'unique_code',
                width: '5%',
                align: "center",
            },

            {
                title: intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed'),
                dataIndex: "coupon",
                width: '4%',
                align: "center",
                render: (record) => {
                    let discount_on_fixed
                    if (record.discount_type === "fixed") {
                        discount_on_fixed = currency_symbol + record.discount_on_fixed
                    } else {
                        discount_on_fixed = (parseFloat(record.discount_on_percentage) * 100).toFixed(0) + "%"
                    }
                    return discount_on_fixed
                }
            },
            {
                title: intl.get("nav.goodsManagement.coupon_date"),
                width: '8%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date && record.created_at.date.substring(0, 19)
                }
            },
            {
                title: intl.get("nav.goodsManagement.coupon_use_date"),
                dataIndex: "use_at",
                width: '8%',
                align: "center",
                render: (record) => {
                    return record && record.date.substring(0, 19)
                }
            },
            {
                title: intl.get('subscription.status'),
                width: '3%',
                align: "center",
                render: (record) => {
                    return (
                        <div>
                            {
                                record.end_status ?
                                    <span style={{ color: "#ff0000" }}>{intl.get("nav.goodsManagement.promotionalgoods.end")}</span> :
                                    <span style={{ color: "green" }}>{intl.get("nav.goodsManagement.promotionalgoods.NormalSales")}</span>
                            }
                        </div>
                    )
                }
            }
        ];
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },

            {
                title: intl.get('home.table.ordernumber'),
                dataIndex: 'order_sn',
                width: '7%',
                align: "center",
            },
            {
                title: intl.get('nav.goodsManagement.goods'),
                width: '10%',
                render: (text, record) => {
                    const data = record.orderSpu && record.orderSpu.data
                    if (record.spu_discount_id > 0) {
                        let attach_data = data[0].attach_data.spu_discount
                        return <div style={{ display: "flex" }}>
                            <img src={attach_data.image} style={{ width: "50px", height: "50px", objectFit: "contain", marginRight: "10px" }} alt="" />
                            <div>
                                {
                                    record.spu_many_help_id > 0 ?
                                        <span>
                                            <span style={{ color: "#ff0000" }}>[{intl.get("nav.goodsManagement.cutgoodsType")}]</span>{attach_data.name}
                                        </span> :
                                        <span>
                                            <span style={{ color: "#ff0000" }}>[{intl.get("nav.goodsManagement.Promotion")}]</span>{attach_data.name}
                                        </span>
                                }
                            </div>
                        </div>
                    } else {
                        return (
                            <div>
                                {
                                    data && data.map(item => {
                                        return (<div key={item.id.toString() + item.order_id} style={{ display: "flex", marginBottom: "5px" }}>
                                            <img src={item.attach_data.image} style={{ width: "50px", height: "50px", objectFit: "contain", marginRight: "10px" }} alt="" />
                                            <div className="good-name" style={{ width: "70px" }}>
                                                <div style={{ textAlign: "left" }} title={item.attach_data.name}>{item.attach_data.name}</div>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    {
                                                        item.attach_data && item.attach_data.spu_sku && item.attach_data.spu_sku.spu_sku_goods_specification_item && item.attach_data.spu_sku.spu_sku_goods_specification_item.map((key, index) => {
                                                            return (
                                                                <span key={item.order_id + key.name + key.goods_specification_id + index + Math.random()} style={{ color: "#878FA5", fontSize: 12, textAlign: "left", margin: "5px 0px" }}>{key.name}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        item.attach_data && item.attach_data.spu_goods_annex_type && item.attach_data.spu_goods_annex_type.map(key => {
                                                            let spu_goods_annex_type_item = key.spu_goods_annex_type_item
                                                            return (
                                                                <div key={item.order_id + Math.random()}>
                                                                    {spu_goods_annex_type_item && spu_goods_annex_type_item.map((type_key, i) => {
                                                                        let goods_annex_type_item = type_key.goods_annex_type_item
                                                                        return <p key={i + goods_annex_type_item.name + Math.random()} style={{ color: "#878FA5", fontSize: 12, textAlign: "left", margin: "5px 0px" }}>{goods_annex_type_item.name} x {goods_annex_type_item.quantity}</p>
                                                                    })}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>)
                                    })
                                }
                            </div>
                        )
                    }

                }

            },

            {
                title: intl.get("home.table.price"),
                dataIndex: 'price',
                width: '5%',
                align: "center",
                render: (record) => {
                    return <span>{currency_symbol}{record}</span>
                }
            },
            {
                title: intl.get("orders.status"),
                dataIndex: "order_status",
                width: '6%',
                align: "center",
                render: (record) => {
                    switch (record) {
                        case "un_pay":
                            return intl.get("home.table.useStatus.un_pay");
                            break;
                        case "wait_order_receiving":
                            return intl.get("home.table.useStatus.wait_order_receiving");
                            break;
                        case "un_order_receiving":
                            return intl.get("home.table.useStatus.un_order_receiving");
                            break;
                        case "for_consumption":
                            return intl.get("home.table.useStatus.for_consumption");
                            break;
                        case "finished":
                            return intl.get("home.table.useStatus.finished");
                            break;
                        case "canceled":
                            return intl.get("home.table.useStatus.canceled");
                            break;
                        case "rejected":
                            return intl.get("home.table.useStatus.rejected");
                            break;
                        case "refunded":
                            return intl.get("home.table.useStatus.refunded");
                            break;


                    }
                }
            },
            {
                title: intl.get('home.table.operation'),
                width: '10%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div>
                            <Button onClick={() => {
                                this.props.history.push("/OrderDetail/" + record.id)
                            }}>{intl.get("home.table.operation.btn.view")}</Button>
                        </div>
                    )
                }
            },

        ];
        let userAddressData = data.userAddress && data.userAddress.data
        return (
            <div>
                <Card>
                    <Descriptions title={intl.get("subscription.info.base")}>
                        <Descriptions.Item label={intl.get("user.table.header")}>{data?.head_portrait && <img style={{ width: "50px", height: "50px", objectFit: "cover" }} src={data?.head_portrait} alt="" />}</Descriptions.Item>
                        {/* <Descriptions.Item label="UUID">{data?.uuid}</Descriptions.Item> */}
                        {/* <Descriptions.Item label={intl.get("user.table.name")}>{data?.name}</Descriptions.Item> */}
                        <Descriptions.Item label={intl.get("user.table.nickname")}>{data?.name}</Descriptions.Item>
                        {
                            data?.email &&
                            <Descriptions.Item label={intl.get("setup.form.email")}>{data?.email}</Descriptions.Item>
                        }

                        {/* <Descriptions.Item label="area code">{data?.area}</Descriptions.Item> */}
                        <Descriptions.Item label={intl.get("user.table.phone")}>{data?.phone}</Descriptions.Item>
                        {
                            userAddressData && userAddressData.map((item, index) => {
                                return <Descriptions.Item
                                    label={
                                        <span>
                                            {intl.get("order.table.address")}
                                            {
                                                userAddressData.length > 1 ? index + 1 : ""
                                            }
                                        </span>
                                    }>{item.fill}</Descriptions.Item>
                            })
                        }

                    </Descriptions>
                    {
                        (is_coupon || is_authorization_code) &&
                        <div style={{ marginBottom: "20px" }}>
                            <Divider />
                            <div>
                                {
                                    is_authorization_code &&
                                    <Button
                                        style={{ marginRight: "5px", backgroundColor: tabType === "order" ? "#5867dd" : "", color: tabType === "order" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                        onClick={() => {

                                            this.setState({
                                                tabType: "order"
                                            }, () => {
                                                this.HandleGetOrderData(1)
                                            })
                                        }}>
                                        {intl.get("nav.orderManagement.list")}
                                    </Button>
                                }

                                {
                                    is_coupon &&
                                    <Button
                                        style={{ marginRight: "5px", backgroundColor: tabType === "coupon" ? "#5867dd" : "", color: tabType === "coupon" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                                        onClick={() => {
                                            this.setState({
                                                tabType: "coupon"
                                            }, () => {
                                                this.HandleGetCouponData(1)
                                            })
                                        }}>
                                        {intl.get("nav.goodsManagement.promotionalgoods.coupon")}
                                    </Button>
                                }

                            </div>
                        </div>
                    }

                    {
                        tabType === "coupon" && is_coupon &&
                        <Table
                            rowKey={record => record.id}
                            dataSource={couponData}
                            columns={couponColumns}
                            pagination={{
                                pageSize: couponMeta && couponMeta.per_page,
                                total: couponMeta && couponMeta.total
                            }}
                            onChange={(e) => {
                                this.HandleGetCouponData(e.current)
                            }}
                        />
                    }
                    {
                        tabType === "order" && is_authorization_code &&
                        <Table
                            rowKey={record => record.id}
                            dataSource={orderData}
                            columns={columns}
                            pagination={{
                                pageSize: orderMeta && orderMeta.per_page,
                                total: orderMeta && orderMeta.total
                            }}
                            onChange={(e) => {
                                this.HandleGetOrderData(e.current)
                            }}
                        />
                    }


                </Card>
                {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div />
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginTop: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.return')}
                        </Button>
                    </Popconfirm>
                </div> */}
                <Modal
                    title={intl.get("home.table.useStatus.orderReply")}
                    visible={this.state.visible}
                    onCancel={() => {
                        this.setState({
                            visible: false,
                            ItemId: "",
                            message: ""
                        })
                    }}
                    onOk={() => {
                        putOrder(ItemId, { "message": message }).then(res => {
                            this.setState({
                                visible: false,
                                ItemId: "",
                                message: ""
                            })
                        })
                    }}
                >
                    <TextArea
                        value={message}
                        onChange={(e) => {
                            this.setState({
                                message: e.target.value
                            })
                        }}
                    />
                </Modal>
            </div>


        );
    }
}

export default withRouter(PlugInList)

