import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, InputNumber,Popconfirm } from 'antd';
import { AddGoodsAnnexTypeItem } from "../../action/goodAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class NewGoodsAnnexTypeItem extends React.Component {
    handleSubmit = (e) => {

        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                AddGoodsAnnexTypeItem(values).then(res => {
                    this.props.history.push("/goodsAnnexTypeItemList")
                })
            }
        });
    };

    checkPsd(rule, value, callback) {
        let num = value && value.toString().split(".")[1] && value.toString().split(".")[1].length
        if (num && num > 2) {
            callback(new Error(intl.get("goods.list.goodsAnnexType.goodsAnnex_price.error")));
        } else {
            callback();
        }
    }


    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('goods.list.goodsAnnex.name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true, message: intl.get("goods.list.goodsAnnex.name_horder") }],
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('goods.list.goodsAnnex.price')}
                >
                    {getFieldDecorator('price', {
                        rules: [{ required: true, message: intl.get("goods.list.goodsAnnex.price_horder") }, { validator: (rule, value, callback) => { this.checkPsd(rule, value, callback) } }],
                    })(<div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "5px" }}>{currency_symbol}</span>
                        <InputNumber prefix={currency_symbol} min={0} style={{ width: "100%" }} />
                    </div>)}
                </Form.Item>

                <Form.Item
                    label={intl.get('pack.form.seq')}
                >
                    {getFieldDecorator('sort', {
                        rules: [{ required: false }],
                        initialValue: 0
                    })(<Input />)}
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewGoodsAnnexTypeItem))
