import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch } from 'antd';
import { getMenuList, deleteMenu, UpdateMenu } from "../../action/AdAction";
import intl from 'react-intl-universal';

class MenuList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: intl.get('menu.form.id'),
                dataIndex: 'spu_id',
                width: '15%',
                align: "center",
            },
            {
                title: intl.get('menu.form.img'),
                dataIndex: 'image',
                width: '30%',
                align: "center",
                render: (image) => {
                    return (<img src={image} style={{ width: "100px", height: "100px", objectFit: 'contain' }} alt="" />)
                }
            },
            {
                title: intl.get('category.list.isShow'),
                width: '10%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_show === 1} onChange={() => this.onChange(record)} />
                    )
                }
            },
            {
                title: intl.get('menu.form.seq'),
                dataIndex: 'sort',
                width: '15%',
                align: "center",
            },
            {
                title: intl.get('home.table.operation'),
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button onClick={() => {
                                this.props.history.push("/EditMenu/" + record.id)
                            }}>
                                {intl.get('seat.table.btn.edit')}
                            </Button>
                            <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    {intl.get('home.table.operation.btn.delete')}
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }

    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getMenuList()
        this.setState({ data: res.data.data })
    }



    async handleDelete(data) {
        await deleteMenu(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    onChange = (item) => {
        const { id, is_show } = item
        let data = { "is_show": is_show === 1 ? "0" : "1" }
        UpdateMenu(data, id).then(res => {
            this.HandleGetData()
        })

    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.props.history.push("/newMenu")}
                    >
                        {intl.get('nav.extension.dishRecommendation.add')}
                    </Button>
                </div>
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>


        );
    }
}

export default withRouter(Form.create()(MenuList))

