import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Switch } from 'antd';
// import intl from 'react-intl-universal';

class SysTemSet extends React.Component {

    state = {
        data: "",
    };

    async componentDidMount() {

        this.setState({})
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let data = []
                for (let key in values) {
                    data.push({
                        "key": key,
                        "value": values[key],
                        "name": key,
                        "desc": key
                    })
                }

            }
        });
    };


    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="waperBox FormView">
                <div style={{ height: 30 }}></div>

                <Form.Item label='客户打开商城时，是否弹出招牌'>
                    <Switch checked={this.state.IsShow} onChange={this.handleCheck} />
                </Form.Item>


            </Form>
        );
    }
}

export default withRouter(Form.create()(SysTemSet))
