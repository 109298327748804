import React, { Component } from 'react'
import { Icon } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import './index.less'
import "./app.less"
import { data } from "../../util/data";
import { connect } from 'react-redux'
import { SetMenuName } from "../../action/setMenuName";
import { getPartner,getConfigDocument } from "../../action/authAction";
import intl from 'react-intl-universal';
import DefaultLogo from "../../img/logo.png" 
//import { createFromIconfontCN } from '@ant-design/icons'; 
//import goodsIcon from "../../img/goods.png"

const goodsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg >
)



// const goodsIcon = createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js', // 在 iconfont.cn 上生成
// });



const plugIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 64 64" fill="#fff" stroke="currentColor" strokeWidth="2" strokeLinecap="round"><g id="Layer_37" data-name="Layer 37"><path d="M62,36a10,10,0,0,0-7.91-9.71V16.66a6.76,6.76,0,0,0-6.75-6.75H37.76C35.42-.61,20.64-.53,18.33,9.91H8.75A6.76,6.76,0,0,0,2,16.66V28a2,2,0,0,0,2,2c7.8.23,7.83,11.54,0,11.83a2,2,0,0,0-2,2V55.25A6.76,6.76,0,0,0,8.75,62H20.13a2,2,0,0,0,2-2c.29-7.81,11.59-7.76,11.83,0a2,2,0,0,0,2,2H47.34a6.76,6.76,0,0,0,6.75-6.75V45.67A10,10,0,0,0,62,36Zm-5.73,4.18a5.88,5.88,0,0,1-4.15,1.74,2,2,0,0,0-2,2V55.25A2.75,2.75,0,0,1,47.34,58H37.76c-2.35-10.55-17.15-10.44-19.43,0H8.75A2.75,2.75,0,0,1,6,55.25V45.67c10.55-2.35,10.44-17.15,0-19.43V16.66a2.75,2.75,0,0,1,2.75-2.75H20.13a2,2,0,0,0,2-2C22.07,6.74,28.66,4,32.22,7.73A5.84,5.84,0,0,1,34,11.88a2,2,0,0,0,2,2H47.34a2.75,2.75,0,0,1,2.75,2.75V28a2,2,0,0,0,2,2C57.26,30,60,36.57,56.27,40.13Z" /></g></svg>
)

const couponIcon = () => (
  // <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  //   <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6C0 4.89543 0.895431 4 2 4H22C23.1046 4 24 4.89543 24 6V8C24 9.15251 23.0762 9.85628 22.3453 10.1093C21.5609 10.3809 21 11.1262 21 12C21 12.8738 21.5609 13.6191 22.3453 13.8907C23.0762 14.1437 24 14.8475 24 16V18C24 19.1046 23.1046 20 22 20H2C0.895432 20 0 19.1046 0 18V16C0 14.8475 0.923793 14.1437 1.65473 13.8907C2.43909 13.6191 3 12.8738 3 12C3 11.1262 2.43909 10.3809 1.65473 10.1093C0.923795 9.85628 0 9.15251 0 8V6ZM14 6H2V7.99732C2.00263 8.00484 2.01143 8.02257 2.03721 8.05111C2.09031 8.10987 2.18601 8.1768 2.30906 8.2194C3.87398 8.76121 5 10.2478 5 12C5 13.7522 3.87397 15.2388 2.30906 15.7806C2.18601 15.8232 2.09031 15.8901 2.03721 15.9489C2.01143 15.9774 2.00263 15.9952 2 16.0027V18H14V17C14 16.4477 14.4477 16 15 16C15.5523 16 16 16.4477 16 17V18H22V16.0027C21.9974 15.9952 21.9886 15.9774 21.9628 15.9489C21.9097 15.8901 21.814 15.8232 21.6909 15.7806C20.126 15.2388 19 13.7522 19 12C19 10.2478 20.126 8.7612 21.6909 8.2194C21.814 8.1768 21.9097 8.10987 21.9628 8.05111C21.9886 8.02257 21.9974 8.00484 22 7.99732V6H16V7C16 7.55228 15.5523 8 15 8C14.4477 8 14 7.55228 14 7V6ZM15 10C15.5523 10 16 10.4477 16 11V13C16 13.5523 15.5523 14 15 14C14.4477 14 14 13.5523 14 13V11C14 10.4477 14.4477 10 15 10Z" fill="#fff" />
  // </svg>
  <svg height="28px" width="28px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff" stroke="#fff">
    <g id="SVGRepo_bgCarrier"></g>
    <g id="SVGRepo_tracerCarrier" stroke="#CCCCCC" >
      <path fill="#ea702b" d="M415.272,52.495H293.808c-14.079,0-27.582,5.593-37.538,15.549L13.479,310.837 c-6.911,6.91-6.911,18.115,0,25.025l162.667,162.667c6.911,6.911,18.115,6.911,25.025,0l242.791-242.792 c9.956-9.955,15.549-23.458,15.549-37.538V96.735C459.512,72.303,439.705,52.495,415.272,52.495z M407.497,142.049 c-10.366,10.366-27.173,10.366-37.538,0c-10.366-10.366-10.366-27.172,0-37.538s27.173-10.366,37.538,0 C417.863,114.877,417.864,131.682,407.497,142.049z"></path>
      <path fill="#FFC850" d="M40.036,337.394L282.827,94.602c9.956-9.955,23.459-15.549,37.538-15.549h121.463 c5.262,0,10.238,1.077,14.92,2.763c-6.142-17.054-22.306-29.32-41.477-29.32H293.808c-14.079,0-27.582,5.593-37.538,15.549 L13.479,310.837c-6.911,6.91-6.911,18.115,0,25.025l26.557,26.557C33.124,355.509,33.124,344.304,40.036,337.394z"></path>
      <path d="M512,34.88c0.039-9.349-3.569-18.126-10.159-24.716c-6.589-6.589-15.384-10.193-24.716-10.159 c-9.322,0.039-18.098,3.702-24.711,10.314c-2.8,2.8-7.893,8.848-10.679,12.205c-2.926,3.525-2.442,8.755,1.083,11.68 c3.524,2.926,8.754,2.442,11.68-1.083c4.364-5.256,7.971-9.395,9.646-11.071c3.498-3.497,8.132-5.435,13.05-5.456 c0.027,0,0.052,0,0.079,0c4.862,0,9.419,1.88,12.837,5.299c3.437,3.437,5.32,8.024,5.299,12.916 c-0.021,4.917-1.959,9.551-5.456,13.05c-3.131,3.131-13.893,11.668-29.312,22.439c-9.121-15.594-26.037-26.099-45.37-26.099H293.808 c-16.396,0-31.81,6.385-43.405,17.978L7.613,304.971C2.704,309.88,0,316.407,0,323.349c0,6.942,2.704,13.47,7.613,18.378 l162.667,162.667c5.068,5.067,11.722,7.6,18.378,7.6c6.656,0,13.312-2.534,18.378-7.6l242.792-242.791 c11.593-11.594,17.978-27.008,17.978-43.405V96.735c0-3.585-0.363-7.085-1.051-10.47c17.551-12.077,30.435-22.18,34.929-26.674 C508.298,52.979,511.961,44.203,512,34.88z M451.217,96.735v121.463c0,11.965-4.66,23.214-13.119,31.673L195.306,492.664 c-3.664,3.665-9.63,3.665-13.295,0L19.344,329.997c-1.775-1.775-2.754-4.136-2.754-6.648c0-2.512,0.978-4.872,2.754-6.647 L262.135,73.911c8.461-8.46,19.709-13.119,31.673-13.119h121.463c13.64,0,25.53,7.637,31.618,18.859 c-9.798,6.488-20.769,13.387-32.408,20.171c-0.363-0.398-0.734-0.792-1.119-1.177c-13.584-13.584-35.686-13.584-49.27,0 s-13.584,35.686,0,49.27c6.792,6.792,15.714,10.187,24.635,10.187s17.843-3.395,24.635-10.187 c9.067-9.067,12.072-21.926,9.036-33.517c10.123-5.893,19.844-11.916,28.815-17.743C451.215,96.682,451.217,96.709,451.217,96.735z M381.319,127.007c1.457,2.897,4.381,4.569,7.417,4.569c1.253,0,2.526-0.285,3.722-0.887c4.862-2.446,9.707-4.99,14.504-7.596 c0.048,4.735-1.722,9.485-5.328,13.09c-7.116,7.115-18.692,7.115-25.808,0c-7.115-7.116-7.115-18.692,0-25.808 c3.558-3.558,8.231-5.336,12.904-5.336c3.7,0,7.389,1.134,10.536,3.363c-4.695,2.552-9.46,5.051-14.263,7.468 C380.908,117.928,379.259,122.915,381.319,127.007z"></path>
      <path d="M247.162,168.962c-4.581,0-8.295,3.713-8.295,8.295v175.179c0,4.581,3.714,8.295,8.295,8.295 c4.581,0,8.295-3.714,8.295-8.295V177.257C255.457,172.674,251.743,168.962,247.162,168.962z"></path>
      <path d="M159.963,240.212c-13.584,13.584-13.584,35.686,0,49.27c6.793,6.793,15.713,10.188,24.635,10.187 c8.92,0,17.843-3.397,24.634-10.187c6.581-6.581,10.205-15.329,10.205-24.635c0-9.306-3.624-18.054-10.205-24.634 C195.648,226.627,173.545,226.628,159.963,240.212z M197.501,277.75c-7.116,7.116-18.692,7.115-25.807,0 c-7.115-7.116-7.115-18.692,0-25.808c3.558-3.558,8.231-5.336,12.904-5.336c4.673,0,9.346,1.778,12.904,5.336l0,0 c3.447,3.447,5.345,8.029,5.345,12.904C202.846,269.721,200.948,274.303,197.501,277.75z"></path>
      <path d="M285.092,240.212c-13.584,13.584-13.584,35.686,0,49.27c6.792,6.792,15.713,10.187,24.635,10.187 c8.921,0,17.843-3.395,24.634-10.187c13.584-13.584,13.584-35.686,0-49.27C320.777,226.627,298.673,226.628,285.092,240.212z M322.629,277.75c-7.116,7.116-18.692,7.115-25.807,0c-7.115-7.116-7.115-18.692,0-25.808c3.558-3.558,8.231-5.336,12.904-5.336 s9.346,1.778,12.904,5.336C329.745,259.058,329.745,270.634,322.629,277.75z"></path> </g>
    <g id="SVGRepo_iconCarrier">
      <path fill="#ea702b" d="M415.272,52.495H293.808c-14.079,0-27.582,5.593-37.538,15.549L13.479,310.837 c-6.911,6.91-6.911,18.115,0,25.025l162.667,162.667c6.911,6.911,18.115,6.911,25.025,0l242.791-242.792 c9.956-9.955,15.549-23.458,15.549-37.538V96.735C459.512,72.303,439.705,52.495,415.272,52.495z M407.497,142.049 c-10.366,10.366-27.173,10.366-37.538,0c-10.366-10.366-10.366-27.172,0-37.538s27.173-10.366,37.538,0 C417.863,114.877,417.864,131.682,407.497,142.049z"></path>
      <path fill="#FFC850" d="M40.036,337.394L282.827,94.602c9.956-9.955,23.459-15.549,37.538-15.549h121.463 c5.262,0,10.238,1.077,14.92,2.763c-6.142-17.054-22.306-29.32-41.477-29.32H293.808c-14.079,0-27.582,5.593-37.538,15.549 L13.479,310.837c-6.911,6.91-6.911,18.115,0,25.025l26.557,26.557C33.124,355.509,33.124,344.304,40.036,337.394z"></path>
      <path d="M512,34.88c0.039-9.349-3.569-18.126-10.159-24.716c-6.589-6.589-15.384-10.193-24.716-10.159 c-9.322,0.039-18.098,3.702-24.711,10.314c-2.8,2.8-7.893,8.848-10.679,12.205c-2.926,3.525-2.442,8.755,1.083,11.68 c3.524,2.926,8.754,2.442,11.68-1.083c4.364-5.256,7.971-9.395,9.646-11.071c3.498-3.497,8.132-5.435,13.05-5.456 c0.027,0,0.052,0,0.079,0c4.862,0,9.419,1.88,12.837,5.299c3.437,3.437,5.32,8.024,5.299,12.916 c-0.021,4.917-1.959,9.551-5.456,13.05c-3.131,3.131-13.893,11.668-29.312,22.439c-9.121-15.594-26.037-26.099-45.37-26.099H293.808 c-16.396,0-31.81,6.385-43.405,17.978L7.613,304.971C2.704,309.88,0,316.407,0,323.349c0,6.942,2.704,13.47,7.613,18.378 l162.667,162.667c5.068,5.067,11.722,7.6,18.378,7.6c6.656,0,13.312-2.534,18.378-7.6l242.792-242.791 c11.593-11.594,17.978-27.008,17.978-43.405V96.735c0-3.585-0.363-7.085-1.051-10.47c17.551-12.077,30.435-22.18,34.929-26.674 C508.298,52.979,511.961,44.203,512,34.88z M451.217,96.735v121.463c0,11.965-4.66,23.214-13.119,31.673L195.306,492.664 c-3.664,3.665-9.63,3.665-13.295,0L19.344,329.997c-1.775-1.775-2.754-4.136-2.754-6.648c0-2.512,0.978-4.872,2.754-6.647 L262.135,73.911c8.461-8.46,19.709-13.119,31.673-13.119h121.463c13.64,0,25.53,7.637,31.618,18.859 c-9.798,6.488-20.769,13.387-32.408,20.171c-0.363-0.398-0.734-0.792-1.119-1.177c-13.584-13.584-35.686-13.584-49.27,0 s-13.584,35.686,0,49.27c6.792,6.792,15.714,10.187,24.635,10.187s17.843-3.395,24.635-10.187 c9.067-9.067,12.072-21.926,9.036-33.517c10.123-5.893,19.844-11.916,28.815-17.743C451.215,96.682,451.217,96.709,451.217,96.735z M381.319,127.007c1.457,2.897,4.381,4.569,7.417,4.569c1.253,0,2.526-0.285,3.722-0.887c4.862-2.446,9.707-4.99,14.504-7.596 c0.048,4.735-1.722,9.485-5.328,13.09c-7.116,7.115-18.692,7.115-25.808,0c-7.115-7.116-7.115-18.692,0-25.808 c3.558-3.558,8.231-5.336,12.904-5.336c3.7,0,7.389,1.134,10.536,3.363c-4.695,2.552-9.46,5.051-14.263,7.468 C380.908,117.928,379.259,122.915,381.319,127.007z"></path>
      <path d="M247.162,168.962c-4.581,0-8.295,3.713-8.295,8.295v175.179c0,4.581,3.714,8.295,8.295,8.295 c4.581,0,8.295-3.714,8.295-8.295V177.257C255.457,172.674,251.743,168.962,247.162,168.962z"></path>
      <path d="M159.963,240.212c-13.584,13.584-13.584,35.686,0,49.27c6.793,6.793,15.713,10.188,24.635,10.187 c8.92,0,17.843-3.397,24.634-10.187c6.581-6.581,10.205-15.329,10.205-24.635c0-9.306-3.624-18.054-10.205-24.634 C195.648,226.627,173.545,226.628,159.963,240.212z M197.501,277.75c-7.116,7.116-18.692,7.115-25.807,0 c-7.115-7.116-7.115-18.692,0-25.808c3.558-3.558,8.231-5.336,12.904-5.336c4.673,0,9.346,1.778,12.904,5.336l0,0 c3.447,3.447,5.345,8.029,5.345,12.904C202.846,269.721,200.948,274.303,197.501,277.75z"></path>
      <path d="M285.092,240.212c-13.584,13.584-13.584,35.686,0,49.27c6.792,6.792,15.713,10.187,24.635,10.187 c8.921,0,17.843-3.395,24.634-10.187c13.584-13.584,13.584-35.686,0-49.27C320.777,226.627,298.673,226.628,285.092,240.212z M322.629,277.75c-7.116,7.116-18.692,7.115-25.807,0c-7.115-7.116-7.115-18.692,0-25.808c3.558-3.558,8.231-5.336,12.904-5.336 s9.346,1.778,12.904,5.336C329.745,259.058,329.745,270.634,322.629,277.75z"></path>
    </g>
  </svg>
)
const dealIcon = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" fill="#fff" stroke="currentColor" strokeWidth="20" strokeLinecap="round">
    <path d="M448.214,42.677c-.167-.008-.3-.01-.495-.01l-149.255.406a56.173,56.173,0,0,0-38.391,15.245L31.922,272.2a33.516,33.516,0,0,0-.771,48.146l160.5,160.5a33.233,33.233,0,0,0,23.682,9.818c.182,0,.365-.005.552-.005A33.251,33.251,0,0,0,239.8,480.078L453.682,251.927a56.174,56.174,0,0,0,15.245-38.4l.4-149.74A21.963,21.963,0,0,0,448.214,42.677Zm-.62,170.8a34.944,34.944,0,0,1-9.474,23.865L224.234,465.484a12.08,12.08,0,0,1-8.693,3.844,11.568,11.568,0,0,1-8.807-3.562l-160.5-160.5a12.191,12.191,0,0,1,.281-17.5L274.667,73.88a34.9,34.9,0,0,1,23.859-9.474L447.385,64c.359.065.615.292.615.221Z" /><path d="M296.12,215.883a10.667,10.667,0,0,0-15.083,0l-9.612,9.608a51.746,51.746,0,0,0-11.482-5.569c-14.583-4.781-29.266-2.148-38.3,6.891-17.323,17.318-7.12,40-1.641,52.19.8,1.792,1.536,3.411,2.089,4.828.755,1.948,1.714,4.109,2.75,6.422,6.339,14.182,8.333,21.958,2.5,27.792-3.214,3.214-9.865,3.9-16.568,1.7-12.776-4.193-21.937-16.734-24.5-33.547a10.669,10.669,0,0,0-21.094,3.219,70.093,70.093,0,0,0,11.992,30.33l-9.227,9.227a10.666,10.666,0,1,0,15.083,15.083l9.611-9.611a51.742,51.742,0,0,0,11.483,5.569,45.869,45.869,0,0,0,14.26,2.349c9.4,0,17.99-3.182,24.042-9.24,17.245-17.245,7.255-39.583,1.891-51.583-.875-1.958-1.7-3.781-2.333-5.427-.667-1.714-1.547-3.693-2.526-5.859-5.271-11.737-9.12-21.969-2.729-28.362,3.208-3.211,9.865-3.9,16.568-1.7,12.776,4.193,21.938,16.734,24.5,33.549a10.669,10.669,0,1,0,21.094-3.219,70.085,70.085,0,0,0-11.992-30.33l9.227-9.222A10.666,10.666,0,0,0,296.12,215.883Z" /><path d="M369.347,110.645a32,32,0,1,0,32,32A32,32,0,0,0,369.347,110.645Zm0,42.667a10.667,10.667,0,1,1,10.667-10.667A10.667,10.667,0,0,1,369.347,153.311Z" />
  </svg>
)
const packageIcon = () => (
  <svg t="1672917297894" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1209" width="22" height="22">
    <path d="M478.759385 0a159.586462 159.586462 0 0 1 159.586461 159.586462L638.293333 226.067692h226.093949A159.586462 159.586462 0 0 1 1024 385.654154v478.759384A159.586462 159.586462 0 0 1 864.413538 1024H385.654154a159.586462 159.586462 0 0 1-159.586462-159.586462V585.124103H159.586462A159.586462 159.586462 0 0 1 0 425.590154V159.586462A159.586462 159.586462 0 0 1 159.586462 0h319.172923z m385.654153 305.887179l-226.093948-0.026256v119.702974a159.586462 159.586462 0 0 1-159.560205 159.586462l-172.898462-0.026256v279.289435a79.819487 79.819487 0 0 0 75.093333 79.661949l4.726154 0.131282h478.733128a79.819487 79.819487 0 0 0 79.661949-75.093333l0.131282-4.699898V385.654154a79.819487 79.819487 0 0 0-75.093333-79.635692l-4.699898-0.157539z m-119.335384 359.056411a26.25641 26.25641 0 0 1 26.25641 26.25641l-0.026256 53.510564h53.563077a26.25641 26.25641 0 0 1 26.25641 26.25641v0.708923a26.25641 26.25641 0 0 1-26.25641 26.256411l-53.563077-0.026257v53.563077a26.25641 26.25641 0 0 1-26.256411 26.25641h-0.65641a26.25641 26.25641 0 0 1-26.25641-26.25641l-0.026256-53.563077h-53.510565a26.25641 26.25641 0 0 1-26.25641-26.25641v-0.65641a26.25641 26.25641 0 0 1 26.25641-26.25641l53.510565-0.026257v-53.510564a26.25641 26.25641 0 0 1 26.25641-26.25641h0.708923zM478.759385 79.793231H159.586462a79.819487 79.819487 0 0 0-79.661949 75.093333l-0.131282 4.699898v265.977435a79.819487 79.819487 0 0 0 75.093333 79.661949l4.699898 0.131282 66.48123-0.026256v-119.676718a159.586462 159.586462 0 0 1 159.586462-159.586462h172.872205V159.586462a79.819487 79.819487 0 0 0-75.093333-79.661949l-4.673641-0.131282z m79.766974 226.067692h-172.872205a79.819487 79.819487 0 0 0-79.635692 75.093333l-0.157539 4.726154v119.650462h172.898462a79.819487 79.819487 0 0 0 79.661948-75.093334l0.131282-4.673641-0.026256-119.702974z" fill="#fff" p-id="1210"></path>
  </svg>
)


class NavLeft extends Component {
  state = {
    logo: "",
    activeMenuIndex: 0,
    activeMenuItemIndex: this.props.location.pathname,
    childData: "",
    shopName: "",
    menuData: "",
    is_partner_seat: "",
    is_system_delivery_free: "",
    is_open_full_delivery_free: "",
    is_authorization_code: "",
    is_printer: "",
    is_open_printer: "",
    is_open_order_receiving:"", 
    is_document_enable:0,
  }

  async componentDidMount() {


    let resDocument = await getConfigDocument() 
      this.setState({
        is_document_enable: resDocument.data.value,
        
      })



    let menuData = data();
    getPartner().then(res => {
      const is_open_goods_annex = res.data.is_open_goods_annex
      const is_discount = res.data.is_discount
      const is_coupon = res.data.is_coupon
      const is_sku = res.data.is_sku
      const is_open_recommend = res.data.is_open_recommend
      const is_authorization_code = res.data.is_authorization_code
      const is_open_on_delivery = res.data.is_open_on_delivery
      const is_open_up_live = res.data.is_open_up_live
      const is_open_in_store = res.data.is_open_in_store //系统 到店功能开关
      const is_open_partner_seat = res.data.is_open_partner_seat //系统 桌号功能
      const isFormal = window.location.hostname.indexOf("www.auepartner.com") !== -1






      for (let i in menuData) {
        if (is_authorization_code === 0 && menuData[i].name === intl.get("nav.orderManagement.list")) {
          menuData.splice(i, 1)
        } else if ((is_authorization_code === 0 || is_discount === 0) && menuData[i].name === intl.get("nav.event")) {
          menuData.splice(i, 1)
        }
        else if (is_coupon === 0 && menuData[i].name === intl.get("nav.goodsManagement.promotionalgoods.coupon")) {
          menuData.splice(i, 1)
        }

        if (menuData[i].children) {
          let children = menuData[i].children
          if (is_open_goods_annex === 0) {
            children = children.filter(item => item.path !== "/goodsAnnexTypeList")
          }
          if (is_sku === 0) {
            children = children.filter(item => item.path !== "/goodsSpecificationList")
          }
          if (is_authorization_code === 0) {
            children = children.filter(item => item.path !== "/toStore")
            children = children.filter(item => item.name !== intl.get("nav.money"))
          }
          // if (isFormal) {
          //   children = children.filter(item => item.name !== intl.get("nav.money"))
          // }

          //系统配送功能
          if (is_open_on_delivery === 0) {
            children = children.filter(item => item.name !== intl.get("setup.form.delivery"))
          }


          //系统 到店功能 过滤掉到店一级菜单
          if (is_open_in_store === 0) {
            children = children.filter( item => item.name !== intl.get("home.table.way.name_d")  )

          }



          // if (is_discount === 0) {
          //   children = children.filter(item => item.name !== intl.get('nav.event'))
          // }
          if (is_open_up_live === 0) {
            children = children.filter(item => item.path !== "/upLiveToken")
          }
          // for (let j = children.length - 1; j >= 0; j--) {
          // if (is_open_goods_annex === 0 && children[j].path === "/goodsAnnexTypeList") {
          //   console.log(is_open_goods_annex, children[j], "212341235213452345")
          //   children.splice(j, 1)
          // }
          // else if (is_open_sku === 0 && children[j] && children[j].name === intl.get("nav.goodsManagement.specifications")) {
          //   children.splice(j, 1)
          // }
          // if (is_authorization_code === 0 && children[j] && children[j].name === intl.get("nav.package")) {
          //   children.splice(j, 1)
          // }
          // if (is_authorization_code === 0 && children[j] && children[j].name === intl.get("home.table.way.name_d")) {
          //   children.splice(j, 1)
          // }
          // if (isFormal && children[j] && children[j].name === intl.get("nav.money")) {
          //   children.splice(j, 1)
          // }


          // if (is_open_on_delivery === 0 && children[j] && children[j].name === intl.get("setup.form.delivery")) {
          //   children.splice(j, 1)
          // }
          // if (is_discount === 0 && children[j] && children[j].path === "/promotionalgoodsList") {
          //   children.splice(j, 1)
          // }
          // if (is_open_up_live === 0 && children[j] && children[j].path === "/upLiveToken") {
          //   children.splice(j, 1)
          // }

          // }
          menuData[i].children = children
        }
      }
      for (let i = 0; i < menuData.length; i++) {
        let child = menuData[i].children
        if (child.length > 0) {
          for (let j = 0; j < child.length; j++) {
            let childData = child[j].children
            if (childData) {
              for (let k = 0; k < childData.length; k++) {
                if (this.props.location.pathname === childData[k].path) {
                  this.setState({ childData: menuData[i], activeMenuIndex: i })
                  let menuStorage = { "childData": menuData[i], "activeMenuIndex": i }
                  sessionStorage.setItem("menuStorage", JSON.stringify(menuStorage))
                }
              }
            } else {
              if (this.props.location.pathname === child[j].path) {
                this.setState({ childData: menuData[i], activeMenuIndex: i })
                let menuStorage = { "childData": menuData[i], "activeMenuIndex": i }
                sessionStorage.setItem("menuStorage", JSON.stringify(menuStorage))
              }
            }
          }
        }
        // else {
        //   menuData.splice(i, 1)
        // }

      }
      let currency_symbol = res.data.region.currency_symbol
      sessionStorage.setItem('currency_symbol', currency_symbol)
      if (!this.state.childData) {
        // let menuStorage = JSON.parse(sessionStorage.getItem("menuStorage"))
        let menuStorage = sessionStorage.getItem("menuStorage")
        if (menuStorage) {
          this.setState({ childData: JSON.parse(menuStorage).childData, activeMenuIndex: menuStorage.activeMenuIndex })
        }

      }
      this.setState({
        logo: res.data.logo,
        shopName: res.data.title,
        menuData,
        is_partner_seat: res.data.is_partner_seat,
        is_open_partner_seat:res.data.is_open_partner_seat, //系统桌号
        is_open_in_store:res.data.is_open_in_store, //系统到店

        is_open_full_delivery_free: res.data.is_open_full_delivery_free,
        is_system_delivery_free: res.data.is_system_delivery_free,
        is_printer: res.data.is_printer,
        is_open_printer: res.data.is_open_printer,
        is_open_order_receiving: res.data.is_open_order_receiving, 
        is_open_member_level: res.data.is_open_member_level,  
        is_authorization_code
      })

      //console.log("is_open_printer===",this.state.is_open_printer);


    })



  }

  createMenu = (menuData) => {

    let menu = [];
    let adminid = sessionStorage.getItem("adminid")
    for (let i = 0; i < menuData.length; i++) {
      if (adminid) {
        if (menuData[i].noShow) {
          menuData.splice(i, 1)
        } else {
          menu.push(
            <li
              key={menuData[i].name}
              className={this.state.activeMenuIndex === i ? "active" : ""}
              onClick={() => {
                this.setState({
                  activeMenuIndex: i,
                  childData: menuData[i]
                })
              }}>
              <a style={{ position: "relative" }} href="javascript:;">
                {
                  menuData[i].icon === "trophy" ?
                    <Icon component={goodsIcon} style={{ fontSize: "20px", color: "#fff" }} /> :
                    menuData[i].icon === "interaction" ?
                      <Icon component={plugIcon} style={{ fontSize: "20px", color: "#fff" }} /> :
                      menuData[i].icon === "coupon" ?
                        <Icon component={couponIcon} style={{ fontSize: "20px", color: "#fff" }} /> :
                        menuData[i].icon === "deal" ?
                          <Icon component={dealIcon} style={{ fontSize: "20px", color: "#fff" }} /> :
                          menuData[i].icon === "package" ?
                            <Icon component={packageIcon} style={{ fontSize: "20px", color: "#fff" }} /> :
                            <Icon type={menuData[i].icon} style={{ fontSize: "20px", color: "#fff" }} />
                }
                <div className="dialog">
                  <div style={{ width: "100%", height: "12px", display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "0", height: "0", border: "6px solid", borderColor: "transparent transparent  #000 transparent" }} />
                  </div>
                  <div style={{ backgroundColor: "#000", textAlign: "center", borderRadius: "5px", padding: "5px 10px", marginLeft: "20px" }}>
                    <span style={{ fontSize: "12px", color: "#fff" }}>{menuData[i].name}</span>
                  </div>
                </div>
              </a>
            </li>
          )
        }
      } else {
        menu.push(
          <li
            key={menuData[i].name}
            className={this.state.activeMenuIndex === i ? "active" : ""}
            onClick={() => {
              this.setState({
                activeMenuIndex: i,
                childData: menuData[i]
              })
            }}>
            <a style={{ position: "relative" }}>
              {
                menuData[i].icon === "trophy" ?
                  <Icon component={goodsIcon} style={{ fontSize: "20px", color: "#fff" }} /> :
                  menuData[i].icon === "interaction" ?
                    <Icon component={plugIcon} style={{ fontSize: "24px", color: "#fff" }} /> :
                    menuData[i].icon === "coupon" ?
                      <Icon component={couponIcon} style={{ fontSize: "20px", color: "#fff" }} /> :
                      menuData[i].icon === "deal" ?
                        <Icon component={dealIcon} style={{ fontSize: "20px", color: "#fff" }} /> :
                        menuData[i].icon === "package" ?
                          <Icon component={packageIcon} style={{ fontSize: "20px", color: "#fff" }} /> :
                          <Icon type={menuData[i].icon} style={{ fontSize: "20px", color: "#fff" }} />
              }
              <div className="dialog">
                <div style={{ width: "100%", height: "12px", display: "flex", justifyContent: "center" }}>
                  <div style={{ width: "0", height: "0", border: "6px solid", borderColor: "transparent transparent  #000 transparent" }} />
                </div>
                <div style={{ backgroundColor: "#000", textAlign: "center", borderRadius: "5px", padding: "5px 10px", marginLeft: "20px" }}>
                  <span style={{ fontSize: "12px", color: "#fff" }}>{menuData[i].name}</span>
                </div>
              </div>
            </a>
          </li>
        )
      }
    }
    return menu
  }

  createMenuItem = (data) => {

    

    let menu = [];
    let menuData = data.children


   
    const isFormal = window.location.hostname.indexOf("www.auepartner.com") !== -1
    let { is_open_in_store, is_open_partner_seat,is_partner_seat, is_printer,  is_open_printer, is_open_order_receiving, is_open_member_level, is_system_delivery_free, is_open_full_delivery_free, is_authorization_code ,is_document_enable} = this.state
    if (is_partner_seat !== "") {
      for (let i = 0; i < menuData.length; i++) {
        let childrenData = menuData[i].children
        let list = []
        if (childrenData) {
          for (let k = 0; k < childrenData.length; k++) {
            if (is_open_partner_seat === 0 && childrenData[k] && childrenData[k].name === intl.get("nav.setting.seat")) {
                childrenData.splice(k, 1)
            }
            else if (is_open_printer === 0 && childrenData[k] && childrenData[k].name === intl.get("nav.setting.printer")) {
              childrenData.splice(k, 1)
            }else if (is_open_order_receiving === 0 && childrenData[k] && childrenData[k].name === "接单" ) {
              childrenData.splice(k, 1)
            }else if (is_open_member_level === 0 && childrenData[k] && childrenData[k].name === "会员" ) {
              childrenData.splice(k, 1)
            }else if (is_open_full_delivery_free === 0 && childrenData[k] && childrenData[k].name === "满包邮" ) {
              childrenData.splice(k, 1)
            }
            else if (is_system_delivery_free === 0 && childrenData[k] && childrenData[k].name === intl.get("nav.setting.freight")) {
              childrenData.splice(k, 1)
            }
            else if (is_open_full_delivery_free === 0 && childrenData[k] && childrenData[k].name === intl.get("nav.plugin.deliveryPlug")) {
              childrenData.splice(k, 1)
            }
            else if (isFormal && childrenData[k] && childrenData[k].name === intl.get("nav.goodsManagement.promotionalgoods.coupon")) {
              childrenData.splice(k, 1)
            }
            else if (is_authorization_code === 0 && childrenData[k] && childrenData[k].path === "/toStorePlug") {
              childrenData.splice(k, 1)
            } else if (is_open_in_store === 0 && childrenData[k] && childrenData[k].path === "/seatList") { 
              
              childrenData.splice(k, 1)
            } 

            else {
              list.push(
                <li
                  key={childrenData[k].path}
                  onClick={() => {
                    this.setState({
                      activeMenuItemIndex: childrenData[k].path,
                    })
                  }}
                >
                  <a className={this.state.activeMenuItemIndex === childrenData[k].path ? "active" : ""}>
                    <Link to={childrenData[k].path}>
                      {childrenData[k].name}
                    </Link>
                  </a>
                </li>
              )
            }
          }
        }
        if (menuData[i].children) {
          menu.push(
            <ul className="navigation-active" key={menuData[i].name}>
              <li className="navigation-divider">{menuData[i].name}</li>
              {list}
            </ul>
          )
        } else {




          if (is_document_enable ==   0  && menuData[i].path == "/documentList" ) { 
              menuData.splice(i, 1) 
          }else{

              menu.push(
              <ul className="navigation-active" key={menuData[i].name} style={{ margin: "0px", padding: "0px" }}>
                <li
                  className="navigation-divider"
                  key={menuData[i].path}
                  style={{ margin: "0px" }}
                  onClick={() => {
                    this.setState({
                      activeMenuItemIndex: menuData[i].path,
                    })
                  }}
                >
                  <a className={this.state.activeMenuItemIndex === menuData[i].path ? "active" : ""}>
                    <Link to={menuData[i].path}>
                      {menuData[i].name}
                    </Link>
                  </a>
                </li>
              </ul>

            )
            
          }
 
          
        }

      }
      return menu
    }
  }


  render() {
    let { logo, childData, shopName, menuData } = this.state
    if (menuData) {
      return (
        <div>
          <div className='logo'>
            <div style={{ display: "flex", alignItems: "center" }}>
              {
                // logo ? <img src={logo} style={{ width: "60px", height: "60px", borderRadius: "30px" }} /> :
                logo ? <div style={{marginLeft:'10px', width: "50px", height: "50px", borderRadius: "30px", backgroundImage: `url(${logo})`, backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }} /> :
                  <div style={{marginLeft:'10px', width: "50px", height: "50px", borderRadius: "30px", backgroundImage: `url(${DefaultLogo})`, backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }} />
              }
              {/* <Icon type="slack" className="lg-icon" /> */}
              <span style={{ marginLeft: "10px", fontSize: "24px", fontFamily: "Microsoft YaHei" }}>{shopName}</span>
            </div>
          </div>
          <div className="navigation">
            <div className="navigation-icon-menu">
              <ul>
                {this.createMenu(menuData)}
              </ul>
            </div>
            <div className="navigation-menu-body">
              {childData && this.createMenuItem(childData)}
            </div>
          </div>

        </div>
      )
    } else {
      return <div />
    }

  }
}


const mapDispatchToProps = dispatch => {
  return {
    dispatchMenu(title) {
      dispatch(SetMenuName(title))
    }
  }
}

export default connect(null, mapDispatchToProps)(withRouter(NavLeft))
