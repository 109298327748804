import React from "react";
import { withRouter } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { Table, Input, InputNumber, Popconfirm, Form, Button, Modal, Switch } from 'antd';
import { getHolidayList, deleteHoliday, putHoliday } from "../../../action/authAction";
import intl from 'react-intl-universal';

class HolidayList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('nav.CostFlow.name'),
                dataIndex: 'name',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('nav.setting.public_holidays_detail'),
                dataIndex: 'detail',
                width: '30%',
                align: "center",
            },
            {
                title: intl.get('setup.form.state'),
                dataIndex: 'state',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('nav.setting.is_rest'),
                width: '10%',
                align: "center",
                render: (record) => {
                    // if (record.holidayBusiness) {
                    return (
                        <Switch checked={record.is_rest === 1} onChange={() => { this.HandleChange(record) }} />
                    )
                    // } else {
                    //     return (
                    //         <Switch checked={false} disabled />
                    //     )
                    // }

                }
            },
            {
                title: intl.get("nav.setting.public_holidays_date"),
                width: '20%',
                align: "center",
                render: (record) => {
                    return record.start_at && record.start_at.date.substring(0, 10)
                }
            },
            // {
            //     title: intl.get('seat.table.operation'),
            //     dataIndex: 'operation',
            //     width: '20%',
            //     align: "center",
            //     render: (text, record) => {
            //         if (record.partner_id !== 0) {
            //             return <div className='deleteView'>
            //                 <Button style={{ marginRight: "5px" }} onClick={() => {
            //                     this.props.history.push("/EditHoliday/" + record.id)
            //                 }}>
            //                     {intl.get('seat.table.btn.edit')}
            //                 </Button>
            //                 <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
            //                     <Button>
            //                         {intl.get('home.table.operation.btn.delete')}
            //                     </Button>
            //                 </Popconfirm>
            //             </div >
            //         }

            //     },
            // },

        ];
    }

    async componentDidMount() {
        this.getData(1)
    }

    getData = (page) => {
        getHolidayList("?partner_id=0&page=" + page).then(res => {
            this.setState({ data: res.data.data, meta: res.data.meta.pagination })
        })

    }

    HandleChange = (item) => {
        let is_rest = item.holidayBusiness.is_rest
        let holidayBusiness = {}
        holidayBusiness.is_rest = is_rest === 1 ? "0" : "1"
        putHoliday({ "holidayBusiness": holidayBusiness }, item.id).then(res => {
            this.HandleGetData()
        })
    }

    async handleDelete(data) {
        await deleteHoliday(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        let { meta } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div>
                        <Button
                            style={{ marginRight: "10px" }}
                            type="primary"
                            onClick={() => {
                                this.props.history.push("/selfBusinessHoliday")
                            }}>
                            {intl.get('nav.setting.self_holidays')}
                        </Button>
                    </div>
                    <div>

                        {/* <Button type="primary" onClick={() => {
                            this.props.history.push("/NewHolidayBusiness")
                        }}>
                            <PlusOutlined /> {intl.get('admin.table.new')}
                        </Button> */}
                    </div>
                </div>
                <Table
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    columns={this.columns}
                    rowClassName="editable-row"
                    pagination={{
                        pageSize: meta && meta.per_page,
                        total: meta && meta.total
                    }}
                    onChange={(e) => {
                        this.getData(e.current)
                    }}
                    className="waperBox"
                />
            </div>

        );
    }
}

export default withRouter(Form.create()(HolidayList))

