import React, { Component, Fragment } from 'react'
import { Button, Form, Input, Upload, message, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import { register } from "../../action/authAction"
import './index.less'
import { setTokenStorage } from "../../util/function";

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.error('Image must smaller than 1MB!');
    }
    return isJpgOrPng && isLt1M;
}
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}


class RegisterForm extends Component {
    state = {
        imageUrl: "",
        imageFoodUrl: "",
        business_license_file: "",
        food_license_file: ""
    }


    componentDidMount() {
        document.title = "Merchant registration page"
    }


    handleOk = () => {
        const { form } = this.props
        const { validateFieldsAndScroll } = form
        validateFieldsAndScroll(async (errors, values) => {
            if (errors) {
                return
            }

            if (values.password !== values.repassword) {
                message.error("Two different passwords!")
            } else {
                delete values.repassword
                this.SubmitData(values)
            }


        })
    }


    async SubmitData(data) {
        const { business_license_file, food_license_file } = this.state
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }
        }
        if (business_license_file) {
            formData.append("business_license_file", business_license_file)
        }
        if (food_license_file) {
            formData.append("food_license_file", food_license_file)
        }

        register(formData).then(res => {
        }).catch(error => {
        })


        let res = await register(formData)
        if (Number(res.status) === 200) {
            message.success('login was successful')
            // 成功跳转给出提示

            setTokenStorage(res.data.access_token)
            setTimeout(() => {
                this.props.history.push('/')
            }, 1000)
        } else {
            message.error("error")
        }

    }


    handleUpload = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl, img) =>
                this.setState({
                    imageUrl,
                    loading: false,
                    business_license_file: img
                }),
            );
        }
    };



    handleUploadFood = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file) {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageFoodUrl, img) =>
                this.setState({
                    imageFoodUrl,
                    loading: false,
                    food_license_file: img
                }),
            );
        }
    };




    render() {
        const FormItem = Form.Item
        const { getFieldDecorator } = this.props.form
        const { imageUrl, loading, imageFoodUrl } = this.state

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 11 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 13 },
            },
        };
        return (
            <Fragment>
                <div className='registerForm'>
                    <div className='Loginlogo'>
                        {/*<img alt='logo' src='../../logo.svg' />*/}
                        {/* <span>澳洲</span> */}
                    </div>
                    <Form {...formItemLayout} style={{ width: "310px" }}>
                        <FormItem label="Shop name">
                            {getFieldDecorator('title', {
                                rules: [{ required: true }]
                            })(<Input />)}
                        </FormItem>
                        <FormItem label="user name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true }]
                            })(<Input />)}
                        </FormItem>
                        <FormItem label="cell-phone">
                            {getFieldDecorator('phone', {
                                rules: [{ required: true }]
                            })(<Input />)}
                        </FormItem>
                        <FormItem label="mailbox">
                            {getFieldDecorator('email', {

                            })(<Input />)}
                        </FormItem>
                        <FormItem label="password">
                            {getFieldDecorator('password', {
                                rules: [{ required: true }]
                            })(<Input type='password' />)}
                        </FormItem>
                        <FormItem label="Confirm password">
                            {getFieldDecorator('repassword', {
                                rules: [{ required: true }]
                            })(<Input type='password' />)}
                        </FormItem>
                        <FormItem label="ABN">
                            {getFieldDecorator('abn', {
                            })(<Input />)}
                        </FormItem>

                        <Form.Item
                            label={<span>Business license</span>}
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={beforeUpload}
                                onChange={this.handleUpload}
                            >
                                {imageUrl && !loading ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            label={<span>Food license</span>}
                        >
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={beforeUpload}
                                onChange={this.handleUploadFood}
                            >
                                {imageFoodUrl && !loading ? <img src={imageFoodUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>

                    </Form>

                    <Button type='primary' onClick={this.handleOk} style={{ width: "100px" }}>
                        register
                    </Button>

                </div>
            </Fragment>
        )
    }
}

export default withRouter(Form.create()(RegisterForm))
