import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Input, InputNumber, Popconfirm, Form, Button, Switch, Popover, Tooltip, Icon } from 'antd';
import { getGoodsTag, putGoodsTag, deleteGoodsTag } from "../../action/goodAction";
import "./category.less"
import intl from 'react-intl-universal';

const EditableContext = React.createContext();

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    renderCell = ({ getFieldDecorator }) => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps} >
                {editing ? (
                    <Form.Item style={{ margin: 0 }}>
                        {getFieldDecorator(dataIndex, {
                            rules: [
                                {
                                    required: true,
                                    message: `${intl.get('seat.table.please')}${title}!`,
                                },
                            ],
                            initialValue: record[dataIndex],
                        })(this.getInput())}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    render() {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class GoodsTagList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], editingKey: '' };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '6%',
                editable: false,
                align: "center",
            },
            {
                title: 'Tag名称',
                dataIndex: 'name',
                width: '15%',
                editable: true,
                align: "center",
            },
            {
                title: intl.get('category.list.isShow'),
                width: '15%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_show === 1} onChange={() => this.onChange(record)} />
                    )
                }
            },
            // {
            //     title: intl.get('lang.list.isDefault'),
            //     width: '15%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <span style={{ color: "green" }}>{record.is_default === 1 && "yes"}</span>
            //         )
            //     }
            // },
            // {
            //     title: intl.get('category.list.Icon'),
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <img src={record.image} style={{ height: "60px" }} alt={intl.get('category.list.Icon')} />
            //         )
            //     }
            // },
            {
                title: intl.get('category.list.sort'),
                dataIndex: 'sort',
                width: '8%',
                editable: true,
                align: "center",
            },
            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    if (record.is_default !== 1) {
                        return <Popover
                            style={{ width: 500 }}
                            content={
                                <div style={{ display: "flex" }}>
                                    <Tooltip title={intl.get('seat.table.btn.edit')}>
                                        <Button
                                            style={{ marginRight: "5px" }}
                                            shape="circle"
                                            icon="edit"
                                            onClick={() => {
                                                this.props.history.push("/EditGoodsTag/" + record.id)
                                            }} />
                                    </Tooltip>

                                    <Popconfirm title={intl.get('category.list.del.horder')} onConfirm={() => this.handleDelete(record)}>
                                        <Tooltip title={intl.get('category.list.del')}>
                                            <Button
                                                style={{ margin: "0px 5px" }}
                                                shape="circle"
                                                icon="delete"
                                            />
                                        </Tooltip>
                                    </Popconfirm>

                                </div>
                            }
                            trigger="hover"
                        >
                            <Button
                                style={{ marginRight: "5px" }}
                                shape="circle"
                                icon="more"
                            />
                        </Popover>

                    }

                },
            },

        ];
    }

    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getGoodsTag();
        this.setState({ data: res.data.data })
    }


    isEditing = record => record.id === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
    };

    save(form, key) {
        form.validateFields((error, row) => {
            if (error) {
                return;
            }
            const newData = [...this.state.data];
            const index = newData.findIndex(item => key.id === item.id);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });

                this.SubmitData(newData, newData[index])


            } else {
                newData.push(row);
                this.setState({ data: newData, editingKey: '' });
            }
        });
    }

    async SubmitData(newData, item) {
        const { id, name, sort } = item
        let data = { "name": name, "sort": sort }
        await putGoodsTag(data, id)
        this.setState({ data: newData, editingKey: '' });
    }


    edit(key) {
        this.setState({ editingKey: key.id });
    }

    async handleDelete(data) {
        await deleteGoodsTag(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    onChange = (item) => {
        const { id, is_show } = item
        let data = { "is_show": is_show === 1 ? "0" : "1" }
        putGoodsTag(data, id).then(res => {
            this.HandleGetData()
        })

    }

    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'sort' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        return (
            <EditableContext.Provider value={this.props.form}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.props.history.push("/NewGoodsTag")}
                    >
                        添加Tag
                    </Button>
                </div>
                <Table
                    rowKey={record => record.id}
                    components={components}
                    // bordered
                    dataSource={this.state.data}
                    columns={columns}
                    rowClassName="editable-row"
                    pagination={{
                        // onChange: this.cancel,
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </EditableContext.Provider>
        );
    }
}

export default withRouter(Form.create()(GoodsTagList))

