import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Radio, Switch } from 'antd';
import { GetConfigSet, UpdateConfigSet,putPartnerMemberLevelSet,getPartnerMemberLevel } from "../../action/authAction";

class Behavior extends React.Component {

    state = {
        data: "", 
        silver:"",
        gold:"",
        diamond:"",
        silver_is_use:0,
        gold_is_use:0,
        diamond_is_use:0,

        silver_value:0,
        gold_value:0,
        diamond_value:0,



    };

    async componentDidMount() {



        let res = await getPartnerMemberLevel("?all_member_level=1")
        let allData = res.data.data
        let data = [] 
        const silver = allData.find(el => el.name == "silver") 
        const gold = allData.find(el => el.name == "gold") 
        const diamond = allData.find(el => el.name == "diamond") 
 
        this.setState({
            data: data, 
            silver:silver,
            gold:gold,
            diamond:diamond,


            silver_is_use:silver?.is_use,
            gold_is_use:gold?.is_use,
            diamond_is_use:diamond?.is_use,


            silver_sort: 1,
            gold_sort: 2,
            diamond_sort: 3,




            silver_value:silver?.value,
            gold_value:gold?.value,
            diamond_value:diamond?.value,





        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {


             if (!err) {
                
                 let {gold,silver,diamond,gold_is_use,silver_is_use,diamond_is_use ,silver_sort,gold_sort,diamond_sort } = this.state


                let dataForm = []
                for (let key in values) {
                    dataForm.push({
                        "key": key,
                        "value": values[key],
                    })
                }

                const silver_value = dataForm.find(el => el.key == "silver_value").value; 
                const gold_value = dataForm.find(el => el.key == "gold_value").value; 
                const diamond_value = dataForm.find(el => el.key == "diamond_value").value; 

 
                let data = [];
                data.push({ "name": "silver", "value": silver_value,"is_use":silver_is_use?"1":"0",sort:silver_sort })
                data.push({ "name": "gold", "value": gold_value,"is_use":gold_is_use?"1":"0" ,sort:gold_sort})
                data.push({ "name": "diamond", "value": diamond_value,"is_use":diamond_is_use?"1":"0",sort:diamond_sort })


                console.log("data",data);

                let param = {'level': data};
                putPartnerMemberLevelSet(param)

            }




            // if (!err) {


                 
            //     let { gold,silver,diamond,gold_is_use,silver_is_use,diamond_is_use  } = this.state
            //     let data = [] 
            //     data.push({ "name": "gold", "value": gold.value,"is_use":gold_is_use?"1":"0" })
            //     data.push({ "name": "silver", "value": silver.value,"is_use":silver_is_use?"1":"0" })
            //     data.push({ "name": "diamond", "value": diamond.value,"is_use":diamond_is_use?"1":"0" })
                
            //     console.log("data===",data);
                 
            //     putPartnerMemberLevelSet(data)
            // }
        });
    };


    render() {
        const { data, silver, gold ,diamond ,silver_is_use,gold_is_use, diamond_is_use,silver_value, gold_value, diamond_value   } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };


        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ maxWidth: "1000px", marginLeft: "100px" }}>


                <Card style={{ marginBottom: "10px" }}>

                    <div style={{  borderBottom:" 1px solid #dedede", padding: "10px 0", display: "flex" , alignItems: "center", justifyContent: "space-between" ,margin: "0 0 20px 0" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>会员成长值设置</div>
                        <div>
                         <Button type="primary" htmlType="submit"

                         onClick={() => {
                                this.props.history.push("/memberLevelSetList/")
                             }} 
                             
                         >
                            成长值变更记录
                        </Button>
                        </div>
                    </div>



 
                    <div>
                    <Form.Item
                        label="白银" style={{ marginBottom:0}}
                    >
                        {getFieldDecorator('silver_value', {
                            rules: [{ required: false }],
                            initialValue: silver_value
                        })(<Input   />)}
                    </Form.Item>


                    <Form.Item label={<span>启用</span>} >
                        <Switch checked={silver_is_use}
                            onChange={() => {
                                this.setState({
                                    silver_is_use   : !silver_is_use
                                })
                            }} />
                     </Form.Item>  
                     </div>


                      <div>
                    <Form.Item
                        label="黄金" style={{ marginBottom:0}}
                    >
                        {getFieldDecorator('gold_value', {
                            rules: [{ required: false }],
                            initialValue: gold_value
                        })(<Input    />)}
                    </Form.Item>

                    <Form.Item label={<span>启用</span>}>
                        <Switch checked={gold_is_use}
                            onChange={() => {
                                this.setState({
                                    gold_is_use : !gold_is_use
                                })
                            }} />
                     </Form.Item>  



                     </div>


                     <div>
                    <Form.Item
                        label="钻石" style={{ marginBottom:0}}
                    >
                        {getFieldDecorator('diamond_value', {
                            rules: [{ required: false }],
                            initialValue: diamond_value
                        })(<Input    />)}
                    </Form.Item>

                    <Form.Item label={<span>启用</span>}>
                        <Switch checked={diamond_is_use}
                            onChange={() => {
                                this.setState({
                                    diamond_is_use: !diamond_is_use
                                })
                            }} />
                     </Form.Item>  


                     </div>


                    



                     
                </Card>


                
                
  

                 
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
