import React from 'react'
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { getBindInfo, getStripeKey, unBindPay, bindToHandle } from "../../action/goodAction";
// import CheckoutForm from './components/CheckoutForm';
// import { Button, Row, Popconfirm, Tag } from 'antd';
// import intl from 'react-intl-universal';
import paypalImg from "../../img/paypal.png"
import stripeImg from "../../img/stripe.png"

class BindPay extends React.Component {
    state = {
        data: '',
        key: ""
    }

    findList = async () => {
        //查看绑定支付信息
        let res = await getBindInfo()
        if (res && res.data && !res.data.card_brand) {
            //获取Stripekey
            let keys = await getStripeKey();
            this.setState({ key: keys.data.key })
        } else {
            this.setState({
                data: res.data
            })
        }
    }

    componentDidMount() {
        this.findList();
    }

    async handleUnbind() {
        await unBindPay();
        //查看绑定支付信息
        this.findList();
    }

    submitHandle = async (data) => {
        await bindToHandle(data);
        //查看绑定支付信息
        let res = await getBindInfo()
        if (res && res.data && !res.data.card_brand) {
            //获取Stripekey
            let keys = await getStripeKey();
            this.setState({ key: keys.data.key, data: '' })
        } else {
            this.setState({
                data: res.data,
                key: ''
            })
        }
    }

    render() {
        return (
            // <div className="waperBox">
            //     {key ? 
            //     <Elements stripe={loadStripe(key)}>
            //         <CheckoutForm bindHandle={this.submitHandle}/>
            //     </Elements>:
            //     <div>
            //         <Row>
            //             <Tag>{data.card_brand}</Tag>
            //             <span>**** **** **** {data.card_last_four}</span>
            //         </Row>
            //         <br/>
            //         <Popconfirm
            //             title={intl.get('home.table.operation.btn.horder')}
            //             onConfirm={this.handleUnbind.bind(this)}
            //         >
            //             <Button type="primary">{intl.get('bindpay.removeBtn')}</Button>
            //         </Popconfirm>
            //     </div>
            // }
            // </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <div className="waperBox" style={{ width: "49%" }}>
                    <a href="https://www.paypal.com/au/webapps/mpp/merchant-fees">Paypal手续费</a>
                    <img src={paypalImg} style={{ width: "100%",marginTop:"15px" }} alt=''/>
                </div>
                <div className="waperBox" style={{ width: "49%" }}>
                    <a href="https://stripe.com/zh-cn-au/pricing">Stripe手续费</a>
                    <img src={stripeImg} style={{ width: "100%" }} alt=''/>
                </div>
            </div>

        )
    }
}


export default BindPay;