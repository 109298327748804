import React from 'react'
import 'braft-editor/dist/index.css'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Upload, message, Icon, Popconfirm, DatePicker } from 'antd';
import intl from 'react-intl-universal';
import { AddHoliday } from "../../../action/authAction";
import moment from 'moment';
class NewHoliday extends React.Component {

    state = {
        date: ""
    };
    SubmitData(data) {
        let { date } = this.state
        data.start_at = date
        AddHoliday(data).then(res => {
            this.props.history.goBack()
            // this.props.history.push("/selfBusinessHoliday")
        })
    };
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.SubmitData(values)
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label={intl.get('nav.CostFlow.name')}
                >
                    {getFieldDecorator('name', {
                        rules: [{ required: true }],
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<span>{intl.get('nav.setting.public_holidays_detail')}</span>}
                >
                    {getFieldDecorator('detail', {
                        rules: [{ required: true }]
                    })(<Input />)}
                </Form.Item>
                {/* <Form.Item
                    label={<span>{intl.get('setup.form.state')}</span>}
                >
                    {getFieldDecorator('state', {
                        rules: [{ required: true }]
                    })(<Input />)}
                </Form.Item> */}
                <Form.Item
                    label={<span>{intl.get('nav.setting.public_holidays_date')}</span>}
                >
                    <DatePicker
                        format='DD-MM-YYYY'
                        onChange={(value) => {
                            this.setState({
                                date: moment(value).format("YYYY-MM-DD")
                            })
                        }}
                    />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>

        );
    }
}

export default withRouter(Form.create()(NewHoliday))
