import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Switch } from 'antd';
//import { getLangList, editLang, editUse } from "../../action/authAction";
import { getOperationLogList } from "../../action/authAction";

import { setLangType } from "../../util/function";
import intl from 'react-intl-universal';

class LangList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] ,page:1,  meta: "",};
        this.columns = [
            {
                title: intl.get("common.table.id"),
                dataIndex: 'id',
                align: "center",
            },
            {
                title: "用户名",
                dataIndex: 'user_name',
                align: "center",
                render: (text, record) => {
                    return record.user_name
                }
            }, {
                title: "来源",
                align: "user_source",
                render: (record) => {
                    return record.user_source
                }
            }, {
                title: "内容",
                align: "content",
                render: (record) => {
                    return record.content
                }
            }, {
                title: "操作时间",
                align: "created_at",
                render: (record) => {
                    return record.created_at.date.substring(0, 19)
                }
            },  
            
             

        ];
    }

    async componentDidMount(page) {
        this.GetUserData(1)
    }

    async GetUserData(page) {

          
        let res = await getOperationLogList(page) 
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

 

    render() {
        const { data, meta,   } = this.state
        return (
            <Table
                rowKey={record => record.id}
                dataSource={this.state.data}
                columns={this.columns}
                pagination={{
                    pageSize: meta.per_page,
                    total: meta.total
                }}

                onChange={(e, a, b) => {
                        this.setState({
                            page: e.current
                        }, () => this.GetUserData(e.current))

                }}


                className="waperBox"
            />

        );
    }
}

export default withRouter(LangList)

