import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Select, message,Popconfirm } from 'antd';
import { UpdateDiscount, getDiscountDeatil } from "../../action/AdAction"
import intl from 'react-intl-universal';

class NewDiscount extends React.Component {

    state = {
        value: "",
        data: [
            { id: 1, name: intl.get('home.table.way.name_d') }, { id: 3, name: intl.get('home.table.way.name_s') }
        ],
        defaultValue: "",
        discount: ""
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(14)
        let Data = await getDiscountDeatil(id)
        let defaultValue
        if (Data.data.service_type === 1) {
            defaultValue = intl.get('home.table.way.name_d')
        } else {
            defaultValue = intl.get('home.table.way.name_s')
        }
        this.setState({
            value: Data.data.service_type,
            defaultValue,
            discount: Data.data.discount
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            values.service_type = this.state.value.toString()
            if (!err) {
                if (values.service_type) {
                    const id = this.props.location.pathname.substring(14)
                    UpdateDiscount(values, id)
                } else {
                    message.error(intl.get('discount.form.way.placehorder'))
                }
            }



        });
    };

    onChange = e => {
        this.setState({
            value: e
        });
    };

    render() {
        const { defaultValue, discount, data } = this.state
        const { Option } = Select;
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('discount.form.discount')}
                >
                    {getFieldDecorator('discount', {
                        rules: [{ required: true, message: intl.get('discount.form.discount.pleasehorder') }],
                        initialValue: discount
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('discount.form.way')}
                >
                    {
                        defaultValue && <Select defaultValue={defaultValue} style={{ width: "100%" }} onChange={this.onChange}>
                            {
                                data.length !== 0 && data.map(item => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    }



                </Form.Item>



                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewDiscount))
