import React from 'react'
import { withRouter } from 'react-router-dom'
import "../shopSetup/setup.less"
import { Form, Input, Upload, Icon, message, Button, Select, Switch, Popconfirm } from 'antd';
import { AddAd } from "../../action/AdAction";
import { getCategory, getGoodsList } from "../../action/goodAction"
import intl from 'react-intl-universal';


const { Option } = Select;
const { TextArea } = Input;
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}


class NewAd extends React.Component {

    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        userData: "",
        is_use: "",
        logo_file: "",
        type: "1",
        cateList: [],
        SearchData: [],
        url_link: "",
        is_show: true,
        is_show_title: true,
        is_show_content: true,
    };


    async componentDidMount() {
        getCategory().then(res => {
            this.setState({
                cateList: res.data.data
            })
        })
    }

    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.type = this.state.type
                values.url_link = this.state.url_link.toString()
                values.is_show = this.state.is_show ? "1" : "0"
                values.is_show_title = this.state.is_show_title ? "1" : "0"
                values.is_show_content = this.state.is_show_content ? "1" : "0"
                this.SubmitData(values)
            }
        });
    };

    async SubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        }


        if (this.state.logo_file) {
            formData.append("image_file", this.state.logo_file)
            AddAd(formData).then(res => {
                this.props.history.push("/advertisementList")
            })
        } else {
            message.error(intl.get('goods.list.img.horder'))
        }


    }

    onSelectSearch = (val) => {
        if (val.length > 1) {
            getGoodsList("", val).then(res => {
                this.setState({
                    SearchData: res.data.data
                })
            })
        }

    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };


        const { imageUrl, cateList, SearchData, is_show, is_show_title, is_show_content } = this.state;

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label={intl.get('ad.form.title')}
                >
                    {getFieldDecorator('title', {
                        rules: [{ required: true, message: intl.get('ad.form.title.placehorder'), whitespace: true }],
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={intl.get('ad.form.isShowTitle')}
                >
                    <Switch checked={is_show_title} onChange={() => {
                        this.setState({
                            is_show_title: !is_show_title
                        })
                    }} />
                </Form.Item>


                <Form.Item
                    label={<span>{intl.get('ad.form.seq')}</span>}
                >
                    {getFieldDecorator('sort', {
                        // rules: [{required: false}],
                        initialValue: "0"
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={<span>{intl.get('ad.form.img')}</span>}
                >
                    <Upload
                        name="avatar"
                        accept=".jpg,.png,.jpeg"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={this.handleChange}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>
                <Form.Item
                    label={intl.get('category.list.isShow')}
                >
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item>
                <Form.Item
                    label={<span>{intl.get('ad.form.type')}</span>}
                >
                    <Select defaultValue={intl.get('ad.form.goods')}
                        onChange={(value) => {
                            this.setState({
                                type: value
                            })
                        }}
                        disabled
                    >
                        <Option value="1">{intl.get('ad.form.goods')}</Option>
                        <Option value="2">{intl.get('ad.form.cate')}</Option>
                    </Select>
                </Form.Item>
                {
                    this.state.type === "1" ?
                        <Form.Item
                            label={<span>{intl.get('ad.form.selectgoods')}</span>}
                        >
                            <Select
                                showSearch
                                // value={this.state.value}
                                placeholder={intl.get('ad.form.selectgoods')}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={this.onSelectSearch}
                                onChange={(value) => {
                                    this.setState({
                                        url_link: value
                                    })
                                }}
                                notFoundContent={null}
                            >
                                {
                                    SearchData.length > 0 && SearchData.map((item, index) => {
                                        return <Option key={index} value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item> :
                        <Form.Item
                            label={<span>{intl.get('ad.form.selecttype')}</span>}
                        >
                            <Select
                                placeholder={intl.get('ad.form.selecttype')}
                                optionFilterProp="children"
                                onChange={(value) => {
                                    this.setState({
                                        url_link: value
                                    })
                                }}
                            >
                                {
                                    cateList.length > 0 && cateList.map((item, index) => {
                                        return <Option key={index} value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>

                        </Form.Item>

                }
                <Form.Item
                    label={intl.get('ad.form.content')}
                >
                    {getFieldDecorator('content')(<TextArea />)}
                </Form.Item>

                <Form.Item
                    label={intl.get('ad.form.isShowContent')}
                >
                    <Switch checked={is_show_content} onChange={() => {
                        this.setState({
                            is_show_content: !is_show_content
                        })
                    }} />
                </Form.Item>



                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewAd))
