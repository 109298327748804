import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch } from 'antd';
import { getAnnouncementList, deleteAnnouncement, UpdateAnnouncement, getEditorContent } from "../../action/AdAction";
import { getPartner, UpdateShopMessage } from "../../action/authAction"
import intl from 'react-intl-universal';

class AnnouncementList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], is_alert_announcement: false };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '15%',
                align: "center",
            },
            {
                title: intl.get('announcementList.table.title'),
                dataIndex: 'title',
                width: '30%',
                align: "center",
            },
            {
                title: intl.get('category.list.isShow'),
                width: '10%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_show === 1} onChange={() => this.onChange(record, "is_show")} />
                    )
                }
            }, {
                title: intl.get('announcementList.table.sort'),
                dataIndex: 'sort',
                width: '10%',
                align: "center",
            },

            // {
            //     title: intl.get('category.list.isAlert'),
            //     width: '10%',
            //     align: "center",
            //     render: (record) => {
            //         return (
            //             <Switch checked={record.is_alert === 1} onChange={() => this.onChange(record, "is_alert")} />
            //         )
            //     }
            // },
            {
                title: intl.get('withdrawLog.table.operation'),
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                    this.props.history.push("/EditAnnouncement/" + record.id)
                                }}>
                                {intl.get('seat.table.btn.edit')}
                            </Button>
                            <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    {intl.get('home.table.operation.btn.delete')}
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }
    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getAnnouncementList()
        let shopData = await getPartner()
        this.setState({
            data: res.data.data,
            is_alert_announcement: shopData.data.is_alert_announcement === 1 ? true : false,
        })
    }


    async handleDelete(data) {
        await deleteAnnouncement(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }


    onChange = (item, type) => {
        getEditorContent(item.content).then(res => {
            let data
            if (type === "is_show") {
                data = { "is_show": item.is_show === 1 ? "0" : "1", "is_alert": item.is_alert, "title": item.title, "content": res.data }
            } else {
                data = { "is_show": item.is_show, "is_alert": item.is_alert === 1 ? "0" : "1", "title": item.title, "content": res.data }
            }

            UpdateAnnouncement(data, item.id).then(res => {
                this.HandleGetData()
            })
        }).catch(error => {
        })
    }

    render() {
        let { is_alert_announcement } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "20px" }}>
                            <span>{intl.get('setup.form.isAlertAnnouncement')}：</span>
                            <Switch checked={is_alert_announcement} onChange={() => {
                                this.setState({
                                    is_alert_announcement: !is_alert_announcement
                                }, () => {
                                    UpdateShopMessage({ "is_alert_announcement": !is_alert_announcement ? "1" : "0" }).then(res => {
                                        this.HandleGetData()
                                    })
                                })
                            }} />
                        </div>
                        <Button type="primary"
                            onClick={() => this.props.history.push("/newChat")}
                        >
                            {intl.get('nav.setting.notice.add')}
                        </Button>
                    </div>
                </div>
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>


        );
    }
}

export default withRouter(Form.create()(AnnouncementList))

