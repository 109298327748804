import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Switch } from 'antd';
import { getPackageDetail } from "../../action/goodAction";
import { getPartner } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class PackageDetail extends React.Component {
    state = {
        data: "",
        partnerData: ""
    }
    componentDidMount() {
        // const id = this.props.location.pathname.substring(15)
        getPartner().then(res => {
            getPackageDetail(res.data.package_id).then(data => {
                this.setState({
                    data: data.data,
                    partnerData: res.data
                })
            })
        })

    }


    render() {
        const { data, partnerData } = this.state
        let is_open_partner_seat = partnerData.is_open_partner_seat == 1 ? true : false
        // const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 10 },
            },
        };

        if (data) {
            return (
                <Form {...formItemLayout} className="waperBox FormView">
                    <div style={{ height: 30 }}></div>
                    <Form.Item
                        label={intl.get('package.name')}
                    >
                        <Input value={data.name} disabled />
                    </Form.Item>
                    {
                        String(data.setup_fee).length > 0 &&
                        <Form.Item
                            label={intl.get('package.set')}
                        >
                            <Input value={data.setup_fee} disabled prefix={currency_symbol} />
                        </Form.Item>
                    }
                    {
                        String(data.year_service_fee).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.year")}
                        >
                            <Input value={data.year_service_fee} disabled prefix={currency_symbol} />
                        </Form.Item>
                    }

                    {
                        String(data.month_service_fee).length > 0 &&
                        <Form.Item
                            label={intl.get("subscription.serviceType.m")}
                        >
                            <Input value={data.month_service_fee} disabled prefix={currency_symbol} />
                        </Form.Item>
                    }

                    {
                        String(data.month_service_fee_by_order_num).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.month_service_fee_by_order_num")}
                        >
                            <Input value={data.month_service_fee_by_order_num} disabled />
                        </Form.Item>
                    }
                    {
                        String(data.money_standard_activity_order_num).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.money_standard_activity_order_num")}
                        >
                            <Input value={data.money_standard_activity_order_num} disabled />
                        </Form.Item>
                    }
                    {
                        String(data.contract_term_standard_order_num).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.contract_term_standard_order_num")}
                        >
                            <Input value={data.contract_term_standard_order_num} disabled />
                        </Form.Item>
                    }
                    {
                        String(data.contract_term_standard_activity_order_num).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.contract_term_standard_activity_order_num")}
                        >
                            <Input value={data.contract_term_standard_activity_order_num} disabled />
                        </Form.Item>
                    }

                    {
                        String(data.order_fixed_fee).length > 0 &&
                        <Form.Item
                            label={intl.get('package.fee')}
                        >
                            <Input value={data.order_fixed_fee} disabled prefix={currency_symbol} />
                        </Form.Item>
                    }
                    {
                        partnerData.rate ?
                            <div>
                                <Form.Item
                                    label={intl.get("setup.form.Merchant_general_service_fee")}
                                >
                                    <Input value={parseFloat(partnerData.rate) * 100} disabled suffix="%" />
                                </Form.Item>
                                <Form.Item
                                    label={intl.get("setup.form.Merchant_service_fee")}
                                >
                                    <Input value={parseFloat(partnerData.activity_service_rate) * 100} disabled suffix="%" />
                                </Form.Item>
                                <Form.Item
                                    label={intl.get("package.fee")}
                                >
                                    <Input prefix="$" value={partnerData.order_fixed_fee} disabled />
                                </Form.Item>
                            </div> :
                            <div>
                                {
                                    String(data.activity_service_rate).length > 0 &&
                                    <Form.Item
                                        label={intl.get('package.ActivityServiceFee')}
                                    >
                                        <Input value={data.activity_service_rate} disabled suffix="%" />
                                    </Form.Item>
                                }
                                {
                                    String(data.rate).length > 0 &&
                                    <Form.Item
                                        label={intl.get('package.rate')}
                                    >
                                        <Input value={data.rate} disabled suffix="%" />
                                    </Form.Item>
                                }
                            </div>
                    }


                    {
                        String(data.trial_period_days).length > 0 &&
                        <Form.Item
                            label={intl.get("package.days")}
                        >
                            <Input value={data.trial_period_days} disabled />
                        </Form.Item>
                    }
                    {
                        String(data.fee_reduction_fixed_fee).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.fee_reduction_fixed_fee")}
                        >
                            <Input value={data.fee_reduction_fixed_fee} disabled prefix={currency_symbol} />
                        </Form.Item>
                    }
                    {
                        String(data.fee_reduction_activity_service_rate).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.fee_reduction_activity_service_rate")}
                        >
                            <Input value={data.fee_reduction_activity_service_rate} disabled suffix="%" />
                        </Form.Item>
                    }

                    {
                        String(data.fee_reduction_rate).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.day_of_fee_reduction")}
                        >
                            <Input value={data.fee_reduction_rate} disabled suffix="%" />
                        </Form.Item>
                    }
                    {
                        String(data.prestore_money).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.prestore_money")}
                        >
                            <Input value={data.prestore_money} disabled prefix={currency_symbol} />
                        </Form.Item>
                    }
                    {
                        String(data.spu_quantity).length > 0 &&
                        <Form.Item
                            label={intl.get("package.service.goods_count")}
                        >
                            <Input value={data.spu_quantity} disabled />
                        </Form.Item>
                    }

                    <Form.Item
                        label={intl.get("package.is_own_payment")}
                    >
                        <Switch checked={data.is_own_payment === 1} disabled />
                    </Form.Item>
                    <Form.Item
                        label={intl.get("package.is_langs")}
                    >
                        <Switch checked={data.is_langs === 1} disabled />
                    </Form.Item>
                    <Form.Item
                        label={intl.get("package.is_have_h5")}
                    >
                        <Switch checked={data.is_have_h5 === 1} disabled />
                    </Form.Item>
                    <Form.Item
                        label={intl.get("package.is_have_domain")}
                    >
                        <Switch checked={data.is_have_domain === 1} disabled />
                    </Form.Item>
                    {
                        is_open_partner_seat &&
                        <Form.Item
                            label={intl.get("package.is_have_seat_qrcode")}
                        >
                            <Switch checked={data.is_scan_code === 1} disabled />
                        </Form.Item>
                    }

                </Form>
            );
        } else {
            return null
        }

    }
}

export default withRouter(Form.create()(PackageDetail))
