export const setTokenStorage = token => {
    sessionStorage.setItem('token', token)
}

export const getTokenStorage = () => {
    return sessionStorage.getItem('token')
}

export const removeToken = () => {
    return sessionStorage.removeItem('token')
}

export const getLangType = () => {
    return localStorage.getItem('lang_type')
}

export const setLangType = (v) => {
    localStorage.setItem('lang_type', v)
}
export const BaseUrl = () => {
    let url = "https://api.auedian.com"
    let dev = "https://dev.api.auedian.com"
    if (window.location.hostname.indexOf("www.auepartner.com") !== -1) {
        return url
    } else {
        return dev
    }

}