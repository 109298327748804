import {type} from "../util/varibales";
import intl from 'react-intl-universal';


const INITIAL_STATE = {
    menuName: sessionStorage.getItem('breadcrumb') || intl.get('nav.home')
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case type.SWITCH_MENU:
            return {...state,menuName:action.payload}
        default:
            return state;
    }
}
