import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form } from 'antd';
import { GetPrizeActivityList } from "../../action/authAction";
import "../category/category.less"
import intl from 'react-intl-universal';

class ActivityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: intl.get('red.table.id'),
                dataIndex: 'user_id',
                width: '15%',
                editable: false,
                align: "center",
            },
            {
                title: intl.get('red.table.no'),
                dataIndex: 'ticket',
                width: '15%',
                align: "center",
            },
            {
                title: intl.get('red.table.money'),
                dataIndex: 'money',
                width: '15%',
                align: "center",

            }, {
                title: intl.get('red.table.time'),
                dataIndex: 'updated_at',
                width: '30%',
                align: "center",

            }
        ];
    }

    async componentDidMount() {
        const id = this.props.location.pathname.substring(14)
        let res = await GetPrizeActivityList(id);
        this.setState({ data: res.data.loginActivity.data })
    }



    render() {

        const columns = this.columns.map(col => {
            return {
                ...col,
                onCell: record => ({
                    record,
                    title: col.title,
                }),
            };
        });

        return (
            <Table
                rowKey={record => record.id}
                // bordered
                dataSource={this.state.data}
                columns={columns}
                pagination={{
                    // onChange: this.cancel,
                    hideOnSinglePage: true
                }
                }
                className="waperBox"
            />
        );
    }
}

export default withRouter(Form.create()(ActivityList))

