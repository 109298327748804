import React from "react";
import { Input, Table, Button, Modal, Descriptions, Divider } from 'antd';
import { getContactUsList, UpdateContactUs } from "../../action/authAction";
import "../../style/common.less"
const { TextArea } = Input;

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            meta: "",
            page: 1,
            visible: false,
            refund_message: "",
            partner_message:"",//商家回复
            ItemData: ""
        };
        this.columns = [

            {
                title: 'ID',
                dataIndex: 'id',
                width: '3%',
                align: "center"
            },
            // {
            //     title: '订单号',
            //     dataIndex: 'sn',
            //     width: '10%',
            //     align: "center"
            // },
            {
                title: '问题类别',
                dataIndex: 'type',
                width: '5%',
                align: "center"
            },

            {
                title: '内容',
                dataIndex: 'message',
                width: '10%',
                align: "center"
            },


             {
                title: '处理',
                dataIndex: 'is_dispose',
                width: '10%',
                align: "center",
                render: (record) => {
                    return record == "1" ? "已处理" : "未处理"
                }

            },


            {
                title: '创建时间',
                dataIndex: 'created_at',
                width: '5%',
                align: "center",
                render: (record) => {
                    return record?.date.substring(0, 19)
                }
            },

            {
                title: '操作',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return <Button onClick={() => {
                        this.setState({
                            visible: true,
                            ItemData: record,
                            refund_message: record.dispose_message,
                            partner_message:record.partner_message,
                        })
                    }}>查看</Button>
                }
            },
        ];
    }

    async componentDidMount() {
        this.GetUserData(1)
    }

    async GetUserData(page) {
        let res = await getContactUsList(page)
        this.setState({
            data: res.data.data,
            meta: res.data.meta.pagination
        })
    }

    async handleDelete(data) {
        // await deleteRecommendShop(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }


    handleCancel = e => {
        this.setState({
            visible: false,
            passwordVisible: false,
        });
    };

    render() {
        const { data, meta, ItemData, refund_message,partner_message } = this.state
        return (
            <div>
                <Table
                    rowKey='id'
                    dataSource={data}
                    columns={this.columns}
                    className="waperBox"
                    pagination={{
                        pageSize: meta.per_page,
                        total: meta.total
                    }}
                    onChange={(e, a, b) => {
                        this.setState({
                            page: e.current
                        }, () => this.GetUserData(e.current))

                    }}
                />
                <Modal
                    title="用户反馈详情"
                    visible={this.state.visible}
                    onCancel={() => {
                        this.setState({
                            visible: false,
                            ItemData: "",
                            refund_message: ""
                        })
                    }}
                    onOk={() => {
                        UpdateContactUs({ "dispose_message": refund_message,  "partner_message": partner_message,    "is_dispose": 1 }, ItemData.id).then(res => {
                            this.setState({
                                visible: false
                            }, () => this.GetUserData(this.state.page))
                        })
                    }}
                >
                    <Descriptions title="用户信息" column={2}>
                        <Descriptions.Item label="用户uuid">{ItemData?.user?.uuid}</Descriptions.Item>
                        <Descriptions.Item label="用户昵称">{ItemData?.user?.name}</Descriptions.Item>
                        <Descriptions.Item label="手机号">{ItemData?.user?.phone}</Descriptions.Item>
                        <Descriptions.Item label="邮箱">{ItemData?.user?.email}</Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions title="店铺信息" column={2}>
                        <Descriptions.Item label="店铺ID">{ItemData?.partner?.id}</Descriptions.Item>
                        <Descriptions.Item label="店铺名称">{ItemData?.partner?.name}</Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions title="用户反馈信息" column={1}>


                        {
                            ItemData?.coupon_sn && <Descriptions.Item label="优惠券编号">{ItemData?.coupon_sn}</Descriptions.Item>
                        }


                        {
                            ItemData?.sn && <Descriptions.Item label="订单号">{ItemData?.sn}</Descriptions.Item>
                        }

                        <Descriptions.Item label="信息类别">{ItemData?.type}</Descriptions.Item>
                        <Descriptions.Item label="用户留言">{ItemData?.message}</Descriptions.Item>
                        <Descriptions.Item label="反馈图片">
                            <a href={ItemData.image} target="_blank"><img style={{ width: "100px" }} src={ItemData.image} alt="" /></a>
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider />


                    <div>
                        <p style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "bold", fontSize: "16px" }}>商家备注</p>
                        <TextArea
                            rows={3}
                            value={partner_message}
                            onChange={(e) => {
                                this.setState({
                                    partner_message: e.target.value
                                })
                            }}
                        />
                    </div>



                    {/*<div>
                        <p style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "bold", fontSize: "16px",marginTop:"20px" }}>管理员备注</p>
                        <TextArea
                            rows={3}
                            value={refund_message}
                            onChange={(e) => {
                                this.setState({
                                    refund_message: e.target.value
                                })
                            }}
                        />
                    </div>*/}


                </Modal>
            </div>
        );
    }
}


export default ContactUs

