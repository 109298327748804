import React from 'react'
import { withRouter } from 'react-router-dom'
import {Form, Descriptions, Card, Divider, Table, Button, message, Modal, Input, Popconfirm ,Upload ,Icon ,Tooltip ,DatePicker, } from 'antd';
import { getOrderDetail,putOrder,putDeliveryOrder, confirmOrderOrder , StripeRefundOrder ,OrderRefund ,OrderRefundGoods ,putOrderStatus} from "../../action/goodAction"
import intl from 'react-intl-universal';
import moment from 'moment';
import gsthave from "../../img/gsthave.png"
import gstno from "../../img/gstno.png"
import silverImg from "../../img/member/silver.png"
import goldImg from "../../img/member/gold.png"
import diamondImg from "../../img/member/diamond.png"



const { TextArea } = Input;


let currency_symbol = sessionStorage.getItem("currency_symbol")

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result, img));
    reader.readAsDataURL(img);
}
function beforeUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.destroy()
        message.error(intl.get('file.size.placehorder'));
    }
    return isLt1M;
}




class OrderDetail extends React.Component {
    //constructor(props) {
    //    super(props); //this.state = {}
        state = { 
            data: "", 
            is_show: false, 
            order_gst: 0 ,

            order_gst_inc : 0,//含gst 
            order_gst_excl:0,//不含gst 
            order_gst_total:0,//总计gst
            weight_total:0,

            deliveryVisible:false,//发货管理
            refundvisible:false,
            refundvisibleGoods:false,
            refund_goods_price:0,//部分商品退款的价格
            ItemData: "",
            partner_message:"",
            imageUrl: "",

            delivery_company_name:"",
            delivery_partner_message:"",
            delivery_partner_note:"",
            delivery_no:"",
            delivery_image:"",
            delivery_track:"",
            delivery_at:"",
            refund_message:"",
            refund_message_part:"",
            refund_spu_id:null,
            refund_spu_num:1,
            refund_quantity:1,
            gstVisible:false,

        };
    //}


    scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if(anchorElement) { anchorElement.scrollIntoView(); }
        }
      }



    async componentDidMount() { 
        this.getDetail();
    }

    async getDetail(){
        const id = this.props.location.pathname.substring(13)
        let res = await getOrderDetail(id);


   

        let orderSpuData = res.data.orderSpu.data
        let sum = 0

        /*
        if (orderSpuData) {
            for (let i in orderSpuData) {
                sum += parseFloat(orderSpuData[i].gst_total)
            }
        }
        */
         //计算重量，gst 
          var order_gst_inc = 0;
          var order_gst_excl = 0;
          orderSpuData.forEach((item) => { 
            if(item.is_gst == 1){ 
                if(item.gst_total){
                    order_gst_inc += parseFloat(item.gst_total);
                } 
            }  
            if(item.is_gst == 2){ 
                if(item.gst_total){
                    order_gst_excl += parseFloat(item.gst_total);
                } 
            }  
            
          }); 

          var order_gst_total =  parseFloat(order_gst_inc) + parseFloat(order_gst_excl)  ;

 
         //计算重量  
          var weight_total = 0; 
          orderSpuData.forEach((item) => {  
            if(item.weight_total){
                weight_total += parseFloat(item.weight_total);
            } 
          }); 
               



        this.setState({ 
            data: res.data, 
            order_gst: sum,
            order_gst_inc:order_gst_inc,
            order_gst_excl:order_gst_excl,
            order_gst_total:order_gst_total,
            weight_total:weight_total,  
            imageUrl: res.data.delivery_image,

             })
    }

    handleChange = info => {
        let isOK = beforeUpload(info.file)
        if (isOK) {
            if (info.file) {
                getBase64(info.file, (imageUrl, img) =>
                    this.setState({
                        imageUrl,
                        logo_file: img
                    }),
                );
            }
        }
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.SubmitData(values)
            }
        });
    };


    SubmitData(data) {
        let formData = new FormData()
        for (let i in data) {
            if (data[i]) {
                formData.append(i, data[i])
            }

        } 

        if (this.state.logo_file) {
            formData.append("delivery_image", this.state.logo_file)
        }

 
       //特殊处理  
       /*
       if(data.order_status == 'be_delivery'){ 
            formData.append("status", 10)
       }else if(data.order_status == 'wait_delivery'){
            formData.append("status", 11)
       }
       */



       formData.append("status", 11);
       //formData.append("delivery_at", moment(data.delivery_at).format("YYYY-MM-DD") )
        
         
        const id = this.props.location.pathname.substring(13)

        putDeliveryOrder(id,formData).then(res => {
            //this.props.history.push("/admin") 
            this.setState({
                deliveryVisible: false
            }, () =>  
                this.getDetail() 
            ) 
        })

    };


    handleReset=()=>{
      this.props.form.resetFields("refund_spu_num"); 

    }



    render() {
        const { getFieldDecorator } = this.props.form;
        const { 
            data, 
            order_gst ,
            order_gst_excl,
            order_gst_inc,
            order_gst_total,
            weight_total, 
            partner_message, 
            ItemData,
            deliveryVisible, 
            imageUrl ,
            delivery_company_name,
            delivery_partner_message,
            delivery_partner_note,
            delivery_no,
            delivery_image,
            delivery_track,
            delivery_at, 
            refundvisible,
            refundvisibleGoods,
            refund_goods_price,
            refund_message,
            refund_message_part,
            refund_spu_id,
            refund_spu_num,
            refund_quantity,
            gstVisible
         } = this.state;

        // const orderSpu = data.orderSpu && data.orderSpu.data
        // let serviceType = ""
        // if (data.service_type === 1) {
        //     serviceType = intl.get('home.table.way.name_d')
        // } else if (data.service_type === 2) {
        //     serviceType = intl.get('home.table.way.name_w')
        // }
        // else if (data.service_type === 3) {
        //     serviceType = intl.get('home.table.way.name_s')
        // } else if (data.service_type === 4) {
        //     serviceType = intl.get('home.table.way.name_t')
        // }
        const columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '10%',
                align: "center",
            },
            {
                title: 'bdid',
                dataIndex: ['attach_data', 'bdid'],
                width: '10%',
                align: "center",
            },
            {
                title: intl.get("home.table.goods"),
                width: '25%',
                render: (text, record) => {
                    return (
                        <div key={record.id} style={{ display: "flex" }}>
                            <img src={record.attach_data.image} style={{ width: "50px", height: "50px", marginRight: "10px", objectFit: "cover" }} alt="" />
                            <div>
                                <p>


                                 

                                {record.is_refunded == 1 ? 

                                    <span style={{color:'#ff5722'}} >[已退款{currency_symbol}{record.is_refunded_price}]</span>  : "" 
                                } 

                                {record.is_refunded_num > 0 ? 

                                    <span style={{color:'#ff5722'}} >[退款数量{record.is_refunded_num}]</span>  : "" 
                                }  


                                {record.attach_data.name}

                                </p>


                                <div style={{ display: "flex" }}>
                                    {
                                        record.attach_data && record.attach_data.spu_sku && record.attach_data.spu_sku.spu_sku_goods_specification_item && record.attach_data.spu_sku.spu_sku_goods_specification_item.map(key => {
                                            return (
                                                <p key={key.id} style={{ marginLeft: 5, color: "#878FA5", fontSize: 12 }}>{key.name}</p>
                                            )
                                        })
                                    }
                                </div>
                                <div>
                                    {
                                        record.attach_data && record.attach_data.spu_goods_annex_type && record.attach_data.spu_goods_annex_type.map(key => {
                                            let spu_goods_annex_type_item = key.spu_goods_annex_type_item
                                            return (
                                                <div>
                                                    {spu_goods_annex_type_item && spu_goods_annex_type_item.map((type_key, i) => {

                                                        let goods_annex_type_item = type_key.goods_annex_type_item
                                                        return <p key={i + goods_annex_type_item.name} style={{ marginLeft: 5, color: "#878FA5", fontSize: 12 }}>{goods_annex_type_item.name} x {goods_annex_type_item.quantity}</p>
                                                    })}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            }, {
                title: intl.get("orders.price"),
                //dataIndex: 'price',
                width: '15%',
                align: "center",
                render: (record) => {


                    if(record.member_price > 0 ){
                        return ( 
                            <div>  
                         {record.member_level_name == 'silver' ? <img src={silverImg} alt='avatar' style={{ width: '20px',paddingRight:'5px' }} /> : "" }  
                         {record.member_level_name == 'gold' ? <img src={goldImg} alt= 'avatar' style={{ width: '20px',paddingRight:'5px' }} /> : "" } 
                         {record.member_level_name == 'diamond' ? <img src={diamondImg} alt='avatar' style={{ width: '20px',paddingRight:'5px' }} /> : "" } 


                             {currency_symbol + record.member_price}  
                            {record.attach_data.is_gst ==  1 &&  
                                <img style={{ width: "20px" }} src={gsthave} alt='="' />
                             } 


                             {record.attach_data.is_gst ==  2 &&  
                                <img style={{ width: "20px" }} src={gstno} alt='="' />
                             } 
                             </div>

                            )

                    }else{
                        return ( 
                            <div>  
                                {currency_symbol + record.price}

                                {record.attach_data.is_gst ==  1 &&  
                                    <img style={{ width: "20px" }} src={gsthave} alt='="' />
                                 } 


                                 {record.attach_data.is_gst ==  2 &&  
                                    <img style={{ width: "20px" }} src={gstno} alt='="' />
                                 } 
                                 </div> 
                         )
                    }



                    // return ( 
                    //     <div>
                    // {currency_symbol + record.price}

                    // {record.attach_data.is_gst ==  1 &&  
                    //     <img style={{ width: "20px" }} src={gsthave} alt='="' />
                    //  } 


                    //  {record.attach_data.is_gst ==  2 &&  
                    //     <img style={{ width: "20px" }} src={gstno} alt='="' />
                    //  } 
                    //  </div>

                    //  )



                }
            }, {
                title: intl.get("orders.count"),
                dataIndex: 'quantity',
                width: '10%',
                align: "center",
            }, {
                title: "退款", 
                width: '10%',
                align: "center",
                render: (record) => {
                    return (data.orderSpu.data.length > 1 && data?.order_status !== "refunded" &&  <Button
                            style={{ marginRight: "5px", backgroundColor: this.state.url === "" ? "#58924d" : "", color: this.state.url === "" ? "#fff" : "rgba(0, 0, 0, 0.65)" }}
                            

                            onClick={() => {
                                 this.setState({
                                    refundvisibleGoods: true,
                                    refund_goods_price: record.member_price > 0? record.member_price: record.price,
                                    refund_spu_id: record.id,
                                    refund_quantity:record.quantity-record.is_refunded_num ,
                                    refund_spu_num:record.quantity,
                                    //refund_message_part: ""
                                 })
                            }}


                            >
                           退款
                        </Button>)
                }

            }
        ]








        const columnsRefund = [
             
            // {
            //     title: '退款商品id',
            //     dataIndex: 'order_refund_spu_id' ,
            //     width: '10%',
            //     align: "center",
            // },

            {
                title: "退款商品",
                width: '25%', 
                render: (text, record) => {
                    return (
                        <div style={{ display: "flex" }}>
                            <img src={record.spu?.image} style={{ width: "50px", height: "50px", marginRight: "10px", objectFit: "cover" }} alt="" />
                            <div>
                                <p> 
                               

                                {record.spu?.name} 
                                </p> 
                            </div>
                        </div>
                    )
                }
            },



            {
                title: "退款金额",
                //dataIndex: 'order_refund_price',
                width: '10%',
                align: "center",
                render: (record) => {



                   return ( 
                        <div>
                    { currency_symbol + record.order_refund_price}

                    {record.spu.is_gst ==  1 &&  
                        <img style={{ width: "20px" }} src={gsthave} alt='="' />
                     } 


                     {record.spu.is_gst ==  2 &&  
                        <img style={{ width: "20px" }} src={gstno} alt='="' />
                     } 
                     </div>

                     ) 


                }
            }, {
                title: intl.get("orders.count"),
                dataIndex: 'order_refund_spu_num',
                width: '10%',
                align: "center",
            }, 
            , {
                title: "备注",
                dataIndex: 'order_refund_message_part',
                width: '10%',
                align: "center",
            },
            
            {
                title: "处理来源", 
                width: '10%',
                align: "center",
                render: (record) => {
                    return   record.refund_source 
                }
            },

            {
                title: "处理人", 
                width: '10%',
                align: "center",
                render: (record) => {
                    return  record.refund_user
                }
            }
            , {
                title: "退款日期", 
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.created_at && record.created_at.date.substring(0, 19)
                }
            }

        ]












        let order_status = ""
        let scan_code_type = ""
        switch (data.order_status) {
            case "un_pay":
                order_status = intl.get("home.table.useStatus.un_pay");
                break;
            case "wait_order_receiving":
                order_status = intl.get("home.table.useStatus.wait_order_receiving");
                break;
            case "un_order_receiving":
                order_status = intl.get("home.table.useStatus.un_order_receiving");
                break;
            case "for_consumption":
                order_status = intl.get("home.table.useStatus.for_consumption");
                break;

            case "wait_delivery":
                order_status = "待配送";
                break;

            case "be_delivery":
                order_status = "配送中";
                break;
 

            case "finished":
                order_status = intl.get("home.table.useStatus.finished");
                break;
            case "canceled":
                order_status = intl.get("home.table.useStatus.canceled");
                break;
            case "rejected":
                order_status = intl.get("home.table.useStatus.rejected");
                break;
            case "refunded":
                order_status = intl.get("home.table.useStatus.refunded");
                break;
        }

        switch (data.scan_code_type) {
            case 1:
                scan_code_type = intl.get("nav.setting.seat")
                break;
            case 2:
                scan_code_type = intl.get("nav.goodsManagement.sales_promotion")
                break;
            case 3:
                scan_code_type = intl.get("nav.setting.seat") + "、" + intl.get("nav.goodsManagement.sales_promotion")
                break;
        }


        let spu_discount = data?.orderSpu?.data[0]?.attach_data?.spu_discount
        let oneUserWithdrawLog = data.oneUserWithdrawLog
        let userCoupon = data.userCoupon && data.userCoupon.data[0]
        let coupon_status = ""
        switch (userCoupon && userCoupon.status) {
            case "used":
                coupon_status = <span style={{ color: "green" }}>{intl.get("home.table.useStatus.name_y")}</span>
                break;
            case "unused":
                coupon_status = intl.get("home.table.useStatus.name_n")
                break;
            case "expired":
                coupon_status = <span style={{ color: "#ff0000" }}>{intl.get("home.table.useStatus.canceled")}</span>
                break;
            case "disabled":
                coupon_status = <span style={{ color: "#ff0000" }}>{intl.get("home.table.useStatus.disabled")}</span>
                break;
        }

        let orderType = ""
        if (data?.spu_discount_id) {
            orderType = intl.get("nav.goodsManagement.promotionalOrder")
        } else if (data?.is_user_cunpon) {
            orderType = intl.get("nav.goodsManagement.promotionalCoupon")
        } else {
            orderType = intl.get("nav.goodsManagement.ordinaryOrder")
        }


        let orderSource = ""
        if (data?.order_source == 1) {
            orderSource = "店铺"
        } else if (data?.order_source == 2) {
            orderSource = "平台"
        }  


        let nostockTypeNote = ""
        if (data?.nostock_type == '0') {
            nostockTypeNote = "商家进行部分商品退款"
        } else if (data?.nostock_type == '1') {
            nostockTypeNote = "订单全额进行退款"
        } else if (data?.nostock_type == '2') {
            nostockTypeNote = "商家联系我进行处理"
        }  






        let coupon_type = "";
        switch (userCoupon && userCoupon.coupon.type) {
            case 1:
                coupon_type = "N";
                break;
            case 2:
                coupon_type = "R";
                break;
            case 3:
                coupon_type = "SR";
                break;
            case 4:
                coupon_type = "SSR";
                break;
            default:
                coupon_type = "";
                break;
        }


        let  operation_type = "";
        switch (userCoupon && userCoupon.coupon.operation_type) {
            case 0:
                operation_type = "线下";
                break;
            case 1:
                operation_type = "线上";
                break;
            case 2:
                operation_type = "线下和线上";
                break;
            default:
                operation_type = "";
                break;
             
        }



        let user_operation_type = "";
        switch (userCoupon && userCoupon.use_operation_type) {
            case 0:
                user_operation_type = "线下";
                break;
            case 1:
                user_operation_type = "线上";
                break;
            case 2:
                user_operation_type = "线下和线上";
                break;
            default:
                user_operation_type = "";
                break;
             
        }





        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">{intl.get('file.btn.name')}</div>
            </div>
        );


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        
        return ( 

           
            <div>
                

                {/*
                  <a onClick={()=>this.scrollToAnchor('deliverybut')}> 
                    <button className="android-fab mdl-button mdl-button--colored mdl-js-button mdl-button--fab mdl-js-ripple-effect">
                      <i className="material-icons">expand_more</i>
                    </button>
                  </a>
               */}


                <Card>



                    <Descriptions title={intl.get('subscription.info.base')}>
                        {
                            data?.spu_discount_id > 0 &&
                            <Descriptions.Item label={intl.get('nav.goodsManagement.promotionalgoods.discount_type')}>
                                {
                                    data?.spu_many_help_id > 0 ? intl.get("nav.goodsManagement.Promotion") + " " + intl.get("nav.goodsManagement.cutgoodsType") : intl.get("nav.goodsManagement.Promotion")
                                }
                            </Descriptions.Item>
                        }



                        {/*
                            data?.spu_discount_id > 0 &&
                            <Descriptions.Item label={intl.get('nav.goodsManagement.promotionalgoods_name')}>
                                {
                                    data?.orderSpu?.data[0]?.attach_data?.spu_discount.name
                                }
                            </Descriptions.Item>
                        */
                        }


                        {/*
                            data?.spu_discount_id > 0 &&
                            <Descriptions.Item label="Discount off">
                                {
                                    spu_discount && spu_discount.discount_type === "fixed" ? currency_symbol + spu_discount.discount_on_fixed : parseFloat(spu_discount.discount_on_percentage) * 100 + "%" + " off"
                                }
                            </Descriptions.Item>
                        */
                        }



                        <Descriptions.Item label={intl.get('home.table.ordernumber')}>{data?.order_sn}</Descriptions.Item>
                        <Descriptions.Item label={intl.get("home.table.ordertime")}>{data?.created_at?.date && data.created_at.date.substring(0, 19)}</Descriptions.Item>
                        

                        {/*<Descriptions.Item label={intl.get('home.table.status')}>{data?.is_pay === 0 ? intl.get('home.table.status.name_n') : intl.get('home.table.status.name_y')}</Descriptions.Item>*/}
                        

                        {
                            data?.order_status !== "refunded" &&
                            <Descriptions.Item label={intl.get('home.table.useStatus')}>{data?.is_finish === 1 ? intl.get('home.table.useStatus.name_y') : intl.get('home.table.useStatus.name_n')}</Descriptions.Item>
                        }
                        {
                            data?.order_status !== "refunded" &&
                            <Descriptions.Item label={intl.get("home.table.orderconfirmationtime")}>{data?.service_at?.date.substring(0, 19)}</Descriptions.Item>
                        }
                        {
                            data?.order_status !== "refunded" &&
                            <Descriptions.Item label={intl.get("home.table.confirm.admin")}>{data && data.service_user_name}</Descriptions.Item>
                        }
                        

                        {/*<Descriptions.Item label={intl.get("home.table.time")}>{data && data.pay_at && data.pay_at.date.substring(0, 19)}</Descriptions.Item>*/}



                        {/*<Descriptions.Item label={intl.get("home.table.payee")}>{data && data.payee && data.payee.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}</Descriptions.Item>*/}


                        {/*
                            data && data.pay_type &&
                            <Descriptions.Item label={intl.get("home.table.payment_method")}>{data.pay_type}</Descriptions.Item>
                        */}


                        <Descriptions.Item label={intl.get('orders.status')}>{order_status}</Descriptions.Item>
                        {
                            data?.scan_code_type > 0 &&
                            <Descriptions.Item label={intl.get('home.table.scanning_mode')}>
                                {scan_code_type}
                            </Descriptions.Item>
                        }
                        {
                            data && data.order_receiving_at && data.order_receiving_at.date &&
                            <Descriptions.Item label={intl.get("setup.form.order_time")}>{data.order_receiving_at.date.substring(0, 19)}</Descriptions.Item>
                        }
                        {
                            data && data.service_end_at && data.service_end_at.date &&
                            <Descriptions.Item label={intl.get("home.table.dissipate")}>{data && data.service_end_at && data.service_end_at.date.substring(0, 19)}</Descriptions.Item>
                        }
                        {
                            data && data.is_eatmoon == 1 &&
                            <Descriptions.Item label={intl.get("home.table.source")}>eatmoon</Descriptions.Item>
                        }
                        {/* {
                            data && data.service_at && data.service_at.date &&
                            <Descriptions.Item label={intl.get("home.table.arrivalTime")}>{data.service_at.date.substring(0, 19)}</Descriptions.Item>
                        } */}


                        {
                            data?.remark &&
                            <Descriptions.Item label={intl.get('order.table.remark')}>{data?.remark}</Descriptions.Item>
                        }
                        {
                            data &&
                            <Descriptions.Item label={intl.get('order.table.type')}>{orderType}</Descriptions.Item>
                        }


                        {
                            data &&
                            <Descriptions.Item label="订单来源">{orderSource}</Descriptions.Item>
                        }

                        {
                            data &&
                            <Descriptions.Item label="缺货处理方式">{nostockTypeNote}</Descriptions.Item>
                        }








                    </Descriptions>
                    <Divider />







                    <Descriptions title="付款信息">
                        

   
                        <Descriptions.Item label={intl.get('home.table.status')}>{data?.is_pay === 0 ? intl.get('home.table.status.name_n') : intl.get('home.table.status.name_y')}</Descriptions.Item>
                        <Descriptions.Item label={intl.get("home.table.time")}>{data && data.pay_at && data.pay_at.date.substring(0, 19)}</Descriptions.Item>


                        
                        
                        
                        <Descriptions.Item label={intl.get("home.table.payee")}>{data && data.payee && data.payee.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())}</Descriptions.Item>
                        {
                            data && data.pay_type &&
                            <Descriptions.Item label={intl.get("home.table.payment_method")}>{data.pay_type}</Descriptions.Item>
                        }
                         
                         
                         <Descriptions.Item label="支付凭证">{data && data.stripe_pay_no}</Descriptions.Item>





                    </Descriptions>
                    <Divider />












                    <Descriptions title={intl.get('user.table.user')}>
                        <Descriptions.Item label="uuid">{data && data.user && data.user.uuid}</Descriptions.Item>
                        <Descriptions.Item label={intl.get('user.table.nickname')}>{data && data.user && data.user.name}</Descriptions.Item>
                        <Descriptions.Item label={intl.get('user.table.phone')}>{data && data.user && data.user.phone}</Descriptions.Item>
                        {
                            data.partner && data.partner.is_send_receipt === 1 &&
                            <Descriptions.Item label={intl.get("setup.form.email")}>{data && data.user && data.user.email}</Descriptions.Item>
                        }
                    </Descriptions>
                    {
                        data && data.address &&
                        <Divider />
                    }
                    {
                        data && data.address &&
                        <Descriptions title={intl.get("home.table.address")}>
                            <Descriptions.Item label={intl.get("nav.CostFlow.name")}>{data.username}</Descriptions.Item>
                            <Descriptions.Item label={intl.get("user.table.phone")}>{data.phone}</Descriptions.Item>
                            <Descriptions.Item label={intl.get("home.table.address")}>{data.address}</Descriptions.Item>
                        </Descriptions>
                    }
                    <Divider />
                    <Descriptions title={intl.get('home.table.order_package')}>
                        <Descriptions.Item label={intl.get('home.table.order_package_id')}>{data && data.orderPartnerMoney && data.orderPartnerMoney.package_id}</Descriptions.Item>
                        <Descriptions.Item label={intl.get('package.name')}>{data && data.orderPartnerMoney && data.orderPartnerMoney.package && data.orderPartnerMoney.package.name}</Descriptions.Item>
                    </Descriptions>
                    <Divider />



                    {
                        userCoupon &&
                        <div>
                            <Descriptions title={intl.get('home.table.order_coupon')}>
                                <Descriptions.Item label={intl.get("nav.goodsManagement.promotionalgoods.coupon") + "id"}>{userCoupon.coupon.id}</Descriptions.Item>
                                <Descriptions.Item label={intl.get('nav.goodsManagement.promotionalgoods.coupon_name')}>{userCoupon.coupon.name}</Descriptions.Item>
                                <Descriptions.Item label="营销类型">{coupon_type}</Descriptions.Item>
                                <Descriptions.Item label="运营类型">{userCoupon.coupon.coupon_type == 0 ? "商品优惠券" : "店铺优惠券"}</Descriptions.Item>
                                <Descriptions.Item label="使用类型">{operation_type}</Descriptions.Item>
                                <Descriptions.Item label="用户使用类型">{user_operation_type}使用</Descriptions.Item>

                                <Descriptions.Item label={intl.get('nav.goodsManagement.promotionalgoods.coupon') + intl.get("plugin.status")}>{coupon_status}</Descriptions.Item>
                                <Descriptions.Item label="Ticket code">{userCoupon.bar_code}</Descriptions.Item>
                            </Descriptions>
                            <Divider />
                        </div>
                    }


                    {
                        userCoupon &&
                        <div>
                            <Descriptions title="会员折扣信息"> 
                                
                                <Descriptions.Item label="营销类型">会员折扣</Descriptions.Item> 
                                <Descriptions.Item label="折扣金额"></Descriptions.Item> 
                            </Descriptions>
                            <Divider />
                        </div>
                    }


                
                    {
                        data.use_user_points_money &&
                        <div>
                            <Descriptions title="积分使用"> 
                                
                                <Descriptions.Item label="营销类型">积分折扣</Descriptions.Item> 
                                <Descriptions.Item label="折扣金额">${data.use_user_points_money}</Descriptions.Item> 
                                <Descriptions.Item label="折扣金额">使用积分</Descriptions.Item> 
                                <Descriptions.Item label="折扣金额">{data.use_user_points}</Descriptions.Item> 
                            </Descriptions>
                            <Divider />
                        </div>
                    }


                    {
                    data?.spu_discount_id > 0 &&
                    <div>
                        <Descriptions title="Deal信息">

                        <Descriptions.Item label="Deal名称">
                            {
                                data?.orderSpu?.data[0]?.attach_data?.spu_discount.name
                            }
                        </Descriptions.Item>
                         
                            
                        <Descriptions.Item label="Deal adid">
                            {
                                data?.orderSpu?.data[0]?.attach_data?.spu_discount.adid
                            }
                        </Descriptions.Item>
                         

 
                            
                        <Descriptions.Item label="Discount off">
                            {
                                spu_discount && spu_discount.discount_type === "fixed" ? "$" + spu_discount.discount_on_fixed : parseFloat(spu_discount.discount_on_percentage) * 100 + "%" + " off"
                            }
                        </Descriptions.Item>
                         

 
                        </Descriptions>
                        <Divider />
                    </div>
                }









                    <Descriptions title={intl.get("nav.CostFlow.cost")}>
                        <Descriptions.Item label={intl.get("home.table.spu_discount_price")}>{currency_symbol}{data && data.spu_discount_price}</Descriptions.Item>
                        
                        <Descriptions.Item label="商品重量">Kg {data && weight_total}</Descriptions.Item>

                        { data.use_user_money >0 && <Descriptions.Item label={intl.get("home.table.use_user_money")}>-{currency_symbol}{data && data.use_user_money}</Descriptions.Item> }
                        
                        


                        {
                            data && data.coupon_reduction_price > 0 &&
                            <Descriptions.Item label={intl.get("home.table.use_user_coupon")}>-{currency_symbol}{data.coupon_reduction_price}</Descriptions.Item>
                        }




                        {/*<Descriptions.Item label="含GST">{currency_symbol}{order_gst_inc}</Descriptions.Item>
                        <Descriptions.Item label="不含GST">{currency_symbol}{order_gst_excl}</Descriptions.Item>
                        */}

                        <Descriptions.Item label={<span>GST Total<Icon type="question-circle" style={{ marginLeft: "5px" }} 
                            onClick={() => {
                                this.setState({
                                    gstVisible: true
                                })
                            }} 
                            /> </span>}>{currency_symbol}{order_gst_total} 

                            
                        </Descriptions.Item>

                        {data.consumption_type == 1 && <Descriptions.Item label="运费">${data && data.delivery_price}</Descriptions.Item> }

                         <Descriptions.Item label="用户系统手续费(用户给系统支付的手续费)">${data && data.system_user_fee}</Descriptions.Item>


                        {data.is_pay != 1 && <Descriptions.Item label={intl.get("home.table.order_price")}>{currency_symbol}{data && parseFloat(data.price).toFixed(2)}</Descriptions.Item> }
                        <Descriptions.Item label={intl.get("home.table.bank")}>{currency_symbol}{data && data.transaction_fee}</Descriptions.Item>
                        
 
                        <Descriptions.Item label={intl.get("home.table.totalprice")}>{currency_symbol}{data && data.pay_total_price}</Descriptions.Item>
                        


                        {/* <Divider /> */}


                        {/*<Descriptions.Item label={(data && data.spu_discount_id > 0 ? intl.get("nav.event") + intl.get("home.table.partner_charge_rate") : intl.get("home.table.partner_charge_rate")) + (data && data.orderPartnerMoney && data.orderPartnerMoney.is_custom_rate == 1 ? intl.get("home.table.change") : "")}>{data && data.orderPartnerMoney && data.orderPartnerMoney.partner_charge_rate}%</Descriptions.Item>
                        <Descriptions.Item label={data && data.spu_discount_id > 0 ? intl.get("nav.event") + intl.get("home.table.partner_fixed_fee") : intl.get("home.table.partner_fixed_fee")}>{currency_symbol}{data && data.orderPartnerMoney && data.orderPartnerMoney.partner_fixed_fee}</Descriptions.Item>
                        */}

                    </Descriptions>




                     <Divider /> 
                     <Descriptions title="服务费">
                         {/* <Divider /> */}

                        {/*
                        <Descriptions.Item label={(data && data.spu_discount_id > 0 ? intl.get("nav.event") + intl.get("home.table.partner_charge_rate") : intl.get("home.table.partner_charge_rate")) + (data && data.orderPartnerMoney && data.orderPartnerMoney.is_custom_rate == 1 ? intl.get("home.table.change") : "")}>{data && data.orderPartnerMoney && data.orderPartnerMoney.partner_charge_rate}%</Descriptions.Item>
                        <Descriptions.Item label={data && data.spu_discount_id > 0 ? intl.get("nav.event") + intl.get("home.table.partner_fixed_fee") : intl.get("home.table.partner_fixed_fee")}>{currency_symbol}{data && data.orderPartnerMoney && data.orderPartnerMoney.partner_fixed_fee}</Descriptions.Item>
                        */}



                        <Descriptions.Item label={   (data.spu_discount_id > 0 || data.is_user_coupon>0) ?    "活动服务费" : "商品服务费"  }>{data && data.orderPartnerMoney && data.orderPartnerMoney.partner_charge_rate}%</Descriptions.Item>
                        <Descriptions.Item label= "定额服务费"  >{currency_symbol}{data && data.orderPartnerMoney && data.orderPartnerMoney.partner_fixed_fee}</Descriptions.Item>
                        
                        {
                           (data.spu_discount_id > 0 || data.is_user_coupon > 0) &&

                            <Descriptions.Item label= "折扣金额" >
                            ${data && data.orderPartnerMoney && data.reduction_price || 0}
                            </Descriptions.Item> 
                        }

                    

                        <Descriptions.Item label= "服务费合计"  >{currency_symbol}{data && data.orderPartnerMoney && data.orderPartnerMoney.partner_charge_money}</Descriptions.Item>
                        

                    </Descriptions>


 

                    {
                        data?.order_status === "refunded" && <Divider />
                    }
                    
                    {
                        data?.order_status === "refunded" &&
                        <Descriptions title={intl.get("home.table.useStatus.refunded_detail")}>

                            <Descriptions.Item label={intl.get("home.table.useStatus.refunded_to")}>
                                {
                                    data?.is_refunded == 1 ?
                                        <Button
                                            type='link'
                                            onClick={() => {
                                                this.setState({
                                                    is_show: true
                                                })
                                            }}
                                        >
                                            "C端用户付款银行卡"
                                        </Button>
                                        : data?.is_pay == 2 ? "C端用户付款银行卡" : "C端用户付款银行卡"
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={intl.get("home.table.useStatus.refunded_time")}>
                                {
                                    data?.is_refunded == 1 ? data?.user_withdraw_at?.date.substring(0, 19) : data?.is_pay == 2 ? data?.refund_at?.date.substring(0, 19) : ""
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label={intl.get("home.table.useStatus.refunded_amount")}>
                                ${data?.refund_price}
                            </Descriptions.Item>



                            {
                                
                                <Descriptions.Item label="处理来源">
                                   {data?.refund_source}
                                </Descriptions.Item>
                            }


                            {
                                
                                <Descriptions.Item label="处理人">
                                    {data?.refund_user}
                                </Descriptions.Item>
                            }






                            {
                                data.refund_message &&  
                                <Descriptions.Item label="备注">
                                    {data?.refund_message}
                                </Descriptions.Item>
                            }


                        
                        </Descriptions>
                    }



                    {
                        data?.message &&
                        <div>
                            <Divider />
                            <div>
                                <p style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "bold", fontSize: "16px" }}>{intl.get("home.table.useStatus.orderReply")}</p>
                                <p>{data?.message}</p>
                            </div>
                        </div>
                    }



                    <Divider />
                    <Descriptions title={intl.get('home.table.goods')}></Descriptions>
                    {
                        data && <Table
                            rowKey={record => record.id}
                            dataSource={data.orderSpu && data.orderSpu.data}
                            columns={columns}
                            pagination={{
                                hideOnSinglePage: true
                            }}
                        />
                    }

                    
                    {
                        data?.is_refund_goods == "1" && data?.order_status !== "refunded" &&  <Divider />
                    }
                    
                    {
                        data?.is_refund_goods ==  "1" && data?.order_status !== "refunded" &&
                        <Descriptions title="订单部分退款">

                            <Descriptions.Item label={intl.get("home.table.useStatus.refunded_to")}>
                                {
                                    data?.is_refunded == 1 ?
                                        <Button
                                            type='link'
                                            onClick={() => {
                                                this.setState({
                                                    is_show: true
                                                })
                                            }}
                                        >
                                            {intl.get("home.table.useStatus.bank_card")}
                                        </Button>
                                        : data?.is_pay == 2 ? intl.get("home.table.useStatus.wallet") : "C端用户付款的银行卡"
                                }
                            </Descriptions.Item>


                            

                            <Descriptions.Item label="部分退款总金额">
                                ${data?.is_refund_goods_total_price}
                            </Descriptions.Item>


 
                        
                        </Descriptions>


                        


                    }

                    {
                        data?.refundPartPayLog && data?.is_refund_goods ==  "1" && data?.order_status !== "refunded" &&
                        <div style={{marginTop:'20px'}}></div>
                    }

                    {
                        data?.refundPartPayLog && data?.is_refund_goods ==  "1" && data?.order_status !== "refunded" &&

                        <Descriptions title='部分退款记录'></Descriptions>
                    }

                    {
                        data?.refundPartPayLog  && data?.is_refund_goods ==  "1" && data?.order_status !== "refunded" && 

                        <Table
                            rowKey={record => record.id}
                            dataSource={data.refundPartPayLog && data.refundPartPayLog.data}
                            columns={columnsRefund}
                            pagination={{
                                hideOnSinglePage: true
                            }}
                        />
                    }


                    






                    {


                        (data.order_status == 'be_delivery' &&  data?.consumption_type == '1' ) &&  
                        <div> 
                            <Divider />    
                            <Descriptions title="发货信息"> 
                                <Descriptions.Item label="快递名称" > {data?.delivery_company_name}  </Descriptions.Item>
                                <Descriptions.Item label="快递单号" > {data?.delivery_no} </Descriptions.Item>
                                <Descriptions.Item label="快递单号追踪" > {data?.delivery_track} </Descriptions.Item>
                                <Descriptions.Item label="商家留言" > {data?.delivery_partner_message} </Descriptions.Item>
                                <Descriptions.Item label="备注"> {data?.delivery_partner_note} </Descriptions.Item>
                                <Descriptions.Item label="附件"> <a href={data.delivery_image} target="_blank"><img style={{ width: "100px" }} src={data.delivery_image} alt="" /></a> </Descriptions.Item> 
                                <Descriptions.Item label="发货时间"> {data?.delivery_at?.date.substring(0, 10)} </Descriptions.Item>
                            </Descriptions>
                        </div> 
                    }



                    { 
                       (data?.consumption_type == '1' && data.is_finish != 1  && data.order_status != 'refunded'  ) && 

                        <Button
                            id="deliverybut"
                            type="primary"
                            style={{ marginRight: "10px", marginTop:"10px" }}
                            onClick={() => {
                                 this.setState({
                                    deliveryVisible: true,
                                    // ItemData: record,
                                    // refund_message: record.dispose_message,
                                    // partner_message:record.partner_message,
                                })
                            }}
                        >
                            发货管理
                        </Button>
                    }




 

                    <Modal
                        visible={this.state.is_show}
                        footer={null}
                        onCancel={() => this.setState({
                            is_show: false
                        })}
                    >
                        {
                            oneUserWithdrawLog &&
                            <Descriptions
                                column={2}
                                title={intl.get("home.table.order_refund.information")}
                            >
                                <Descriptions.Item label={intl.get("home.table.order_refund.name")}>{oneUserWithdrawLog.user_name}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.bank")}>{oneUserWithdrawLog.account_name}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.account")}>{oneUserWithdrawLog.account}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.abn")}>{oneUserWithdrawLog.abn}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.bsb")}>{oneUserWithdrawLog.bsb}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.price")}>{oneUserWithdrawLog.money}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.message")}>{oneUserWithdrawLog.dispose_message}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.type")}>{oneUserWithdrawLog.is_finish}</Descriptions.Item>
                                <Descriptions.Item label={intl.get("home.table.order_refund.img")}>{oneUserWithdrawLog.ticket_image && <img src={oneUserWithdrawLog.ticket_image} style={{ width: "100px" }} />}</Descriptions.Item>
                            </Descriptions>
                        }

                    </Modal>
                </Card>

                <Modal
                    title="发货管理"
                    visible = {this.state.deliveryVisible}
                    onCancel={() => {
                        this.setState({
                            deliveryVisible: false,
                            ItemData: "", 
                        })
                    }}

                    // onOk={() => {
                    //     putOrder(
                    //         data.id,

                    //         { "delivery_company_name": delivery_company_name, 
                    //           "delivery_partner_note": delivery_partner_note,   
                    //           "delivery_partner_message": delivery_partner_message,   
                    //           "delivery_no": delivery_no,  
                    //           "delivery_track":delivery_track, 
                    //           "delivery_at":delivery_at,
                    //           "status": 11
                    //          }).then(res => {
                    //         this.setState({
                    //             deliveryVisible: false
                    //         }, () =>  
                    //             this.getDetail() 
                    //         )
                    //     })
                    // }}

                    footer={null}
                >
                   
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} className=" ">

                    <Form.Item
                        label="快递公司"
                    >
                        {getFieldDecorator('delivery_company_name', {
                            rules: [{ required: false,  }],
                            initialValue: data.delivery_company_name
                        })(<Input 

                        placeholder="请输入快递公司名称" 
                            onChange={(e) => {
                                 this.setState({
                                    delivery_company_name: e.target.value
                                })
                            }}
                            />)}
                    </Form.Item>




                    <Form.Item
                        label="快递单号"
                    >
                        {getFieldDecorator('delivery_no', {
                            rules: [{ required: true ,}],
                            initialValue: data.delivery_no
                        })(<Input 

                            placeholder="请输入快递单号"
                            
                            onChange={(e) => {
                                 this.setState({
                                    delivery_no: e.target.value
                                })
                            }}
                            />)}
                    </Form.Item>



                    <Form.Item
                        label="快递单号追踪"
                    >
                        {getFieldDecorator('delivery_track', {
                            rules: [{ required: false ,}],
                            initialValue: data.delivery_track
                        })(<Input  
                            placeholder="请输入快递单号追踪的Link"
                            
                            onChange={(e) => {
                                 this.setState({
                                    delivery_track: e.target.value
                                })
                            }} 
                            />)}
                    </Form.Item>



                    <Form.Item
                        label="商家留言"
                    >
                        {getFieldDecorator('delivery_partner_message', {
                            rules: [{ required: false ,}],
                            initialValue: data.delivery_partner_message
                        })(<TextArea
                            rows={3}
                            placeholder="请输入商家留言"
                             
                            onChange={(e) => {
                                this.setState({
                                    delivery_partner_message: e.target.value
                                })
                            }}
                        />)}
                    </Form.Item>



                <Form.Item
                    label={<span>附件</span>}
                >
                    <Upload
                        name="delivery_image"
                        accept=".jpg,.png,.jpeg"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        customRequest={this.handleChange}
                    >
                        {imageUrl ? <img src={imageUrl} alt="imageUrl" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>



                    <Form.Item
                        label="发货日期"
                    > 
                       <DatePicker
                            //value={moment(data.delivery_at.date, "DD-MM-YYYY")}
                            //initialValue : moment(data.delivery_at  ? data.delivery_at.date:'',"YYYY-MM-DD")
                            defaultValue={moment(data.delivery_at?.date, "DD-MM-YYYY")}
                            format='YYYY-MM-DD'
                            onChange={(value) => {
                                this.setState({
                                    delivery_at: moment(value).format("YYYY-MM-DD")
                                })
                            }}
                        /> 
                    </Form.Item>



                    <Form.Item
                        label="商家备注"
                    >
                        {getFieldDecorator('delivery_partner_note', {
                            rules: [{ required: false ,  }],
                            initialValue : data.delivery_partner_note
                        })(<TextArea
                            rows={3}
                            placeholder="请输入备注" 
                            onChange={(e) => {
                                this.setState({
                                    delivery_partner_note: e.target.value
                                })
                            }}
                        />)}
                    </Form.Item>


                         
                 
                        { data.order_status == 'be_delivery' && 
                        <Button
                            type="primary" 
                            style={{ marginRight: "10px", marginTop:"10px" }}

                            onClick={() => {

                                  putOrder(
                                    data.id, 
                                    { 
                                      "delivery_company_name": "", 
                                      "delivery_partner_note": "",   
                                      "delivery_partner_message": "",   
                                      "delivery_image":"",
                                      "delivery_no": "",  
                                      "delivery_track":"", 
                                      "delivery_at":"",
                                      "status": 10,//待配送
                                     }).then(res => {
                                    this.setState({
                                        deliveryVisible: false
                                    }, () =>  
                                        this.getDetail() 
                                    )
                                })

                            }}


                        >
                            撤销发货
                        </Button>
                        }


                        { data.order_status == 'wait_delivery' && 
                        <Button
                            type="primary"

                            htmlType="submit"

                            style={{ marginRight: "10px", marginTop:"10px" }}

                            // onClick={() => {
                            //       putOrder(
                            //         data.id, 
                            //         { "delivery_company_name": delivery_company_name, 
                            //           "delivery_partner_note": delivery_partner_note,   
                            //           "delivery_partner_message": delivery_partner_message,   
                            //           "delivery_no": delivery_no,  
                            //           "delivery_track":delivery_track, 
                            //           "delivery_at":delivery_at,
                            //           "status": 11, //配送中
                            //          }).then(res => {
                            //         this.setState({
                            //             deliveryVisible: false
                            //         }, () =>  
                            //             this.getDetail() 
                            //         )
                            //     })
                            // }}


                        >
                            确认发货
                        </Button>
                        }


                        { data.order_status == 'be_delivery' && 
                        <Button
                            type="primary"

                            htmlType="submit"

                            style={{ marginRight: "10px", marginTop:"10px" }}

                            // onClick={() => {
                            //       putOrder(
                            //         data.id, 
                            //         { "delivery_company_name": delivery_company_name, 
                            //           "delivery_partner_note": delivery_partner_note,   
                            //           "delivery_partner_message": delivery_partner_message,   
                            //           "delivery_no": delivery_no,  
                            //           "delivery_track":delivery_track, 
                            //           "delivery_at":delivery_at,
                            //           "status": 11, //配送中
                            //          }).then(res => {
                            //         this.setState({
                            //             deliveryVisible: false
                            //         }, () =>  
                            //             this.getDetail() 
                            //         )
                            //     })
                            // }}


                        >
                            确定修改
                        </Button>
                        } 
                    </Form> 
                </Modal>


                <Modal
                    title="确认部分退款"
                    visible={refundvisibleGoods} 
                    onCancel={() => {
                        this.setState({
                            refundvisibleGoods: false,
                            refund_spu_id: "",
                            refund_message_part: "",
                            refund_spu_num:"",
                        }, () =>{
                            this.handleReset();  
                            } 
                        )

                      


                    }}
                    onOk={() => {
 
                        OrderRefundGoods(data.id, { refund_message_part,refund_goods_price,refund_spu_id,refund_spu_num }).then(res => {
                            

                            setTimeout(() => {
                                 this.setState({
                                    refundvisibleGoods: false
                                }, () =>  {
                                    this.getDetail() ;
                                    this.handleReset(); 
                                }
                                    
                                ) 
                             }, 3000)
                            
                        }).catch(error => {
                            this.setState({ 
                                refundvisibleGoods: false
                            })
                        })


                       





                    }}
                >
                    
                    <Form.Item
                    label={
                        <span>
                            商品数量
                            <Tooltip title="不能超过用户购买的最大商品数量">
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>

                    }
                >
                        {getFieldDecorator('refund_spu_num', {
                            initialValue: refund_quantity,
                            rules: [{ required: false, pattern: new RegExp(/^[1-9]\d*$/, "g"), message: "请输入整数" }],
                        })(<Input type="number" onBlur={(e) => {
                                 this.setState({
                                    refund_spu_num: e.target.value
                                })    

                        }}/>)}
                    </Form.Item>




                    <TextArea
                        value={refund_message_part}
                        placeholder="请输入管理员备注（客户不可见）"
                        onChange={(e) => {
                            this.setState({
                                refund_message_part: e.target.value
                            })
                        }}
                    />
                </Modal>




                 <Modal
                    title="确认退款"
                    visible={refundvisible}
                    placeholder="请输入管理员备注（客户不可见）"
                    onCancel={() => {
                        this.setState({
                            refundvisible: false,
                            //ItemId: "",
                            refund_message: ""
                        })
                    }}
                    onOk={() => {
 
                        OrderRefund(data.id, { refund_message }).then(res => {
 
                            

                            setTimeout(() => {
                                 this.setState({
                                    refundvisible: false
                                }, () =>  
                                    this.getDetail() 
                                ) 
                             }, 3000)



                             
                        }).catch(error => {
                            this.setState({ 
                                refundvisible: false
                            })
                        })



                    }}
                >
                    <TextArea
                        value={refund_message}
                        placeholder="请输入管理员备注（客户不可见）"
                        onChange={(e) => {
                            this.setState({
                                refund_message: e.target.value
                            })
                        }}
                    />
                </Modal>



                <Modal
                    title="GST 说明"
                    visible={this.state.gstVisible}
                    footer={null}
                    onOk={() => { 
                        
                    }}
                    onCancel={() => {
                        this.setState({ 
                            gstVisible: false
                        })
                    }} 
                    > 
                    <div style={{ display: "flex", alignItems: "flex-start", marginBottom: "10px" ,flexDirection: 'column' }} > 
                        <span>带<img src={gsthave}  style={{width:'15px',height:'15pxr'}} />的商品，价格含gst</span>
                        <span>带<img src={gstno}    style={{width:'15px',height:'15pxr'}} />的商品，价格不含gst</span>
                        <span>不带图标的商品，价格不存在gst</span>   
                    </div>  
                 </Modal> 


 

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}> 
                    { ( data.order_status == "wait_order_receiving"   )   &&  
                           <div> 
                                <Popconfirm title={intl.get('home.table.operation.btn.refuse')}
                                    style={{ marginRight: "5px" }}
                                    onConfirm={() => {
                                        putOrderStatus(data.id, { "status": 6 }).then(res => { 
                                            this.getDetail() 
                                        })
                                    }}> 
                                      <Button 
                                            type="primary"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => {
                                                this.setState({
                                                    //visible: true,
                                                    //ItemId: record.id
                                                })
                                                 
                                            }}
                                        >
                                            拒绝接单
                                        </Button> 
                                
                                 </Popconfirm>

                                <Popconfirm title={intl.get('home.table.operation.btn.isAgree')}
                                    style={{ marginRight: "5px" }}
                                    onConfirm={() => {
                                        putOrderStatus(data.id, { "status": 1 }).then(res => {
                                            this.getDetail() 
                                        }).catch(error => {
                                            console.log(error.response)
                                        })
                                    }}>
                                    
                                         
                                        <Button 
                                            type="primary"
                                            style={{ marginRight: "10px" }}
                                            onClick={() => {
                                                this.setState({
                                                    //visible: true,
                                                    //ItemId: record.id
                                                })
                                                 
                                            }}
                                        >
                                            确认接单
                                        </Button>
 
                                </Popconfirm>
                            </div>


                          
                     
                    }





                    { ( data.is_pay == 1  &&  data.order_status != 'refunded' )   && 
                         
                            <Button 
                                type="primary"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                     this.setState({
                                        refundvisible: true,
                                        //ItemId: record.id,
                                        //refund_message: ""
                                     })
                                }}
                            >
                                退款
                            </Button>
                     
                    }




                    { ( data.is_finish == 0  &&  data.order_status != 'refunded'  )   && 
                        <Popconfirm
                            title="确认完成订单？"
                            onConfirm={() => {
                                 confirmOrderOrder({ "unique_code": data.unique_code }).then(res => {
                                    this.getDetail() 
                                })
                            }}>
                            <Button
                                type="primary"
                                style={{ marginRight: "10px" }}
                                onClick={() => {
                                    //this.props.history.goBack()
                                }}
                            >
                                确认完成
                            </Button>
                        </Popconfirm>
                    }


                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.return')}
                        </Button>
                    </Popconfirm>

                </div>
            </div>
           

        );
    }
}

//export default withRouter(OrderDetail)

export default withRouter(Form.create()(OrderDetail))