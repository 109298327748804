export default {
  'nav.home': 'Home',
  'nav.user': 'Customer',
  'nav.orderManagement': 'Order',
  'nav.orderManagement.list': 'Orders',
  'nav.orderManagement.orderLine': 'Queuing orders',
  'nav.event': 'Deal',
  'nav.event.openSelected': 'Open featured',
  'nav.event.selected': 'Featured',
  'nav.tools': 'Tools',
  'nav.behavior': 'Behavior',
  'nav.business': 'Business',
  'nav.account': 'Account',
  'nav.behavior.MinimumOrderAmount': 'Minimum order amount',
  'nav.money': 'Bill',
  'nav.money.withdrawalRecord': 'Payout history',
  'nav.money.withdrawal': 'Payout',
  'nav.goodsManagement': 'Products',
  'nav.goodsManagement.goods': 'Product',
  'nav.goodsManagement.goods.newGood': 'New product',
  'nav.goodsManagement.goods.goodslist': 'Products',
  'nav.goodsManagement.goods.prompt': 'The allowed formats for product pictures are: JPG, JPEG, PNG. The uploaded image memory is now within 1m.',
  'nav.goodsManagement.goods.isdeletegoodslist': 'Archive',
  'nav.goodsManagement.classify': 'Category',
  'nav.goodsManagement.classify.addClassify': 'New category',
  'nav.goodsManagement.classify.classifyList': 'Category',

  'nav.goodsManagement.openSpecifications': ' Open product specifications',
  'nav.goodsManagement.specifications': 'Product specifications',
  'nav.goodsManagement.sku_goods': 'Sku',
  'nav.goodsManagement.coupon_goods': 'Coupon',
  'nav.goodsManagement.specifications.add': 'New specification',
  'nav.goodsManagement.itemspecifications': 'Commodity specification value',
  'nav.goodsManagement.itemspecifications.add': 'New specification item',

  'nav.goodsManagement.additional': 'Add-ons',
  'nav.goodsManagement.isitional': 'Attach add-ons?',
  'nav.goodsManagement.additional.add': 'New add-on group',
  'nav.goodsManagement.additional.list': 'Add-ons group',
  'nav.goodsManagement.itemadditional': 'Add-on items',
  'nav.goodsManagement.itemadditional.add': 'New add-on items',
  'nav.goodsManagement.itemadditional.list': 'All add-on items',
  'nav.goodsManagement.noAdditionalGoods': 'No add-ons',
  'nav.goodsManagement.rrp_cannot_price': 'The minimum quantity cannot be greater than the limit. The RRP price must be greater than the commodity price',
  'nav.goodsManagement.fill_in_price': 'Please fill in the price first!',
  'nav.goodsManagement.save_sku_first': 'Please save the product SKU first',
  'nav.goodsManagement.sku_null': 'Product SKU cannot be empty',

  'goods.list.goodsAnnex.name': 'name',
  'goods.list.goodsAnnex.name_horder': 'Please enter name',
  'goods.list.goodsAnnex.price': 'price',
  'goods.list.goodsAnnex.price_horder': 'Please enter price',
  'goods.list.goodsAnnexType.chooseNumError': 'The quantity of additional goods cannot be less than the minimum number of choices',
  'goods.list.goodsAnnexType.minOneError': 'Please select at least one add-on',

  'nav.goodsManagement.promotionalgoods': 'Deal',
  'nav.goodsManagement.promotionalgoods_name': 'Activity name',
  'nav.goodsManagement.promotionalgoods_name_error': 'Please enter the activity name',
  'nav.goodsManagement.couponOrder': 'Active Order',
  'nav.goodsManagement.promotionalOrder': 'Deal Order',
  'nav.goodsManagement.promotionalCoupon': 'Coupon Order',
  'nav.goodsManagement.ordinaryOrder': 'Product Order',
  'nav.goodsManagement.promotionalgoods.add': 'New Deal',
  'nav.goodsManagement.promotionalgoods.addTips': 'A maximum of 10 new events and 3 events can be launched each month',
  'nav.goodsManagement.promotionalgoods.chooseGoodsTips': 'The selected product is one that does not contain additional products and product specifications.',
  'nav.goodsManagement.promotionalgoods.list': 'Deal',
  'nav.goodsManagement.promotionalgoods.coupon': 'Coupon',
  'nav.goodsManagement.promotionalgoods.coupon_new': 'New coupon',
  'nav.goodsManagement.promotionalgoods.coupon_name': 'Coupon name',
  'nav.goodsManagement.promotionalgoods.coupon_count': 'Coupon count',
  'nav.goodsManagement.promotionalgoods.coupon_receive_count': 'Receive count',
  'nav.goodsManagement.promotionalgoods.coupon_use_count': 'Use count',
  'nav.goodsManagement.promotionalgoods.coupon_remaining_count': 'Remaining count',
  'nav.goodsManagement.promotionalgoods.coupon_total_count': 'Total count',
  'nav.goodsManagement.promotionalgoods.discount_type': 'Promotion category',
  'nav.goodsManagement.promotionalgoods.quota_discount': 'Fixed amount discount',
  'nav.goodsManagement.promotionalgoods.percent_discount': 'Percent discount',
  'nav.goodsManagement.promotionalgoods.coupon_type': 'Coupon type',
  'nav.goodsManagement.promotionalgoods.coupon_discount_type': 'Discount type',
  'nav.goodsManagement.promotionalgoods.coupon_type_goods': 'Product coupon',
  'nav.goodsManagement.promotionalgoods.coupon_type_shop': 'Shop coupon',
  'nav.goodsManagement.promotionalgoods.coupon_type_poster': 'Poster',
  'nav.goodsManagement.promotionalgoods.coupon_style': 'Coupon style',
  'nav.goodsManagement.promotionalgoods.coupon_style_default': 'Default',
  'nav.goodsManagement.promotionalgoods.coupon_style_self': 'Custom',
  'nav.goodsManagement.promotionalgoods.coupon_style_img': 'Custom picture',
  'nav.goodsManagement.promotionalgoods.marketing_type': ' Marketing type',
  'nav.goodsManagement.promotionalgoods.external_coupon': 'Global coupon',
  'nav.goodsManagement.promotionalgoods.coupon_detail': 'Coupon detail',
  'nav.goodsManagement.promotionalgoods.coupon_number': 'Ticker Number',

  'nav.goodsManagement.promotionalgoods.isBuy': 'Is Buy',
  'nav.goodsManagement.promotionalgoods.during_the_event': 'During the event',
  'nav.goodsManagement.promotionalgoods.price_type': 'Price type',
  'nav.goodsManagement.promotionalgoods.discount_on_fixed': 'Dollars', //Discount
  'nav.goodsManagement.promotionalgoods.discount_on_percentage': 'As percentage off(%)',
  'nav.goodsManagement.promotionalgoods.time': 'Promotion time',
  'nav.goodsManagement.promotionalgoods.term_of_validity': 'Period',
  'nav.goodsManagement.promotionalgoods.timeError': 'Please select promotion time',
  'nav.goodsManagement.sales_promotion': 'Promotions',
  'nav.goodsManagement.promotionalgoods.goodsImg': 'Active picture',
  'nav.goodsManagement.promotionalgoods.goodsImgError': 'Please upload picture',
  'nav.goodsManagement.promotionalgoods.activity_service_rate': 'Event service fee',
  'nav.goodsManagement.promotionalgoods.order_fixed_fee': 'Fixed service charge',
  'nav.goodsManagement.promotionalgoods.activity_service_rate_money': 'Service fee settlement',
  'nav.goodsManagement.promotionalgoods.order_fixed_fee': 'Fixed fee',
  'nav.goodsManagement.promotionalgoods.stock': 'Quantity',
  'nav.goodsManagement.promotionalgoods.total_inventory': 'Total inventory',
  'nav.goodsManagement.promotionalgoods.Remaining_inventory': 'Stocks remaining',
  'nav.goodsManagement.promotionalgoods.stockEnter': 'please enter stock',
  'nav.goodsManagement.promotionalgoods.stock_error': 'Cannot less than current stock',
  'nav.goodsManagement.promotionalgoods.integer': 'Please enter an integer greater than 1',
  'nav.goodsManagement.promotionalgoods.sale_quantity': 'Promotion quantity',
  'nav.goodsManagement.promotionalgoods.quota': 'Quota',
  'nav.goodsManagement.promotionalgoods.full_price': 'Price-break discount',
  'nav.goodsManagement.promotionalgoods.full_price_tips': 'No entry is no threshold.',
  'nav.goodsManagement.promotionalgoods.full_price_error_tips': 'The full or reduced amount is an integer or up to two decimal places.',
  'nav.goodsManagement.promotionalgoods.full_price_error': 'The full reduction amount cannot be less than the sum of the discount amount and the minimum payment amount',
  'nav.goodsManagement.promotionalgoods.total_quota': 'The maximum quantity that each user can purchase',
  'nav.goodsManagement.promotionalgoods.coupon_total_quota': 'The maximum amount each user can receive',
  'nav.goodsManagement.promotionalgoods.select_goods_word_tips': 'Enter more than 3 characters to search automatically',
  'nav.goodsManagement.promotionalgoods.expire_day': 'Deal Orders effective days',
  'nav.goodsManagement.promotionalgoods.discount_price': 'Price after discount',
  'nav.goodsManagement.promotionalgoods.price': 'Price',
  'nav.goodsManagement.promotionalgoods.selectError': 'Please select a product',
  'nav.goodsManagement.promotionalgoods.quotaError': 'Minimum quota is 1',
  'nav.goodsManagement.promotionalgoods.discountPrice': 'Discounted price must less than normal price.',
  'nav.goodsManagement.promotionalgoods.minimumOrder': 'Discounted price must highter than minimum order amount.',
  'nav.goodsManagement.promotionalgoods.orderValidity': 'Order validity',
  'nav.goodsManagement.promotionalgoods.max_quantity': 'max quantity',
  'nav.goodsManagement.promotionalgoods.Single_max_quantity': 'Maximum quantity of single purchase',
  'nav.goodsManagement.promotionalgoods.min_quantity': 'min quantity',
  'nav.goodsManagement.promotionalgoods.Single_min_quantity': 'Minimum quantity of single purchase',
  'nav.goodsManagement.promotionalgoods.inside': 'In shop',
  'nav.goodsManagement.promotionalgoods.external': 'Global',
  'nav.goodsManagement.promotionalgoods.mysterious': 'Mysterious',
  'nav.goodsManagement.promotionalgoods.operationType_tips': 'Please choose operation type',
  'nav.goodsManagement.promotionalgoods.operationType': 'Operation type',
  'nav.goodsManagement.promotionalgoods.on-line': 'On-line',
  'nav.goodsManagement.promotionalgoods.Offline': 'Offline',
  'nav.goodsManagement.coupon.detail_tips': 'After receiving the coupon, you can show the coupon or order at the store, and the clerk can confirm it. The right to interpret the coupon belongs to the $shop_name.',
  'nav.goodsManagement.promotionalgoods.detail_tips': 'The right to interpret the deal belongs to the $shop_name.',


  'nav.goodsManagement.promotionalgoods.displayOnly': '(Price is for display only.)',
  'nav.goodsManagement.promotionalgoods.OffTheShelf': 'Launched',
  'nav.goodsManagement.promotionalgoods.OffTheShelf_tips': 'After the activity is removed from the shelf, it cannot be put on the shelf again',
  'nav.goodsManagement.promotionalgoods.InputError': 'Input error!',
  'nav.goodsManagement.promotionalgoods.EnterClosed': 'Please enter "confirm" in the input box to confirm',
  'nav.goodsManagement.promotionalgoods.end': 'Has ended',
  'nav.goodsManagement.promotionalgoods.Terminated': 'Terminated',
  'nav.goodsManagement.promotionalgoods.NormalSales': 'In progress',
  'nav.goodsManagement.promotionalgoods.select_products': 'Please select products first',
  'nav.goodsManagement.promotionalgoods.1_person_required': '1 person is required',
  'nav.goodsManagement.promotionalgoods.2_person_required': '2 person is required',
  'nav.goodsManagement.promotionalgoods.price_less_than_1': 'The price cannot be less than $1',
  'nav.goodsManagement.promotionalgoods.two_people_cannot_equal_one_person': 'The bargaining price of two people cannot be greater than or equal to one person',
  'nav.goodsManagement.promotionalgoods.three_people_cannot_equal_two_person': 'The bargaining price of three people cannot be greater than or equal to two person',
  'nav.goodsManagement.promotionalgoods.enter_integer': 'please enter an integer',
  'nav.goodsManagement.promotionalgoods.quota_cannot_stock': 'Limit cannot be greater than inventory',
  'nav.goodsManagement.promotionalgoods.maximum_cannot_limit': 'The maximum quantity cannot be greater than the limit',
  'nav.goodsManagement.promotionalgoods.minimum_cannot_maximum': 'The minimum quantity cannot be greater than the maximum quantity',
  'nav.goodsManagement.promotionalgoods.minimum_cannot_limit': 'The minimum quantity cannot be greater than the limit',
  'nav.goodsManagement.promotionalgoods.detail': 'Promotional detail',
  'nav.goodsManagement.cutgoods.BargainingNumber': 'Bargaining number',
  'nav.goodsManagement.cutgoods.isOpen': 'Open or not',
  'nav.goodsManagement.cutgoods': 'More Discount list',
  'nav.goodsManagement.cutgoodsType': 'More Discount',
  'nav.goodsManagement.openCutGoodsType': 'Open more Discount',
  'nav.goodsManagement.openAward': 'Open award',
  'nav.goodsManagement.OpenAdditionalItems': 'Open additional items',
  'nav.goodsManagement.OpenInStoreItems': 'Time of order opening',
  'nav.goodsManagement.send_receipt': 'Compulsory Mail',
  'nav.goodsManagement.send_receipt_tips': 'The user must fill in the email on the order page',
  'nav.goodsManagement.OpenUserAddress': 'Open user address',
  'nav.goodsManagement.Forced_open': 'Forced open',
  'nav.goodsManagement.Forced_open_tips': 'In store consumption and in store self collection will not be displayed (h5 terminal, the tab of in store consumption and in store self collection will not be displayed)',
  'nav.goodsManagement.OpenHoliday': 'Open public holidays',
  'nav.goodsManagement.OpenOrderReceiving': 'Open order receiving',
  'nav.goodsManagement.Promotion': 'Promotion',
  'nav.goodsManagement.isPromotion': 'Is promotion',
  'nav.goodsManagement.coupon_detail': 'Ticket',
  'nav.goodsManagement.coupon_user': 'Customer',
  'nav.goodsManagement.coupon_date': 'Collection time',
  'nav.goodsManagement.coupon_use_date': 'Usage time',
  'nav.goodsManagement.coupon_barcode': 'Barcode',
  'nav.goodsManagement.coupon_ticket_unique_code': 'Ticket code',

  'nav.delivery': 'Delivery',
  'nav.delivery.discount': 'Delivery discounts',
  'nav.delivery.discount.new': 'New delivery discounts',
  'nav.delivery.discount.list': ' All delivery discounts',
  'nav.delivery.area': 'Delivery area',
  'nav.delivery.area.add': 'New delivery area',
  'nav.delivery.area.list': 'All delivery area',
  'nav.delivery.packingCharge': 'Packing surcharge',
  'nav.delivery.packingCharge.add': 'New packing surchrage',
  'nav.delivery.packingCharge.list': 'All packing surcharge',
  'nav.delivery.set': 'Delivery methods',
  'nav.extension': 'Marketing',
  'nav.extension.tag': 'Tags',
  'nav.extension.tag.new': 'New tags',
  'nav.extension.tag.list': 'All tags',
  'nav.extension.carousel': 'Slider',
  'nav.extension.carousel.add': 'New slider',
  'nav.extension.carousel.list': 'All slider',
  'nav.extension.dishRecommendation': 'Featured dishes',
  'nav.extension.dishRecommendation.add': 'New featured dishes',
  'nav.extension.dishRecommendation.list': 'All featured dishes',
  'nav.extension.partnerNav': 'Navigation',
  'nav.extension.partnerNav.list': 'All navigations',
  'nav.extension.groupRed': 'Group lucky pockets',
  'nav.extension.groupRed.get': 'Apply for lucky pockets',
  'nav.extension.groupRed.list': 'All lucky pockets',
  'nav.setting': 'Settings',
  'nav.settingMe': 'Me',
  'nav.setting.base': 'Settlement Information',
  'nav.setting.password': 'Change password',
  'nav.setting.password.password': 'New password',
  'nav.setting.password.confirm': 'Confirm New password',
  'nav.setting.password.error': 'Passwords entered mismatch',
  'nav.setting.management': 'Staff',
  'nav.setting.businessHours': 'Business Hours',
  'nav.setting.is_rest': 'Rest or not',
  'nav.setting.businessHoursError': 'format error',
  'nav.setting.holiday_business': 'Open on holidays',
  'nav.setting.public_holidays': 'Public holidays',
  'nav.setting.holidays': 'Holidays',
  'nav.setting.comment': 'Comment',
  'nav.setting.self_holidays': 'Customize holidays',
  'nav.setting.public_holidays_detail': 'Detail',
  'nav.setting.public_holidays_date': 'Start date',
  'nav.setting.select_public_holidays': 'Please select a holiday',
  'nav.setting.businessHours.isOpenOnHoliday': 'Open on holidays',
  "nav.setting.businessHours.week1": 'Monday',
  "nav.setting.businessHours.week2": 'Tuesday',
  "nav.setting.businessHours.week3": 'Wednesday',
  "nav.setting.businessHours.week4": 'Thursday',
  "nav.setting.businessHours.week5": 'Friday',
  "nav.setting.businessHours.week6": 'Saturday',
  "nav.setting.businessHours.week7": 'Sunday',
  "nav.setting.businessHours.alreadyExists": 'Public Holiday is already set.',
  'nav.setting.notice': 'Notice board',
  'nav.setting.notice.add': 'New notice board',
  'nav.setting.notice.list': 'All notice board',
  'nav.setting.notice.system': 'System notice board',
  'nav.setting.seat': 'Table number',
  'nav.setting.seat.add': 'New table number',
  'nav.setting.seat.list': 'All table number',
  'nav.setting.seat.open_the_sign': 'Enable table stands?',
  'nav.setting.freight': 'Freight',
  'nav.setting.freight.add': 'New freight',
  'nav.setting.SiteLocation': 'Location',
  'nav.setting.SiteSuburb': 'Suburb',
  'nav.setting.lang': 'Language',
  'nav.setting.lang.list': 'All languages',
  'nav.setting.printer': 'Printers',
  'nav.setting.printerList': 'All printers',
  'nav.setting.printerBrand': 'Printer brand',
  'nav.setting.printerType': 'Printer type',
  'nav.setting.printerName': 'Printer name',
  'nav.setting.printerDefaultName': 'Printer default name',
  'nav.setting.printerAddTime': 'Printer added at:',
  'nav.setting.printerSn': 'Printer ID',
  'nav.setting.printerSecretKey': 'Printer secret key',
  'nav.setting.automaticPrinting': 'Automatic printing',
  'nav.setting.openPrinter': 'Printer',

  'nav.download.app_download': 'Auepartner APP',
  'nav.download.merchant': 'Merchant Client',

  'nav.CostFlow.other': 'Other',
  'nav.CostFlow.order': 'Order',
  'nav.CostFlow.income': 'Income',
  'nav.CostFlow.expenditure': 'Expense',
  'nav.CostFlow.name': 'Name',
  'nav.CostFlow.cost': 'Amount',
  'nav.CostFlow.costType': 'Amount type',
  'nav.CostFlow.feeType': 'Fee type',
  'nav.PaySet': 'Payment settings',
  'nav.PayRoute': 'Payment method',
  'nav.CostFlow.other.billingDetails': 'Billing details',

  'nav.album': 'Shop gallery',
  'nav.album.img': 'Shop gallery picture',
  'nav.open_promotion_payment': 'Open promotion independent payment',
  'nav.RefundInstructions': 'Refund Policy',
  'nav.RefundInstructions_title': 'Instructions for issuing refunds for paid orders:',
  'nav.RefundInstructions_1': '1.	Funds will be returned to customer’s digital wallet in the system, if: a.	the order has been paid for, but not been accepted by the restaurant over the order accept time limit,b.	the order has been paid for and accepted by the restaurant, but the service has not been provided over the service time limit. The order accept time limit and service time limit is set by restaurant.',
  'nav.RefundInstructions_2': '2.	Refunded orders will be recognised by the system automatically and will display these orders in Business Portal > Wallet > All page. All of these will also reflect on the settlement payment for the next month.',
  'nav.RefundInstructions_3': '3.	Any orders paid using all or part of funds in customers digital wallet, the payment settlement will only reflect the price difference and will be paid in the next month settlement.',
  'nav.RefundInstructions_4': '4.	Any customers wishing to get refund via third-party payment platforms or manual bank transfer will be serviced by Auedian customer service representative. Restaurants will not be involved.a.	Any dispute raised by issuing refund via third-party payment platforms or manual bank transfer, conducted by restaurant, will not be covered by Auedian, andb.	Any legal responsibilities and financial loss resulted from such behaviours will be covered by restaurant and the customer. Auedian may also pursuit if such behaviours are also resulted in financial loss to Auedian.',
  // 'nav.RefundInstructions_5': '5、如果C端客户申请第三方支付渠道或线下手动的银行转账退款到个人账号，商家无需为他服务，而将由系统管理员为他服务，无需商家介入。',
  // 'nav.RefundInstructions_6': '6、系统不承认商家与C端客户进行的第三方支付渠道或线下手动的银行转账退款到个人账号的行为。',
  'nav.independent_settlement': 'The merchant does not support independent settlement',

  'nav.bindPay': 'Payment',
  'nav.plugin': 'Plug-in',
  'nav.plugin.toStore': 'In-store',
  'nav.plugin.currency': 'General',
  'nav.plugin.deliveryPlug': 'delivery Plug',
  'nav.plugin.third_party_video_platform': 'Third party video platform',
  'nav.package': 'Plan',
  'nav.package.list': 'Plan',
  'nav.package.currentPackage': 'Current Plan',
  'nav.package.packageLog': 'Package change records',
  'nav.package.packageLog.list': 'All change records',
  'nav.subscription': 'My subscription',
  'nav.myOrders': 'My Orders',
  'nav.datastatics': 'Dashboard',

  'home.phoneNumber': 'Phone number',
  'home.search': 'Search',
  'home.placehorder': 'Enter order number to search',
  'home.placehorder_p': 'Enter phone number to search',

  'home.table.uuid': 'Customer Unique ID',
  'home.table.ordernumber': 'Order number',
  'home.table.orderprice': 'Order total($)',
  'home.table.price': 'Payment total',
  'home.table.spu_discount_price': 'Subtotal',
  'home.table.order_price': 'Order total',
  'home.table.order_package': 'Package information',
  'home.table.order_coupon': 'Coupon information',
  'home.table.order_package_id': 'Package id',
  'home.table.use_user_money': 'Credits from wallet',
  'home.table.use_user_coupon': 'Coupon deduction',
  'home.table.bank': 'Fees for payment channel',
  'home.table.totalprice': 'Actual payment amount',
  'home.table.partner_charge_money': 'Order service fees',
  'home.table.partner_charge_rate': 'Percentage of order service fees',
  'home.table.partner_fixed_fee': 'Fixed amount of order service fees',
  'home.table.change': '(Change)',
  'home.table.payee': 'payee',
  'home.table.payment_method': 'Payment method',
  'home.table.time': 'Paid at',
  'home.table.dissipate': 'Dissipate',
  'home.table.source': 'Source',
  'home.table.arrivalTime': 'Arrival time',
  'home.table.ordertime': 'Order time',
  'home.table.scanning_mode': 'Scanning mode',
  'home.table.orderconfirmationtime': 'Order confirmed at',
  'home.table.confirm.admin': 'Order confirmed by',
  'home.table.redpack': 'Lucky pockets',
  'home.table.delivery': 'Delivery fee',
  'home.table.packingCharge': 'Packing surcharge',
  'home.table.service': 'Service fee',
  'home.table.goods': 'Products',
  'home.table.status': 'Payment status',
  'home.table.way': 'Fulfillment',
  'home.table.address': 'Customer address',
  'home.table.useStatus': 'Use status',
  'home.table.operation': 'Operation',
  'home.table.isShowOrder': "Display the user's recent purchase records",
  'home.table.isShowOrder_tips': 'Used by store front desk users',
  'home.table.status.name_y': 'Paid',
  'home.table.status.name_n': 'Unpaid',

  'home.table.order_refund.information': 'Refund information',
  'home.table.order_refund.name': 'Name',
  'home.table.order_refund.bank': 'Bank Name',
  'home.table.order_refund.account': 'Bank account',
  'home.table.order_refund.abn': 'ABN',
  'home.table.order_refund.bsb': 'BSB',
  'home.table.order_refund.price': 'Total withdrawal',
  'home.table.order_refund.message': 'Withdrawal message',
  'home.table.order_refund.type': 'Withdrawal type',
  'home.table.order_refund.img': 'Bill picture',

  'home.table.way.name_d': 'In store',
  'home.table.way.name_s': 'Delivery',
  'home.table.way.name_w': 'take-out',
  'home.table.way.name_t': 'Consume in Store',
  'home.table.way.name_z': 'Take-away',

  'home.table.useStatus.name_y': 'Used',
  'home.table.useStatus.name_n': 'Not used',
  'home.table.useStatus.all': 'All',
  'home.table.useStatus.unpaid': 'Unpaid',
  'home.table.useStatus.paid': 'Paid',
  'home.table.useStatus.unfinish': 'Pending',
  'home.table.useStatus.completed': 'Completed',
  'home.table.useStatus.cancelled': 'Cancelled',
  'home.table.reject_the_order': 'Reject this order',
  'home.table.confirm_reject': 'Do you really want to reject this order? Input "DENY" to confirm.',

  'home.table.useStatus.un_pay': 'un_pay',
  'home.table.useStatus.wait_order_receiving': 'wait_order_receiving',
  'home.table.useStatus.un_order_receiving': 'un_order_receiving',
  'home.table.useStatus.for_consumption': 'for_consumption',
  'home.table.useStatus.finished': 'finished',
  'home.table.useStatus.canceled': 'canceled',
  'home.table.useStatus.disabled': 'Expired',
  'home.table.useStatus.rejected': 'rejected',
  'home.table.useStatus.refunded': 'refunded',
  'home.table.useStatus.refunded_detail': 'Refund detail',
  'home.table.useStatus.refunded_amount': 'Refund amount',
  'home.table.useStatus.refunded_to': 'Refund to',
  'home.table.useStatus.refunded_time': 'Refund time',
  'home.table.useStatus.bank_card': 'Bank card',
  'home.table.useStatus.wallet': 'Wallet',
  'home.table.useStatus.orderReply': 'Order reply',
  'home.table.useStatus.Reply': 'Reply',
  'home.table.useStatus.isBuy': 'Is buy',
  'home.table.useStatus.exportOrder': 'Export order',

  'home.table.operation.btn.file': 'File',
  'home.table.operation.btn.delete': 'Delete',
  'home.table.operation.btn.recover': 'Recover',
  'home.table.operation.btn.view': 'View',
  'home.table.operation.btn.refuse': 'Reject',
  'home.table.operation.btn.agree': 'Accept',
  'home.table.operation.btn.isAgree': 'Is accept?',
  'home.table.operation.btn.isOrder': 'Confirm order',
  'home.table.operation.btn.print': 'Print',
  'home.table.operation.btn.printHorder': 'Print out order info?',
  'home.table.operation.btn.file_horder': 'Do you really want to file',
  'home.table.operation.btn.horder': 'Do you really want to delete?',
  'home.table.operation.btn.recoverHorder': 'Do you really want to recover?',
  'home.table.placehorder': 'No order yet.',

  'user.table.name': 'Username',
  'user.table.header': 'Avatar',
  'user.table.nickname': 'Nickname',
  'user.table.phone': 'Phone number',
  'user.table.user': 'Customer',
  'user.table.avatar': 'Avatar',
  'user.table.growup': 'Points',
  'user.table.money': 'Balance',
  'user.table.share': 'Share',

  'order.table.remark': 'Note',
  'order.table.type': 'order type',
  'order.table.tixian': 'Payout status',
  'order.table.address': 'Address',
  'order.table.tixian.name_y': 'Payout finished',
  'order.table.tixian.name_n': 'Available for Payouts',

  'orderline.table.price': '($)Order total',
  'orderline.table.payStatus': 'Payment status',
  'orderline.table.useStatus': 'Order status',
  'orderline.table.operation': 'Operation',

  'withdrawLog.table.name': 'Payout applicator',
  'withdrawLog.table.money': 'Payout amount',
  'withdrawLog.table.order': 'Orders involved',
  'withdrawLog.table.result': 'Payment status',
  'withdrawLog.table.operation': 'Operation',

  'withdrawLog.table.operation.btn': 'View',
  'withdrawLog.table.result.ycl': 'Processed',
  'withdrawLog.table.result.juj': 'Declined',
  'withdrawLog.table.result.check': 'Under review',

  'cashwithdrawal.form.money': 'Total amount available for payouts',
  'cashwithdrawal.form.bank': 'Bank name',
  'cashwithdrawal.form.bank.placehorder': 'Enter bank name here',
  'cashwithdrawal.form.account': 'Account name',
  'cashwithdrawal.form.account.placehorder': 'Enter account name here',
  'cashwithdrawal.form.bsb': 'BSB number',
  'cashwithdrawal.form.num': 'Account number',
  'cashwithdrawal.form.num.placehorder': 'Enter account number here',
  'cashwithdrawal.form.submit': 'Submit',
  'cashwithdrawal.form.bsb.placehorder': 'Enter BSB number here',
  'cashwithdrawal.form.abn.placehorder': 'Enter ABN number here',

  'cashwithdrawal.form.ok': 'Agree',
  'cashwithdrawal.form.no': 'Decline',

  'cashwithdrawal.form.orderNo': 'Request payout for this orders',
  'cashwithdrawal.form.handle': 'Process notes',
  'cashwithdrawal.form.img': 'Images of receipts',

  'seat.table.name': 'Table name',
  'seat.table.min': 'Min. people',
  'seat.table.max': 'Max. people',
  'seat.table.tablenumber': 'Number of tables',
  'seat.table.describe': 'Description',
  'seat.table.number': 'Table number',
  'seat.table.open_seat': 'Open Table number',
  'seat.table.operation': 'Operation',

  'seat.table.btn.return': 'Go back',
  'seat.table.btn.cancel': 'Cancel',
  'seat.table.btn.save': 'Save',

  'seat.table.btn.cancel.placeHorder': 'Do you want to cancel?',
  'seat.table.btn.edit': 'Edit',
  'seat.table.please': 'Enter table description',

  'seat.table.name.pleacehorder': 'Enter table name',
  'seat.table.num.pleacehorder': 'Enter table capicity',
  'seat.table.table.pleacehorder': 'Enter number of tables',

  'announcementList.table.title': 'Table name',
  'announcementList.table.time': 'Time',
  'announcementList.table.sort': 'Table order',
  'announcementList.table.error': 'Please enter table name',

  'admin.table.name': 'Table manager name',
  'admin.table.name_tips': "The administrator's name cannot be consistent with the merchant's user name",
  'admin.table.no': 'Table manager ID',
  'admin.table.new': 'New',
  'admin.table.newA': 'New table manager',
  'admin.table.aname': 'Table manager name：',
  'admin.table.passward': 'password：',
  'admin.table.passward_error': 'Please keep the password 6-16 digits long',
  'setup.form.user': 'Username',
  'setup.form.licence': 'Licence',
  'setup.form.no_licence': 'Not authorized yet.',
  'setup.form.Choose': 'Choose',
  'setup.form.apply': 'Apply',
  'setup.form.title': 'Shop name',
  'setup.form.shop': 'Shop',
  'setup.form.phone': 'Phone number',
  'setup.form.otherPhone': 'Alternative phone number',
  'setup.form.email': 'Email',
  'setup.form.email_enter': 'Please enter email',
  'setup.form.code_enter': 'Please enter verification code',
  'setup.form.orderemail': 'Email to receive order notifications',
  'setup.form.orderemail_tips': 'Multiple mailboxes are separated by "," in English',
  'setup.form.email.placehorder': 'Invalid email format.',
  'setup.form.abstract': 'Shop description',
  'setup.form.ShowAddress': 'Show address',
  'setup.form.domain': 'Domain',
  'setup.form.time': 'Shop registered at',
  'setup.form.info': 'Comment',
  'setup.form.license': 'Business certificate',
  'setup.form.licensefile': 'Food Authority Certificate',
  'setup.form.wechatfile': 'WeChat qrCode',
  'setup.form.logo': 'Shop Logo',
  'setup.form.ercode': 'Wechat mini-app code',
  'setup.form.img': 'Shop banner',
  'setup.form.recruitmentImg': 'Shop banner images',
  'setup.form.recruitmentVideo': 'Shop banner videos',
  'setup.form.recruitmentVideo.tips': 'The maximum video size is no more than 5m, and the horizontal size is 16:9',
  'setup.form.isclose': 'Open the store temporarily',
  'setup.form.prepareTime': 'Stock up time',
  'setup.form.isOpenPrepareTime': 'Is open stock up time',
  'setup.form.openDelivery': 'Order delivery',
  'setup.form.delivery': 'Delivery',
  'setup.form.openFreight': 'Order Delivery Fee',
  'setup.form.full_delivery_free': ' Full free delivery',
  'setup.form.is_logistics': 'Merchant self-delivery',
  'setup.form.stop_order_time': 'Stop order time',
  'setup.form.order_time': 'Order time',
  'setup.form.order_expire_header': 'Order expiration days',
  'setup.form.key_header': 'Text variable',
  'setup.form.Consumption_variable': 'Consumption variable',
  'setup.form.Self_extracting_variable': 'Self extracting variable',
  'setup.form.Table_variable': 'Table variable',
  'setup.form.order_expire': 'Validity period of commodity order',
  'setup.form.activity_order_expire': 'Active order validity',
  'setup.form.many_help_order_expire': 'discount+ order Validity',
  'setup.form.order_expire_tip': 'The minimum expiration days are 15 days and the maximum is 180 days',
  'setup.form.activity_order_expire_tip': 'The minimum number of overdue days is 1 day and the maximum is 90 days',
  'setup.form.isAutoPrint': 'Receipt auto-print',
  'setup.form.tostore': 'Enable Take-away',
  'setup.form.isDistribution': 'Enable Delivery',
  'setup.form.isAlertAnnouncement': 'Enable Notice Board',
  'setup.form.isAlertMenuImage': 'Enable store banner',
  'setup.form.money': 'Payout quota',
  'setup.form.way': 'Payment method',
  'setup.form.way.all': 'Enable all',
  'setup.form.way.outline': 'In store payment',
  'setup.form.way.inline': 'Pay online',
  'setup.form.logo.placehorder': 'Please upload logo',
  'setup.form.info.placehorder': 'Please fill in all required',
  'setup.form.streets': 'Street',
  'setup.form.suburbs': 'Suburb',
  'setup.form.abn': 'ABN',
  'setup.form.company': 'company',
  'setup.form.region': 'Country',
  'setup.form.salesArea': 'BD Area',
  'setup.form.salesArea_small': 'Sales area',
  'setup.form.state': 'State',
  'setup.form.Merchant_service_fee': 'Merchant Deal Orders service fee',
  'setup.form.Merchant_general_service_fee': 'General order service charge of merchants',

  'newtag.form.name': 'Tag name',
  'newtag.form.name.horder': 'Enter tag name',
  'newtag.form.id': 'Select to product',
  'newtag.form.id.horder': 'Enter product ID',
  'newtag.form.sort': 'Order',

  'ad.form.title': 'Ad title',
  'ad.form.isShowTitle': 'Show Ad title',
  'ad.form.content': 'Ad contents',
  'ad.form.isShowContent': 'Show ad contents',
  'ad.form.content.placehorder': 'Enter ad title',
  'ad.form.title.placehorder': 'Enter ad contants',
  'ad.form.seq': 'Order',
  'ad.form.img': 'Ad images',
  'ad.form.type': 'Ad type',
  'ad.form.style': 'Navigator button color',
  'ad.form.selectgoods': 'Select to products',
  'ad.form.selecttype': 'Select to categories',
  'ad.form.goods': 'Products',
  'ad.form.cate': 'Category',
  'ad.table.link': 'Ad ID',

  'menu.form.logo': 'Shop logo',
  'menu.form.id': 'Product ID',
  'menu.form.seq': 'Order',
  'menu.form.img': 'Menu picture',

  'red.table.id': 'Customer ID',
  'red.table.no': 'Lucky pocket number',
  'red.table.money': 'Lucky pocket amount',
  'red.table.time': 'received at',

  'red.table.redname': 'Lucky pocket name',
  'red.table.activeMoney': 'Total amount',
  'red.table.activenum': 'Total shares',
  'red.table.activeTotal': 'Total amount',
  'red.table.status': 'Status',
  'red.table.status.dis': 'Disable',
  'red.table.status.en': ' Enabled',
  'red.table.log': 'Claim records',
  'red.table.activebalance': 'Lucket pocket balance',
  'red.table.currentTotal': 'Current total amount',
  'red.table.currentTotalNum': 'Current total shares',
  'red.table.balanceNum': 'Shares remaining',

  'discount.form.discount': 'Discount',
  'discount.form.discount.pleasehorder': 'Enter discount',
  'discount.form.way': 'Discount type',
  'discount.form.way.placehorder': 'Enter discount type',
  'discount.form.free': 'Full free order price',
  'discount.form.othermoney': 'Apply packing surcharge',

  'pack.form.name': 'Packing surcharge name',
  'pack.form.name.pleasehorder': 'Enter packing surcharge name',
  'pack.form.seq': 'Order',
  'pack.form.price': 'Price($)',
  'pack.form.opt': 'Operation',

  'suburbs.table.suburbs': 'Suburb',
  'suburbs.table.price': 'Price($)',

  'goods.list.name': 'Product name',
  'goods.list.name.horder': 'Enter product name',
  'goods.list.name.horder.max': 'The number of words exceeds the maximum limit',
  'goods.list.price': 'Product price',
  'goods.list.rrp_price': 'RRP',
  'goods.list.rrp_price_tips': 'Suggested retail price',
  'goods.list.price.error': 'Please use the same price as default SKU price.',
  'goods.list.price.horder': 'Enter product price',
  'goods.list.type': 'Product category',
  'goods.list.classification': 'Choose product category',
  'goods.list.sort': 'Priority',
  'goods.list.unit_number': 'Custom number',
  'goods.list.sale_quantity': 'Sale quantity',
  'goods.list.attribute': 'Attribute',
  'goods.list.isOk': 'Launched',
  'goods.list.isShow': 'Show in-Shop',
  'goods.list.disableLanguage': 'Activate language',
  'goods.list.is_gst': 'Include gst',
  'goods.list.img': 'Images',
  'goods.list.img.horder': 'Choose images',
  'goods.list.selected': 'Featured',
  'goods.list.selected.maxselect': '* Display 5 featured items at most',
  'goods.list.isspeci': 'Enable specifications',
  'goods.list.abstract': 'Product shop description',
  'goods.list.info': 'Comment',
  'goods.list.speciname': 'Specification name',
  'goods.list.speciname_tips': 'Adjust the specification value, and the SKU price will be reset',

  'goods.list.sku': 'SKU price($)',
  'goods.list.spei': 'Choose specification',
  'goods.list.good': 'Product SKU',

  'goods.list.addtype': 'New product type',
  'goods.list.addtype.horder': 'New product type',

  'goods.list.goodsAnnexType': 'Product add-ons',
  'goods.list.goodsAnnexType.name': 'Add-on group name',
  'goods.list.goodsAnnexType.required': 'Required',
  'goods.list.goodsAnnexType.check': 'Enable multi-selection',
  'goods.list.goodsAnnexType.max_select': 'Max. numebr of selection',
  'goods.list.goodsAnnexType.min_select': 'Min. number of selection',
  'goods.list.goodsAnnexType.min_select_error': 'Max. must greater than min. number of selection',
  'goods.list.goodsAnnexType.max_select_error': 'Maximum selected cannot greater than selected add-ons.',
  'goods.list.goodsAnnexType.max_count': 'Maximum qantity',
  'goods.list.goodsAnnexType.set_default': 'Default',
  'goods.list.goodsAnnexType.must_default': 'Forced default',
  'goods.list.goodsAnnexType.must_default.error': 'Please choose required option',
  'goods.list.goodsAnnexType.set_default.error': 'Please enable multi-selection',
  'goods.list.goodsAnnexType.set_default_num.error': 'Default selected cannot more than maximum selection.',
  'goods.list.goodsAnnexType.must_default_num.error': 'The number of mandatory default entries cannot exceed the minimum number of entries selected',
  'goods.list.goodsAnnexType.goodsAnnex_price.error': 'Please enter an amount with up to two decimal places.',

  'category.list.name': 'Category name',
  'category.list.img': 'Category image',
  'category.list.Icon': 'Category icon',
  'category.list.name.horder': 'Enter category name',
  'category.list.sort': 'Order',
  'category.list.del': 'Delete',
  'category.list.isShow': 'Show category',
  'category.list.isAlert': 'Enable popup',
  'category.list.del.horder': 'Do you want to remove this category? Any products under this category may be disabled.',

  'gooditem.table.name': 'Product specification name',
  'gooditem.table.name.horder': 'Enter product specification name',
  'gooditem.table.id': 'Product specification ID',
  'goodsArr.form.name': 'Product attribute',
  'goodsArr.form.name.plachordr': 'Enter product attribute',
  'goodsArr.form.type': 'Choose product type',

  'bindpay.removeBtn': 'Unbind',
  'bindpay.card': 'Card details',
  'bindpay.bindBtn': 'Bind payment',

  'package.name': 'Package name',
  'package.reason': 'Reason for change',
  'package.record': 'Changed at',
  'package.implement': 'Started from',
  'package.created': 'Created at',
  'package.rate': 'Product Order Fee',
  'package.fee': 'Fixed Order Fee',
  'package.set': 'Initial fee',
  'package.fixed_fee': 'Fixed service charge',
  'package.ActivityServiceFee': 'Campaign Order Fee',
  'package.service.year': 'Tech support fees',
  'package.service.year_service_fee_by_order_num': 'Order amount required for tech support fees rebate',
  'package.service.month_service_fee_by_order_num': 'Orders required monthly',
  'package.service.money_standard_activity_order_num': 'Number of qualified orders in monthly promotions',
  'package.service.contract_date': 'Signing period(month)',
  'package.service.contract_term_standard_order_num': 'Number of qualified orders during the signing period',
  'package.service.contract_term_standard_activity_order_num': 'Period Order Amount',
  'package.service.day_of_fee_reduction': 'Fixed product service fees for trial period',
  'package.service.prestore_money': 'Deposit amount',
  'package.service.goods_count': 'Product amount',
  'package.service.coupon_count': 'Coupon ticket amount',
  'package.service.fee_reduction_fixed_fee': 'Fixed order service fees for trial period',
  'package.service.fee_reduction_activity_service_rate': 'Fixed promotion service fees for trial period',
  'package.is_own_payment': 'Independent settlement',
  'package.is_langs': 'Multi-language support',
  'package.is_have_h5': 'Shopfront',
  'package.is_have_domain': 'BYO domain',
  'package.is_have_seat_qrcode': 'Enable QR code ordering (Table Stands)',
  'package.month.fix': 'Fixed monthly fees',
  'package.month': 'Monthly fees',
  'package.days': 'Trial period (Months)',
  'package.option': 'Operation',
  'package.open': 'Current package',
  'package.subscribe': 'Package subscribed',
  'package.hasOpen': 'Package provisioned',
  'package.toOpen': 'Package to be provisioned',
  'package.service.status': 'Service fee status',
  'package.month.status': 'Monthly fee status',
  'package.sub.service': 'Service fee subscribed',
  'package.sub.month': 'Monthly fee subscribed',
  'package.system.recommendation': ' Eatmoon system recommendation',
  "package.slogan": "Don't hesitate, Eatmoon is the best choice.",
  "package.join.eatmoon": "Join Eatmoon to completely change your marketing mode, try it for free, feel free to contact the system.",

  'plugin.name': 'Plugin name',
  'plugin.client': 'Plugin client',
  'plugin.route': 'Plugin route',
  'plugin.price': 'Monthly fees($)',
  'plugin.sort': 'Order',
  'plugin.sub': 'Subscribed',
  'plugin.hasSub': 'Subscribed',
  'plugin.toSub': 'To be subscribed',
  'plugin.status': 'status',

  // 'login.sitname':'Merchant login',
  // 'login.success':'Login successful',
  // 'login.manaNo':'Administrator number',
  // 'login.btn.login':'Sign in',
  // 'login.userlogin':'Store administrator login',
  // 'login.passlogin':'Account password login',

  'summit.success': 'submitted',
  'delete.success': 'Deleted',
  'file.success': 'Archiving succeeded ',
  'update.success': 'Updated',
  'recover.success': 'Recover',
  'common.table.id': 'Id',

  'file.type.placehorder': 'Support file type: JPG/PNG.',
  'file.size.placehorder': 'Image size is limited to 1MB.',
  'file.btn.name': 'Upload',

  'system.title': 'Business portal',
  'system.loginout': 'Logout',
  'system.is_audit': 'Not approved',

  'announcement.placeholder': 'Do not use large sorting values.',
  'lang.list.name': 'language name',
  'lang.list.symbol': 'Language abbr.',
  'lang.list.isUse': 'Enabled',
  'lang.list.isDefault': 'Set as default',
  'lang.list.isH5Default': 'Set as default for shopfront',
  'lang.list.isManageDefault': 'Set as default for business portal',
  'lang.list.H5Default': 'Default for shopfront',
  'lang.list.ManageDefault': 'Default for business portal',
  'lang.list.option': 'Operation',
  'lang.list.change': 'Default language',
  'lang.list.yes': 'Yes',
  'lang.list.no': 'No',
  'lang.list.use': 'Enable language',
  'lang.list.notUse': 'Disable',

  'subscription.price': 'Price($)',
  'subscription.status': 'Status',
  'subscription.type': 'Type',
  'subscription.serviceType': 'Service type',
  'subscription.startTime': 'Service start time',
  'subscription.endTime': 'Service end time',
  'subscription.expire': 'Order expired',
  'subscription.option': 'Operation',
  'subscription.status.zero': 'Expired or terminated',
  'subscription.status.one': 'In service',
  'subscription.serviceType.s': 'Service provision',
  'subscription.serviceType.y': 'Annual fees',
  'subscription.serviceType.m': 'Monthly fees',
  'subscription.see': 'View',
  'subscription.expire.one': 'Expired',
  'subscription.expire.zero': 'Valid',
  'subscription.info.package': 'Service package',
  'subscription.info.addon': 'Plugin',
  'subscription.info.base': 'Basic info',
  'subscription.crateTime': 'Created at',
  'subscription.payBtn': 'Renew',

  'orders.price': 'Price($)',
  'orders.count': 'Quantity',
  'orders.real_price': 'Actual paid',
  'orders.use_money': 'Balance',
  'orders.status': 'Order status',
  'orders.pay_type': 'Payment method',
  'orders.is_trial': 'Trial order',
  'orders.is_expire': 'Order expired',
  'orders.status.zero': 'Unpaid',
  'orders.status.one': 'Paid',
  'orders.status.two': 'Refunded',
  'orders.status.three': 'Terminate',
  'orders.status.four': 'Completed',
  'orders.is_trial.zero': 'Normal',
  'orders.is_trial.one': 'Trial',
  'orders.payTime': 'Payment time',
  'orders.subsrc': 'Subscription info',
  'modal.title': 'Subscription type',
  'modal.title.selectNo': 'Trial',
  'modal.title.selectYes': 'Normal',

  'data.order': 'Order totals',
  'data.order.total': 'Total orders',
  'data.order.year': 'Annual orders',
  'data.order.quarter': 'Quarterly orders',
  'data.order.month': 'Monthly orders',
  'data.order.today': 'Orders today',


  'data.coupon': 'Coupon totals',
  'data.coupon.total': 'Total coupon',
  'data.coupon.year': 'Annual coupon',
  'data.coupon.quarter': 'Quarterly coupon',
  'data.coupon.month': 'Monthly coupon',
  'data.coupon.today': 'Coupon today',

  'data.deal': 'Deal totals',
  'data.deal.total': 'Total deal',
  'data.deal.year': 'Annual deal',
  'data.deal.quarter': 'Quarterly deal',
  'data.deal.month': 'Monthly deal',
  'data.deal.today': 'Deal today',

  'data.spu': 'Total products',
  'data.spu.total': 'Total products',
  'data.spu.year': 'Anual products',
  'data.spu.quarter': 'Quarterly products',
  'data.spu.month': 'Monthly products',
  'data.spu.today': 'Products today',
  'behavior.Dining.mode': "Dining method",
  'behavior.Online.payment.order': "Min. amount for online orders",
  'behavior.Order.amount.setting': "Order amount settings",


  //帮助文档
  'helpdoc':'Help Documents',


};