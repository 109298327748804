import React from 'react'
import 'braft-editor/dist/index.css'
import { withRouter } from 'react-router-dom'
import BraftEditor from 'braft-editor'
import { Form, Input, Button, Switch,Popconfirm } from 'antd';
import intl from 'react-intl-universal';
import "../shopSetup/setup.less"
import { AddAnnouncement } from "../../action/AdAction";
import { getLangType } from "../../util/function";

class NewAnnouncement extends React.Component {

    state = {
        value: "",
        sort: 0,
        is_show: true,
        editorState: BraftEditor.createEditorState(null)
    };


    SubmitData(data) {

        AddAnnouncement(data).then(res => {
            this.props.history.push("/AnnouncementList")
        })
    };

    handleChange = (editorState) => {
        this.setState({ editorState })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.content = this.state.editorState.toHTML()
                values.is_show = this.state.is_show ? "1" : "0"
                this.SubmitData(values)
            }
        });
    };


    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };
        const { is_show } = this.state;

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label={intl.get('announcementList.table.title')}
                >
                    {getFieldDecorator('title', {
                        rules: [{ required: true, whitespace: true }],
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={<span>{intl.get('announcementList.table.sort')}</span>}
                >
                    {getFieldDecorator('sort', {
                        initialValue: "0"
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('category.list.isShow')}
                >
                    <Switch checked={is_show} onChange={() => {
                        this.setState({
                            is_show: !is_show
                        })
                    }} />
                </Form.Item>


                <Form.Item
                    label={intl.get('ad.form.content')}
                >
                    <div style={{ border: "solid 0.5px rgba(0, 0, 0, 0.2)" }}>
                        <BraftEditor
                            value={this.state.editorState}
                            language={Number(getLangType()) == 1 ? "zh" : "en"}
                            onChange={this.handleChange} />
                    </div>
                </Form.Item>



                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm 
                title={intl.get('seat.table.btn.cancel.placeHorder')} 
                onConfirm={() => {
                        this.props.history.goBack()
                }}>
                    <Button
                        type="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                            this.props.history.goBack()
                        }}
                    >
                        {intl.get('seat.table.btn.cancel')}
                    </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>

        );
    }
}

export default withRouter(Form.create()(NewAnnouncement))
