import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Button, Modal, Radio } from 'antd';
import { QuerySubscrption, submitPayAgain } from "../../action/authAction";
import intl from 'react-intl-universal';

// const serviceStatus = {
//     'setup': intl.get('subscription.serviceType.s'),
//     'year':intl.get('subscription.serviceType.y'),
//     'month': intl.get('subscription.serviceType.m'),
// }

class MySubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            is_trial: 0,
            visible: false,
            row: null
        };

        this.columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('subscription.price'),
                dataIndex: 'price',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get('subscription.status'),
                dataIndex: 'status',
                width: '7%',
                align: "center",
                render: text => <span>{text === 0 ? intl.get('subscription.status.zero') : text === 1 ? intl.get('subscription.status.one') : ''}</span>
            },
            {
                title: intl.get('subscription.type'),
                dataIndex: 'prod_type',
                width: '6%',
                align: "center",
            },
            {
                title: intl.get('subscription.serviceType'),
                dataIndex: 'service_type',
                width: '7%',
                render: (text, record) => {
                    return text === 'setup' ? intl.get('subscription.serviceType.s') :
                        text === 'year' ? intl.get('subscription.serviceType.y') :
                            text === 'month' ? intl.get('subscription.serviceType.m') : ''
                }
            },
            {
                title: intl.get('subscription.startTime'),
                dataIndex: 'service_start_at',
                width: '7%',
                align: "center",
            }, {
                title: intl.get('subscription.endTime'),
                dataIndex: 'service_end_at',
                width: '7%',
                align: "center",
            },
            {
                title: intl.get('subscription.expire'),
                dataIndex: 'is_expire',
                width: '8%',
                align: "center",
                render: (text, record) => {
                    return (
                        <span style={{ color: record.is_expire === 1 ? "green" : "#ff0000" }}>{record.is_expire === 1 ? intl.get('subscription.expire.one') : intl.get('subscription.expire.zero')}</span>
                    )
                }
            },
            {
                title: intl.get('subscription.option'),
                width: '18%',
                align: "center",
                render: (text, record) => {
                    return (
                        <><Button type="primary" style={{ marginRight: 15 }} onClick={this.payOrderHandle.bind(this, record)}>{intl.get('subscription.payBtn')}</Button>
                            <Button onClick={() => {
                                this.props.history.push("/MySubscriptionDetail?" + record.id)
                            }}>{intl.get('subscription.see')}</Button></>
                    )
                }
            },
        ];
    }

    async componentDidMount() {
        QuerySubscrption().then(data => {
            if (data && data.data) {
                this.setState({
                    data: data.data.data
                })
            }
        })
    }

    payOrderHandle = (record) => {
        this.setState({
            row: record,
            visible: true
        })
    }

    onChange = e => {
        this.setState({
            is_trial: e.target.value,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            row: null
        })
    }

    handleOk = async () => {
        const { is_trial, row } = this.state;
        setTimeout(() => {
            this.setState({
                visible: false,
                row: null,
            })
        }, 0)
        submitPayAgain(row.id, is_trial).then(res => {
            if (res) {
                this.props.history.push("/myOrderDetail?" + res.data.id)
            }
        })
    }


    render() {

        return (
            <div className="waperBox">
                <Table
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                />
                <Modal title={intl.get('modal.title')} visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Radio.Group onChange={this.onChange} value={this.state.is_trial}>
                        <Radio value={1}>{intl.get('modal.title.selectNo')}</Radio>
                        <Radio value={0}>{intl.get('modal.title.selectYes')}</Radio>
                    </Radio.Group>
                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(MySubscription))

