import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Switch, Popconfirm } from 'antd';
import { UpdateShopMessage, getPartner } from "../../action/authAction";
import intl from 'react-intl-universal';

class DiscountSet extends React.Component {

    state = {
        is_pack_charge: 0
    };

    async componentDidMount() {
        let res = await getPartner()
        let data = res.data
        this.setState({
            userData: data,
            is_pack_charge: data.is_pack_charge,
        })
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.is_pack_charge = this.state.is_pack_charge.toString()

                UpdateShopMessage(values).then(res => {

                })
            }

        });
    };

    onChangePack = (checked) => {
        if (checked === 0) {
            this.setState({ is_pack_charge: 1 })
        } else {
            this.setState({ is_pack_charge: 0 })
        }
    }

    render() {
        const { is_pack_charge, userData } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('discount.form.free')}
                >
                    {getFieldDecorator('full_delivery_free', {
                        rules: [{ required: false }],
                        initialValue: userData && userData.full_delivery_free
                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={intl.get('discount.form.othermoney')}
                >
                    <Switch checked={is_pack_charge === 1} onChange={() => this.onChangePack(is_pack_charge)} />
                </Form.Item>


                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                                this.props.history.goBack()
                            }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(DiscountSet))
