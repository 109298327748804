import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon } from 'antd';
import { getPartner, UpdateShopMessage } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class CurrencyPlug extends React.Component {

    state = {
        is_open_printer: false,
        is_printer: false,
        is_open_up_live: false,
        is_up_live: false,
    };

    async componentDidMount() {
        let res = await getPartner()
        let data = res.data
        this.setState({
            is_open_printer: data.is_open_printer == 1 ? true : false,
            is_printer: data.is_printer == 1 ? true : false,
            is_open_up_live: data.is_open_up_live == 1 ? true : false,
            is_up_live: data.is_up_live == 1 ? true : false,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { is_printer, is_up_live } = this.state
        let data = {}
        data.is_printer = is_printer ? 1 : "0"
        data.is_up_live = is_up_live ? 1 : "0"
        UpdateShopMessage(data)
    };

    render() {
        const { is_open_printer, is_printer, is_open_up_live, is_up_live } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('nav.setting.openPrinter')}</div>
                    <Form.Item label={<span>{intl.get('nav.setting.openPrinter')}</span>}>
                        <Switch
                            disabled={!is_open_printer}
                            checked={is_printer}
                            onChange={() => {
                                this.setState({
                                    is_printer: !is_printer
                                })
                            }} />
                    </Form.Item>
                </Card>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('nav.plugin.third_party_video_platform')}</div>
                    <Form.Item label={<span>{intl.get('nav.plugin.third_party_video_platform')}</span>}>
                        <Switch
                            disabled={!is_open_up_live}
                            checked={is_up_live}
                            onChange={() => {
                                this.setState({
                                    is_up_live: !is_up_live
                                })
                            }} />
                    </Form.Item>
                </Card>


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(CurrencyPlug))
