import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch, Popover, Tooltip } from 'antd';
import { getPartnerPhotoList, deletePartnerPhoto, UpdatePartnerPhoto } from "../../action/AdAction";
import intl from 'react-intl-universal';

class AlbumList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '10%',
                align: "center",
            },

            {
                title: '图片',
                dataIndex: 'image',
                width: '20%',
                align: "center",
                render: (record) => {
                    return (
                        <img src={record} style={{ height: "80px" }} alt="" />
                    )
                }
            },

            {
                title: intl.get("seat.table.describe"),
                dataIndex: 'describe',
                width: '20%',
                align: "center",
            },

            {
                title: intl.get('category.list.isShow'),
                width: '10%',
                align: "center",
                render: (record) => {
                    return (
                        <Switch checked={record.is_show === 1} onChange={() => this.onChange(record)} />
                    )
                }
            },

            {
                title: intl.get('announcementList.table.sort'),
                dataIndex: 'sort',
                width: '10%',
                align: "center",
            },

            {
                title: intl.get('pack.form.opt'),
                width: '5%',
                align: "center",
                render: (record) => {
                    return <Popover
                        content={
                            <div style={{ display: "flex" }}>
                                <Tooltip title={intl.get('seat.table.btn.edit')}>
                                    <Button
                                        style={{ marginRight: "5px" }}
                                        shape="circle"
                                        icon="edit"
                                        onClick={() => {
                                            this.props.history.push("/EditAlbum/" + record.id)
                                        }} />
                                </Tooltip>
                                <Popconfirm
                                    title={intl.get('home.table.operation.btn.horder')}
                                    onConfirm={() => this.handleDelete(record)}
                                >
                                    <Tooltip title={intl.get('home.table.operation.btn.delete')}>
                                        <Button
                                            style={{ margin: "0px 5px" }}
                                            shape="circle"
                                            icon="delete"
                                        />
                                    </Tooltip>
                                </Popconfirm>
                            </div>
                        }
                        trigger="hover"
                    >
                        <Button
                            style={{ marginRight: "5px" }}
                            shape="circle"
                            icon="more"
                        />
                    </Popover>
                },
            },

        ];
    }
    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getPartnerPhotoList()
        this.setState({ data: res.data.data })
    }


    async handleDelete(data) {
        await deletePartnerPhoto(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }


    onChange = (item) => {
        let data = { "is_show": item.is_show === 1 ? "0" : "1" }
        UpdatePartnerPhoto(data, item.id).then(res => {
            this.HandleGetData()
        }).catch(error => {
            console.log(error.response)
        })
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.props.history.push("/NewAlbum")}
                    >
                        新建
                    </Button>
                </div>
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>


        );
    }
}

export default withRouter(Form.create()(AlbumList))

