import React from 'react'
import { withRouter } from 'react-router-dom'
import intl from 'react-intl-universal';
import { Form} from 'antd';
import { GetWithdrawDetail } from "../../action/authAction";


class WithdrawLogDetail extends React.Component {

    state = {
        data: "",
        disposeType: "",
        imageUrl: "",
        goodImage: "",
        dispose: [{
            id: 1,
            name: intl.get('cashwithdrawal.form.ok')
        }, {
            id: 3,
            name: intl.get('cashwithdrawal.form.no')
        }]
    };


    async componentDidMount() {
        const id = this.props.location.pathname.substring(19)
        let res = await GetWithdrawDetail(id)
        this.setState({
            data: res.data,
        })
    }


   

    handleChooseType = (e) => {
        this.setState({
            disposeType: e
        })
    }
    render() {
      
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
       


        const { money, is_finish, name, bank_name, bank_number, abn, bsb, dispose_message, ticket_image,order_ids } = this.state.data;
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">
                <Form.Item
                    label={intl.get('withdrawLog.table.name')}
                >
                    <span style={{ marginLeft: "10px" }}>{name}</span>
                </Form.Item>


                <Form.Item
                    label={intl.get('cashwithdrawal.form.bank')}
                >
                    <span style={{ marginLeft: "10px" }}>{bank_name}</span>
                </Form.Item>


                <Form.Item label={intl.get('cashwithdrawal.form.num')}>
                    <span style={{ marginLeft: "10px" }}>{bank_number}</span>
                </Form.Item>


                <Form.Item
                    label={<span>{intl.get('setup.form.abn')}</span>}
                >
                    <span style={{ marginLeft: "10px" }}>{abn}</span>
                </Form.Item>


                <Form.Item
                    label={<span>{intl.get('cashwithdrawal.form.bsb')}</span>}
                >
                    <span style={{ marginLeft: "10px" }}>{bsb}</span>
                </Form.Item>

                <Form.Item
                    label={intl.get('withdrawLog.table.money')}
                >
                    <span style={{ marginLeft: "10px" }}>{money}</span>
                </Form.Item>
                <Form.Item
                    label={intl.get('cashwithdrawal.form.orderNo')}
                >
                    <div style={{ maxWidth: "800px" }}>
                        <span style={{ marginLeft: "10px", wordBreak: "break-word" }}>{order_ids}</span>
                    </div>
                </Form.Item>
              
                <Form.Item
                    label={<span>{intl.get('cashwithdrawal.form.handle')}</span>}
                >
                    <span style={{ marginLeft: "10px" }}>{dispose_message}</span>
                </Form.Item>
                <Form.Item
                    label={<span>{intl.get('withdrawLog.table.result')}</span>}
                >
                    <span style={{ marginLeft: "10px" }}>
                        {is_finish === 1 ? intl.get('withdrawLog.table.result.ycl') :
                            <span>{is_finish === 3 ? intl.get('withdrawLog.table.result.juj')  : intl.get('withdrawLog.table.result.check')}</span>
                        }
                    </span>
                </Form.Item>
                <Form.Item
                    label={<span>{intl.get('cashwithdrawal.form.img')}</span>}
                >
                    {
                        ticket_image && <img src={ticket_image} alt="avatar" style={{ width: '100%' }} />
                    }

                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(WithdrawLogDetail))
