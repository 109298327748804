import React, { useRef } from "react";
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom'
import JsBarcode from 'jsbarcode';
import { Table, Form, Button, Modal } from 'antd';
import { getUserCouponList, checkCoupon } from "../../action/goodAction";
import SimpleBarcode from "./barcodeComponent"
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")
class UserCoupon extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [], page: 1, BarcodeVisible: false, barcode: "" };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '2%',
                align: "center",
            },
            {
                title: intl.get("nav.goodsManagement.promotionalgoods_name"),
                width: '6%',
                align: "center",
                render: (record) => {
                    return <span>{record.coupon.name}</span>
                }
            },
            // {
            //     title: intl.get('nav.goodsManagement.promotionalgoods.discount_on_fixed'),
            //     dataIndex: "coupon",
            //     width: '4%',
            //     align: "center",
            //     render: (record) => {
            //         let discount_on_fixed
            //         if (record.discount_type === "fixed") {
            //             discount_on_fixed = currency_symbol + record.discount_on_fixed
            //         } else {
            //             discount_on_fixed = (parseFloat(record.discount_on_percentage) * 100).toFixed(0) + "%"
            //         }
            //         return discount_on_fixed
            //     }
            // },


            // {
            //     title: intl.get('nav.goodsManagement.promotionalgoods.coupon_count'),
            //     width: '5%',
            //     render: (record) => {
            //         return <div style={{ display: "flex", flexDirection: "column" }}>
            //             <span style={{ marginBottom: "5px" }}>{intl.get("nav.goodsManagement.promotionalgoods.coupon_receive_count")}:{record.coupon.sale_quantity}</span>
            //             <span style={{ marginBottom: "5px" }}>{intl.get('nav.goodsManagement.promotionalgoods.coupon_use_count')}:{record.coupon.use_quantity}</span>
            //         </div>
            //     }
            // },

            {
                title: intl.get("nav.goodsManagement.coupon_user"),
                width: '8%',
                // align: "center",
                render: (record) => {
                    // console.log(record, "12121212")
                    let phone = record.user.phone
                    let email = record.user.email
                    let phoneLength = phone.length
                    let emailLength = email.length
                    let s = "***"

                    if (record.status !== "used") {
                        let phoneComponent = ""
                        let emailComponent = ""
                        if (phoneLength && phoneLength > 0) {
                            let left3 = phone.substring(0, 3)
                            let right3 = phone.substring(phoneLength - 3, phoneLength)
                            phoneComponent = <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ margin: "0px" }}>{left3}</p>
                                <span style={{ fontSize: "18px", fontWeight: "700", marginTop: "6px" }}>{s}</span>
                                <p style={{ margin: "0px" }}>{right3}</p>
                            </div>
                        }

                        if (emailLength && emailLength > 0) {
                            let left3 = email.substring(0, 3)
                            let num = email.indexOf("@")
                            let right3 = email.substr(num, phoneLength)
                            emailComponent = <div style={{ display: "flex", alignItems: "center" }}>
                                <p style={{ margin: "0px" }}>{left3}</p>
                                <span style={{ fontSize: "18px", fontWeight: "700", marginTop: "6px" }}>{s}</span>
                                <p style={{ margin: "0px" }}>{right3}</p>
                            </div>
                        }

                        return <div style={{ display: "flex", flexDirection: "column" }}>
                            <span>{phoneComponent}</span>
                            <span>{emailComponent}</span>
                        </div>

                    } else {
                        return <div style={{ display: "flex", flexDirection: "column" }}>
                            <span>{phone}</span>
                            <span>{email}</span>
                        </div>
                    }

                }
            },
            {
                title: intl.get("nav.goodsManagement.coupon_ticket_unique_code"),
                width: '6%',
                align: "center",
                render: (record) => {
                    return <span>{record.unique_code.slice(-16)}</span>
                }
            },
            {
                title: intl.get("nav.goodsManagement.coupon_date"),
                width: '8%',
                align: "center",
                render: (record) => {
                    return <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{record.created_at && record.created_at.date.substring(0, 19)}</span>
                        {
                            record.created_at &&
                            <span>~</span>
                        }

                        <span>{record.created_at && record.created_at.date.substring(0, 19)}</span>
                    </div>
                }
            },
            // {
            //     title: intl.get("nav.goodsManagement.coupon_use_date"),
            //     dataIndex: "use_at",
            //     width: '8%',
            //     align: "center",
            //     render: (record) => {
            //         return record && record.date.substring(0, 19)
            //     }
            // },
            {
                title: intl.get('subscription.status'),
                dataIndex: "status",
                width: '3%',
                align: "center",
                render: (record) => {
                    let order_status = ""
                    switch (record) {
                        case "used":
                            order_status = <span style={{ color: "green" }}>{intl.get("home.table.useStatus.name_y")}</span>
                            break;
                        case "unused":
                            order_status = intl.get("home.table.useStatus.name_n")
                            break;
                        case "expired":
                            order_status = <span style={{ color: "#ff0000" }}>{intl.get("home.table.useStatus.canceled")}</span>
                            break;
                        case "disabled":
                            order_status = <span style={{ color: "#ff0000" }}>{intl.get("home.table.useStatus.disabled")}</span>
                            break;
                    }
                    return order_status

                }
            },
            {
                title: intl.get("nav.goodsManagement.coupon_barcode"),
                // dataIndex: "bar_code",
                width: '4%',
                align: "center",
                render: (record) => {
                    return record.status === "used" &&
                        <div
                            style={{ cursor: "pointer", width: "100px" }}
                            onClick={() => this.setState({
                                BarcodeVisible: true,
                                barcode: record.bar_code
                            })}
                        >
                            <SimpleBarcode
                                height={40}
                                width={0.5}
                                label={record.bar_code}
                            />
                        </div>
                }
            },
            {
                title: intl.get('pack.form.opt'),
                width: '3%',
                align: "center",
                render: (record) => {
                    // let qrcode = record.qrcode
                    // let id = qrcode.substring(qrcode.indexOf("=id") + 3, qrcode.indexOf("type"))
                    let use_at = record.use_at
                    let status = record.status
                    return !use_at && status === "unused" && <Button
                        onClick={() => {
                            checkCoupon({ "user_coupon_id": record.id }).then(res => {
                                this.HandleGetData(1)
                            })
                        }}
                        shape="circle"
                        icon="check-circle"
                    />
                },
            },
        ];
    }


    componentDidMount() {
        let search = this.props.location.search
        if (search) {
            this.columns.splice(8, 1)
        }

        this.HandleGetData(1)
    }

    async HandleGetData(page) {
        const id = this.props.location.pathname.substring(12)
        let res = await getUserCouponList(page, "?coupon_id=" + id)
        let data = res.data.data
        this.setState({ data, meta: res.data.meta.pagination })
    }

    createBarcode = () => {
        if (!this.barcode) return;
        const {
            width = 1, height = 35, margin = 0, displayValue = true,
        } = this.props;
        JsBarcode(this.barcode, "21345234", {
            displayValue,
            width,
            height,
            margin,
        });
    }

    render() {
        let { meta, data, BarcodeVisible, barcode } = this.state
        return (
            <div>
                <Table
                    rowKey={record => record.id}
                    dataSource={data}
                    columns={this.columns}
                    // scroll={{ x: 1000 }}
                    pagination={{
                        pageSize: meta && meta.per_page,
                        total: meta && meta.total
                    }}
                    onChange={(e) => {
                        this.HandleGetData(e.current)
                    }}
                    className="waperBox"
                />
                <Modal
                    centered
                    visible={BarcodeVisible}
                    footer={null}
                    closable={false}
                    onCancel={() => this.setState({
                        BarcodeVisible: false,
                        barcode: ""
                    })}
                >
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <SimpleBarcode
                            height={80}
                            width={2}
                            label={barcode}
                        />
                    </div>

                </Modal>
            </div>
        );
    }
}

export default withRouter(Form.create()(UserCoupon))

