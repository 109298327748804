import React, { Component } from 'react'
import { Row, Col } from 'antd'
import intl from 'react-intl-universal';
import NavLeft from './page/navLeft/index';
import Header from './page/header/index';
import EN from './locales/en_US';
import CH from './locales/zh_CN';
import './style/common.less'
import { getTokenStorage, removeToken, getLangType } from "./util/function";

const locales = {
  2: EN,
  1: CH
}
class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initDone: false
    }
  }



  componentDidMount() {
    let token = getTokenStorage()
    if (token && token !== "null" && getLangType() && Number(getLangType()) > 0) {
      this.loadLocales();
    } else {
      removeToken()
      window.location.href = '/'
    }

  }

  loadLocales = () => {
    intl.init({
      currentLocale: getLangType(),
      locales
    }).then(() => {
      this.setState({
        initDone: true
      })
    })
  }


  render() {
    const { initDone } = this.state;
    return (
      initDone && <Row className="container">
        <Col span={4} className="nav-left">
          <NavLeft />
        </Col>
        <Col span={20} className="main" >
          <Header />
          <Row className="content">{this.props.children}</Row>
        </Col>
      </Row >
    )
  }
}

export default Admin
