import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button, Switch, Tooltip } from 'antd';
import { getSystemAnnouncementList } from "../../action/AdAction";
import intl from 'react-intl-universal';

class SystemAnnouncement extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '15%',
                align: "center",
            },
            {
                title: intl.get('announcementList.table.title'),
                dataIndex: 'title',
                width: '30%',
                align: "center",
            },
            {
                title: intl.get('announcementList.table.sort'),
                dataIndex: 'sort',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('package.created'),
                dataIndex: 'created_at',
                width: '15%',
                align: "center",
                render: (text) => text.date.substring(0, text.date.length - 15)
            },
            {
                title: intl.get('withdrawLog.table.operation'),
                dataIndex: 'operation',
                width: '20%',
                align: "center",
                render: (text, record) => {
                    return (
                        <Tooltip title={intl.get('home.table.operation.btn.view')}>
                            <Button
                                shape="circle"
                                icon="eye"
                                onClick={() => {
                                    this.props.history.push("/EditSystemAnnouncement/" + record.id)
                                }}
                            />
                        </Tooltip>
                    );
                },
            },

        ];
    }
    componentDidMount() {
        this.HandleGetData()
    }

    async HandleGetData() {
        let res = await getSystemAnnouncementList()
        this.setState({
            data: res.data.data,
        })
    }

    render() {
        return (
            <div>
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>


        );
    }
}

export default withRouter(Form.create()(SystemAnnouncement))

