import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Popconfirm } from 'antd';
import { AddFreight } from "../../action/authAction";
import intl from 'react-intl-universal';

class NewFreight extends React.Component {

    state = {
        loading: false,
        logo_file: "",
        imageUrl: "",
        IsShow: true
    };



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                AddFreight(values).then(res => {
                    this.props.history.push("/freightList")
                })
            }
        });
    };



    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };


        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} className="FormView">

                <Form.Item
                    label={intl.get('nav.CostFlow.name')}
                >
                    {getFieldDecorator('name', {
                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('orders.price')}
                >
                    {getFieldDecorator('price', {
                    })(<Input prefix="$" />)}
                </Form.Item>
                <Form.Item
                    label={intl.get('goods.list.sort')}
                >
                    {getFieldDecorator('sort', {
                        initialValue: "0"
                    })(<Input />)}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Popconfirm
                        title={intl.get('seat.table.btn.cancel.placeHorder')}
                        onConfirm={() => {
                            this.props.history.goBack()
                        }}>
                        <Button
                            type="primary"
                            style={{ marginRight: "10px" }}
                        >
                            {intl.get('seat.table.btn.cancel')}
                        </Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withRouter(Form.create()(NewFreight))
