import React from "react";
import { withRouter } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons';
import { Table, Input, InputNumber, Popconfirm, Form, Button, Modal } from 'antd';
import { getHolidayBusinessList, deleteHolidayBusiness, getPartner } from "../../../action/authAction";
import intl from 'react-intl-universal';

class HolidayBusinessList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            is_holiday_business: false
        };
        this.columns = [
            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '5%',
                align: "center",
            },
            {
                title: intl.get("nav.setting.public_holidays"),
                dataIndex: ["holiday", "name"],
                width: '10%',
                align: "center",
            },
            {
                title: intl.get("nav.setting.public_holidays_date"),
                width: '10%',
                align: "center",
                render: (record) => {
                    return record.start_at && record.start_at.date.substring(0, 10)
                }
            },
            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '5%',
                align: "center",
                render: (text, record) => {
                    return <div className='deleteView'>
                        <Button style={{ marginRight: "5px" }} onClick={() => {
                            this.props.history.push("/EditHolidayBusiness/" + record.id)
                        }}>
                            {intl.get('seat.table.btn.edit')}
                        </Button>
                        <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                            <Button>
                                {intl.get('home.table.operation.btn.delete')}
                            </Button>
                        </Popconfirm>
                    </div >
                },
            },

        ];
    }

    async componentDidMount() {
        let data = await getHolidayBusinessList()
        let shopData = await getPartner()

        this.setState({
            data: data.data.data,
            is_holiday_business: shopData.data.is_holiday_business === 1
        })
    }

    async handleDelete(data) {
        await deleteHolidayBusiness(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        let { is_holiday_business } = this.state
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div>
                        {
                            is_holiday_business &&
                            <Button
                                style={{ marginRight: "10px" }}
                                type="primary"
                                onClick={() => {
                                    this.props.history.push("/businessHoliday")
                                }}>
                                {intl.get('nav.setting.public_holidays')}
                            </Button>
                        }

                        <Button
                            style={{ marginRight: "10px" }}
                            type="primary"
                            onClick={() => {
                                this.props.history.push("/selfBusinessHoliday")
                            }}>
                            {intl.get('nav.setting.self_holidays')}
                        </Button>
                    </div>
                    <div>

                        <Button type="primary" onClick={() => {
                            this.props.history.push("/NewHolidayBusiness")
                        }}>
                            <PlusOutlined /> {intl.get('admin.table.new')}
                        </Button>
                    </div>
                </div>
                <Table
                    rowKey={record => record.id}
                    dataSource={this.state.data}
                    columns={this.columns}
                    rowClassName="editable-row"
                    pagination={{
                        hideOnSinglePage: false
                    }}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(HolidayBusinessList))

