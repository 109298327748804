import React from 'react'
import { Form, Input, Card, Button } from 'antd';
import { getPartner } from "../../action/authAction";
import intl from 'react-intl-universal';
import licenceImahe from "../../img/token.png"
class UpLiveToken extends React.Component {
    state = {
        up_live_token: ""
    };
    async componentDidMount() {
        let res = await getPartner()
        let data = res.data
        this.setState({
            up_live_token: data.up_live_token,
        })
    }
    render() {
        const { up_live_token } = this.state; 
            return <Card>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ width: "300px", display: "flex", alignItems: "center" }}>
                        
                        <div style={{ marginLeft: "20px" }}>

                            <div >
                                <div style={{ fontWeight:"bold"}}>如果您需要支持，请联系:</div>
                                <div style={{ padding:"5px 0"}} ><span>邮箱：info@eatmoon.com</span></div> 
                            </div>

                            {/*
                            <div style= {{ margin:"10px 0"}} >
                                  <div  style={{ fontWeight:"bold"}}>EATMOON 公司信息:</div> 
                                 <div style={{ padding:"5px 0"}} ><span>银行：eatmoon</span></div> 
                                <div style={{ padding:"5px 0"}} ><span>ABN：8923434</span></div> 
                            </div>*/}


                        </div>
                    </div>

                </div>
            </Card>
        

    }
}

export default UpLiveToken
