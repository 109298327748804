import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon } from 'antd';
import { getPartner, UpdateShopMessage } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class GoodsPlug extends React.Component {

    state = { 
        is_member_level: false,
        is_open_member_level:false,
    };

    async componentDidMount() {
        let res = await getPartner()
        let data = res.data


        //let configData = await GetConfigSet()
        //configData.data.data.find(el => el.key === "min_pay_fee_on_online").value,  
 
        this.setState({
            is_open_member_level: data.is_open_member_level == 1 ? true : false, 
            is_member_level: data.is_member_level == 1 ? true : false,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let {  is_member_level } = this.state
        let data = {}
        
        data.is_member_level = is_member_level ? 1 : "0" 
        UpdateShopMessage(data)
    };

    render() {
        const {is_member_level,  is_open_member_level } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                { 
                    <Card style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>会员</div>
                        <Form.Item label={<span>开启会员</span>}>
                            <Switch
                                checked={is_member_level}
                                disabled ={!is_open_member_level}
                                onChange={() => {
                                    this.setState({
                                        is_member_level: !is_member_level
                                    })
                                }} />
                        </Form.Item>
                    </Card>
                }


            

                {is_open_member_level && <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item> }

            </Form >

        );
    }
}

export default withRouter(Form.create()(GoodsPlug))
