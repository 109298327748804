import React from "react";
import { withRouter } from 'react-router-dom'
import { Table, Form, Input, Button, Card, Pagination } from 'antd';
import { getDocumentList, getContent ,   GetDocumentcategory } from "../../action/AdAction";
import intl from 'react-intl-universal';
import "./index.css"
import Header from "../header/index"
let { Search } = Input

class documentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            data: "",
            meta: "",
            Height: document.body.clientHeight - 140 + "px",
            dataCategory:"", 
            id:'',

        };
    }


    async componentDidMount() {
        window.addEventListener("resize", () => {
            this.setState({
                Height: document.body.clientHeight - 140 + "px"
            })
        })

        this.GetDocumentcategoryData(1);//获取分类


        //获取的参数
         const id = this.props.location.pathname.substring(14);

         console.log("id========",id);

         if(id){
             this.setState({
                id: id, 
            })    
            this.GetDocumentData(1,id);//获取分类
         }else{
            this.GetDocumentData(1)
         }
          
        
        
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {
            this.setState({
                Height: document.body.clientHeight - 140 + "px"
            })
        })
    }

    async GetDocumentData(page,category_id = null) {
 
        let res = await getDocumentList(page,category_id)
        let data = res.data.data 
        if (data.length != 0 ) {
            for (let i in data) {
                let a = await getContent(data[i].content)
                data[i].current_content = a.data
            }
            if (data[data.length - 1].current_content) {
                this.setState({
                    data: data,
                    meta: res.data.meta.pagination
                })
            }

        }else{
             this.setState({
                data: [],
                meta: res.data.meta.pagination
            })
        }


    }



    async GetDocumentcategoryData(page) {
        let res = await GetDocumentcategory(page)
        let dataCategory = res.data.data 
        if (dataCategory.length != 0 ) { 
            this.setState({
                dataCategory: dataCategory,
                meta: res.data.meta.pagination
            })
        

        }


    }






    render() {
        const { data, meta, Height,dataCategory ,id} = this.state
        if (data) {
            return (
                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>




                        <div style={{ display: "flex", justifyContent: "flex-start", marginBottom: "10px" ,marginLeft: "10px" }}>
                            <Button
                                style={{ backgroundColor: "#fff"  , color:  "rgba(0, 0, 0, 0.65)" }}
                                onClick={() => {
                                    this.GetDocumentData(1)  
                                }}
                            >
                                {intl.get('home.table.useStatus.all')}
                            </Button>


                            {   

                                //backgroundColor: (id == item.id) ? "#58924d" :"#fff"  , color:  (id == item.id) ? "#fff" :"rgba(0, 0, 0, 0.65)" 


                               dataCategory && 
                                dataCategory.map((item, index) => {
                                    return    <Button
                                        style={{ marginLeft: "5px", backgroundColor: "#fff"  , color: "rgba(0, 0, 0, 0.65)"   }}
                                        
                                        
                                        onClick={() => {
                                             this.props.history.push("/documentList/" + item.id)
                                             this.GetDocumentData(1,item.id)
                                           }  
                                        } 
                                    >
                                        {item.title}
                                    </Button> 
                                }) 
                            }
                        </div>


 
                     
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start" }}>
                        <div className="container" style={{ maxWidth: "1260px", height: Height, overflowY: "scroll", flexFlow: "wrap", justifyContent: "center", alignContent: "flex-start" }}>

                            {
                                data.map((item, index) => {
                                    return <Card
                                        key={item.id}
                                        title={item.title}
                                        onClick={() => this.props.history.push("/documentDetail/" + item.id)} 

                                        /* extra={<Button onClick={() => this.props.history.push("/documentDetail/" + item.id)} type="link">More</Button>} */


                                        style={{ width: "400px", height: "210px", margin: "0px 10px", marginBottom: "20px",cursor: "pointer" }}>
                                        <p
                                            style={{ width: "350px", height: "102px", wordBreak: "break-word", wordBreak: "break-all", overflow: "hidden" }}
                                            dangerouslySetInnerHTML={{
                                                __html: item.current_content
                                            }}
                                        />
                                    </Card>
                                })
                            }
                            {
                                data.length % 3 === 2 &&
                                <div style={{ width: "400px", margin: "10px", }} />
                            }

                        </div>
                        <div style={{ width: "100%", maxWidth: "1260px", marginTop: "10px", marginRight: "10px", display: "flex", justifyContent: "flex-end" }}>
                            <Pagination
                                current={meta.current_page}
                                pageSize={15}
                                total={meta.total}
                                onChange={(a) => {
                                    this.setState({
                                        page: a.current
                                    }, () => this.GetDocumentData(a.current))
                                }}
                            />
                        </div>
                    </div>
                </div >

            );
        } else {
            return (<div />)
        }
    }
}

export default withRouter(Form.create()(documentList))

