import React from 'react'
import { withRouter } from 'react-router-dom'
import { Table, Popconfirm, Form, Button } from 'antd';
import { getDiscountList, deleteDiscount } from "../../action/AdAction";
import intl from 'react-intl-universal';

class DiscountList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] };
        this.columns = [

            {
                title: intl.get('common.table.id'),
                dataIndex: 'id',
                width: '10%',
                align: "center",
            },
            {
                title: intl.get('discount.form.discount'),
                dataIndex: 'discount',
                width: '10%',
                align: "center",
            }, {
                title: intl.get('discount.form.way'),
                dataIndex: 'sort',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    let serviceType = ""
                    if (record.service_type === 1) {
                        serviceType = intl.get('home.table.way.name_d')
                    } else if (record.service_type === 2) {
                        serviceType = intl.get('home.table.way.name_w')
                    }
                    else if (record.service_type === 3) {
                        serviceType = intl.get('home.table.way.name_s')
                    } else if (record.service_type === 4) {
                        serviceType = intl.get('home.table.way.name_t')
                    }
                    return (
                        <div>
                            <span>{serviceType}</span>
                        </div>
                    )
                }
            },
            {
                title: intl.get('seat.table.operation'),
                dataIndex: 'operation',
                width: '10%',
                align: "center",
                render: (text, record) => {
                    return (
                        <div className='deleteView'>
                            <Button onClick={() => {
                                this.props.history.push("/editdiscount/" + record.id)
                            }}>
                                {intl.get('seat.table.btn.edit')}
                            </Button>
                            <Popconfirm title={intl.get('home.table.operation.btn.horder')} onConfirm={() => this.handleDelete(record)}>
                                <Button>
                                    {intl.get('home.table.operation.btn.delete')}
                                </Button>
                            </Popconfirm>
                        </div>

                    );
                },
            },

        ];
    }

    async componentDidMount() {
        let res = await getDiscountList()
        this.setState({ data: res.data.data })
    }


    async handleDelete(data) {
        await deleteDiscount(data.id)
        const dataSource = [...this.state.data];
        this.setState({ data: dataSource.filter(item => item.id !== data.id) });
    }

    render() {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div />
                    <Button type="primary"
                        onClick={() => this.props.history.push("/discount")}
                    >
                        {intl.get('nav.delivery.discount.new')}
                    </Button>
                </div>
                <Table
                    rowKey={record => record.id}
                    // bordered
                    dataSource={this.state.data}
                    columns={this.columns}
                    pagination={{
                        hideOnSinglePage: true
                    }}
                    className="waperBox"
                />
            </div>
        );
    }
}

export default withRouter(Form.create()(DiscountList))

