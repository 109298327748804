import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon, InputNumber } from 'antd';
import { getPartner, UpdateShopMessage, GetConfigSet } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class Behavior extends React.Component {

    state = {
        is_on_delivery: false,
        is_on_delivery_goods: false,
        is_open_on_delivery: false,
        is_open_on_delivery_goods: false,
        is_delivery_free: false,
        is_system_delivery_free: false,
        is_full_delivery_free: false,
        is_open_full_delivery_free: false,
        full_delivery_free: "",
        is_logistics: false,
        is_open_logistics: false,
        is_send_receipt: false,
        is_weight:false,
    };

    async componentDidMount() {
        let res = await getPartner()
        let configData = await GetConfigSet()
        let data = res.data
        this.setState({
            is_on_delivery: data.is_on_delivery == 1 ? true : false,
            is_on_delivery_goods: data.is_on_delivery_goods == 1 ? true : false,
            is_open_on_delivery: data.is_open_on_delivery == 1 ? true : false, //系统配送开关
            is_open_on_delivery_goods: data.is_open_on_delivery_goods == 1 ? true : false, //系统送货开关
            is_system_delivery_free: data.is_system_delivery_free == 1 ? true : false,
            is_delivery_free: data.is_delivery_free == 1 ? true : false,
            is_full_delivery_free: data.is_full_delivery_free == 1 ? true : false,
            is_open_full_delivery_free: data.is_open_full_delivery_free == 1 ? true : false,
            is_logistics: data.is_logistics == 1 ? true : false,
            is_open_logistics: data.is_open_logistics == 1 ? true : false, //系统商家送货开关
            full_delivery_free: data.full_delivery_free,
            is_send_receipt: data.is_send_receipt == 1 ? true : false,
            is_weight: data.is_weight,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { is_on_delivery, is_on_delivery_goods,is_send_receipt, is_delivery_free, is_weight, is_full_delivery_free, is_logistics } = this.state
        let data = {}
        data.is_on_delivery = is_on_delivery ? 1 : "0"
        data.is_on_delivery_goods = is_on_delivery_goods ? 1 : "0"
        data.is_delivery_free = is_delivery_free ? 1 : "0"
        data.is_full_delivery_free = is_full_delivery_free ? 1 : "0"
        data.is_weight = is_weight ? 1 : "0"
        data.is_logistics = is_logistics ? 1 : "0"
        data.is_send_receipt = is_send_receipt ? 1 : "0"
        data.full_delivery_free = this.props.form.getFieldValue('full_delivery_free')
        UpdateShopMessage(data)
    };

    render() {
        const { is_weight,is_show_spu_many_help, is_send_receipt, is_on_delivery, is_on_delivery_goods,is_system_delivery_free, is_open_on_delivery, is_open_on_delivery_goods,is_open_full_delivery_free, is_delivery_free, is_full_delivery_free, full_delivery_free, is_logistics, is_open_logistics } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };
        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get("setup.form.delivery")}方式</div>
                    <Form.Item label={<span>{intl.get('setup.form.openDelivery')}</span>}>
                        <Switch
                            disabled={!is_open_on_delivery}
                            checked={is_on_delivery}
                            onChange={() => {
                                this.setState({
                                    is_on_delivery: !is_on_delivery,
                                    //is_on_delivery_goods: !is_on_delivery_goods
                                })
                                if (is_on_delivery) {
                                    this.setState({
                                        is_logistics: false,
                                        is_delivery_free: false,
                                        //is_on_delivery_goods:false,
                                    })
                                }
                            }} />
                    </Form.Item>


                    <Form.Item label={<span> 开启送货</span>} style={{marginLeft:"0px"}}>
                        <Switch
                            disabled={!is_open_on_delivery_goods  } 
                            disabled

                            checked={ is_on_delivery  } //is_on_delivery_goods && is_open_on_delivery_goods
                            onChange={() => {
                                this.setState({
                                    is_on_delivery_goods: !is_on_delivery_goods
                                })
                                if (is_on_delivery_goods) {
                                    this.setState({
                                        is_logistics: false,
                                        is_delivery_free: false,
                                    })
                                }
                            }} />
                    </Form.Item>






                  


                   
                     
                     

                </Card>


                { is_on_delivery && 
                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>开启运费</div>
                      
                        <Form.Item label={<span>{intl.get('setup.form.openFreight')}</span>}>
                            <Switch
                                checked={is_delivery_free}
                                disabled={!is_on_delivery || !is_logistics || !is_system_delivery_free}

                                disabled={  !is_system_delivery_free}


                                onChange={() => {
                                    if (is_delivery_free) {
                                        this.setState({
                                            is_full_delivery_free: false
                                        })
                                    }
                                    this.setState({
                                        is_delivery_free: !is_delivery_free
                                    })
                                }} />
                        </Form.Item> 
                   
                </Card>
                 }
                


                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>开启重量</div>
                       <Form.Item label={<span>开启重量</span>}>
                        <Switch
                            
                            checked={is_weight}
                            onChange={() => { 
                                this.setState({
                                    is_weight: !is_weight
                                }) 
                            }} />
                    </Form.Item>
                </Card>








                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('nav.goodsManagement.send_receipt')}</div>
                    <Form.Item label={
                        <span>
                            {intl.get('nav.goodsManagement.send_receipt')}
                            <Tooltip title={intl.get("nav.goodsManagement.send_receipt_tips")}>
                                <Icon type="question-circle" style={{ marginLeft: "5px" }} />
                            </Tooltip>
                        </span>
                    }>
                        <Switch
                            checked={is_send_receipt}
                            onChange={() => {
                                this.setState({
                                    is_send_receipt: !is_send_receipt
                                })
                            }} />
                    </Form.Item>
                </Card>


                
                 

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(Behavior))
