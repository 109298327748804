import React from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Card, Switch, Tooltip, Icon } from 'antd';
import { getPartner, UpdateShopMessage } from "../../action/authAction";
import intl from 'react-intl-universal';
let currency_symbol = sessionStorage.getItem("currency_symbol")

class GoodsPlug extends React.Component {

    state = {
        is_open_spu_many_help: false,
        is_show_spu_many_help: false,
        is_open_goods_annex: false,
        is_sku: false,
        is_open_sku: false,
        is_disable_goods_annex: false,
    };

    async componentDidMount() {
        let res = await getPartner()
        let data = res.data
        this.setState({
            is_open_goods_annex: data.is_open_goods_annex == 1 ? true : false,
            is_sku: data.is_sku == 1 ? true : false,
            is_open_sku: data.is_open_sku == 1 ? true : false,
            is_disable_goods_annex: data.is_disable_goods_annex == 1 ? true : false,
            is_open_spu_many_help: data.is_open_spu_many_help == 1 ? true : false,
            is_show_spu_many_help: data.is_show_spu_many_help == 1 ? true : false,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { is_show_spu_many_help, is_open_goods_annex, is_sku } = this.state
        let data = {}
        data.is_show_spu_many_help = is_show_spu_many_help ? 1 : "0"
        data.is_open_goods_annex = is_open_goods_annex ? 1 : "0"
        data.is_sku = is_sku ? 1 : "0"
        UpdateShopMessage(data)
    };

    render() {
        const { is_open_spu_many_help, is_show_spu_many_help, is_open_goods_annex, is_sku, is_open_sku, is_disable_goods_annex } = this.state
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 11,
                },
            },
        };

        return (

            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ width: "1000px", marginLeft: "100px" }}>
                {is_open_spu_many_help &&
                    <Card style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('nav.goodsManagement.openCutGoodsType')}</div>
                        <Form.Item label={<span>{intl.get('nav.goodsManagement.openCutGoodsType')}</span>}>
                            <Switch
                                checked={is_show_spu_many_help}
                                onChange={() => {
                                    this.setState({
                                        is_show_spu_many_help: !is_show_spu_many_help
                                    })
                                }} />
                        </Form.Item>
                    </Card>
                }


                <Card style={{ marginBottom: "10px" }}>
                    <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('nav.goodsManagement.openSpecifications')}</div>
                    <Form.Item label={<span>{intl.get('nav.goodsManagement.openSpecifications')}</span>}>
                        <Switch
                            disabled={!is_open_sku}
                            checked={is_sku}
                            onChange={() => {
                                this.setState({
                                    is_sku: !is_sku
                                })
                            }} />
                    </Form.Item>
                </Card>


                {
                    is_disable_goods_annex &&
                    <Card style={{ marginBottom: "10px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}>{intl.get('nav.goodsManagement.OpenAdditionalItems')}</div>
                        <Form.Item label={<span>{intl.get('nav.goodsManagement.OpenAdditionalItems')}</span>}>
                            <Switch
                                disabled={!is_disable_goods_annex}
                                checked={is_open_goods_annex}
                                onChange={() => {
                                    this.setState({
                                        is_open_goods_annex: !is_open_goods_annex
                                    })
                                }} />
                        </Form.Item>
                    </Card>
                }


                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        {intl.get('cashwithdrawal.form.submit')}
                    </Button>
                </Form.Item>
            </Form >

        );
    }
}

export default withRouter(Form.create()(GoodsPlug))
